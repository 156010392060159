import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { ErrorMessage } from '@hookform/error-message';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

interface TextareaFieldProps {
  debounce?: number;
  name: keyof Pick<BlockFormFields, 'instructions'>;
  label: string;
  placeholder?: string;
  isDisabled?: boolean;
}

export const TextareaField = ({ debounce = 800, name, label, placeholder, isDisabled }: TextareaFieldProps) => {
  const debounceTimer = useRef<NodeJS.Timeout>();
  const { control, formState, setValue } = useFormContext<BlockFormFields>();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel hasErrorStyle={false}>{label}</FormLabel>
          {isDisabled ? (
            <div className="border-1 flex min-h-[2.36rem] w-full whitespace-pre-wrap rounded-md border-border bg-secondary p-2 text-sm">
              {field.value}
            </div>
          ) : (
            <FormControl>
              <Textarea
                {...field}
                autoComplete="off"
                className="text-md lg:text-sm"
                maxRows={10}
                rows={4}
                value={field.value ?? ''}
                placeholder={`${placeholder}...`}
                onChange={(e) => {
                  clearTimeout(debounceTimer.current);
                  debounceTimer.current = setTimeout(() => {
                    setValue(field.name, e.target.value);
                  }, debounce);
                }}
              />
            </FormControl>
          )}
          <ErrorMessage
            errors={formState.errors}
            name={name}
            render={({ message }) => (
              <FormErrorMessage message={message} prefix="sections.studies.build.formValidation" />
            )}
          />
        </FormItem>
      )}
    />
  );
};
