import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import _ from 'lodash';
import { CircleAlert, CircleCheckBig } from 'lucide-react';
import { useState, useEffect } from 'react';
import Spinner from 'react-spinkit';

import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';
import { validations } from 'lib/validations';

function OnlineLinkVariant(props: any) {
  const [onlineTaskURL, setOnlineTaskURL] = useState('');
  const [onlineTaskID, setOnlineTaskID] = useState();
  const [onlineTaskName, setOnlineTaskName] = useState('');
  const [linkValidated, setLinkValidated] = useState(false);
  const [validatingLink, setValidatingLink] = useState(false);
  const [hadCheckedHisStudyCheckbox, setHadCheckedHisStudyCheckbox] = useState(false);
  const [linkError, setLinkError] = useState(null);
  const [fullNameError, setFullNameError] = useState(false);
  const [linkFocused, setLinkFocused] = useState('');
  const [, setVariantNameFocused] = useState('');

  useEffect(() => {
    setLinkError(null);
    if (
      _.get(props.bookingSteps, 'link_to_task') === 'error' &&
      _.size(_.get(props.booking, 'config.online_task.links')) === 0
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Required"' is not assignable to... Remove this comment to see the full error message
      setLinkError('Required');
    }
  }, [props]);

  useEffect(() => {
    if (_.get(props, 'onlineTask._id')) {
      const { _id, url, name, checked } = props.onlineTask;
      setOnlineTaskURL(url);
      setOnlineTaskID(_id);
      setOnlineTaskName(name);
      setLinkValidated(validations.validateURL(url));
      setHadCheckedHisStudyCheckbox(checked);
      if (_.has(props.bookingSteps, 'link_to_task') && !checked) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Required"' is not assignable to... Remove this comment to see the full error message
        setFullNameError('Required');
      }
    } else {
      setOnlineTaskName(props.index === 0 ? 'Primary Link' : `Variation ${props.index}`);
    }
  }, [props.onlineTask]);

  const onChangeOnlikeTaskURL = (value: any) => {
    setLinkError(null);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setFullNameError(null);
    setOnlineTaskURL(value);
    setHadCheckedHisStudyCheckbox(false);
    setLinkValidated(false);
    setValidatingLink(true);
    if (value !== '') {
      // Validate the link input by the user
      if (validations.validateURL(value)) {
        setLinkValidated(true);
      }
    }
    setValidatingLink(false);
  };

  const onUpdateOnlineTask = ({ url, checked, name }: any) => {
    const objectToSendBack = {
      _id: onlineTaskID,
      index: props.index,
      name,
      url,
      checked,
    };
    props.onOnlineTaskUpdated(objectToSendBack);
  };

  const onSetCheckedStudy = (checked: any) => {
    setHadCheckedHisStudyCheckbox(checked);
    onUpdateOnlineTask({
      url: onlineTaskURL,
      checked,
      name: onlineTaskName,
    });
  };

  const renderValidationIcon = () => {
    if (validatingLink) {
      return <Spinner fadeIn="none" name="ball-clip-rotate" color="#FF5266" />;
    }

    if (!linkValidated && linkFocused !== 'focused' && onlineTaskURL !== '') {
      return <CircleAlert className="h-4 w-4 bg-background text-destructive" />;
    }

    if (linkValidated) {
      return <CircleCheckBig className="h-4 w-4 bg-background text-success" />;
    }
  };

  const renderStep = ({ index, label }: any) => {
    return (
      <div key={`step-${index}`} className="step">
        <p className="stepTitle">Step {index}</p>
        <p className="stepLabel">{label}</p>
      </div>
    );
  };

  const renderStepGuideContainer = () => {
    let researchTool = '';
    if (bookingUtils.isSurvey(props.booking)) {
      const toolName = _.get(props.booking, 'config.online_task.tool', 'surveyMonkey');
      researchTool = utils.getLabelFromArray(bookingUtils.bookingSurveyToolOptions(), toolName, 'label');
      if (researchTool === 'other') researchTool = 'survey';
    } else if (bookingUtils.isOnlineTask(props.booking)) {
      const toolName = _.get(props.booking, 'config.online_task.tool', 'optimalWorkshop');
      researchTool = utils.getLabelFromArray(bookingUtils.bookingOnlineTaskToolOptions(), toolName, 'label');
      if (researchTool === 'other') researchTool = 'online task';
    }

    return (
      <>
        <div
          id={`__studyCheckedContainer_${props.index}`}
          style={{ display: hadCheckedHisStudyCheckbox ? 'block' : 'none' }}
        >
          <div className="flex items-center gap-2 whitespace-nowrap py-1">
            <Checkbox
              key="checkedMyStudy"
              id={`__checkedFullNameQuestion_${props.index}`}
              checked={hadCheckedHisStudyCheckbox}
              onCheckedChange={() => onSetCheckedStudy(false)}
              disabled={props.disableChangeLink}
            />
            <Label htmlFor={`__checkedFullNameQuestion_${props.index}`}>
              {`Yes, I've added a "Full Name" question to my ${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'study'}`}
            </Label>

            {props.index !== 0 && !props.disableChangeLink && (
              <a onClick={() => props.onRemoveVariant(onlineTaskID)}>Remove this variant</a>
            )}
          </div>
        </div>
        <div
          id={`__studyCheckContainer_${props.index}`}
          style={{ display: hadCheckedHisStudyCheckbox ? 'none' : 'block' }}
        >
          <p className="stepHintText">
            {`Please make sure your ${
              bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'study'
            } includes a question to capture ‘full name’. This is so you’ll know which Askable participant completed your ${
              bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'study'
            }.`}
          </p>
          <div id="__stepsContainer" className="stepsContainer">
            <p className="stepTitle">Step by step guide</p>
            {_.get(props, 'booking.config.online_task.tool') === 'optimalWorkshop' && (
              <>
                {renderStep({ index: 1, label: `Go to your ${researchTool} study` })}
                {renderStep({ index: 2, label: 'Click on the Questionaire tab' })}
                {renderStep({ index: 3, label: 'Add a Post-study question to capture full name' })}
              </>
            )}
            {_.get(props, 'booking.config.online_task.tool') !== 'optimalWorkshop' && (
              <>
                {renderStep({
                  index: 1,
                  label: `Go to your ${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'unmoderated study'}`,
                })}
                {renderStep({ index: 2, label: 'Add a question to capture Full Name' })}
              </>
            )}
          </div>
          <div id={`__stepsContainerCheckFullName_${props.index}`} className="checkboxContainer">
            <p className="confirmStep">{`Have you added a ‘Full Name’ question to your ${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'study'}?`}</p>
            <div className={`${fullNameError ? 'invalid' : ''} optionsOpened`}>
              <div className="flex items-center gap-2 whitespace-nowrap py-1">
                <Checkbox
                  key="checkedMyStudy"
                  id={`__checkFullNameQuestion_${props.index}`}
                  className="defaultCheckBoxOption"
                  checked={hadCheckedHisStudyCheckbox}
                  onCheckedChange={() => onSetCheckedStudy(true)}
                />
                <Label htmlFor={`__checkFullNameQuestion_${props.index}`}>Yes, I've done that</Label>

                {fullNameError && (
                  <div className="errorMessage">
                    <CircleAlert className="checkIcon h-4 w-4" color="#FF0000" />
                    <p id="__errorMessage">This option is required!</p>
                  </div>
                )}
                {props.index !== 0 && (
                  <a className="text-sm" onClick={() => props.onRemoveVariant(onlineTaskID)}>
                    Remove this variant
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={`onlineTaskLinkInternalContainer ${props.index !== 0 ? 'additionalLink' : ''} flex-col gap-4 divide-y`}
    >
      {props.index !== 0 && (
        <Input
          id={`__variationName_${props.index}`}
          value={onlineTaskName}
          onChange={(e) => {
            setOnlineTaskName(e.target.value);
            if (onlineTaskID) {
              onUpdateOnlineTask({
                url: onlineTaskURL,
                checked: hadCheckedHisStudyCheckbox,
                name: e.target.value,
              });
            }
          }}
          placeholder="Variation name"
          onFocus={() => setVariantNameFocused('focused')}
          onBlur={() => setVariantNameFocused('')}
        />
      )}
      <div className="relative flex w-full items-center gap-1">
        <Input
          id={`__variationLink_${props.index}`}
          value={onlineTaskURL}
          onChange={(e) => onChangeOnlikeTaskURL(e.target.value)}
          placeholder={`Paste the link to your ${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'online task'} here`}
          disabled={props.disableChangeLink}
          onFocus={() => setLinkFocused('focused')}
          onBlur={() => setLinkFocused('')}
        />
        <div className="absolute right-2">{renderValidationIcon()}</div>
      </div>

      {linkError ? <div className="flex text-xs text-destructive">{linkError}</div> : null}

      <div id="__stepGuideContainer" className={`stepGuideContainer ${linkValidated ? 'showContainer' : ''}`}>
        {renderStepGuideContainer()}
      </div>
      {!linkValidated && props.index !== 0 && (
        <div className="removeVariantContainer">
          <a onClick={() => props.onRemoveVariant(onlineTaskID)}>Remove this variant</a>
        </div>
      )}
    </div>
  );
}

export default OnlineLinkVariant;
