import { useMutation } from 'urql';

import fetchUserById from 'data/queries/user/fetchUserById';
import { useUpdateMeMutation } from 'generated/graphql';
import { graphql } from 'src/graphql';

const removeProfilePictureMutation = graphql(`
  mutation DeleteProfilePictureMutation {
    deleteProfilePicture {
      _id
      picture
    }
  }
`);

export const useProfilePicture = () => {
  const [{ fetching }, removeProfilePicture] = useMutation(removeProfilePictureMutation);
  const [updateMe, { loading }] = useUpdateMeMutation();

  return {
    addProfilePicture: async (url: string) => {
      return updateMe({
        variables: {
          user: {
            picture: url,
          },
        },
        refetchQueries: [{ query: fetchUserById }],
      });
    },
    removeProfilePicture: () => removeProfilePicture({}),
    loading: loading || fetching,
  };
};
