import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { utils } from 'lib/utils';
import { useAddUTMToAppState } from 'src/hooks/useAddUTMToAppState';

export const SignupContainer = () => {
  const { addUTMToAppState } = useAddUTMToAppState();
  const { isAuthenticated, register } = useKindeAuth();
  const isKinde = useIsKinde();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      try {
        const redirectPath = new URL(searchParams.get('redirectPath') ?? '');

        return navigate(redirectPath.pathname);
      } catch (e) {
        console.error('Error redirecting to the path', e);
      }

      navigate({ pathname: '/' }, { replace: true });
      return;
    }

    // Get extra arguments coming from the URL
    const args = utils.parseQueryParams(location?.search);
    const userType = args?.get('user_type') ?? 'client';
    const redirectPath = (() => {
      const paramRedirectPath = args?.get('redirectPath');
      if (paramRedirectPath) {
        return paramRedirectPath;
      }
      if (userType === 'researcher') {
        const redirectUri = new URL(window.location.origin);
        redirectUri.searchParams.set('user_type', 'researcher');
        return redirectUri.href;
      }
      return null;
    })();

    (async () => {
      if (isKinde) {
        await register({
          app_state: addUTMToAppState({
            user_type: userType,
            redirect_uri: redirectPath,
          }),
        });

        return;
      }

      console.error('Signup flow is not being handled correctly.');
      navigate({ pathname: '/' }, { replace: true });
    })();
  }, [isAuthenticated, location?.search]);

  return null;
};
