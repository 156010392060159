import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Textarea } from '@askable/ui/components/ui/textarea';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';

import CardContainer from '../components/cardContainer';
import PriceCardContainer from '../components/priceCardContainer';

import './styles/researchObjectiveStyles.scss';

function WelcomeEndScreen(props: any) {
  const [booking, setBooking] = useState(_.get(props, 'booking'));
  const [objective, setObjective] = useState(_.get(booking, 'config.ai_moderated.prompt.overview') || '');
  const [helpfulContext, setHelpfulContext] = useState(_.get(booking, 'config.ai_moderated.prompt.context') || '');
  const [objectiveError, setObjectiveError] = useState<boolean>(false);
  const [helpfulContextError, setHelpfulContextError] = useState<boolean>(false);

  const MAX_INPUT_LENGTH = 300;

  useEffect(() => {
    props.updateLastStep({
      step: 'AI_MODERATED',
      subStep: `/booking-setup/${props.booking._id}/ai-moderated/research-objective`,
      stepId: 'ai_moderated_research_objective',
    });
    props.renderRightContent(rightContent());
  }, []);

  useEffect(() => {
    props.validateBooking(booking, document.location.pathname, true);
  }, [booking]);

  const isError = _.get(props, 'booking.steps.ai_moderated_research_objective') === 'error';

  useEffect(() => {
    if (!isError) {
      return;
    }
    if (!objective) {
      setObjectiveError(true);
    }
    if (objective > MAX_INPUT_LENGTH) {
      setObjectiveError(true);
    }
    if (helpfulContext > MAX_INPUT_LENGTH) {
      setHelpfulContextError(true);
    }
  }, [isError]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <div className="researchObjectiveRightContentContainer">
            <span className="researchObjectiveParagraph" style={{ fontWeight: 'bold' }}>
              Need help writing your AI research prompts?
            </span>
            <span className="researchObjectiveParagraph">
              Check our help articles to understand how to write clear prompts that give the AI the information it needs
              to run your discovery research.
            </span>
          </div>
        ),
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span className="researchObjectiveParagraph">
            Understand and analyse the shopping route preferences of consumers within supermarkets, investigating the
            factors influencing their aisle choice and how they navigate the store.
          </span>
        ),
      },
    ];
    const cardTexts3 = [
      {
        cardText: (
          <span className="researchObjectiveParagraph">
            Analyze what influences individuals' transport preferences when commuting to and from work. This study aims
            to investigate factors such as geographic location, access to public transportation, environmental
            considerations, personal preferences, and time constraints.
          </span>
        ),
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer booking={booking} team={_.get(props, 'team')} context={props.context} condensedCard />
        <p className="cardContainerTitle" style={{ marginTop: 18 }}>
          Help articles
        </p>
        <CardContainer
          cardId="__descriptionCard1"
          cardClass="slideInAnimationDelay80"
          cardTexts={cardTexts1}
          onLinkClick={() => console.log('gam')}
        />
        <p className="cardContainerTitle">Examples of research objectives</p>
        <CardContainer
          additionalCard
          cardId="__descriptionCard2"
          cardClass="slideInAnimationDelay160"
          cardTexts={cardTexts2}
        />
        <CardContainer
          additionalCard
          cardId="__descriptionCard3"
          cardClass="slideInAnimationDelay240"
          cardTexts={cardTexts3}
        />
      </>
    );
  };

  const onClickNext = () => {
    props.validateBooking(booking, document.location.pathname, true);
    const researchObjectiveState = {
      config: {
        ai_moderated: {
          ...(booking.config.ai_moderated ?? { information: { welcome_body: '', welcome_title: '' } }),
          prompt: {
            overview: objective,
            context: helpfulContext,
          },
        },
      },
    };

    const redirectTo = `/booking-setup/${booking._id}/ai-moderated/welcome-end-screen`;
    props.history.push({ pathname: redirectTo, booking: researchObjectiveState, bookingState: booking });
  };

  const onChangeValue = (field: any, value: any) => {
    if (field === 'objective') {
      setObjective(value);
    } else if (field === 'helpful_context') {
      setHelpfulContext(value);
    }

    const bookingObj = {
      ...booking,
      config: {
        ...booking.config,
        ai_moderated: {
          ...booking.config.ai_moderated,
          prompt: {
            ...booking.config.ai_moderated?.prompt,
            overview: field === 'objective' ? value : objective,
            context: field === 'helpful_context' ? value : helpfulContext,
          },
        },
      },
    };
    setBooking(bookingObj);
  };

  return (
    <div className="researchObjectiveContainer">
      <h3 className="researchObjectiveTitle">Research objective</h3>

      <div className=" flex max-w-lg flex-col gap-2">
        <Label htmlFor="__researchobjective" className="leading-normal">
          What are you trying to understand or learn from your audience? Describe your goal in a few sentences that will
          guide the interview conversations.
        </Label>
        <Textarea
          id="__researchobjective"
          placeholder="Provide research context..."
          value={objective}
          rows={4}
          maxRows={6}
          onChange={(e) => {
            setObjectiveError(e.target.value.length === 0 || e.target.value.length > MAX_INPUT_LENGTH);
            onChangeValue('objective', e.target.value);
          }}
          variant={objectiveError ? 'error' : undefined}
        />

        <div className="flex items-center gap-1 text-xs text-muted-foreground">
          <div className={objectiveError ? 'text-sm font-bold text-destructive' : undefined}>{objective.length}</div>/
          <div>{MAX_INPUT_LENGTH}</div>
        </div>
      </div>

      <h3 className="researchObjectiveTitle">Helpful context</h3>

      <div className="flex max-w-lg flex-col gap-2">
        <Label htmlFor="__helpfulcontext" className="leading-normal">
          Provide any information that may help guide the conversation, such as details about your company, product
          offerings, customers, etc.
        </Label>
        <Textarea
          id="__helpfulcontext"
          placeholder="Provide helpful context..."
          value={helpfulContext}
          rows={4}
          maxRows={6}
          onChange={(e) => {
            setHelpfulContextError(e.target.value.length === 0 || e.target.value.length > MAX_INPUT_LENGTH);
            onChangeValue('helpful_context', e.target.value);
          }}
          variant={objectiveError ? 'error' : undefined}
        />

        <div className="flex items-center gap-1 text-xs text-muted-foreground">
          <div className={helpfulContextError ? 'text-sm font-bold text-destructive' : undefined}>
            {helpfulContext.length}
          </div>
          /<div>{MAX_INPUT_LENGTH}</div>
        </div>
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(WelcomeEndScreen);
