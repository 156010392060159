import { Check, Figma } from '@askable/ui/components/unmod/icons';
import { PermissionsList } from '@askable/ui/components/unmod/permissions-list';
import { TaskCard, TaskCardHeader, TaskCardNavigation } from '@askable/ui/components/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableHeader } from '@askable/ui/components/unmod/task-card-draggable';
import { cn } from '@askable/ui/lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTaskNavigation } from 'containers/Studies/Preview/hooks/useTaskNavigation';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';

import type { StudyPreviewConfig } from 'containers/Studies/data/StudyPreview.query';

interface TaskContainerProps {
  studyConfig: StudyPreviewConfig;
}

const getActiveTask = (config: StudyPreviewConfig, activeBlockId: string | null) => {
  if (activeBlockId === 'welcome') {
    return config.welcome_block;
  }

  if (activeBlockId === 'thank_you') {
    return config.thank_you_block;
  }

  return (
    config.task_blocks.find((task) => task?._id === activeBlockId) ?? {
      _id: 'welcome',
      instructions: '',
      is_recording_enabled: false,
      title: '',
      type: 'welcome',
    }
  );
};

export const TaskContainer = ({ studyConfig }: TaskContainerProps) => {
  const { t } = useTranslation();
  const { activeBlockId, currentStep, totalSteps, isFirstScreen, isLastScreen, handleProgress } =
    useTaskNavigation(studyConfig);
  const activeBlock = getActiveTask(studyConfig, activeBlockId);
  const [isCardCollapsed, setIsCardCollapsed] = useState(false);
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' && studyConfig.task_blocks.some((task) => task?.is_recording_enabled)) ?? false;

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      activeBlock.type === 'figma_prototype' && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCardCollapsed(false);
    }
  };

  return (
    <div
      className={cn('h-full w-full p-4 lg:p-6', {
        'p-0 lg:p-0': activeBlock.type === 'figma_prototype',
      })}
    >
      {activeBlock.type === 'figma_prototype' ? (
        <>
          {!activeBlock.figma_prototype?.file_id ? (
            <div className="drag-container relative h-full w-full @container">
              <div className="h-full w-full bg-prototype p-6">
                <div className="flex h-full w-full items-center justify-center">
                  <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                    <Figma size={48} />
                    {t('sections.studies.previewNoPrototype')}
                  </div>
                </div>
                <TaskCardDraggable
                  buttonLabel={t('sections.studies.endTask')}
                  collapseLabel={t('sections.studies.hideInstructions')}
                  id={activeBlockId}
                  isCollapsed={isCardCollapsed}
                  isDisabled
                  onCollapse={setIsCardCollapsed}
                  subtitle={t('sections.studies.task')}
                >
                  <TaskCardDraggableHeader
                    instructions={activeBlock.instructions}
                    title={activeBlock.title}
                    type={activeBlock.type}
                  />
                </TaskCardDraggable>
              </div>
            </div>
          ) : null}
          {activeBlock.figma_prototype?.file_id ? (
            <FigmaPreview
              key={activeBlockId}
              previewMode
              activeBlockId={activeBlockId}
              fileId={activeBlock.figma_prototype.file_id}
              startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
              onSelectedNode={handleSelectedNode}
            >
              <TaskCardDraggable
                buttonLabel={isGoalScreen ? t('global.continue') : t('sections.studies.endTask')}
                collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                id={activeBlockId}
                isCollapsed={isCardCollapsed}
                isDisabled
                isDraggable={!isGoalScreen}
                onCollapse={setIsCardCollapsed}
                subtitle={t('sections.studies.task')}
              >
                {isGoalScreen ? (
                  <div className="flex flex-col gap-2 pt-4">
                    <Check bgColor="success" />
                    <TaskCardDraggableHeader
                      instructions={t('sections.studies.taskSuccessDescription')}
                      title={t('sections.studies.taskSuccessTitle')}
                      type={activeBlock.type}
                    />
                  </div>
                ) : (
                  <TaskCardDraggableHeader
                    instructions={activeBlock.instructions}
                    title={activeBlock.title}
                    type={activeBlock.type}
                  />
                )}
              </TaskCardDraggable>
            </FigmaPreview>
          ) : null}
        </>
      ) : (
        <TaskCard
          id={activeBlock._id}
          footerSlot={
            <>
              {isRecordingEnabled ? (
                <div className="px-4 md:px-8">
                  <PermissionsList
                    description={t('sections.studies.permissions.description')}
                    permissions={[
                      { key: 'camera', description: t('sections.studies.permissions.camera') },
                      { key: 'microphone', description: t('sections.studies.permissions.microphone') },
                    ]}
                  />
                </div>
              ) : null}

              <TaskCardNavigation
                isNextDisabled={isLastScreen}
                isPreviousDisabled={isFirstScreen}
                totalSteps={totalSteps}
                step={currentStep}
                labels={{
                  complete: t('sections.studies.complete'),
                  getStarted: t('sections.studies.getStarted'),
                  next: t('sections.studies.next'),
                  previous: t('sections.studies.previous'),
                }}
                handleProgress={handleProgress}
              />
            </>
          }
        >
          <TaskCardHeader instructions={activeBlock.instructions} title={activeBlock.title} type={activeBlock.type} />
        </TaskCard>
      )}
    </div>
  );
};
