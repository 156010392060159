import { Navigate } from 'react-router-dom';

import { RequireAuth } from 'containers/Auth/RequireAuth';

import { SettingsContainer } from './SettingsContainer';
import { SettingsAccount } from './containers/Account/Account';
import { SettingsBilling } from './containers/Billing/Billing';
import { SettingsInvoices } from './containers/Invoices/Invoices';
import { SettingsMembers } from './containers/Members/Members';
import { SettingsNotifications } from './containers/Notifications/Notifications';
import { SettingsResources } from './containers/Resources/Resources';
import { SettingsTeam } from './containers/Team/Team';
import { EnterpriseSSO } from './containers/Team/containers/EnterpriseSSO/EnterpriseSSO';
import { TeamResearchContainer } from './containers/Team/containers/Research/TeamResearchContainer';
import { SettingsUsage } from './containers/Usage/Usage';

import type { RouteObject } from 'react-router-dom';

interface SettingsRoutesPermissions {
  ssoManage: boolean;
  researchManage: boolean;
}

export const settingsRoutes = ({ ssoManage, researchManage }: SettingsRoutesPermissions): RouteObject[] => [
  {
    path: 'settings',
    element: (
      <RequireAuth>
        <SettingsContainer />
      </RequireAuth>
    ),
    children: [
      {
        path: 'account',
        element: <SettingsAccount />,
      },
      {
        path: 'notifications',
        element: <SettingsNotifications />,
      },
      {
        path: 'team',
        element: <SettingsTeam />,
      },
      {
        path: 'members',
        element: <SettingsMembers />,
      },
      {
        path: 'billing',
        element: <SettingsBilling />,
      },
      {
        path: 'usage',
        element: <SettingsUsage />,
      },
      {
        path: 'invoices',
        element: <SettingsInvoices />,
      },
      {
        path: 'billing/usage',
        element: <Navigate to="/settings/usage" replace />,
      },
      {
        path: 'billing/invoice',
        element: <Navigate to="/settings/invoices" replace />,
      },
      {
        path: 'resources',
        element: <SettingsResources />,
      },
      ...(ssoManage
        ? [
            {
              path: 'sso',
              element: <EnterpriseSSO />,
            },
          ]
        : []),
      ...(researchManage
        ? [
            {
              path: 'research',
              element: <TeamResearchContainer />,
            },
          ]
        : []),
      {
        index: true,
        element: <Navigate to="/settings/account" replace />,
      },
    ],
  },
];
