import { parseIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

import { localStorage } from 'lib/storage';

const validations = {
  shouldValidate: false,
  startValidations() {
    this.shouldValidate = true;
  },
  stopValidations() {
    this.shouldValidate = false;
  },
  validateEmail(email: string) {
    if (!this.shouldValidate) return true;
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  },
  validatePassword(password: any) {
    if (!this.shouldValidate) return true;
    return password.length >= 8;
  },
  validatePhoneNumber(phone: any, countryCode: any) {
    if (phone) {
      let phoneNumber = parseIncompletePhoneNumber(phone);
      if (phoneNumber) {
        try {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'PhoneNumber | undefined' is not assignable t... Remove this comment to see the full error message
          phoneNumber = parsePhoneNumberFromString(phone, countryCode);
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isValid' does not exist on type 'string'... Remove this comment to see the full error message
          return phoneNumber.isValid();
        } catch (errorArg) {
          return false;
        }
      }
      return false;
    }
    return true;
  },
  validateMatch(str1: any, str2: any) {
    if (!this.shouldValidate) return true;
    return str1 === str2;
  },
  validateABN(str: string) {
    if (!this.shouldValidate) return true;
    // Rules
    //   * It should only accept numbers
    //   * The maximum length should be 11
    //   * First digit shouldn't be 0
    //   * It should only be validated if the client is in Austrlia
    if (localStorage.get('countryName') !== 'Australia') return true;
    if (str.length !== 11) return false;
    if (str.substr(0, 1) === '0') return false;
    return true;
  },
  validateURL(url: any) {
    return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(url);
  },
};

const errors = {
  emailInvalid: 'Email is not valid',
  passwordInvalid: 'Password should be at least 8 characters long',
  passwordMatch: 'Password doesnt match the confirmation one',
  nameInvalid: 'Please enter a valid name',
  phoneInvalid: 'Please enter a valid phone number including area code',
  nonEmptyField: 'Mandatory field',
  urlInvalid: 'Please enter a valid URL',
};

export { validations, errors };
