import { Button } from '@askable/ui/components/ui/button';
import { Flame, ListVideo } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'containers/Studies/Results/components/Card';
import { ScreenItem } from 'containers/Studies/Results/components/ScreenItem';
import { Section } from 'containers/Studies/Results/components/Section';
import { Stat } from 'containers/Studies/Results/components/Stat';
import { Tabs } from 'containers/Studies/Results/components/Tabs';

import type { TaskSectionProps, TaskSectionType } from 'containers/Studies/Results/Results';

interface TaskAnalysisProps {
  sections: TaskSectionProps[];
}

export const TaskAnalysis = ({ sections }: TaskAnalysisProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TaskSectionType>(sections[0]?.type);

  const tabs = sections.map((section) => ({
    label: `${t(`sections.studiesResults.${section.type}`)} (${section.responses})`,
    value: section.type,
  }));

  if (sections.length === 0) {
    return null;
  }

  return (
    <Section title={t('sections.studiesResults.taskAnalysisTitle')} id="task-analysis">
      <Tabs
        value={activeTab as unknown as string}
        tabs={tabs}
        onValueChange={(value) => setActiveTab(value as TaskSectionType)}
      />

      {sections.map((section) => (
        <div className={section.type !== activeTab ? 'hidden' : undefined}>
          <Card
            key={section.title}
            headerSlot={
              <>
                <div className="flex flex-col gap-1">
                  <h4 className="text-lg font-semibold">{section.title}</h4>
                  <div className="flex gap-3 text-sm text-foreground-subtle">
                    <Stat type="successful" value={t(`sections.studiesResults.${section.type}`)} />
                    <Stat type="responses" value={section.responses} />
                    <Stat type="misclickRate" value={section.clickRate} />
                    <Stat type="averageDuration" value={section.duration} />
                  </div>
                </div>
                <div className="flex gap-2">
                  <Button variant="outline" onClick={() => alert('Todo')}>
                    <ListVideo className="h-4 w-4" /> {t('sections.studiesResults.recordings')}
                  </Button>
                  <Button variant="outline" onClick={() => alert('Todo')}>
                    <Flame className="h-4 w-4" /> {t('sections.studiesResults.heatmaps')}
                  </Button>
                </div>
              </>
            }
          >
            {section.screens.length > 0 ? (
              <ol className="grid grid-cols-[repeat(auto-fit,12rem)] gap-3">
                {section.screens.map((screen, index) => (
                  <ScreenItem key={screen.id} index={index} {...screen} />
                ))}
              </ol>
            ) : (
              <div className="py-4 text-sm text-foreground-subtle">{t('sections.studiesResults.noScreens')}</div>
            )}
          </Card>
        </div>
      ))}
    </Section>
  );
};
