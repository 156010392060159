/**
 * Accepts a value eg. 0.15
 * Returns a formatted percentage eg. 15%
 */

export const formatPercentage = (value: number | string): string => {
  const percentage = Number(value) * 100;
  return `${Number.isInteger(percentage) ? percentage : percentage.toFixed(1)}%`;
};

export const formatSecondsToTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};
