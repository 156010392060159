import { cn } from '@askable/ui/lib/utils';
import { Pencil } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';

import type { FigmaFileFrame } from 'generated/graphql';

export type FigmaScreenType = 'goal' | 'start';

interface SelectScreenButtonProps {
  frame: FigmaFileFrame | null;
  isMobile?: boolean;
  screenType: FigmaScreenType;
  onClick: (screenType: FigmaScreenType) => void;
  isDisabled?: boolean;
}

export const SelectScreenButton = ({
  frame,
  isDisabled,
  isMobile = false,
  screenType,
  onClick,
}: SelectScreenButtonProps) => {
  const { t } = useTranslation();
  const { screenSelection } = useBlockContext();

  return (
    <button
      id={`select-${screenType}-screen`}
      type="button"
      disabled={isDisabled}
      className={cn(
        'border-1 group grid h-20 w-[134px] overflow-hidden rounded-sm bg-background font-semibold leading-tight',
        {
          'border-dashed hover:border-solid focus:border-solid active:border-solid': !frame?.image_url,
          'border-primary ring-[0.5px] ring-primary hover:border-primary focus:border-primary':
            screenSelection?.mode === screenType,
          'h-44 w-[100px]': isMobile,
          'cursor-not-allowed': isDisabled,
          'active:ring-primary` hover:border-input focus:border-input active:border-primary active:ring-[0.5px]':
            !isDisabled,
        },
      )}
      onClick={() => onClick(screenType)}
    >
      <div className="col-start-1 col-end-2 row-start-1 row-end-2 overflow-hidden">
        {frame?.image_url ? (
          <img
            alt={t(`sections.studies.${screenType}`)}
            className={cn('size-full object-cover object-top', { 'object-center': isMobile })}
            height={frame.height ?? undefined}
            src={frame.image_url}
            width={frame.width ?? undefined}
          />
        ) : null}
      </div>

      <div
        className={cn(
          `z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex h-full items-center justify-center bg-darkest/60 text-lightest
          opacity-0 transition-opacity`,
          {
            'group-hover:opacity-100 group-focus-visible:opacity-100':
              !isDisabled && screenSelection?.mode !== screenType,
          },
        )}
      >
        <Pencil className="h-4 w-4" />
      </div>

      <div
        className={cn(
          `col-start-1 col-end-2 row-start-1 row-end-2 flex h-full items-center justify-center bg-background opacity-0
          transition-opacity`,
          {
            'opacity-100': screenSelection?.mode === screenType || !frame?.image_url,
            'z-0': !frame?.image_url,
          },
        )}
      >
        {t('sections.studies.build.formFields.figmaPrototypeScreens.action', {
          screen: t(`sections.studies.${screenType}`).toLocaleLowerCase(),
        })}
      </div>
    </button>
  );
};
