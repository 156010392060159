import { graphql } from 'src/graphql';

export const SubscribeStudy = graphql(`
  subscription study($input: StudySubscriptionInput!) {
    study(input: $input) {
      study {
        _id
        name
        type
        created
        updated
        status

        ... on UnmoderatedStudy {
          draft_config {
            _id
            welcome_block {
              _id
              type
              title
              instructions
              is_recording_enabled
            }
            thank_you_block {
              _id
              type
              title
              instructions
              is_recording_enabled
            }
            task_blocks {
              _id
              type
              title
              instructions
              is_recording_enabled
            }
          }
        }
      }
      meta {
        event
        taskBlockId
        triggeredBy
      }
    }
  }
`);
