import { TableCell, TableRow } from '@askable/ui/components/ui/table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { formatPercentage, formatSecondsToTime } from 'containers/Studies/utils/utils';

import { Tag } from './Tag';

import type { ParticipantProps } from 'containers/Studies/Results/Results';

interface ParticipantTableRowProps {
  participant: ParticipantProps;
}

export const ParticipantTableRow = ({ participant }: ParticipantTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow key={participant.id} className="h-auto">
      <TableCell className="flex items-center gap-2 font-medium">
        <div className="h-8 w-8 overflow-hidden rounded-sm bg-primary">
          <img
            src={participant.thumbnail}
            height="128"
            width="72"
            alt=""
            className="m-auto aspect-[9/16] h-full w-auto object-cover object-top"
          />
        </div>
        {participant.name}
      </TableCell>
      <TableCell>
        <Tag variant={participant.outcome}>{t(`sections.studiesResults.${participant.outcome}`)}</Tag>
      </TableCell>
      <TableCell className="text-end">{formatSecondsToTime(participant.duration)}</TableCell>
      <TableCell className="text-end">{formatPercentage(participant.misclickRate)}</TableCell>
      <TableCell className="text-end">{participant.screensViewed}</TableCell>
      <TableCell>{participant.completed ? format(participant.completed, 'd MMM yyyy, h:mmaaa') : <>-</>}</TableCell>
    </TableRow>
  );
};
