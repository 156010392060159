import { HttpLink, execute, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { makePromise } from 'apollo-link';
import fetch from 'cross-fetch';

import { globalVariables } from 'lib/globalVariables';
import { AUTH_CONFIG } from 'network/auth';

// Creates the main object to fetch data
const fetchLink = new HttpLink({
  uri: globalVariables.getEnvironmentVariables().graphqlURI,
  fetch,
});

const authLink = setContext(async (_arg1, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = (await AUTH_CONFIG.getToken()) ?? '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
      'x-graphql-client-name': 'clients',
      'x-graphql-client-version': import.meta.env.VITE_APP_VERSION ?? 'N/A',
      Accept: 'application/json',
      ...(localStorage.getItem('connectedAsClient') ? { 'x-askable-admin': true } : {}),
    },
  };
});

const links = from([authLink, fetchLink]);

export const apolloFetch = {
  async fetch(query: any, variables = {}, operationName?: any) {
    const token = await AUTH_CONFIG.getToken();

    const operation = {
      query,
      variables,
      operationName,
      context: {
        headers: {
          Authorization: token,
        },
      },
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Observable<FetchResult<Record<st... Remove this comment to see the full error message
    return makePromise(execute(links, operation));
  },
};
