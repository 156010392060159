import { cn } from '@askable/ui/lib/utils';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export type BlockType =
  | 'welcome'
  | 'thank_you'
  | 'figma_prototype'
  | 'multiple_choice'
  | 'open_answer'
  | 'opinion_scale';

export interface NavParticipantBlockProps {
  completed: number;
  id: string;
  isActive?: boolean;
  name: string;
}

export const NavParticipantBlock = ({ completed, id, isActive = false, name }: NavParticipantBlockProps) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <Link
      to={{ search: `?participant_id=${id}`, hash: 'main' }}
      title={name}
      className={cn(
        'border-1 flex w-full items-center gap-2 rounded-xl border-border bg-background p-2 text-sm text-secondary-foreground',
        'hover:border-input focus:border-input active:border-primary active:ring-[0.5px] active:ring-primary',
        {
          'border-primary bg-background ring-[0.5px] ring-primary hover:border-primary focus:border-primary': isActive,
        },
      )}
    >
      <div
        className="text-md flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-primary font-semibold
          text-primary-foreground"
      >
        {initials}
      </div>
      <div className="flex flex-col truncate text-left font-medium">
        <div className="truncate">{name}</div>
        <div className="text-xs text-muted-foreground">
          {format(completed, 'd MMM')} &middot; {format(completed, 'h:mmaaa')}
        </div>
      </div>
    </Link>
  );
};
