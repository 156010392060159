import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter } from '@askable/ui/components/ui/dialog';

export const DialogDuplicate = ({
  resourceTypeName,
  resourceName,
  onDuplicate,
  onCancel,
  onOpenChange,
  open,
}: {
  resourceTypeName: string;
  resourceName: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  onCancel: () => void;
  onDuplicate: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>Duplicate {resourceTypeName}</DialogTitle>
        <DialogDescription>
          <p className="text-foreground">
            Create a duplicate of <span className="font-medium">{resourceName}</span>
          </p>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="primary" onClick={onDuplicate}>
            Duplicate
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
