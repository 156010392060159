import { Textarea } from '@askable/ui/components/ui/textarea';
import _ from 'lodash';
import { CalendarFold } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { Component } from 'react';

import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import './revokeInvitationStyles.scss';

class RevokeInvitationContentContainer extends Component {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'smsMessage' does not exist on type 'Read... Remove this comment to see the full error message
    const { smsMessage, onSmsMessageChange } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { session, booking, participant } = this.props;
    const timezone = _.get(booking, 'config.timezone') || utils.getCurrentTimezone();
    const firstName = participant.user.meta.identity.firstname;

    return (
      <div className="revokeInvitationContentContainer">
        <div className="sessionTimeContainer">
          <CalendarFold style={{ width: 32, height: 32 }} className="eventNote" />
          <div className="timeContainer">
            {bookingUtils.isOnlineTask(booking) && <p>Online Task</p>}
            {bookingUtils.isLongitudinal(booking) && <p>Longitudinal Study</p>}
            {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
              <>
                <p>{`${momentTimezone(_.get(session, 'start')).tz(timezone).format('hh:mm A')} - ${momentTimezone(_.get(session, 'end')).tz(timezone).format('hh:mm A')}`}</p>
                <p>{momentTimezone(_.get(session, 'start')).tz(timezone).format('dddd Do MMMM')}</p>
              </>
            )}
          </div>
        </div>
        <div className="smsMessageContainer">
          <p>SMS Message (this will be sent to {firstName}&apos;s mobile)</p>
          <Textarea
            name="revokeInvitationSMS"
            value={smsMessage}
            rows={6}
            onChange={(e) => {
              onSmsMessageChange(e.target.value);
            }}
          />
        </div>
        <div className="helpContainer">
          <p>{firstName} will be notified via SMS</p>
        </div>
      </div>
    );
  }
}

export default RevokeInvitationContentContainer;
