import { Alert } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import { ExternalLink, X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

/**
 * Extends the Alert with a styling and closable option
 * Persist the hidden state in local storage
 */

interface InfoAlertProps {
  description: string;
  Icon?: ReactNode;
  id: string;
  moreLink?: string;
  title: string;
}

const HIDE_INFO_KEY = 'hide_info_';

export const InfoAlert = ({ description, Icon, id, moreLink, title }: InfoAlertProps) => {
  const { t } = useTranslation();
  const [isInfoHidden, setIsInfoHidden] = useState(localStorage.getItem(`${HIDE_INFO_KEY}${id}`) === 'true');

  const hideInfo = () => {
    setIsInfoHidden(true);
    localStorage.setItem(`${HIDE_INFO_KEY}${id}`, 'true');
  };

  return !isInfoHidden ? (
    <Alert className="flex w-full gap-2 text-pretty border-0 bg-info-foreground text-sm">
      <div className="mt-1">{Icon}</div>

      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full items-center justify-between gap-2 font-semibold">
          {title}
          <Button
            variant="ghost"
            size="icon"
            onClick={hideInfo}
            aria-label={t('global.close')}
            className="-m-2 hover:bg-info/10 focus:bg-info/10 active:bg-info/20"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>

        <div className="whitespace-pre-wrap text-pretty">{description}</div>

        {moreLink ? (
          <a
            href={moreLink}
            className="flex items-center gap-1 font-semibold text-info hover:underline focus:underline"
            target="_blank"
            rel="noreferrer"
          >
            {t('global.learnMore')} <ExternalLink className="h-3 w-3" />
          </a>
        ) : null}
      </div>
    </Alert>
  ) : null;
};
