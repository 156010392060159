import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { Maybe, StudyFigmaPrototypeTaskBlock, StudyThankYouBlock, StudyWelcomeBlock } from 'generated/graphql';

let lastBlockId: string | undefined;

export type ActiveBlock = StudyWelcomeBlock | StudyThankYouBlock | Maybe<StudyFigmaPrototypeTaskBlock> | undefined;

export const useStudyActiveBlockId = (): {
  activeBlockId: string | null;
  activeBlock: ActiveBlock;
  setActiveBlockId: (newBlockId: string, replace?: boolean) => void;
} => {
  const { study } = useStudyContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeBlockId = searchParams.get('block_id');

  const activeBlock = useMemo(() => {
    if (!activeBlockId) {
      return null;
    }

    return match(activeBlockId)
      .with('welcome', () => study.draft_config?.welcome_block)
      .with('thank_you', () => study.draft_config?.thank_you_block)
      .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === activeBlockId));
  }, [study, activeBlockId]);

  const setActiveBlockId = useCallback(
    (newBlockId: string, replace?: boolean) => {
      if (newBlockId !== activeBlockId) {
        setSearchParams({ ...Object.fromEntries(searchParams), block_id: newBlockId }, { replace });
      }
    },
    [searchParams, setSearchParams, activeBlockId],
  );

  useEffect(() => {
    if (activeBlockId) {
      lastBlockId = activeBlockId;
      return;
    }

    // If no id in the url, fall back to the last one or to the welcome block which is guaranteed to be there
    // We only want to use the fallback though if it's still a valid block on the current study (e.g. not if the
    // user has navigated to a different study)
    let fallback = 'welcome';

    if (lastBlockId) {
      if (
        ['welcome', 'thank_you'].includes(lastBlockId) ||
        study?.draft_config?.task_blocks?.find((b) => b?._id === lastBlockId)
      ) {
        fallback = lastBlockId;
      }
    }
    setActiveBlockId(fallback, true);
  }, [activeBlockId, setSearchParams, setActiveBlockId, study?.draft_config?.task_blocks]);

  return {
    activeBlockId,
    setActiveBlockId,
    activeBlock,
  };
};
