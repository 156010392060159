import _ from 'lodash';
import React, { useEffect } from 'react';

function GoogleMaps(props: any) {
  const { googleMap } = props;
  useEffect(() => {
    if (props.googleMap) updateMap();
  }, [props]);

  const updateMap = async () => {
    const bounds = new window.google.maps.LatLngBounds();
    const hasViewport =
      props.locationViewport &&
      _.get(props.locationViewport, '[0].northeast.lat') &&
      _.get(props.locationViewport, '[0].northeast.lng') &&
      _.get(props.locationViewport, '[0].southwest.lat') &&
      _.get(props.locationViewport, '[0].southwest.lng');

    if (hasViewport) {
      await Promise.all(
        _.map(props.locationViewport, (viewPort: any) => {
          if (_.get(viewPort, 'northeast.lat') && _.get(viewPort, 'northeast.lng')) {
            const northeast = new window.google.maps.LatLng(viewPort.northeast.lat, viewPort.northeast.lng);
            bounds.extend(northeast);
          }
          if (_.get(viewPort, 'southwest.lat') && _.get(viewPort, 'southwest.lng')) {
            const southwest = new window.google.maps.LatLng(viewPort.southwest.lat, viewPort.southwest.lng);
            bounds.extend(southwest);
          }
        }),
      );
    } else if (
      _.get(props.locations, 'states') ||
      _.get(props.locations, 'bounds') ||
      _.get(props.locations, 'countries')
    ) {
      _.forEach(_.get(props.locations, 'states'), (state: any) => {
        const latLng = new window.google.maps.LatLng(_.get(state, 'latitude'), _.get(state, 'longitude'));
        bounds.extend(latLng);
      });
      _.forEach(_.get(props.locations, 'bounds'), (bound: any) => {
        const latLng = new window.google.maps.LatLng(_.get(bound, 'latitude'), _.get(bound, 'longitude'));
        bounds.extend(latLng);
      });
      _.forEach(_.get(props.locations, 'countries'), (country: any) => {
        const latLng = new window.google.maps.LatLng(_.get(country, 'latitude'), _.get(country, 'longitude'));
        bounds.extend(latLng);
      });
      if (
        _.size(_.get(props.locations, 'states')) === 1 &&
        _.size(_.get(props.locations, 'bounds')) === 0 &&
        _.size(_.get(props.locations, 'countries')) === 0
      ) {
        setTimeout(() => googleMap.setZoom(4), 100);
      } else if (
        _.size(_.get(props.locations, 'states')) === 0 &&
        _.size(_.get(props.locations, 'bounds')) === 1 &&
        _.size(_.get(props.locations, 'countries')) === 0
      ) {
        setTimeout(() => googleMap.setZoom(13), 100);
      } else if (
        _.size(_.get(props.locations, 'countries')) === 1 &&
        _.size(_.get(props.locations, 'bounds')) === 0 &&
        _.size(_.get(props.locations, 'states')) === 0
      ) {
        setTimeout(() => googleMap.setZoom(2), 100);
      }
    } else if (_.get(props, 'lat') && _.get(props, 'lng')) {
      const latLng = new window.google.maps.LatLng(_.get(props, 'lat'), _.get(props, 'lng'));
      bounds.extend(latLng);
    }

    // center the map to the geometric center of all markers
    googleMap.setCenter(bounds.getCenter());
    googleMap.fitBounds(bounds);
    adjustZoomLevel();
  };

  const adjustZoomLevel = () => {
    const totalLocations = _.size(_.get(props, 'locations.states', [])) + _.size(_.get(props, 'locations.bounds', []));
    // Adjust zoom level of the locations.
    if (totalLocations === 0) {
      switch (_.get(props, 'countryLocation.country')) {
        case 'AU':
        case 'NZ':
          googleMap.setZoom(googleMap.getZoom() + 1);
          break;
        default:
          break;
      }
    }
    // set a minimum zoom
    // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
    if (googleMap.getZoom() > 15) {
      googleMap.setZoom(15);
    }
  };

  return <div id={props.id} style={props.style || { width: 548, height: 233 }} />;
}

export default GoogleMaps;
