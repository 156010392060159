import _ from 'lodash';
import { useEffect } from 'react';

import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

export default function SSOLogoutCallback(props: any) {
  useEffect(() => {
    const extraArguments = utils.parseQueryParams(_.get(props, 'location.search'));
    if (extraArguments) {
      signoutUser(extraArguments.get('email'), extraArguments.get('teamId'));
    } else {
      props.history.push({ pathname: '/' });
    }
  }, []);

  const signoutUser = async (email: any, teamID: any) => {
    const cliendDetails = utils.parseJSON(localStorage.get('client_details'));
    if (localStorage.get('teamId') === teamID && _.get(cliendDetails, 'email') === email) {
      props.history.push({ pathname: '/logout' });
    } else {
      props.history.push({ pathname: '/' });
    }
  };

  return null;
}
