import { useEffect } from 'react';

import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import { useConnectedClient } from 'context/ConnectedClientContext';

function CreditsOrderAction() {
  const context = useAppContext();
  const { details: clientDetails } = useConnectedClient();

  useEffect(() => {
    const executeAction = async () => {
      context?.onOpenBuyCreditsModal({
        team_id: clientDetails?.team?.id!,
        creditsToBuy: 10000,
        onSuccess: () => {
          // Close the checkout page
          context?.onCloseBuyCreditsModal({ redirect: '/' });
        },
      });
    };
    executeAction();
  }, []);

  return null;
}

export default CreditsOrderAction;
