import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useConnectedClient } from 'context/ConnectedClientContext';

function ProjectTitle(props: any) {
  const booking = _.get(props, 'booking');
  const { details } = useConnectedClient();
  const [bookingState, setBookingState] = useState(booking);
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [bookingData, setBookingData] = useState(null);
  const [bookingName, setBookingName] = useState(_.get(booking, 'name', `${details?.team?.name} - 12 participants`));
  const [bookingNameError, setBookingNameError] = useState(booking && !_.get(booking, 'name') ? 'Required' : null);

  useEffect(() => {
    if (_.get(props, 'booking._id')) {
      props.updateLastStep?.({
        step: 'Project Setup',
        subStep: `/booking-setup/${props.booking._id}/project-setup/project-title`,
        stepId: 'project_setup_project_title',
      });
    }
    props.renderRightAppPreview?.(null);
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
    setBookingName(_.get(props.booking, 'name', `${details?.team?.name} - 12 participants`));
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  const onChangeValue = (value: any) => {
    if (_.size(value) === 0) {
      setBookingNameError('Required');
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      setBookingNameError();
    }
    setBookingName(value);

    const bookingStateObj = {
      ...bookingState,
      name: value,
    };
    setBookingState(bookingStateObj);

    if (
      (_.get(bookingSteps, 'project_setup_project_title') === 'error' && value) ||
      (_.get(bookingSteps, 'project_setup_project_title') === 'seen' && !value)
    ) {
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }

    if (value !== _.get(props, 'booking.name')) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name: any; }' is not assignabl... Remove this comment to see the full error message
      setBookingData({ name: value });
      props.history.replace({ booking: { name: value }, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    let redirectTo = '/booking-setup/:bookingID/project-setup/research-type';
    if (booking) redirectTo = `/booking-setup/${booking._id}/project-setup/research-type`;

    if (!booking) {
      const bookingExtraInfo = {
        name: bookingName,
        steps: {
          project_setup_project_title: 'seen',
          project_setup_research_type: 'seen',
        },
      };
      props.createBooking(bookingExtraInfo, redirectTo);
    } else {
      props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
    }
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Give your study a title
      </h1>

      <div className="fieldContainer flex flex-col gap-2">
        <Label htmlFor="__projectTitle">This won't be seen by participants</Label>
        <Input
          id="__projectTitle"
          placeholder="Study title"
          value={bookingName}
          required
          autoFocus
          onChange={(e) => {
            onChangeValue(e.target.value);
          }}
          variant={bookingNameError ? 'error' : undefined}
        />
        {bookingNameError ? <div className="text-xs text-destructive">{bookingNameError}</div> : null}
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ProjectTitle);
