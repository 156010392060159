import { Button } from '@askable/ui/components/ui/button';
import { Zap } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';

import type { Teams } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  team?: Teams;
  openCreditsModal: () => void;
  subscribeToNewCredits?: any;
};

const CreditsContainer: FC<Props> = ({ team, openCreditsModal, subscribeToNewCredits }) => {
  const hasCredits = teamUtils.hasCredits(team);
  const { t } = useTranslation();

  useEffect(() => {
    subscribeToNewCredits?.();
  }, [subscribeToNewCredits]);

  const onClick = () => {
    openCreditsModal();
  };

  return (
    <div className="flex items-center gap-2 text-sm">
      {hasCredits ? (
        <>
          <div className="hidden md:!inline">{t('sections.navigation.teamCreditBalance')}:</div>
          <strong>{`${utils.numberWithCommas(teamUtils.getCredits(team))} credits`}</strong>
        </>
      ) : null}
      <Button id="__buyCredits" variant="secondary" className="font-semibold" onClick={onClick}>
        {hasCredits ? (
          t('sections.navigation.buyCredits')
        ) : (
          <>
            <Zap className="h-4 w-4" /> {t('sections.navigation.buyCreditsPromo')}
          </>
        )}
      </Button>
    </div>
  );
};

export default CreditsContainer;
