import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@askable/ui/components/ui/dropdown-menu';
import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { Ellipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { TaskType } from 'containers/Studies/Results/Results';

interface TaskBlockHeaderProps {
  responses: number;
  title: string;
  type: TaskType;
}

export const TaskBlockHeader = ({ responses, title, type }: TaskBlockHeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="flex flex-wrap items-center justify-between gap-2">
      <div className="flex items-center gap-3">
        <IconBlock type={type} size="lg" />

        <div className="flex flex-col">
          <h2 className="text-xl font-semibold">{title}</h2>
          <div className="text-sm text-foreground-subtle">{t(`sections.studies.build.blocks.${type}.title`)}</div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <div className="text-sm text-foreground-subtle">
          {responses} {t('sections.studiesResults.responses').toLocaleLowerCase()}
        </div>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <Ellipsis className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Item 1</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
