import { Button } from '@askable/ui/components/ui/button';
import _ from 'lodash';
import { X } from 'lucide-react';
import React, { useEffect, useState, Fragment } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Card } from 'components/common';

import './styles/incentivesStyles.scss';

function Quota(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [incentivesType, setIncentivesType] = useState(
    _.get(askablePlusBrief, 'askable_plus.audience.booking_config.participant_category'),
  );
  const [showIncentivesTip, setShowIncentivesTip] = useState(false);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Audience',
        subStep: `/askable-plus/${askablePlusBrief._id}/audience/incentives`,
        stepId: 'audience_incentives',
      });
    }
    setTimeout(() => setShowIncentivesTip(true), 350);
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </>
    );
  };

  const onClickCard = (value: any) => {
    setIncentivesType(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            participant_category: value,
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-locations`;
    props.history.push({ pathname: redirectTo });
  };

  const premiumIncentiveSubtitle = () => {
    return (
      <div className="premiumIncentiveContainer">
        <img className="premiumIncentiveIcon" src="/booking/icons/flashCircleIcon.svg" alt="flashCircle" />
        <span className="premiumIncentiveSubtitle">1.5x standard incentives</span>
      </div>
    );
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Choose your incentive level</h1>
      <div className="incentivesTypes">
        <Card
          id="__standardIncentiveCard"
          title="Standard incentives"
          className="incentivesCard"
          content={[
            { label: 'Best for lower income earners,' },
            { label: 'people with plenty of free time,' },
            { label: 'easy to reach etc' },
          ]}
          selected={incentivesType === 1}
          image="/booking/illustrations/oneCoin.svg"
          onClickCard={() => onClickCard(1)}
        />
        <Card
          id="__premiumIncentiveCard"
          title="Premium incentives"
          subtitle={premiumIncentiveSubtitle()}
          className="incentivesCard"
          content={[
            { label: 'Best for higher income earners,' },
            { label: 'busy professionals, hard to' },
            { label: 'reach people etc' },
          ]}
          selected={incentivesType === 2}
          image="/booking/illustrations/manyCoins.svg"
          onClickCard={() => onClickCard(2)}
        />
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
      {showIncentivesTip && (
        <div className="additionalInfoContainer flipAnimation">
          <img className="additionalInfoImage" src="/booking/illustrations/money.svg" alt="illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">Why I can&apos;t set an exact amount?</p>
            <p className="additionalInfoText">
              Askable&apos;s smart algorithms and experienced team do the heavy lifting when it comes to setting the
              incentive at the perfect amount for your project, so you can focus on the important stuff: doing the
              research!
            </p>
          </div>
          <X
            className="closeIcon"
            onClick={() => {
              setShowIncentivesTip(false);
            }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
    </div>
  );
}

export default deprecatedWithRouter(Quota);
