import { Archive, CircleDashed, CirclePause, Circle, CircleEllipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { UnmoderatedStudy } from 'generated/graphql';

const statusTypes = {
  draft: { icon: CircleDashed, tone: 'text-muted-foreground' },
  in_review: { icon: CircleEllipsis, tone: 'text-warning' },
  active: { icon: Circle, tone: 'text-success' },
  paused: { icon: CirclePause, tone: 'text-warning' },
  archived: { icon: Archive, tone: 'text-error' },
} as const;

type Props = {
  study: UnmoderatedStudy;
};

export const StudyStatusIndicator = ({ study }: Props) => {
  const { t } = useTranslation();

  const status = study.status as keyof typeof statusTypes;
  if (!status) {
    return null;
  }

  const IconComponent = statusTypes[status]?.icon;

  return (
    <div className="flex items-center gap-2 text-sm text-muted-foreground">
      {IconComponent && <IconComponent className={`h-3.5 w-3.5 ${statusTypes[status]?.tone}`} />}
      {t(`sections.studies.status.${status}`)}
    </div>
  );
};
