import { Button } from '@askable/ui/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { Tabs, TabsList, TabsTrigger } from '@askable/ui/components/ui/tabs';
import { cn } from '@askable/ui/lib/utils';
import { Link, Monitor, Smartphone } from 'lucide-react';
import { sleep } from 'radash';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCopyToClipboard } from 'shared-utils/hooks';

import { useTaskNavigation } from '../hooks/useTaskNavigation';

import { PreviewNav } from './PreviewNav';

import type { NavItem } from './PreviewNav';
import type { StudyPreviewConfig } from 'containers/Studies/data/StudyPreview.query';
import type { ReactNode } from 'react';

interface PreviewContainerProps {
  studyName: string;
  studyConfig: StudyPreviewConfig;
  children: ReactNode;
}

export const PreviewContainer = ({ studyName, studyConfig, children }: PreviewContainerProps) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const { activeBlockId } = useTaskNavigation(studyConfig);
  const [searchParams, setSearchParams] = useSearchParams();
  const activePreviewMode = searchParams.get('preview_mode') ?? 'desktop';
  const [hasCopied, setHasCopied] = useState(false);
  const previewLink = window.location.href;

  // Construct the navigation items
  const taskNavItems: NavItem[] =
    studyConfig.task_blocks.map((task) => {
      return {
        _id: task?._id ?? '',
        title: task?.title ?? '',
        type: task?.type ?? 'welcome',
      };
    }) ?? [];

  const navItems: NavItem[] = [
    { _id: 'welcome', title: studyConfig.welcome_block.title ?? '', type: 'welcome' },
    ...taskNavItems,
    { _id: 'thank_you', title: studyConfig.thank_you_block.title ?? '', type: 'thank_you' },
  ];

  const onCopyLink = async () => {
    copyToClipboard(previewLink);
    setHasCopied(true);
    await sleep(3000);
    setHasCopied(false);
  };

  return (
    <div className="flex h-screen flex-col bg-secondary text-foreground">
      <header className="flex items-center justify-between gap-2 border-b border-border bg-background px-2 py-3">
        <h1 className="group truncate py-1 pl-3 text-lg font-medium text-foreground">{studyName}</h1>

        <Tabs
          defaultValue="build"
          value={activePreviewMode}
          onValueChange={(value: string) => {
            setSearchParams({ ...Object.fromEntries(searchParams), preview_mode: value });
          }}
        >
          <TabsList asChild>
            <nav className="hidden w-fit md:flex">
              <TabsTrigger value="desktop" className="flex gap-2">
                <Monitor className="h-4 w-4" />
                {t('sections.studies.desktop')}
              </TabsTrigger>
              <TabsTrigger value="mobile" className="flex gap-2">
                <Smartphone className="h-4 w-4" /> {t('sections.studies.mobile')}
              </TabsTrigger>
            </nav>
          </TabsList>
        </Tabs>

        <div>
          <Popover openOnHover>
            <PopoverTrigger asChild>
              <Button variant="primary">{t('sections.studies.share')}</Button>
            </PopoverTrigger>

            <PopoverContent className="flex w-[10rem] flex-col gap-2" align="end">
              <Button
                aria-label={t('sections.discover.copyLink')}
                className="w-full"
                disabled={hasCopied}
                onClick={onCopyLink}
                variant="secondary"
              >
                <Link className="h-4 w-4" />{' '}
                {hasCopied ? t('sections.discover.copyLinkSuccess') : t('sections.discover.copyLink')}
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </header>

      <div className="grid h-full min-h-0 grid-cols-[min-content_1fr] transition-all">
        <PreviewNav navItems={navItems} activeBlockId={activeBlockId} />

        <main
          className={cn('relative flex w-full justify-center overflow-auto transition-all @container', {
            'mx-auto max-w-96': activePreviewMode === 'mobile',
          })}
        >
          <Fragment key={activeBlockId}>{children}</Fragment>
        </main>
      </div>
    </div>
  );
};
