import get from 'lodash/get';
import momentTimezone from 'moment-timezone';
import { Box, Heading, HStack, Link, Text, VStack } from 'ui';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { ProjectUserStatus } from 'generated/graphql';
import { utils } from 'lib/utils';

import { getResearcherPayout } from '../../../utils/plus-utils/researcher-utils';

import type { Project } from 'generated/graphql';
import type { FC, PropsWithChildren } from 'react';
import type { BoxProps } from 'ui';

type Props = BoxProps & {
  project: Project;
  projectOfferPage?: boolean;
};

export const AskablePlusCardContainer: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box rounded="lg" p="4" border="1px" borderColor="gray.300" w="full" {...props}>
      {children}
    </Box>
  );
};

function PriceCardContainer({ project, projectOfferPage = false, ...props }: Props) {
  const connectedClient = useConnectedClient();
  const creditsToBeUsed = get(project, 'pricing.total_credits');
  const researcherEarn = getResearcherPayout(project);

  if (connectedClient.details?.type?.researcher) {
    return (
      <AskablePlusCardContainer {...props}>
        <VStack spacing={1} alignItems="flex-start">
          <Text color="gray.600">
            {project.users?.some((u) => u?._id === connectedClient.details?.id) ? 'Payout' : 'Earn'}
          </Text>
          <Box>
            <Heading size="md">${utils.formatMoney(researcherEarn, 2)} AUD + GST</Heading>
          </Box>
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <HStack justifySelf="flex-start" spacing={6} mt="6">
            <VStack spacing={0} alignItems="flex-start">
              <Text color="gray.500">Client</Text>
              <Text>{project?.team?.name}</Text>
            </VStack>
            <VStack spacing={0} alignItems="flex-start">
              <Text color="gray.500">Due date</Text>
              <Text>{momentTimezone(project?.askable_plus?.due_date).format('DD MMM YYYY')}</Text>
            </VStack>
          </HStack>
          {!projectOfferPage && (
            <VStack spacing={0} alignItems="flex-start">
              <Text color="gray.500">{project.owner?.displayName}</Text>
              <Link
                href={`mailto:${get(project, 'owner.email')}`}
                target="_blank"
                rel="noopener noreferrer"
                noOfLines={1}
              >
                {get(project, 'owner.email')}
              </Link>
            </VStack>
          )}
        </VStack>
      </AskablePlusCardContainer>
    );
  }

  const acceptedResearcher = project.users?.find((user) => user?.status === ProjectUserStatus.Accepted);

  return (
    <AskablePlusCardContainer {...props}>
      <div className="priceContainer">
        <Text>Credits</Text>
        <Heading className="info font--title2">{utils.numberWithCommas(creditsToBeUsed)}</Heading>
      </div>
      <HStack spacing={10}>
        <VStack spacing={0} alignItems="flex-start">
          <Text color="gray.500">Researcher</Text>
          {acceptedResearcher && (
            <>
              <Text>{acceptedResearcher.User?.displayName}</Text>
              <Link href={`mailto:${acceptedResearcher?.User?.email}`} target="_blank" rel="noopener noreferrer">
                {acceptedResearcher?.User?.email}
              </Link>
            </>
          )}
          {!acceptedResearcher && <Text>Not assigned</Text>}
        </VStack>
        <VStack spacing={0} alignItems="flex-start">
          <Text color="gray.500">Due date</Text>
          <Text>{momentTimezone(project?.askable_plus?.due_date).format('DD MMM YYYY')}</Text>
        </VStack>
      </HStack>
    </AskablePlusCardContainer>
  );
}

export default PriceCardContainer;
