import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';

export function normalizeText(text: string): string {
  // We go lowerCase first because that utility removes underscores and dashes from text.
  return capitalize(lowerCase(text));
}

export type FigmaParseError = 'empty_input' | 'no_url' | 'no_file_id' | 'unknown';
export type FigmaUrlDetails =
  | { isValid: false; error: FigmaParseError }
  | { isValid: true; fileId: string; startingPointNodeId?: string };

export function parseFigmaEmbedUrl(input: string): FigmaUrlDetails {
  try {
    if (!input?.trim?.()) {
      return { isValid: false, error: 'empty_input' };
    }

    // find the full URL in the input string
    const urlMatch = input.match(/(?:https:\/\/[\w.-]+.?)?figma.com\/(?:[\w-]+)\/([0-9a-zA-Z]{22,128})[^'"]*/im);

    console.log(input, urlMatch);

    if (!urlMatch?.[0]) {
      return { isValid: false, error: 'no_url' };
    }

    // parse the URL
    const url = new URL(urlMatch[0].substring(0, 5) === 'https' ? urlMatch[0] : `https://${urlMatch[0]}`);

    // find the file id in the URL
    const fileId = urlMatch?.[1];
    if (!fileId) {
      return { isValid: false, error: 'no_file_id' };
    }

    // find the starting point node id in the URL: https://www.figma.com/developers/embed#example-prototype-embed
    const startingPoint =
      url.searchParams.get('starting-point-node-id') || url.searchParams.get('node-id') || undefined;

    const startingPointNodeId = startingPoint?.replace && startingPoint.replace(/-+/g, ':');

    return {
      isValid: true,
      fileId,
      startingPointNodeId,
    };
  } catch (error: unknown) {
    console.warn('Error parsing Figma URL', error);
    return {
      isValid: false,
      error: 'unknown',
    };
  }
}
