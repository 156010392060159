import { cn } from '@askable/ui/lib/utils';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';

import { JoinTeams } from './components/JoinTeams';
import { YourTeams } from './components/YourTeams';

type Props = {
  className?: string;
};

export const TeamsContainer = ({ className }: Props) => {
  return (
    <MinimalPage bodyClasses="mt-10 md:container py-10 !max-w-5xl">
      <div className={cn('space-y-10', className)}>
        <YourTeams />
        <JoinTeams />
      </div>
    </MinimalPage>
  );
};
