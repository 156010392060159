import { cn } from '@askable/ui/lib/utils';
import { Check, Figma, Hand, Heart, Message, Plus, Star } from './icons';

const iconSizesInPx = {
  xl: 96,
  lg: 32,
  md: 20,
  sm: 14,
} as const;

const blockTypes = {
  add: { Icon: Plus, bgClass: 'bg-secondary' },
  welcome: { Icon: Hand, bgClass: 'bg-secondary' },
  thank_you: { Icon: Heart, bgClass: 'bg-secondary' },
  figma_prototype: { Icon: Figma, bgClass: 'bg-pastel-blue' },
  multiple_choice: { Icon: Check, bgClass: 'bg-pastel-indigo' },
  open_answer: { Icon: Message, bgClass: 'bg-pastel-purple' },
  opinion_scale: { Icon: Star, bgClass: 'bg-pastel-yellow' },
} as const;

interface Props {
  hasBackground?: boolean;
  size?: keyof typeof iconSizesInPx;
  type: keyof typeof blockTypes;
}

export const IconBlock = ({ hasBackground = true, size = 'md', type }: Props) => {
  const config = blockTypes[type];
  const iconSize = iconSizesInPx[size];

  return (
    <div className={hasBackground ? cn('rounded-sm p-2', config.bgClass) : undefined}>
      {config?.Icon ? (
        <config.Icon
          size={iconSize}
          bgColor={hasBackground ? 'darkest' : undefined}
          fgColor={hasBackground ? 'lightest' : undefined}
        />
      ) : null}
    </div>
  );
};
