import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from 'ui';

import { PhoneInput, BoxMessage } from 'components/common';
import { useUpdateMeMutation } from 'generated/graphql';
import { localStorage } from 'lib/storage';
import { validations, errors } from 'lib/validations';

import './styles/updateOrganiserDetailsStyles.scss';

function UpdateOrganiserDetails(props: any) {
  const [emailOrganiser, setEmailOrganiser] = useState('');
  const [firstNameOrganiser, setFirstNameOrganiser] = useState('');
  const [lastNameOrganiser, setLastNameOrganiser] = useState('');
  const [phoneOrganiser, setPhoneOrganiser] = useState('');
  const [phoneCountryCodeOrganiser, setPhoneCountryCodeOrganiser] = useState('');
  const [errorTextPhoneNumber, setErrorTextPhoneNumber] = useState('');
  const [errorTextEmail, setErrorTextEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [onFocusInput, setOnFocusInput] = useState('');
  const [error, setError] = useState(null);

  const [updateMe] = useUpdateMeMutation();

  useEffect(() => {
    if (props.open) {
      setPhoneCountryCodeOrganiser(_.get(props, 'contact.UserOrganiser.contact.phone.country_code'));
      setPhoneOrganiser(_.get(props, 'contact.UserOrganiser.contact.phone.mobile'));
      setEmailOrganiser(_.get(props, 'contact.UserOrganiser.email'));
      setFirstNameOrganiser(_.get(props, 'contact.UserOrganiser.meta.identity.firstname'));
      setLastNameOrganiser(_.get(props, 'contact.UserOrganiser.meta.identity.lastname'));
      setPhoneOrganiser(_.get(props, 'contact.UserOrganiser.contact.phone.mobile'));
    }
  }, [props.open]);

  const validateFields = () => {
    validations.startValidations();

    // Validate the phone field
    if (!validations.validatePhoneNumber(phoneOrganiser, phoneCountryCodeOrganiser)) {
      setErrorTextPhoneNumber(errors.phoneInvalid);
      return false;
    }

    // Validate the email field
    if (!validations.validateEmail(emailOrganiser)) {
      setErrorTextEmail(errors.emailInvalid);
      return false;
    }

    return true;
  };

  const onSave = async (event: any) => {
    if (event) event.preventDefault();

    if (validateFields()) {
      setLoading(true);
      try {
        const userUpdated = await updateMe({
          variables: {
            user: {
              email: emailOrganiser,
              meta: {
                identity: {
                  firstname: firstNameOrganiser,
                  lastname: lastNameOrganiser,
                },
              },
              contact: {
                phone: {
                  country_code: phoneCountryCodeOrganiser,
                  mobile: phoneOrganiser,
                },
              },
            },
          },
        });
        if (props.onChangeContact) props.onChangeContact(userUpdated.data?.updateMe);
        setError(null);
        setLoading(false);
        props.onClose();
      } catch (err) {
        setLoading(false);
        setError(_.get(err, 'message'));
      }
    }
  };

  return (
    <Modal isOpen={props.open} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent pb={4} className="modalUpdateOrganiserDetails">
        <ModalHeader>Update Contact Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <BoxMessage type="error">
              <p className="error">{error}</p>
            </BoxMessage>
          )}
          <form onSubmit={onSave} className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Label htmlFor="__firstname">First name</Label>
              <Input
                id="__firstname"
                placeholder="First Name *"
                value={firstNameOrganiser}
                onChange={(e: any) => setFirstNameOrganiser(e.target.value)}
                autoFocus
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label htmlFor="__lastname">Last name</Label>
              <Input
                id="__lastname"
                placeholder="Last Name *"
                value={lastNameOrganiser}
                onChange={(e: any) => setLastNameOrganiser(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label htmlFor="__email">Email</Label>
              <Input
                id="__email"
                placeholder="Email *"
                value={emailOrganiser}
                onChange={(e: any) => setEmailOrganiser(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label htmlFor="__phone">Phone number</Label>
              <PhoneInput
                id="__phone"
                countryCode={phoneCountryCodeOrganiser || localStorage.get('countryByIp') || ''}
                phoneNumber={phoneOrganiser}
                onChange={({ phone, country_code }) => {
                  setPhoneOrganiser(phone);
                  setPhoneCountryCodeOrganiser(country_code);
                  setErrorTextPhoneNumber('');
                }}
                onFocus={() => setOnFocusInput('phone')}
                className={`phoneInput ${onFocusInput === 'phone' ? 'focused' : ''}`}
                errorTextBottom={errorTextPhoneNumber}
              />

              {errorTextEmail ? <div className="text-xs text-destructive">{errorTextEmail}</div> : null}
            </div>

            <Button
              type="submit"
              variant="primary"
              disabled={!firstNameOrganiser || !lastNameOrganiser || !emailOrganiser}
            >
              {loading ? 'Saving...' : 'Update'}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UpdateOrganiserDetails;
