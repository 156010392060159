import { graphql } from 'src/graphql';

export const UpdateStudyConfigTaskBlockOrder = graphql(`
  mutation UpdateStudyConfigTaskBlockOrder($input: UpdateStudyConfigTaskBlockOrderInput!) {
    updateStudyConfigTaskBlockOrder(input: $input) {
      _id

      ... on UnmoderatedStudy {
        draft_config {
          _id
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled
            ... on StudyFigmaPrototypeTaskBlock {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }
          }
        }
      }
    }
  }
`);
