import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { cn } from '@askable/ui/lib/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type BlockType =
  | 'welcome'
  | 'thank_you'
  | 'figma_prototype'
  | 'multiple_choice'
  | 'open_answer'
  | 'opinion_scale';

export interface NavTaskBlockProps {
  id: string;
  isActive?: boolean;
  title: string;
  type: BlockType;
}

export const NavTaskBlock = ({ id, isActive = false, title, type }: NavTaskBlockProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to={{ search: `?block_id=${id}`, hash: 'main' }}
      title={title}
      className={cn(
        'border-1 flex w-full items-center gap-2 rounded-xl border-border bg-background p-2 text-sm text-secondary-foreground',
        'hover:border-input focus:border-input active:border-primary active:ring-[0.5px] active:ring-primary',
        {
          'border-primary bg-background ring-[0.5px] ring-primary hover:border-primary focus:border-primary': isActive,
        },
      )}
    >
      <IconBlock type={type} />
      <div className="flex flex-col truncate text-left font-medium">
        <div className="truncate">{title}</div>
        <div className="truncate text-xs text-muted-foreground">{t(`sections.studies.build.blocks.${type}.title`)}</div>
      </div>
    </Link>
  );
};
