import { AskableIconLoading } from '@askable/ui/components/ui/askable-icon';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery, useSubscription } from 'urql';

import { SubscribeStudy } from 'containers/Studies/data/Study.subscription';
import { StudyPreview } from 'containers/Studies/data/StudyPreview.query';

import { EmptyState } from '../components/EmptyState';
import { getPageTitle } from '../utils/getPageTitle';

import { PreviewContainer } from './components/PreviewContainer';
import { TaskContainer } from './components/TaskContainer';

export const Preview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { studyId } = params;
  invariant(studyId, 'Study ID is required');

  const [{ data, fetching }] = useQuery({
    pause: !studyId,
    query: StudyPreview,
    variables: { id: studyId },
  });

  useSubscription(
    {
      query: SubscribeStudy,
      pause: !studyId || fetching,
      variables: { input: { _id: studyId } },
    },
    (_previous, response) => {
      return response;
    },
  );

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.preview'), data?.studyPreview?.name ?? 'Study')}</title>
      </Helmet>

      {fetching && !data?.studyPreview ? (
        <div className="flex h-full w-full items-center justify-center">
          <AskableIconLoading />
        </div>
      ) : null}

      {!data?.studyPreview ? <EmptyState title={t('sections.studies.studyNotFound')} /> : null}

      {!fetching && data?.studyPreview ? (
        <PreviewContainer studyName={data?.studyPreview.name} studyConfig={data?.studyPreview.draft_config}>
          <TaskContainer studyConfig={data?.studyPreview.draft_config} />
        </PreviewContainer>
      ) : null}
    </>
  );
};
