import { graphql } from 'src/graphql';

export const SaveFigmaFile = graphql(`
  mutation SaveFigmaFile($fileId: String!) {
    saveFigmaFile(fileId: $fileId) {
      _id
      created
      updated
      file_id
      _importer_user_id
      name
      figma_last_modified
      link_is_public
      frames {
        node_id
        name
        width
        height
        image_url
      }
      canvases {
        node_id
        name
        flow_starting_point_node_ids
      }
    }
  }
`);
