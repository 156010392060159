import _ from 'lodash';
import { TriangleAlert } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Box } from 'ui';
import { useQuery } from 'urql';

import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import { bookingUtils } from 'lib/booking';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';

import './styles/cardContainerStyles.scss';

function PriceCardContainer(props: any) {
  const intercom = useIntercom();

  const [{ data: creditPacks }] = useQuery({ query: FetchCreditPacksQuery });

  const taxesForCountry = teamUtils.getTax();
  const currency = teamUtils.getCurrency();
  const totalParticipants = _.get(props, 'booking.total_participants') || 0;
  const bookingTypeSettings = bookingUtils.getBookingTypeSettings(_.get(props, 'booking'));

  const [booking, setBooking] = useState(_.get(props, 'booking'));
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [shouldBlinkPrice, setShouldBlinkPrice] = useState(false);

  // Number of credits needed per participant
  const creditsPerParticipant = bookingUtils.getCreditsPerParticipant(booking);
  let tool = '';
  if (bookingUtils.isRemote(booking)) {
    tool = bookingUtils.bookingRemoteCallToolUsed(booking);
  } else if (bookingUtils.isOnlineTask(booking)) {
    tool = bookingUtils.bookingOnlineTaskToolUsed(booking);
  }

  // Credits to buy is equals to the number of participants needed minus the number of credits the team currently has
  const creditsToBeUsed = Math.ceil(totalParticipants * creditsPerParticipant);
  const bookingPrice = _.get(booking, 'total_amount.bookingPrice') || 0;

  const creditsRemaining = teamUtils.getCredits(_.get(props, 'team') || _.get(props, 'booking.team'));
  const creditsToBuy = creditsToBeUsed - creditsRemaining;

  useEffect(() => {
    const bookingObj = {
      ...props.booking,
      total_amount: {
        ...props.booking.total_amount,
        creditsPerParticipant,
      },
    };
    const bookingTotal = bookingUtils.getTotalAmount({
      team: _.get(props, 'booking.team'),
      booking: bookingObj,
      creditPacks: creditPacks?.creditPacks,
    });
    setBooking({
      ...props.booking,
      total_amount: {
        ...props.booking.total_amount,
        bookingPrice: bookingTotal,
        creditsPerParticipant,
      },
    });
  }, [props.booking, creditsToBuy]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  // When number of credits change it should blink in yellow
  useEffect(() => {
    setShouldBlinkPrice(true);
    setTimeout(() => {
      setShouldBlinkPrice(false);
    }, 200);
  }, [creditsToBeUsed]);

  const parseSessionDuration = () => {
    const session = utils.getLabelFromArray(
      bookingUtils.sessionDurations(_.get(booking, 'type'), _.get(booking, 'config.online_task.type')),
      _.get(props, 'booking.config.session.duration'),
    );
    const parsedDuration = typeof session === 'string' ? session.slice(0, -1) : `${session} mins`;
    return parsedDuration;
  };

  const renderCreditsBalanceContainer = () => {
    return (
      <div className="creditsBalanceContainer">
        <span>Your credit balance</span>
        <h3>
          {utils.numberWithCommas(creditsRemaining)} credit{creditsRemaining > 1 ? 's' : ''}
        </h3>
      </div>
    );
  };

  const renderCreditsToBuyContainer = () => {
    return (
      <div className="creditsToBuyContainer">
        <p>
          {creditsRemaining > 0 ? 'Top up with' : 'Price for'} {utils.numberWithCommas(creditsToBuy)} credit
          {creditsToBuy > 1 ? 's' : ''}:
        </p>
        <h3>
          {`${currency.symbol}${utils.formatMoney(_.get(booking, 'total_amount.bookingPrice', 0), 2)} ${currency.code}`}
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {_.get(taxesForCountry, 'tax_rate') > 0 && <span> + {_.get(taxesForCountry, 'tax_label')}</span>}
        </h3>
      </div>
    );
  };

  const renderLongitudinalTimes = () => {
    const workloadTime = _.get(booking, 'config.longitudinal_study.participant_workload.time');
    const workloadMeasure = _.get(booking, 'config.longitudinal_study.participant_workload.measure');
    const workloadFrequency = _.get(booking, 'config.longitudinal_study.participant_workload.frequency');
    const workloadMeasureLabel = bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1);
    const workloadFrequencyLabel = bookingUtils.getFrequencyLabel(workloadFrequency);

    const periodTime = _.get(booking, 'config.longitudinal_study.period.time');
    const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency');
    const periodFrequencyLabel = bookingUtils.getFrequencyLabel(periodFrequency, periodTime > 1);
    return (
      <>
        {workloadTime && (
          <p className="cardText">
            • {workloadTime} {workloadMeasureLabel} per {workloadFrequencyLabel} workload
          </p>
        )}
        {periodTime && (
          <p className="cardText">
            • To be done over {periodTime} {periodFrequencyLabel}
          </p>
        )}
        {_.size(_.get(booking, 'config.longitudinal_study.extra')) > 0 && (
          <>
            <p className="cardText">• Extra time:</p>
            {_.map(_.get(booking, 'config.longitudinal_study.extra'), (item: any) => {
              const extraTime = _.get(item, 'time', '');
              const extraMeasure = _.get(item, 'measure');
              const extraDescription = _.get(item, 'description', '');
              const extraMeasureLabel = bookingUtils.getMeasureLabel(extraMeasure, extraTime > 1);
              return (
                <p className="cardText">
                  &emsp;● {extraTime} {extraMeasureLabel} for {extraDescription}
                </p>
              );
            })}
          </>
        )}
      </>
    );
  };

  const shouldShowInfo = (stepInfo: any) => {
    return _.get(booking, 'cloned') || _.get(bookingSteps, `[${stepInfo}]`);
  };

  return (
    <Box padding="24px" border="1px" borderColor="gray.200" borderRadius="8px" className="cardContainer">
      <p id="__bookingTotalCredits" className={`cardTitle credits ${shouldBlinkPrice ? 'shouldBlink' : ''}`}>
        {utils.numberWithCommas(creditsToBeUsed)} Credits
      </p>
      {bookingUtils.isAskablePlus(booking) && _.get(booking, 'config.project.allowed_credits') && (
        <div className="allocatedCreditsContainer">
          {creditsToBeUsed > _.get(booking, 'config.project.allowed_credits') && (
            <div className="allocatedCredits">
              <TriangleAlert style={{ width: '13px', height: '13px' }} color="#FF2E00" />
              <span className="allocatedCreditsMessage exceeded">Allocated credits exceeded</span>
            </div>
          )}
          {creditsToBeUsed < _.get(booking, 'config.project.allowed_credits') && (
            <div className="allocatedCredits">
              <TriangleAlert style={{ width: '13px', height: '13px' }} color="#FD8261" />
              <span className="allocatedCreditsMessage notMet">Allocated credits not met</span>
            </div>
          )}
          <span className="allocatedCreditsLabel">Allocated credits</span>
          <span className="allocatedCreditsTotal">
            {utils.numberWithCommas(_.get(booking, 'config.project.allowed_credits'))} credits
          </span>
        </div>
      )}
      <div className="cardPrice">
        {creditsRemaining === 0 &&
          // Hide price when loading / invalid (NaN)
          (typeof bookingPrice === 'number' && bookingPrice > 0 ? (
            <>
              <span>
                {currency.symbol}
                {utils.formatMoney(bookingPrice, 2)} {currency.code}
              </span>
              {taxesForCountry?.tax_label && <span> + {taxesForCountry.tax_label}</span>}
            </>
          ) : (
            '\u00A0' /* Non-breaking space */
          ))}
        {creditsRemaining > 0 && renderCreditsBalanceContainer()}
        {creditsRemaining > 0 && creditsToBuy > 0 && renderCreditsToBuyContainer()}
        {creditsRemaining === 0 && (
          <div
            className="getBulkCreditsLink"
            onClick={() => {
              props.context.onOpenBuyCreditsModal({
                team_id: props.team._id,
                creditsToBuy: 10000,
                onSuccess: () => {
                  // Close the checkout page
                  props.context.onCloseBuyCreditsModal({ shouldGoBack: true });
                },
              });
            }}
          >
            or get bulk credits and save <br />
            up to 30%
          </div>
        )}
      </div>
      <p className="cardText additionalText">
        <u>Includes</u> participant incentives
      </p>
      {!props.condensedCard && (
        <>
          <div className="line" />
          <p className="cardText">
            • {totalParticipants} participant{totalParticipants > 1 ? 's' : ''}
          </p>
          {bookingUtils.isAiModeration(booking) ? (
            <p className="cardText">• AI Moderated</p>
          ) : (
            <p className="cardText">
              • {_.get(bookingTypeSettings, 'label')} {tool ? `using ${tool}` : ''}
            </p>
          )}
          {shouldShowInfo('project_setup_study_duration') &&
            bookingUtils.isLongitudinal(booking) &&
            renderLongitudinalTimes()}
          {shouldShowInfo('project_setup_task_duration') &&
            bookingUtils.isOnlineTask(booking) &&
            _.get(props, 'booking.config.session.duration') &&
            !bookingUtils.isAiModeration(booking) && <p className="cardText">• {parseSessionDuration()}</p>}
          {shouldShowInfo('project_setup_session_duration') &&
            (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) &&
            _.get(props, 'booking.config.session.duration') && <p className="cardText">• {parseSessionDuration()}</p>}
          {shouldShowInfo('audience_incentives') && _.get(props, 'booking.config.participant_category') && (
            <p className="cardText">
              • {`${bookingUtils.isBookingForProfessionals(booking) ? 'Premium' : 'Standard'} incentive`}
            </p>
          )}
          <p className="cardText additionalText">Got a pricing question?</p>
        </>
      )}
      <div
        className={`cardLinkContainer ${props.condensedCard ? 'noMarginTop' : ''}`}
        onClick={() => {
          intercom.showNewMessages();
        }}
      >
        <span className="cardLink">Chat to us</span>
      </div>
    </Box>
  );
}

export default PriceCardContainer;
