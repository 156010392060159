import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'ui';
import { useMutation } from 'urql';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';
import { figmaRedirectUri } from 'src/hooks/useFigmaAuth';

import { FigmaOauthCallback } from './data/FigmaOauthCallback.mutation';

export const FigmaAuthCallbackContainer = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const authCode = searchParams.get('code');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean | undefined>();
  const [, callbackMutation] = useMutation(FigmaOauthCallback);

  useEffect(() => {
    if (authCode && typeof authCode === 'string') {
      callbackMutation({ code: authCode, redirectUrl: figmaRedirectUri() })
        .then((result) => {
          if (result.data?.figmaOauthCallback?.is_connected === true) {
            setIsConnected(true);
            try {
              window?.close?.();
            } catch {
              console.warn('Failed to close window automatically');
            }
          } else {
            console.warn('Callback successful but not connected');
            setErrorMessage(t('sections.oauthFigma.callback.notAuthenticatedMessage'));
          }
        })
        .catch((error: unknown) => {
          console.error(error);
          setErrorMessage(t('sections.oauthFigma.callback.errorMessage'));
        });
    } else {
      setErrorMessage(t('sections.oauthFigma.callback.invalidCodeMessage'));
    }
  }, [authCode]);

  if (errorMessage) {
    return (
      <MinimalPage bodyClasses="m-auto h-auto md:max-w-md py-4 space-y-4">
        <p className="text-center">{errorMessage}</p>
      </MinimalPage>
    );
  }
  return (
    <MinimalPage bodyClasses="m-auto h-auto md:max-w-md py-4 space-y-4">
      {isConnected ? <p className="text-center">{t('sections.oauthFigma.callback.successMessage')}</p> : <Spinner />}
    </MinimalPage>
  );
};
