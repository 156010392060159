import { toast } from '@askable/ui/components/ui/sonner';

import type { NetworkError } from '@apollo/client/errors';
import type { Query, Mutation } from 'generated/graphql';
import type { GraphQLError } from 'graphql';

type QueryName = keyof Query extends infer Key ? (Key extends string ? Key : never) : never;
type MutationName = keyof Mutation extends infer Key ? (Key extends string ? Key : never) : never;

type GraphqlName = QueryName | MutationName;

export type KNOWN_ERROR_TYPES = 'no_connected_team' | 'user_not_on_team';

export const KNOWN_ERROR_TYPES_ARRAY: ReadonlyArray<KNOWN_ERROR_TYPES> = [
  'no_connected_team',
  'user_not_on_team',
] as const;

const criticalErrors: GraphqlName[] = ['viewer', 'maybeCreateKindeOrganization'];
// const moderateErrors = [];
const lowErrors: GraphqlName[] = ['patchKindeUser'];

export const graphqlErrorHandler = ({
  graphqlErrors,
  networkError,
}: {
  graphqlErrors?: ReadonlyArray<GraphQLError>;
  networkError?: NetworkError | Error;
}) => {
  if (networkError) {
    toast.error(
      networkError.message && networkError.message !== 'Network request failed' && networkError.message !== 'undefined'
        ? networkError.message
        : 'Looks like you are no longer connected to the internet.',
    );

    return;
  }

  // eslint-disable-next-line no-unreachable-loop
  for (const error of graphqlErrors ?? []) {
    const isCriticalError = error.path?.some((path) => criticalErrors.includes(path as GraphqlName));
    const isLowError = error.path?.some((path) => lowErrors.includes(path as GraphqlName));

    if (
      (error.extensions?.code === 401 || error?.message === 'invalid token' || error?.message === 'jwt expired') &&
      ['/whoops', '/logout'].every((v) => window.location.pathname !== v)
    ) {
      // If error is 401, redirect user to logout page
      window.location.replace('/logout');
      return;
    }

    // If it is low error, don't do anything
    if (isLowError) {
      return;
    }

    // If error is critical or moderate,
    // Make sure this runs before the rest so it will inform info to user, and the rest might redirect user.
    // toast('Something went wrong. Please contact to support team.');
    if (isCriticalError && ['/whoops', '/logout'].every((v) => window.location.pathname !== v)) {
      // If error is critical, redirect user to whoops page
      // error.extension should be object in type, but it can be undefined
      window.location.replace(`/whoops?error=${error.extensions?.type}`);
    }
    break;
  }
};
