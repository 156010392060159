import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { CheckCircle, CircleOff, MousePointerClick, Timer, UserCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { formatPercentage, formatSecondsToTime } from 'containers/Studies/utils/utils';

import type { StatType } from 'containers/Studies/Results/Results';

interface StatProps {
  isBlock?: boolean;
  subtitle?: string;
  type: StatType;
  value: string | number;
}

const iconByType = {
  successful: CheckCircle,
  incomplete: CircleOff,
  responses: UserCircle,
  misclickRate: MousePointerClick,
  averageDuration: Timer,
};

export const Stat = ({ isBlock = false, subtitle, type, value }: StatProps) => {
  const { t } = useTranslation();

  const Icon = iconByType[type];
  let localValue = value;

  if (['successful', 'incomplete', 'misclickRate'].includes(type) && typeof value === 'number') {
    localValue = formatPercentage(value);
  }

  if (type === 'averageDuration') {
    localValue =
      Number(value) > 60
        ? formatSecondsToTime(Number(value))
        : `${value} ${t('sections.studiesResults.seconds').toLocaleLowerCase()}`;
  }

  return isBlock ? (
    <div className="border-1 flex flex-col justify-center gap-2 rounded-2xl border-border p-4 text-sm leading-none">
      <div>{t(`sections.studiesResults.${type}`)}</div>
      <div className="flex flex-wrap items-center justify-between gap-x-2 gap-y-1">
        <div className="text-3xl font-semibold leading-none">{localValue}</div>{' '}
        {subtitle ? <div className="text-foreground-subtle">{subtitle}</div> : null}
      </div>
    </div>
  ) : (
    <Tooltip>
      <TooltipTrigger className="flex items-center gap-1">
        <Icon className="h-4 w-4" /> {localValue}
      </TooltipTrigger>
      <TooltipContent>{t(`sections.studiesResults.${type}`)}</TooltipContent>
    </Tooltip>
  );
};
