import { gql } from '@apollo/client';

import { projectFieldsFragment } from 'data/fragments/project/projectFields';

export default gql`
  mutation updateAskablePlusDetails($_project_id: ID!, $project: ProjectInput!) {
    updateAskablePlusDetails(_project_id: $_project_id, project: $project) {
      ...projectFields
    }
  }
  ${projectFieldsFragment}
`;
