import _ from 'lodash';
import { Clock, Coffee, MessageSquare, Monitor, User } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Box, Flex, Grid, GridItem, Heading, HStack, Text, VStack } from 'ui';

import { ResearcherMethods } from 'generated/graphql';
import { askablePlusUtils } from 'lib/askablePlus';
import { normalizeText } from 'utils/string-utils';

import type {
  LongitudinalParticipantWorkloadConfig,
  LongitudinalPeriodConfig,
  Maybe,
  Project,
} from 'generated/graphql';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import type { StackProps } from 'ui';

import 'components/manageAskablePlus/styles/askablePlusStyles.scss';

type Props = {
  project: Project;
};

type ResearchQuotaProps = StackProps & {
  icon: ReactNode;
};

const ResearchQuota: FC<PropsWithChildren<ResearchQuotaProps>> = ({ children, icon, ...props }) => {
  return (
    <HStack alignItems="flex-start" {...props}>
      <Box mt="1">{icon}</Box>
      <Text>{children}</Text>
    </HStack>
  );
};

const BriefContentItem: FC<{ title: string; description?: Maybe<string> }> = ({ title, description }) => {
  if (!description) {
    return null;
  }

  return (
    <VStack alignItems="flex-start">
      <Heading size="md">{title}</Heading>
      {description && (
        <ReactMarkdown className="briefContentDescription" source={description.replaceAll('\n\n', '\n &nbsp;\n')} />
      )}
    </VStack>
  );
};

function ProjectBrief({ project }: Props) {
  const renderBriefContentItem = ({ title, description }: any) => {
    return (
      <VStack alignItems="flex-start">
        <Heading size="md">{title}</Heading>
        {description && (
          <ReactMarkdown className="briefContentDescription" source={description.replaceAll('\n\n', '\n &nbsp;\n')} />
        )}
      </VStack>
    );
  };

  const renderParticipantCriteria = () => {
    if (_.get(project, 'askable_plus.audience.description')) {
      return renderBriefContentItem({
        title: 'Participant criteria',
        description: _.get(project, 'askable_plus.audience.description'),
      });
    }
    let eligibilitySummary = [];
    eligibilitySummary = askablePlusUtils.getEligibilitySummary(
      _.get(project, 'askable_plus.audience.booking_config.criteria'),
    );

    const screenerQuestions = _.filter(
      _.get(project, 'askable_plus.audience.booking_config.question'),
      (question: any) => question.config.display_participant,
    );
    _.forEach(screenerQuestions, (question: any) => {
      const screenInOptions = _.filter(question.options, (option: any) => option.screen_in).map((option: any) => {
        return {
          key: `option_${option._id}`,
          description: option.label,
        };
      });
      if (screenInOptions.length === 0) {
        return null;
      }
      eligibilitySummary.push({
        key: `question_${question._id}`,
        description: question.title,
        children: screenInOptions,
      });
    });

    if (_.size(eligibilitySummary) > 0) {
      const eligibilityListItems = eligibilitySummary.map((item: any) => (
        <span key={item.key}>
          &nbsp; &bull; &nbsp; {item.description}
          {item.children ? (
            <span>
              {item.children.map((child: any) => (
                <p key={child.key}>&emsp; &middot; &nbsp;{child.description}</p>
              ))}
            </span>
          ) : null}
        </span>
      ));

      return (
        <div className="briefContentItemContainer">
          <p className="briefContentTitle font--headline">Participant criteria</p>
          <div className="briefContentDescription">{eligibilityListItems}</div>
        </div>
      );
    }
    return null;
  };

  const renderResearch = () => {
    const researches = Object.values(ResearcherMethods)
      .filter((a) => (project.askable_plus?.research_type?.[a]?.quota ?? 0) > 0)
      .map((a) => {
        return {
          key: a,
          ...project.askable_plus?.research_type?.[a],
        };
      });

    const getSessionDurationCopy = ({
      researchMethod,
      duration,
      participantWorkload,
      period,
    }: {
      researchMethod: ResearcherMethods;
      participantWorkload?: Maybe<LongitudinalParticipantWorkloadConfig>;
      period?: Maybe<LongitudinalPeriodConfig>;
      duration?: number | null;
    }) => {
      if (typeof duration !== 'number' && researchMethod !== ResearcherMethods.Longitudinal) {
        return null;
      }
      switch (researchMethod) {
        case ResearcherMethods.Longitudinal:
          if (
            !participantWorkload ||
            !period ||
            !participantWorkload.frequency ||
            !participantWorkload.measure ||
            !participantWorkload.time ||
            !period.frequency ||
            !period.time
          ) {
            return null;
          }
          const getFrequencyValue = (
            frequency: LongitudinalParticipantWorkloadConfig['frequency'] | LongitudinalPeriodConfig['frequency'],
          ) => {
            switch (frequency) {
              case 1:
                return 'day';
              case 2:
                return 'week';
              case 3:
                return 'month';
              default:
                return null;
            }
          };
          const getPeriodFrequencyValue = (
            value: number,
            frequency: LongitudinalParticipantWorkloadConfig['frequency'] | LongitudinalPeriodConfig['frequency'],
          ) => {
            const plural = (() => (value > 1 ? 's' : ''))();
            switch (frequency) {
              case 1:
                return `day${plural}`;
              case 2:
                return `week${plural}`;
              case 3:
                return `month${plural}`;
              default:
                return null;
            }
          };

          const participantWorkloadFrequency = getFrequencyValue(participantWorkload.frequency);
          const periodFrequency = getPeriodFrequencyValue(period.time, period.frequency);

          if (!participantWorkloadFrequency || !periodFrequency) return null;
          return `${participantWorkload.time} ${participantWorkload.measure === 1 ? 'minute' : 'hour'} task every ${participantWorkloadFrequency} for ${
            period.time
          } ${periodFrequency}`;
        case ResearcherMethods.Survey:
          return `${duration} minute survey`;
        case ResearcherMethods.Discovery:
        case ResearcherMethods.Usability:
          return `${duration} minute`;
        case ResearcherMethods.CompetitiveAnalysis:
        default:
          return null;
      }
    };

    const getBookingTypeCopy = (researchMethod: ResearcherMethods, bookingType?: number | null) => {
      switch (researchMethod) {
        case ResearcherMethods.Discovery:
        case ResearcherMethods.Usability:
          return bookingType === 1 ? 'In-person' : 'Remote';
        default:
          return null;
      }
    };
    const getResearchTypeCopy = (researchMethod: ResearcherMethods, researchSessionType?: number | null) => {
      const INDIVIDUAL_INTERVIEWS = 'Individual sessions';
      const FOCUS_GROUP = 'Focus group';
      switch (researchMethod) {
        case ResearcherMethods.Discovery:
          return researchSessionType === 1 ? INDIVIDUAL_INTERVIEWS : FOCUS_GROUP;
        case ResearcherMethods.Usability:
          return INDIVIDUAL_INTERVIEWS;
        default:
          return null;
      }
    };
    const getHeading = (researchMethod: ResearcherMethods) => {
      switch (researchMethod) {
        case ResearcherMethods.Discovery:
          return 'Discovery interviews';
        case ResearcherMethods.Usability:
          return 'Usability testing';
        case ResearcherMethods.Longitudinal:
          return 'Longitudinal study';
        case ResearcherMethods.CompetitiveAnalysis:
          return 'Competitor analysis';
        case ResearcherMethods.ContinuousAiModerated:
          return 'Continuous AI moderated interviews';
        case ResearcherMethods.ContinuousResearcherModerated:
          return 'Continuous researcher moderated interviews';
        default:
          return normalizeText(researchMethod);
      }
    };

    return (
      <Box>
        <Heading mb="4" size="md">
          Research
        </Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {researches.map((research) => {
            const heading = getHeading(research.key);
            const researchTypeCopy = getResearchTypeCopy(research.key, research?.session?.type);
            const bookingTypeCopy = getBookingTypeCopy(research.key, research.booking_type);
            const sessionDurationCopy = getSessionDurationCopy({
              researchMethod: research.key,
              duration: research.session?.duration,
              participantWorkload: research.longitudinal_study?.participant_workload,
              period: research.longitudinal_study?.period,
            });
            return (
              <Flex direction="column" as={GridItem} alignItems="flex-start" key={research.key}>
                <Heading size="sm" mb={2}>
                  {heading}
                </Heading>
                {research.quota &&
                  (() => {
                    const participantOrCompetitorPlural = research?.quota > 1 ? 's' : '';
                    return (
                      <ResearchQuota
                        icon={
                          research.key === ResearcherMethods.CompetitiveAnalysis ? (
                            <Monitor className="h-4 w-4" />
                          ) : (
                            <User className="h-4 w-4" />
                          )
                        }
                        mb={1}
                      >
                        {research.quota}{' '}
                        {research.key === ResearcherMethods.CompetitiveAnalysis
                          ? `competitor${participantOrCompetitorPlural}`
                          : `participant${participantOrCompetitorPlural}`}
                      </ResearchQuota>
                    );
                  })()}
                {sessionDurationCopy && (
                  <ResearchQuota icon={<Clock className="h-4 w-4" />} mb={1}>
                    {sessionDurationCopy}
                  </ResearchQuota>
                )}
                {bookingTypeCopy && (
                  <ResearchQuota icon={<Coffee className="h-4 w-4" />} mb={1}>
                    {bookingTypeCopy}
                  </ResearchQuota>
                )}
                {researchTypeCopy && (
                  <ResearchQuota icon={<MessageSquare className="h-4 w-4" />} mb={1}>
                    {researchTypeCopy}
                  </ResearchQuota>
                )}
              </Flex>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const getDeliverables = () => {
    const deliverables = askablePlusUtils.deliverables().map((deliverable) => {
      if (!project?.askable_plus?.deliverables?.[deliverable.key]) {
        return undefined;
      }

      if (deliverable.key === 'other') {
        return `&nbsp; &bull; &nbsp; Other: ${_.get(project, 'askable_plus.deliverables.other_description')}`;
      }

      return `&nbsp; &bull; &nbsp; ${deliverable.label}`;
    });

    return deliverables.toString().replaceAll(',', '\n');
  };

  return (
    <VStack alignItems="flex-start" spacing={6}>
      <Heading display={['none', 'none', 'block']} size="lg">
        {project.name}
      </Heading>
      <BriefContentItem title="Key objectives" description={project.askable_plus?.objective} />
      <BriefContentItem title="Project context" description={project.askable_plus?.description} />
      {renderParticipantCriteria()}
      {renderResearch()}
      <BriefContentItem title="Deliverables" description={getDeliverables()} />
      <BriefContentItem title="Additional notes" description={project?.askable_plus?.additional_info?.notes} />
    </VStack>
  );
}

export default ProjectBrief;
