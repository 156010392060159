import { Stat } from './Stat';

import type { StatProps } from 'containers/Studies/Results/Results';

export const Stats = ({ stats }: { stats: StatProps[] }) => {
  if (stats.length === 0) {
    return null;
  }

  return (
    <section id="stats" className="grid grid-cols-2 gap-4 md:grid-cols-4">
      {stats.map((stat) => (
        <Stat key={stat.type} {...stat} isBlock />
      ))}
    </section>
  );
};
