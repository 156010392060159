import { useMutation } from '@apollo/client';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';

import { Dialog, Modal, NoShowDialog, ParticipantIssueDialog } from 'components/common';
import ReInviteParticipant from 'components/manageBooking/inviteApplicants/invitationDialog/reInviteParticipant';
import InvitationDialog from 'components/manageBooking/inviteApplicants/invitationDialog/view';
import RevokeInvitationDialog from 'components/manageBooking/inviteApplicants/revokeInvitationDialog/revokeInvitationDialog';
import cancelParticipantSessionMutation from 'data/mutations/bookingSubmission/cancelParticipantSession';
import reInviteParticipantSessionMutation from 'data/mutations/bookingSubmission/reInviteParticipantSession';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import SessionToReschedulePopover from './sessionToReschedulePopover';

import './actionsContainer.scss';

function ActionsContainer(props: any) {
  const [openCancelParticipantDialog, setOpenCancelParticipantDialog] = useState(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openReinviteParticipant, setOpenReinviteParticipant] = useState(false);
  const [loadingReinvitation, setLoadingReinvitation] = useState(false);
  const [openRevokeInviteModal, setOpenRevokeInviteModal] = useState(false);
  const [openNoShowDialog, setOpenNoShowDialog] = useState(false);
  const [openIssueDialog, setOpenIssueDialog] = useState(false);
  const [openInviteParticipantDialog, setOpenInviteParticipantDialog] = useState(false);
  const [singleSessionWaitlisted, setSingleSessionWaitlisted] = useState<any>(undefined);

  const firstName = _.get(props.participantSelected, 'user.meta.identity.firstname');
  const startSession = momentTimezone(_.get(props.sessionSelected, 'start'))
    .tz(props.timezone)
    .format('ddd Do MMM hh:mm');
  const endSession = momentTimezone(_.get(props.sessionSelected, 'end')).tz(props.timezone).format('hh:mm a');
  const singleSession = {
    session: _.get(props, 'sessionSelected'),
    participant: {
      ..._.get(props, 'participantSelected'),
      invitedSession: {
        _id: _.get(props, 'participantSelected._id'),
      },
      user: _.get(props, 'participantSelected.user'),
    },
    booking_participant_id: _.get(props, 'participantSelected._id'),
    isAvailableForSession: true,
  };
  const hasRating =
    _.get(props.participantSelected, 'rating.engagement') || _.get(props.participantSelected, 'rating.overall');

  const [cancelParticipantSession] = useMutation(cancelParticipantSessionMutation, {
    client: props.client,
  });
  const [reInviteParticipantSession] = useMutation(reInviteParticipantSessionMutation, {
    client: props.client,
  });

  const onCancelParticipant = () => {
    cancelParticipantSession({
      variables: {
        booking_id: props.booking._id,
        participant_session_id: _.get(props, 'participantSelected._id'),
      },
    });
    props.onClose();
  };

  const onSendMessage = () => {
    props.onSendMessage(_.get(props, 'participantSelected'));
    props.onClose();
  };

  const onCloseSessionToReschedulePopover = () => {
    setOpenRescheduleModal(false);
    props.onClose();
  };

  const onConfirmReInvitation = async () => {
    setLoadingReinvitation(true);

    await reInviteParticipantSession({
      variables: {
        booking_id: props.booking._id,
        participant_session_id: _.get(props, 'participantSelected._id'),
      },
    });
    setLoadingReinvitation(false);
    props.onClose();
  };

  const renderActionContainer = (iconPath: any, label: any, action: any, id?: any) => {
    return (
      <div className={`action ${action ? 'hasAction' : 'noAction'}`} onClick={action} id={id}>
        <div className="iconContainer">
          <img alt={`icon-${label}`} src={iconPath} className="actionIcon" />
        </div>
        <span>{label}</span>
      </div>
    );
  };

  const renderParticipantIssueContainer = () => {
    return renderActionContainer('/icons/issueIcon.svg', 'There was an issue', () => {
      setOpenIssueDialog(true);
    });
  };

  const renderContextActions = () => {
    // Confirmed participant (upcoming session) actions:
    //   Cancel Participant
    //   Reschedule
    // Confirmed participant (happening session) actions:
    //   Mark as no show
    // Invited participant actions:
    //   Resend invite
    //   Invite to different time
    //   Revoke invite
    // Completed participant actions:
    //   Mark as no show
    const isInPast = utils.isInPast(props.sessionSelected.end, _.get(props.booking, 'config.timezone'));
    const sessionStarted =
      utils.isInPast(props.sessionSelected.start, _.get(props.booking, 'config.timezone')) && !isInPast;

    // Waitlisted participant
    if (_.get(props, 'sessionStatus.label') === 'Waitlisted') {
      const sessionParticipants = _.get(props, 'sessionSelected.participants');
      const invitedParticipants = sessionParticipants.filter(
        (participant: any) => participant.status === 4 && participant.cancel === 0,
      );
      const confirmedParticipants = sessionParticipants.filter(
        (participant: any) => participant.status === 1 && participant.cancel === 0,
      );

      return (
        <div className="contextContainer">
          {confirmedParticipants.length === 0 &&
            renderActionContainer('/icons/sendInvite.svg', 'Invite', () => {
              let sessionStatus;

              // It should check how many participants got invited for session
              if (invitedParticipants.length > 0) {
                sessionStatus = {
                  label: `(${invitedParticipants.length} ${invitedParticipants.length === 1 ? 'person' : 'people'} invited)`,
                  qtd: invitedParticipants.length,
                  status: 'invited',
                };
              }

              setSingleSessionWaitlisted({
                session: _.get(props, 'sessionSelected'),
                sessionStatus,
                participant: {
                  ..._.get(props, 'participantSelected'),
                  user: _.get(props, 'participantSelected.user'),
                },
                participant_session_id: _.get(props, 'participantSelected._id'),
                isAvailableForSession: true,
              });
              setOpenInviteParticipantDialog(true);
            })}
          {renderActionContainer('/icons/cancelParticipant.svg', 'Cancel participant', () =>
            setOpenCancelParticipantDialog(true),
          )}
        </div>
      );
    }

    // Confirmed participant (upcoming session) actions:
    if (_.get(props, 'sessionStatus.label') === 'Confirmed' && !isInPast) {
      return (
        <div className="contextContainer">
          {renderActionContainer('/icons/cancelParticipant.svg', 'Cancel participant', () =>
            setOpenCancelParticipantDialog(true),
          )}
          {sessionStarted &&
            !hasRating &&
            renderActionContainer('/icons/noShow.svg', "Person didn't show up", () => setOpenNoShowDialog(true))}
          {sessionStarted && !hasRating && renderParticipantIssueContainer()}
          {!bookingUtils.isFocusGroup(props.booking) &&
            renderActionContainer(
              '/icons/rescheduleParticipant.svg',
              'Reschedule',
              () => {
                setOpenRescheduleModal(true);
              },
              'rescheduleAction',
            )}
        </div>
      );
    }

    // Confirmed participant (happening session) actions:
    if (_.get(props, 'sessionStatus.label') === 'Confirmed' && isInPast) {
      return (
        <div className="contextContainer">
          {renderActionContainer('/icons/noShow.svg', "Person hasn't shown up", () => setOpenNoShowDialog(true))}
        </div>
      );
    }

    // Invited participant actions:
    if (_.get(props, 'sessionStatus.class') === 'invited') {
      return (
        <div className="contextContainer">
          {renderActionContainer('/icons/resendInvite.svg', 'Resend invite', () => setOpenReinviteParticipant(true))}
          {renderActionContainer('/icons/revokeInvite.svg', 'Revoke invite', () => setOpenRevokeInviteModal(true))}
        </div>
      );
    }

    // Completed participant actions:
    if (_.get(props, 'sessionStatus.label') === 'Completed') {
      return (
        <div className="contextContainer">
          {!hasRating &&
            renderActionContainer('/icons/noShow.svg', "Person didn't show up", () => {
              setOpenNoShowDialog(true);
            })}
          {!hasRating && renderParticipantIssueContainer()}
        </div>
      );
    }
  };

  return (
    <div className="actionsComponent">
      {renderContextActions()}
      {renderActionContainer('/icons/sendMessage.svg', 'Send message', () => onSendMessage())}
      <hr />
      {renderActionContainer('/icons/viewOnParticipantsPage.svg', 'View on participant page', () => {
        props.viewParticipantsScreen();
      })}
      {openCancelParticipantDialog && (
        <Dialog
          title={`Are you sure you want to cancel ${firstName}'s session?`}
          modal={false}
          open={openCancelParticipantDialog}
          onRequestClose={() => setOpenCancelParticipantDialog(false)}
          styles={{ width: '300px' }}
          bodyStyle={{ zIndex: 2200 }}
          customActions={[
            {
              label: 'No',
              primary: true,
              customFunction: () => setOpenCancelParticipantDialog(false),
            },
            {
              label: 'Yes',
              primary: true,
              customFunction: () => onCancelParticipant(),
            },
          ]}
        >
          <>
            <h3>Session details</h3>
            <p>
              {startSession} - {endSession}
            </p>
            <p>
              <strong>Note:</strong> {firstName} will get notified
            </p>
          </>
        </Dialog>
      )}
      {openRescheduleModal && (
        <Modal
          open={openRescheduleModal}
          onClose={() => setOpenRescheduleModal(false)}
          className="modalRescheduleParticipantInContext"
          styles={{ zIndex: 2000 }}
        >
          <SessionToReschedulePopover
            client={props.client}
            sessionToReschedule={props.sessionSelected}
            timezone={props.timezone}
            booking_id={_.get(props, 'booking._id')}
            sessionDuration={_.get(props, 'booking.config.session.duration')}
            onClose={onCloseSessionToReschedulePopover}
            sessionSelected={{
              ...props.sessionSelected,
              username: _.get(props, 'participantSelected.user.meta.identity.firstname'),
              userTimezone: _.get(props, 'participantSelected.user.location.timezone'),
            }}
          />
        </Modal>
      )}
      {openReinviteParticipant && (
        <ReInviteParticipant
          {...props}
          open={openReinviteParticipant}
          onCloseInvitationDialog={() => setOpenReinviteParticipant(false)}
          styles={{ zIndex: 2000 }}
          singleSession={singleSession}
          loading={loadingReinvitation}
          onConfirm={onConfirmReInvitation}
          showConfirmationMessage={false}
        />
      )}
      {openRevokeInviteModal && (
        <RevokeInvitationDialog
          {...props}
          open={openRevokeInviteModal}
          styles={{ zIndex: 2000 }}
          onClose={() => {
            setOpenRevokeInviteModal(false);
            props.onClose();
          }}
          singleSession={singleSession}
        />
      )}
      {openNoShowDialog && (
        <NoShowDialog
          client={props.client}
          open={openNoShowDialog}
          onClose={() => setOpenNoShowDialog(false)}
          onClickDone={props.onClose}
          participant={_.get(props, 'participantSelected')}
          booking={props.booking}
        />
      )}

      {openIssueDialog && (
        <ParticipantIssueDialog
          client={props.client}
          open={openIssueDialog}
          onClose={() => setOpenIssueDialog(false)}
          onClickDone={props.onClose}
          participant={_.get(props, 'participantSelected')}
          booking={props.booking}
        />
      )}
      {openInviteParticipantDialog && (
        <InvitationDialog
          {...props}
          open={openInviteParticipantDialog}
          onCloseInvitationDialog={() => setOpenInviteParticipantDialog(false)}
          singleSession={singleSessionWaitlisted!} // This is set before openInviteParticipantDialog
          isBulkInvite={false}
          onRefreshTable={() => null}
          showConfirmationMessage={false}
          onFinishInvitationProcess={() => props.onClose()}
        />
      )}
    </div>
  );
}

export default ActionsContainer;
