import {
  getExampleNumber,
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { localStorage } from 'lib/storage';
import { update } from 'lib/utils';

import CountryCodeSelect from '../CountryCodeSelect/view';

import type { CountryCode, PhoneNumber as PhoneNumberType } from 'libphonenumber-js';

import './styles/PhoneInputStyles.scss';

type Props = {
  id?: string;
  phoneNumber: string;
  countryCode: string;
  errorTextBottom?: string;
  className?: string;
  underlineShow?: boolean;
  onChange?: ({ phone, country_code }: { phone: string; country_code: string }) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

function PhoneInput(props: Props) {
  const [defaultCountry, setDefaultCountry] = useState<typeof countryCodeData>([]);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState('');

  const [phoneState, setPhoneState] = useState({
    countryPhoneCode: _.get(defaultCountry, '[0].country_code'),
    countryCodeSelected: _.get(defaultCountry, '[0].region'),
    countryPhone: getExamplePhoneNumber(_.get(defaultCountry, '[0].region')),
    phoneNumber: formatPhone(
      props.phoneNumber,
      _.get(defaultCountry, '[0].country_code'),
      _.get(defaultCountry, '[0].region'),
    ),
  });

  const [countryList, setCountryList] = useState(countryCodeData);

  useEffect(() => {
    let defaultCountryList = _.filter(
      countryCodeData,
      (country) => country.country_code === props.countryCode || country.region === props.countryCode,
    );
    if (defaultCountryList.length > 1) {
      defaultCountryList = _.filter(
        defaultCountryList,
        (country) => country.region === localStorage.get('countryByIp'),
      );
    }
    if (defaultCountryList.length === 0) {
      if (localStorage.get('countryByIp')) {
        defaultCountryList = _.filter(countryCodeData, (country) => country.region === localStorage.get('countryByIp'));
      } else {
        defaultCountryList = _.filter(countryCodeData, (country) => country.region === 'AU');
      }
    }
    if (!phoneState.countryCodeSelected) {
      setDefaultCountry(defaultCountryList);
    }
  }, [props.phoneNumber]);

  useEffect(() => {
    setPhoneState({
      countryPhoneCode: _.get(defaultCountry, '[0].country_code'),
      countryCodeSelected: _.get(defaultCountry, '[0].region'),
      countryPhone: getExamplePhoneNumber(_.get(defaultCountry, '[0].region')),
      phoneNumber: formatPhone(
        props.phoneNumber,
        _.get(defaultCountry, '[0].country_code'),
        _.get(defaultCountry, '[0].region'),
      ),
    });
  }, [defaultCountry]);

  function formatPhone(phone: string, countryCode: string, region: CountryCode) {
    if (!phone) {
      return '';
    }
    let nationalPhone = phone;
    if (nationalPhone.startsWith(countryCode)) {
      nationalPhone = nationalPhone.replace(countryCode, '');
    }

    if (['AU', 'AF', 'GB', 'FR', 'SE', 'NZ', 'IR', 'IL'].includes(region) && !nationalPhone.startsWith('0')) {
      nationalPhone = `0${nationalPhone}`;
    }
    return formatIncompletePhoneNumber(nationalPhone, region);
  }

  function getExamplePhoneNumber(countryCode: CountryCode) {
    try {
      const PhoneNumber = getExampleNumber(countryCode, examples);
      return PhoneNumber?.formatNational();
    } catch (e) {
      return '';
    }
  }

  const updateCountryList = (country: string) => {
    let newCountryList = [];
    if (country && country !== '') {
      const countryData = countryCodeData.find((c) => c.name.toLowerCase() === country.toLowerCase());
      if (!countryData) {
        console.warn(`Country ${country} not found in the list`);
        return;
      }
      newCountryList = update(countryList, {
        $set: [countryData],
      });
    } else {
      newCountryList = update(countryList, {
        $set: countryCodeData,
      });
    }

    setCountryList(newCountryList);

    if (newCountryList.length > 0) {
      try {
        setPhoneState({
          countryCodeSelected: _.get(newCountryList, '[0].region'),
          countryPhoneCode: _.get(newCountryList, '[0].country_code'),
          countryPhone: getExamplePhoneNumber(_.get(newCountryList, '[0].region')),
          phoneNumber: formatIncompletePhoneNumber(phoneState.phoneNumber, _.get(newCountryList, '[0].region')),
        });
        if (phoneState.phoneNumber && phoneState.phoneNumber !== '') {
          const phone = parsePhoneNumber(
            parseIncompletePhoneNumber(phoneState.phoneNumber),
            _.get(newCountryList, '[0].region'),
          );
          setError(!phone.isValid());
        }
      } catch (e) {
        setError(true);
      }
    }
  };

  const updatePhoneNumber = (value: string) => {
    const phoneFormatted = formatIncompletePhoneNumber(value, phoneState.countryCodeSelected);
    // setPhoneNumber(phoneFormatted || value);
    setPhoneState({
      ...phoneState,
      phoneNumber: phoneFormatted || value,
    });
    let phone: string | PhoneNumberType = parseIncompletePhoneNumber(value);
    if (phone) {
      try {
        phone = parsePhoneNumber(phone, phoneState.countryCodeSelected);
      } catch (e) {
        setError(false);
      }
    }

    if (props.onChange) {
      props.onChange({
        phone: _.get(phone, 'number', value),
        country_code: `+${_.get(phone, 'countryCallingCode', '')}`,
      });
    }

    if (!(value && value !== '')) setError(false);
  };

  return (
    <div className="phoneComponentContainer" id={props.id}>
      <div
        className={`phoneInputContainer ${focused} ${props.className ? props.className : ''} ${props.underlineShow ? 'underlined' : ''} ${
          props.errorTextBottom || error ? 'error' : ''
        } border-1 border-border bg-transparent`}
      >
        <CountryCodeSelect
          currentRegion={phoneState.countryCodeSelected}
          onChange={(country) => updateCountryList(country.name)}
        />
        <div className="phoneInputValue ml-2">
          <input
            id="__phoneInputComponent"
            className="phoneInput"
            value={phoneState.phoneNumber}
            onChange={(item) => updatePhoneNumber(item.target.value)}
            onFocus={(e) => {
              e.target.select();
              setFocused('focused');
              if (props.onFocus) props.onFocus();
            }}
            onBlur={() => {
              setFocused('');
              if (props.onBlur) props.onBlur();
            }}
          />
        </div>
      </div>
      {error && <p className="errorMessage">Invalid phone number</p>}
      {props.errorTextBottom && <p className="errorMessage">{props.errorTextBottom}</p>}
    </div>
  );
}

export default PhoneInput;
