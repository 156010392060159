import type { ReactNode } from 'react';

interface SideNavProps {
  children: ReactNode;
  title?: string;
}

export const SideNav = ({ title, children }: SideNavProps) => {
  return (
    <nav className="page-nav overflow-hidden p-4 pr-0">
      <div className="flex h-full flex-col rounded-xl bg-background p-3 lg:p-4">
        <header className="flex items-center justify-between gap-2">{title}</header>
        {children}
      </div>
    </nav>
  );
};
