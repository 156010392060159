import { gql } from '@apollo/client';

import { projectFieldsFragment } from 'data/fragments/project/projectFields';

export default gql`
  query FetchProjectsByTeam($_team_id: ID, $type: [Int], $status: [Int], $sortBy: String, $order: Int, $_user_id: ID) {
    projectsByTeam(
      _team_id: $_team_id
      type: $type
      status: $status
      sortBy: $sortBy
      order: $order
      _user_id: $_user_id
    ) {
      ...projectFields
      bookings {
        _id
        name
        status
        created
        type
        total_participants
        config {
          demo
          question {
            _id
            title
            description
            config {
              display_participant
              multiple_selection
              none_of_the_above
              type
            }
            options {
              _id
              label
              screen_in
            }
          }
          recruitment {
            byo
          }
          online_task {
            type
          }
          project {
            project_type
          }
        }
        session {
          _id
          start
        }
        user {
          _id
          email
          meta {
            identity {
              firstname
              lastname
              middlename
              gender
            }
            social {
              google {
                id
              }
            }
            work {
              title
              company_name
            }
          }
        }
        ParticipantSessions(status: [1], cancel: [0]) {
          _id
          cancel
          no_show_request
          issue_request
          status
        }
        rating {
          overall
          worthwhile
          stress_free
        }
      }
    }
  }
  ${projectFieldsFragment}
`;
