import { FacedFilter as FacedFilterComp } from '@askable/ui/components/ui/table-faced-filter';

import type { Column } from '@tanstack/react-table';
import type { ComponentType } from 'react';

type Props<TData, TValue> = {
  popoverContentClassName?: string;
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    label: string;
    value: string | number;
    icon?: ComponentType<{ className?: string }>;
  }[];
};

export const FacetedFilter = <TData, TValue>({
  column,
  title,
  options,
  popoverContentClassName,
}: Props<TData, TValue>) => {
  const facets = column?.getFacetedUniqueValues();

  return (
    <FacedFilterComp
      facets={facets}
      title={title}
      filterValue={column?.getFilterValue() as (string | number)[]}
      options={options}
      setFilterValue={column?.setFilterValue}
      popoverContentClassName={popoverContentClassName}
    />
  );
};
