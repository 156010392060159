import { Button } from '@askable/ui/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { askablePlusUtils } from 'lib/askablePlus';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

function LongitudinalStudyDuration(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const arrayOfDaysWeeks = utils.generateNumbersArray(1, 31);
  const [, setFrequencyError] = useState(false);
  const [, setPeriodError] = useState(false);
  const [, setWorkloadError] = useState(false);

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected <= 1 || !hasLongitudinalStudyType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`);
    } else {
      props.updateLastStep({
        step: 'Longitudinal Study',
        subStep: `/askable-plus/${askablePlusBrief._id}/longitudinal-study/study-duration`,
        stepId: 'longitudinal_study_duration',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onChangeParticipantWorkloadTime = (value: string) => {
    setWorkloadError(false);

    const [time, frequency] = value.split(':');

    const parsedValue = bookingUtils
      .measureTimes()
      .find((v) => v.time === Number(time) && v.measure === Number(frequency));

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              participant_workload: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study
                  .participant_workload,
                time: parsedValue?.time ?? null,
                measure: parsedValue?.measure ?? null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeParticipantWorkloadFrequency = (value: string) => {
    setFrequencyError(false);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              participant_workload: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study
                  .participant_workload,
                frequency: value ? Number(value) : null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeBookingPeriod = async (value: string, field: 'frequency' | 'time') => {
    const parsedValue = Number(value);
    if (field === 'frequency') {
      setFrequencyError(false);
    }

    if (field === 'time') {
      setPeriodError(false);
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              period: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study.period,
                [field]: parsedValue || null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const renderParticipantWorkloadContainer = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
      '',
    );

    const workloadMeasure = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.measure',
      '',
    );

    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
    );

    return (
      <div key="participantWorkloadContainer" className="longitudinalConfigContainer bookingFormAccordion space-x-2">
        <Select onValueChange={onChangeParticipantWorkloadTime} defaultValue={`${workloadTime}:${workloadMeasure}`}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.measureTimes().map((value) => {
              return (
                <SelectItem key={value.time} value={`${value.time}:${value.measure}`}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
        <span>per</span>
        <Select onValueChange={onChangeParticipantWorkloadFrequency} value={`${workloadFrequency}`}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.frequencyTypes().map((value) => {
              return (
                <SelectItem key={value.value} value={`${value.value}`}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderParticipantWorkloadError = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
    );
    if (!workloadTime || workloadTime === 0) {
      return (
        <div className="requiredFieldContainer">
          <span className="smallText">This field is required</span>
        </div>
      );
    }
    return null;
  };

  const renderBookingPeriodContainer = () => {
    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
    );
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );

    return (
      <div key="studyDurationContainer" className="flex space-x-4">
        <Select onValueChange={(value) => onChangeBookingPeriod(value, 'time')} value={`${periodTime}`}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {arrayOfDaysWeeks.map((value) => {
              return (
                <SelectItem key={value} value={`${value}`}>
                  {value}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <Select onValueChange={(value) => onChangeBookingPeriod(value, 'frequency')} value={`${periodFrequency}`}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.frequencyTypes(true).map((value) => {
              return (
                <SelectItem key={value.value!} value={`${value.value}`}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderBookingPeriodError = () => {
    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
      '',
    );
    if (!periodTime || periodTime === 0) {
      return (
        <div className="periodTimeRequired">
          <span className="smallText">This field is required</span>
        </div>
      );
    }
    return null;
  };

  const renderBookingPeriodFrequencyError = () => {
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    // You cannot select a period frequency lower than the workload frequency
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
      1,
    );
    if (periodFrequency < workloadFrequency) {
      return (
        <div className="requiredFieldContainer">
          <span className="smallText">Period must be greater than workload.</span>
        </div>
      );
    }
    return null;
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Study duration</h1>
      <div className="space-y-6">
        <div className="space-y-2">
          <p>Participant workload</p>
          {renderParticipantWorkloadContainer()}
          {renderParticipantWorkloadError()}
        </div>
        <div className="space-y-2">
          <p>To be done over</p>
          {renderBookingPeriodContainer()}
          {renderBookingPeriodError()}
          {renderBookingPeriodFrequencyError()}
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(LongitudinalStudyDuration);
