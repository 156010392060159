import { useEffect } from 'react';

import { useUserLatLong } from '../LocationAutocomplete/useUserLatLong';

import type { Location } from 'generated/graphql';
import type { CSSProperties } from 'react';

const MAX_ZOOM = 12;
const DEFAULT_ZOOM = 8;
const STATE_ZOOM = 4;
const COUNTRY_ZOOM = 2;

type LatLng = { lat: number; lng: number };
type Props = {
  map: google.maps.Map;
  location?: (Location & { locationViewport?: { northeast: LatLng; southwest: LatLng } }) | null;
  type?: 'state' | 'country' | 'bounds';
  id: string;
  style: CSSProperties;
};
function GoogleMapsMultiRegion({ map, location, id, style, type }: Props) {
  const { latitude, longitude } = useUserLatLong();
  useEffect(() => {
    updateMap();
  }, [map, location, type]);

  const updateMap = async () => {
    const _locations = new window.google.maps.LatLngBounds();

    if (!location) {
      // set to users ip. we should change this to team location once we have lat/long for team.
      if (latitude && longitude) {
        const latLng = new window.google.maps.LatLng(latitude, longitude);
        _locations.extend(latLng);
      }
    } else {
      if (!location?.latitude || !location?.longitude) {
        return;
      }
      const latLng = new window.google.maps.LatLng(location.latitude, location.longitude);
      _locations.extend(latLng);
    }

    map.setCenter(_locations.getCenter());

    if (type === 'country') {
      map.setZoom(COUNTRY_ZOOM);
    }

    if (type === 'state') {
      map.setZoom(STATE_ZOOM);
    }

    if (type === 'bounds') {
      map.setZoom(DEFAULT_ZOOM);
    }

    const currentZoom = map.getZoom() ?? 0;
    if (currentZoom > MAX_ZOOM) {
      map.setZoom(MAX_ZOOM);
    }
  };

  return <div id={id} style={style || { width: 548, height: 233 }} />;
}

export default GoogleMapsMultiRegion;
