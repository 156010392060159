import { useTranslation } from 'react-i18next';

import { useResultsContext } from 'containers/Studies/Results/Results';
import { Stats } from 'containers/Studies/Results/components/Stats';
import { TaskBlockHeader } from 'containers/Studies/Results/components/TaskBlockHeader';

import { IndividualResponses } from './IndividualResponses';
import { ScreenAnalysis } from './ScreenAnalysis';
import { TaskAnalysis } from './TaskAnalysis';

export const TaskBlockContent = () => {
  const { t } = useTranslation();
  const { results, activeBlockId } = useResultsContext();

  const taskBlock =
    activeBlockId === 'welcome' || activeBlockId === 'thank_you'
      ? results?.tasks[0]
      : results?.tasks?.find((block) => block.id === activeBlockId);

  return (
    <div id={`task-block-${taskBlock?.id}`} className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8">
      {taskBlock ? (
        <>
          <TaskBlockHeader title={taskBlock.title} type={taskBlock.type} responses={taskBlock.responses} />
          <Stats stats={taskBlock.stats} />
          <TaskAnalysis sections={taskBlock.sections} />
          <ScreenAnalysis sections={taskBlock.sections} />
          <IndividualResponses participants={results?.participants ?? []} />
        </>
      ) : (
        <div>{t('sections.studiesResults.noTaskBlock')}</div>
      )}
    </div>
  );
};
