import _ from 'lodash';

import type { Taxes, TeamCurrency, Teams } from 'generated/graphql';

type SavedParamsType = {
  currency?: TeamCurrency | undefined;
  tax?: Taxes | undefined;
};

const teamUtils = {
  stored: {} as SavedParamsType,
  save(params: SavedParamsType) {
    teamUtils.stored = { ...teamUtils.stored, ...params };
  },
  getDefaultCurrency(): TeamCurrency {
    return { symbol: '$', code: 'USD' };
  },
  getCurrency() {
    // Fallback to USD
    return teamUtils.stored?.currency ?? teamUtils.getDefaultCurrency();
  },
  getTax: () => teamUtils.stored?.tax,
  validateTeamBillingAddress(team: any) {
    // Function to check if all team billing address details have been filled
    let valid = false;
    const { settings } = team;
    if (_.has(settings, 'billing.location')) {
      if (
        _.get(settings, 'billing.location.street1') !== '' &&
        _.get(settings, 'billing.location.city') !== '' &&
        _.get(settings, 'billing.location.postal_code') !== null &&
        _.get(settings, 'billing.location.state') !== '' &&
        _.get(settings, 'billing.location.country') !== ''
      ) {
        valid = true;
      }
    }
    return valid;
  },
  normaliseTeamObjectToSave(team: any) {
    const newTeamObject = { ...team };
    // Check if the postal code is "" (In case the user has left it blank) and transform it to null
    if (_.get(team, 'settings.billing.location.postal_code') === '') {
      newTeamObject.settings.billing.location.postal_code = null;
    }
    return newTeamObject;
  },
  hasCredits(team: any) {
    return _.get(team, 'settings.billing.subscription.credit.remaining', 0) > 0;
  },
  getCredits(team: any) {
    return _.get(team, 'settings.billing.subscription.credit.remaining', 0);
  },
  getCreditsExpireDate(team: any) {
    return _.get(team, 'settings.billing.subscription.end');
  },
  getTeamUserStatus(user: any) {
    switch (_.get(user, 'status')) {
      case 0:
        return 'Deactivated';
      case 1:
        return 'Active';
      default:
        return 'Active';
    }
  },
  getRoleDescription(user: any) {
    switch (_.get(user, 'role')) {
      case 1:
        return 'Admin';
      case 2:
        return 'Full Member';
      case 3:
        return 'Guest';
      case 4:
        return 'Askable+ Researcher';
      case 99:
        return 'Super Admin';
      default:
        return 'Admin';
    }
  },
  isAdmin(user: any) {
    // Method to check whether the user is an Admin or Super Admin
    const role = _.get(user, 'role');
    return parseInt(role, 10) === 1 || parseInt(role, 10) === 99;
  },
  isAdminByRoleId(roleId?: number | null) {
    // Method to check whether the user is an Admin or Super Admin
    return roleId === 1 || roleId === 99;
  },
  hasSuperAdmins(team: Pick<Teams, 'users'>) {
    // Method to check whether the team has super admins
    return _.size(_.filter(team.users, (user: any) => user.role === 99)) > 0;
  },
};

export { teamUtils };
