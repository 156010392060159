import { useEffect, type ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingOverlay } from 'components/common';
import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { ResearcherAuthContainer } from 'src/containers/ResearcherRegister/ResearcherAuthContainer';
import { ResearcherAuthProvider } from 'src/containers/ResearcherRegister/ResearcherAuthProvider';
import { ResearcherCertificationContainer } from 'src/containers/ResearcherRegister/ResearcherCertificationContainer/ResearcherCertificationContainer';

import type { RouteObject } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const KindeResearcherRedirect = ({ children }: Props) => {
  const isKinde = useIsKinde();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isKinde) {
      console.error('Current researcher is not a Kinde user');
    }

    const params = searchParams.toString();
    // Redirect to '/login' with all the current search parameters
    navigate(`/login${params ? `?${params}` : ''}`, { replace: true });
  }, [isKinde]);

  return isKinde ? <LoadingOverlay /> : children;
};

export const researcherRoutes: RouteObject[] = [
  {
    path: '/researcher',
    element: (
      <ResearcherAuthProvider>
        <ResearcherAuthContainer />
      </ResearcherAuthProvider>
    ),
    children: [
      {
        path: 'certification',
        element: <ResearcherCertificationContainer />,
      },
      {
        path: 'login',
        element: (
          <KindeResearcherRedirect>
            <div />
          </KindeResearcherRedirect>
        ),
      },
    ],
  },
];
