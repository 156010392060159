import { gql } from '@apollo/client';

import { projectFieldsFragment } from 'data/fragments/project/projectFields';

export default gql`
  subscription updateProjectSubscription($id: ID!) {
    projectByID(id: $id) {
      ...projectFields
    }
  }
  ${projectFieldsFragment}
`;
