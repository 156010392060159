import { AskableIconLoading } from '@askable/ui/components/ui/askable-icon';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState, type ReactNode } from 'react';
import { useMutation } from 'urql';

import { PatchKindeUserDocument } from 'generated/graphql';
import { useAddUTMToAppState } from 'src/hooks/useAddUTMToAppState';

import { useIsKinde } from './AuthWrapper';

import type { PatchKindeUserMutation } from 'generated/graphql';

export const KindePropertyWrapper = ({ children }: { children: ReactNode }) => {
  const isKinde = useIsKinde();
  const [isReady, setIsReady] = useState(false);
  const { getToken, login, isLoading } = useKindeAuth();
  const { addUTMToAppState } = useAddUTMToAppState();
  const [, patchUser] = useMutation<PatchKindeUserMutation>(PatchKindeUserDocument);

  useEffect(() => {
    if (!isKinde) {
      setIsReady(true);
      return;
    }

    if (isLoading) {
      return;
    }

    getToken().then((r) => {
      const decodedToken = jwtDecode(r!) as {
        user_properties: { askable_user_id: { t?: string; v?: string }; askable_user_type: { t?: string; v?: string } };
      };

      if (decodedToken.user_properties.askable_user_id?.v && decodedToken.user_properties.askable_user_type?.v) {
        setIsReady(true);
        return;
      }

      patchUser()
        .then((patchResult) => {
          if (patchResult.data?.patchKindeUser) {
            login({
              app_state: addUTMToAppState(),
            });
          } else {
            setIsReady(true);
          }
        })
        .catch(() => {
          setIsReady(true);
        });
    });
  }, [isLoading, isKinde]);

  if (!isReady) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <AskableIconLoading />
      </div>
    );
  }

  return children;
};
