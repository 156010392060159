import { Button } from '@askable/ui/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';

function TaskDuration(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [sessionDurationValue, setSessionDurationValue] = useState(_.get(booking, 'config.session.duration'));

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/task-duration`,
      stepId: 'project_setup_task_duration',
    });
    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/project-setup/session-duration` });
    }
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
  }, [props.booking]);

  useEffect(() => {
    parseSessionDuration(sessionDurationValue);
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [booking, bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText:
          'Yes they do. Since the price above includes participant incentives, running longer sessions will require higher incentives and therefore cost a bit more.',
      },
    ];
    let typeLabel = 'sessions';
    if (bookingUtils.isLongitudinal(booking)) typeLabel = 'studies';
    else if (bookingUtils.isSurvey(booking)) typeLabel = 'surveys';
    else if (bookingUtils.isOnlineTask(booking)) typeLabel = 'tasks';

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__taskDurationCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle={`Do longer ${typeLabel} cost more?`}
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'http://help.askable.com/en/articles/4254373-learning-more-about-credits#h_8ee2bdaff0',
              '_blank',
            );
          }}
        />
      </Fragment>
    );
  };

  const parseSessionDuration = (duration: any) => {
    setSessionDurationValue(duration);
  };

  const onChangeValue = (value: any) => {
    parseSessionDuration(value);

    if (value && value > 0) {
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          session: {
            ...bookingState.config.session,
            duration: value,
          },
        },
      };
      setBookingState(bookingStateObj);

      if (value !== _.get(booking, 'config.session.duration')) {
        const bookingObj = {
          config: {
            session: {
              duration: value,
            },
          },
        };
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
        setBookingData(bookingObj);
        props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
      } else {
        setBookingData(null);
        props.history.replace({ booking: null, bookingState: null });
      }
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/project-setup/participant-selection`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Task duration
      </h1>
      <p className="label">{`Roughly how long should your ${bookingUtils.isSurvey(booking) ? 'survey' : 'online task'} take to complete?`}</p>

      <Select onValueChange={onChangeValue} value={sessionDurationValue}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {bookingUtils
            .sessionDurations(_.get(booking, 'type'), _.get(booking, 'config.online_task.type'))
            ?.map((value) => {
              return (
                <SelectItem key={value.value} value={value.value as unknown as string}>
                  {value.label}
                </SelectItem>
              );
            })}
        </SelectContent>
      </Select>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(TaskDuration);
