import { useMemo } from 'react';
import { Heading, Text, VStack, Image, Container } from 'ui';

import { ResearcherCertificationStatus } from 'generated/graphql';

import type { Maybe } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  firstname?: Maybe<string>;
  status?: Maybe<ResearcherCertificationStatus>;
};

export const ResearcherCertificationPending: FC<Props> = ({ firstname, status }) => {
  const config = useMemo(() => {
    switch (status) {
      case ResearcherCertificationStatus.InProgress:
      case ResearcherCertificationStatus.InReview:
        return {
          icon: '/thumbs-up.svg',
          description:
            'We’ll review your submission and notify you once we come to a decision on your certification. This can take up to 3 business days.',
          title: `Thanks for your submission, ${firstname}!`,
        };

      case ResearcherCertificationStatus.OnHold: {
        return {
          icon: '/researcher_on_hold.svg',
          description:
            'We’re currently are looking for researchers with a bit more experience. As we expand our offering, there may be opportunities that call for your skillset, and we’ll notify you as they become available. Otherwise, we’ll hopefully see you in a few years.',
          title: "We're not a no, just not right now",
        };
      }

      case ResearcherCertificationStatus.Ineligible: {
        return {
          icon: '/researcher_ineligible.svg',
          title: "Sorry, we're just not meant to be",
          description: (
            <p>
              We appreciate your enthusiasm, but we don’t think Askable+ is the right fit for you right now. <br />
              <br />
              Your account will automatically be deleted in 5 days, however, if your circumstances change, and you gain
              more experience in UX research, we’d love to hear from you again.
            </p>
          ),
        };
      }
      default:
        return null;
    }
  }, [status]);

  // Shouldn't ever happen
  if (!config || status === ResearcherCertificationStatus.Certified) {
    return null;
  }

  return (
    <Container>
      <VStack alignItems="flex-start" spacing={9}>
        <Image src={config.icon} />
        <Heading>{config.title}</Heading>
        <Text>{config.description}</Text>
      </VStack>
    </Container>
  );
};
