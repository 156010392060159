import { useTranslation } from 'react-i18next';

import { ScreenItem } from 'containers/Studies/Results/components/ScreenItem';
import { ScreenListScrollable } from 'containers/Studies/Results/components/ScreenListScrollable';
import { Section } from 'containers/Studies/Results/components/Section';

import type { TaskSectionProps } from 'containers/Studies/Results/Results';

interface ScreenAnalysisProps {
  sections: TaskSectionProps[];
}

export const ScreenAnalysis = ({ sections }: ScreenAnalysisProps) => {
  const { t } = useTranslation();

  if (sections.length === 0) {
    return null;
  }

  const screens = sections[0].screens;

  return (
    <Section
      description={t('sections.studiesResults.screenAnalysisDescription')}
      id="screen-analysis"
      title={t('sections.studiesResults.screenAnalysisTitle')}
    >
      {screens.length > 0 ? (
        <ScreenListScrollable itemWidth={192}>
          {screens.map((screen, index) => (
            <ScreenItem key={screen.id} {...screen} index={index} />
          ))}
        </ScreenListScrollable>
      ) : (
        <div className="py-4 text-sm text-foreground-subtle">{t('sections.studiesResults.noScreens')}</div>
      )}
    </Section>
  );
};
