import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useFeatureFlags } from 'feature-flags';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { Table } from 'containers/Root/components/Table';
import { SkeletonLoaderTable } from 'containers/Studies/components/SkeletonLoaderTable';
import { Studies } from 'containers/Studies/data/Studies.query';
import { useConnectedClient } from 'context/ConnectedClientContext';

import { columns as columnsData } from './data/table/studiesColumns';

import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { StudiesTableSchemaType } from 'containers/Studies/data/table/studiesTableSchema';

type Props<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  fetching: boolean;
};

function StudiesTable<TData extends StudiesTableSchemaType, TValue>({ columns, data, fetching }: Props<TData, TValue>) {
  const { details } = useConnectedClient();
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    // These defaultColumn value is required for fixed columns
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <Table
      fetching={fetching}
      toolbar={null} // TODO: StudiesToolbar w/ status filter etc
      table={table}
      searchId="name"
      skeletons={<SkeletonLoaderTable />}
      tableNoDataToDisplayUi={{
        title: 'Studies',
        description1: !details?.type?.researcher
          ? "Here you'll find all the unmoderared studies created by you or anyone on your team."
          : "Here you'll find all of your Askable Plus project studies.",
        description2: !details?.type?.researcher
          ? 'Create a new study to choose your study type and start the recruitment process.'
          : 'You currently have no studies assigned to you.',
        createButton: !details?.type?.researcher
          ? {
              link: '/booking-setup/create',
              text: 'Create a study',
            }
          : undefined,
      }}
    />
  );
}

export const StudiesListContainer = () => {
  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  const [{ data, fetching }] = useQuery({ query: Studies, variables: { first: 1000, filter: {} } });
  const tableData = useMemo(() => [...(data?.studies?.nodes || [])].reverse?.(), [data?.studies?.nodes]);

  if (!UNMODERATED_STUDY) {
    return <Navigate to="/not-found" />;
  }

  return (
    <>
      <Helmet>
        <title>Studies</title>
      </Helmet>
      <div className="w-full flex-auto">
        <StudiesTable data={tableData} columns={columnsData} fetching={fetching} />
      </div>
    </>
  );
};
