import { z } from 'zod';

const baseBlockSchema = z.object({
  instructions: z.string().optional(),
  is_recording_enabled: z.boolean(),
  title: z.string().min(1, { message: 'title.required' }),
});

const welcomeBlockSchema = baseBlockSchema.extend({
  type: z.literal('welcome'),
});

const thankYouBlockSchema = baseBlockSchema.extend({
  type: z.literal('thank_you'),
});

const figmaPrototypeBlockSchema = baseBlockSchema.extend({
  type: z.literal('figma_prototype'),
  figma_prototype: z.object({
    file_id: z.string().min(1, { message: 'figmaPrototypeFileId.required' }),
    start_screen_id: z.string().min(1, { message: 'figmaPrototypeStartScreenId.required' }),
    goal_screen_id: z.string().min(1, { message: 'figmaPrototypeGoalScreenId.required' }),
  }),
});

export const blockSchema = z.discriminatedUnion('type', [
  welcomeBlockSchema,
  thankYouBlockSchema,
  figmaPrototypeBlockSchema,
]);
