import { ListTodo, MousePointerClick, Repeat2, User, Video, MessageSquareText } from 'lucide-react';
import { DataTestId } from 'shared-utils';

import { RESOLVED_BOOKING_TYPES } from 'utils/booking-utils';

import type { ReactNode } from 'react';

const defaultProps = {
  size: '16',
  className: 'text-muted-foreground',
};

export const typeData: Record<RESOLVED_BOOKING_TYPES, { Icon: (props: object) => ReactNode; title: string }> = {
  [RESOLVED_BOOKING_TYPES.IN_PERSON]: {
    Icon: User,
    title: 'In-person',
  },
  [RESOLVED_BOOKING_TYPES.VIDEO_CALL]: {
    Icon: Video,
    title: 'Video call',
  },
  [RESOLVED_BOOKING_TYPES.SURVEY]: {
    Icon: ListTodo,
    title: 'Survey',
  },
  [RESOLVED_BOOKING_TYPES.ONLINE_TASK]: {
    Icon: MousePointerClick,
    title: 'Online task',
  },
  [RESOLVED_BOOKING_TYPES.LONGITUDINAL_STUDY]: {
    Icon: Repeat2,
    title: 'Longitudinal study',
  },
  [RESOLVED_BOOKING_TYPES.AI_MODERATED]: {
    Icon: MessageSquareText,
    title: 'AI Moderated',
  },
};

type Props = {
  type: RESOLVED_BOOKING_TYPES;
};

export const BookingsCellType = ({ type }: Props) => {
  const { Icon, title } = typeData[type];

  return (
    <div className="flex items-center">
      <Icon {...defaultProps} />
      <span data-testid={DataTestId.cellBookingType} className="ml-2 text-muted-foreground">
        {title}
      </span>
    </div>
  );
};
