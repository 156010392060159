import type { Project } from 'generated/graphql';

export function getResearcherPayout(project: Project): number {
  const researcherHours = project?.pricing?.researcher_hours;
  const researcherHourlyRate = project.askable_plus?.researcher_hourly_rate;

  if (!researcherHours || !researcherHourlyRate) {
    throw new Error(
      'Project missing required arguments to calculate researcher payout. [pricing.researcher_hours, researcher_hourly_rate]',
    );
  }

  return researcherHours * researcherHourlyRate;
}
