import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@askable/ui/components/ui/dropdown-menu';
import { Filter } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const ResultsHeader = () => {
  const { t } = useTranslation();

  return (
    <header className="results-header flex flex-wrap items-center justify-between gap-2 px-5 py-2">
      <h1 className="text-xl font-semibold">{t('sections.studiesResults.responses')}</h1>

      <div>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <Filter className="h-4 w-4" /> {t('sections.studiesResults.filter')}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Item 1</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
