import { IconProps } from '.';
import { getColorValue } from '@askable/ui/lib/utils';

export const Smile = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        d="M11 10.3a27.3 27.3 0 0 0-1 39.1c2.5 2.7 5.4 5 8.7 6.5 14.1 6.6 29.2 4.4 37.9-5.8a25.7 25.7 0 0 0 4-24.7C58.2 18 49.2 11.2 41.9 7.6a33 33 0 0 0-11.3-3.4c-7-.8-14.2.7-19.6 6Z"
      />
      <path
        fill={fgHsl}
        d="M42 51.3a24.7 24.7 0 0 1-20.5 3.3A23.9 23.9 0 0 1 7.9 43 25.1 25.1 0 0 1 18 8.2c6-3.3 12.6-3 18.7-.6A31 31 0 0 1 51 19c3.2 5 4.5 11 3.2 16.7-1.3 5.7-5 11.3-12.1 15.6Z"
      />
      <path
        fill={bgHsl}
        d="M19.5 35a2.8 2.8 0 0 0-4-.7c-1.7 1.2-.8 3.7-.6 4a11 11 0 0 0 .9 1.2 17.3 17.3 0 0 0 12.3 6.2 16 16 0 0 0 10-3 19.7 19.7 0 0 0 3.7-3.1c1-1.2 1.3-2.8-.3-4.2-1.2-1-3-.8-4 .4a5.2 5.2 0 0 1-.5.6L35 38a12 12 0 0 1-6.7 2c-2.7-.1-5-1.3-6.5-2.6a14 14 0 0 1-2.3-2.4Zm-1.2-12.6a3 3 0 0 1 3.1-3 3 3 0 0 1 2.9 3.3 3 3 0 0 1-3.1 2.9 3 3 0 0 1-3-3.2ZM34 23a3 3 0 0 1 3.2-2.8 3 3 0 0 1 2.9 3.1 3 3 0 0 1-3.2 3 3 3 0 0 1-2.8-3.2Z"
      />
    </svg>
  );
};
