import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent } from '@askable/ui/components/ui/dialog';
import _ from 'lodash';
import { CircleAlert, X, ArrowLeft } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import PerfectScrollbar from 'perfect-scrollbar';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'react-spinkit';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { LoadingOverlay, MiniCalendar, ParticipantIssueDialog } from 'components/common';
import MessageDrawer from 'components/manageBooking/messages/messageDrawer';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchParticipantSessionsSearch from 'data/queries/bookingSubmission/fetchParticipantSessionsSearch';
import { bookingUtils } from 'lib/booking';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE, SUBMISSION_AGREEMENT_STATUS } from 'lib/constants';
import { csv } from 'lib/csv';
import { apolloFetch } from 'lib/http';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

import { useAppContext } from '../Askable/Providers/appProvider';

import SessionDetailsPopover from './sessionDetailsPopover';
import SessionToAddPopover from './sessionToAddPopover';
import SessionToReschedulePopover from './sessionToReschedulePopover';

import './calendarStyles.scss';

const isDisplayingHeaderAds = localStorage.get('showingHeaderAds') === 'true';

function CalendarComponent(props: any) {
  const timezone = utils.getCurrentTimezone();
  const { details } = useConnectedClient();

  const connectedUser = details?._id;

  const isBookingCompleted = bookingUtils.isCompleted(props.booking);
  const isBookingInDraft = bookingUtils.isInDraft(props.booking);
  const isBookingInReview = bookingUtils.isInReview(props.booking);
  const isBookingActive = bookingUtils.isActive(props.booking);
  const isFocusGroup = bookingUtils.isFocusGroup(props.booking);

  const [sessionTimes, setSessionTimes] = useState(_.get(props.data, 'session') || _.get(props, 'booking.session'));
  // If there are some sessions created already, it should default the start month to be the start of the month of the first created session
  const firstSession = _.minBy(sessionTimes, 'start');
  const lastSession = _.maxBy(sessionTimes, 'start');
  const [month, setMonth] = useState(momentTimezone(_.get(firstSession, 'start')).month());
  const [year, setYear] = useState(momentTimezone(_.get(firstSession, 'start')).year());
  const [weekSelected, setWeekSelected] = useState(
    momentTimezone(_.get(firstSession, 'start')).tz(timezone).week() - 1,
  );
  const [selectedDate, setSelectedDate] = useState(momentTimezone(_.get(firstSession, 'start')).tz(timezone));
  const [todayTopPosition, setTodayTopPosition] = useState();
  const [openSessionDetails, setOpenSessionDetails] = useState(false);
  const [sessionSelected, setSessionSelected] = useState<any>();
  const [sessionToAdd, setSessionToAdd] = useState([]);
  const [openSessionToAddPopover, setOpenSessionToAddPopover] = useState(false);
  const [sessionToReschedule, setSessionToReschedule] = useState([]);
  const [openSessionToReschedulePopover, setOpenSessionToReschedulePopover] = useState(false);
  const [openMessageDrawer, setOpenMessageDrawer] = useState(false);
  const [participantSelected, setParticipantSelected] = useState();
  const [bookingParticipantSelected, setBookingParticipantSelected] = useState();
  const [participantFeedback, setParticipantFeedback] = useState();
  const [openIssueDialog, setOpenIssueDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionResearchers, setSessionResearchers] = useState<
    {
      _id: string;
      name: any[];
      color: string;
    }[]
  >([]);

  // Drag actions
  const [draggedTo, setDraggedTo] = useState();
  const [draggingEventId, setDraggingEventId] = useState();
  const [draggingEvent, setDraggingEvent] = useState({});

  const [, setSearchParams] = useSearchParams();
  const context = useAppContext();
  const daysOfWeek = utils.getDaysOfWeek();
  const hoursOfDay = utils.getHoursOfDay();
  const multiplier = 1.05;
  const sessionDuration = _.get(props, 'booking.config.session.duration');
  const eventsHeight = sessionDuration * 1.04;
  const arrayOfHalfHours: any = [];
  for (let r = 0; r < 1450; r += 30) {
    arrayOfHalfHours.push({ value: r });
  }
  const arrayOfFifteenMinutes: any = [];
  for (let r = 0; r < 1450; r += 15) {
    arrayOfFifteenMinutes.push({ value: r });
  }
  let scrollbar: any;

  // Creates an observer
  let observer: any;
  if (props.withMiniCalendar) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'IntersectionObserverEntry[]' is ... Remove this comment to see the full error message
    observer = new window.IntersectionObserver((entries) => onIntersectObject(entries));
  }
  // Creates an array for the whole period
  // This array will be used to render the calendar days
  let firstSessionDate = momentTimezone(_.get(firstSession, 'start')).tz(timezone);
  const lastSessionDate = momentTimezone(_.get(lastSession, 'start')).tz(timezone);

  const todayDate = momentTimezone().tz(timezone);
  const diffDays = firstSessionDate.diff(todayDate, 'days');

  // Subtract the days between first session and today
  if (diffDays >= 0) {
    firstSessionDate = firstSessionDate.subtract(diffDays + 3, 'days');
  }
  const daysInPeriod = utils.getDaysInMonths(
    firstSessionDate.startOf('month').subtract(3, 'month'),
    timezone,
    lastSessionDate.endOf('month').add(1, 'month'),
  );

  useEffect(() => {
    const extraArguments = utils.parseQueryParams(_.get(props, 'location.search'));
    if (extraArguments) {
      // Show participant feedback
      if (extraArguments.get('showParticipantFeedback')) {
        const showParticipantFeedback = extraArguments.get('showParticipantFeedback');
        const showParticipantFeedbackSession = _.find(sessionTimes, (session: any) =>
          _.find(_.get(session, 'participants'), (participant: any) => participant._id === showParticipantFeedback),
        );

        if (showParticipantFeedbackSession) {
          setTimeout(() => {
            setOpenSessionDetails(true);
            setSessionSelected(showParticipantFeedbackSession);
          }, 500);
        }
      }

      setSearchParams('');
    }
  }, []);

  // Triggered on mount
  useEffect(() => {
    if (props.subscribeToUpdates) props.subscribeToUpdates();
  }, []);

  // Triggered on mount
  useEffect(() => {
    if (!props.loadingUI) {
      const lastCalendarDay = daysInPeriod[daysInPeriod.length - 1];
      // Opening active or draft bookings with more than 3 months
      if (todayDate.valueOf() > lastCalendarDay.date) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        scrollToPosition('session', firstSession.start);
      } else if (bookingUtils.isActive(props.booking) || bookingUtils.isInDraft(props.booking)) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
        scrollToPosition('today');
      } else if (firstSession) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        scrollToPosition('session', firstSession.start);
      } else {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
        scrollToPosition('today');
      }

      if (observer) {
        _.each(document.querySelectorAll('.columnDay'), (obj: any) => {
          observer.observe(obj);
        });
      }
    }
  }, [props.loadingUI]);

  // Position the today line into the right absolute position when it mounts
  useEffect(() => {
    let interval: any;
    const topPosition = getTopPosition(momentTimezone());

    // Set the initial position
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setTodayTopPosition(topPosition);

    // Creates a timeout to trigger every time the system clock changes minute
    setTimeout(
      () => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setTodayTopPosition(getTopPosition(momentTimezone()));
        // Creates an interval that gets triggered every minute from the starting point.
        interval = setInterval(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          setTodayTopPosition(getTopPosition(momentTimezone()));
        }, 60000);
      },
      (60 - momentTimezone().second()) * 1000,
    );

    return function cleanup() {
      clearInterval(interval);
      interval = null;
    };
  }, []);

  // Effect to create an update the custom horizontal scrollbar
  useEffect(() => {
    if (!scrollbar) {
      const container = document.getElementById('daysColumnContainer');
      if (container) {
        setTimeout(() => {
          scrollbar = new PerfectScrollbar(container);
        }, 100);
      }
    }
  }, []);

  useEffect(() => {
    if (_.get(firstSession, 'start')) {
      if (props.weekFirstSession && props.weekFirstSession !== weekSelected) {
        setWeekSelected(props.weekFirstSession);
      }
    }
  }, [props.weekFirstSession]);

  // Effect after event creation
  useEffect(() => {
    // Check if the element can be found
    const elementToBeCreated = document.getElementById('pendingEventToAdd');
    const elementToBeRescheduled = document.getElementById('pendingEventToReschedule');
    if (elementToBeCreated) setOpenSessionToAddPopover(true);
    if (elementToBeRescheduled) {
      setOpenSessionToReschedulePopover(true);
      resetDragState();
    }
    resetMessageState();
  }, [sessionToAdd, sessionToReschedule]);

  // Adding new sessions
  useEffect(() => {
    if (_.get(props, 'params.showToday')) {
      onClickToday();
    }
  }, []);

  // Effect to update locally the events when they come saved in the database
  useEffect(() => {
    const sessionDataComingFromDb = _.get(props.data, 'session') || _.get(props, 'booking.session');
    // Update local state when new data comes through
    setSessionTimes(sessionDataComingFromDb);
  }, [_.get(props, 'data.session'), _.get(props, 'booking.session')]);

  useEffect(() => {
    setSessionResearchers(
      bookingUtils
        .getBookingResearchers(
          sessionTimes,
          details?._id!,
          `${details?.meta?.identity?.firstname} ${details?.meta?.identity?.lastname}`,
        )
        .reverse() as any,
    );
  }, [sessionTimes]);

  // Method to scroll to a specific position on the calendar
  // Type: can be either 'today' or 'session'
  //  If 'session' then it should have some params with the start date of the event
  const scrollToPosition = (type: any, params: any, callback: any) => {
    // Firstly scrolls horizontally
    if (type === 'today') {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.querySelector('.columnDay-today').scrollIntoView({ inline: 'center' });
    } else if (type === 'session') {
      const sessionElement = `columnDay-${momentTimezone(params).tz(timezone).format('DD/MM/YYYY')}`;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getElementById(sessionElement).scrollIntoView({ inline: 'center' });
    }

    // Scrolls vertically
    const topPosition = getTopPosition(params || momentTimezone());
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    document.getElementById('hoursContainer').scrollTo({ top: topPosition - 150, behavior: 'smooth' });
    setTimeout(() => {
      const element = document.getElementById('daysColumnContainer');
      if (element) element.scrollBy({ left: element.clientWidth / 5 });
    }, 150);

    if (callback) callback();
  };

  const resetDragState = () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setDraggingEventId();
    setDraggingEvent({});
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setDraggedTo();
  };

  const getTimestampToCreateSession = ({ minutesClicked, date, action = 'add' }: any) => {
    // Depending on the action that the user is taking, it should give them the ability to access 15-minute slots or just 30-minute slot
    // Example: When creating a session, the client should only be able to access 30-minute slots whereas when dragging he should have access to 15-minute sessions
    let arrayOfSlots = arrayOfHalfHours;
    if (action !== 'add') arrayOfSlots = arrayOfFifteenMinutes;

    // Get the closest half an hour in relation to the time the user has clicked
    let nextLowerValue = _.filter(arrayOfSlots, (item: any) => item.value < minutesClicked);
    const nextHigherValue = _.filter(arrayOfSlots, (item: any) => item.value >= minutesClicked);
    if (_.size(nextLowerValue) > 0) nextLowerValue = nextLowerValue[_.size(nextLowerValue) - 1];
    return {
      // @ts-expect-error ts-migrate(2551) FIXME: Property 'value' does not exist on type 'any[]'. D... Remove this comment to see the full error message
      timestampLower: momentTimezone(date).tz(timezone).minutes(nextLowerValue.value).valueOf(),
      timestampHigher: momentTimezone(date).tz(timezone).minutes(nextHigherValue[0].value).valueOf(),
    };
  };

  const getNormalisedEvent = (event: any) => {
    const isInPast = utils.isInPast(_.get(event, 'end'), timezone);

    return {
      _id: event._id,
      start: event.start,
      end: event.end,
      username: _.get(event, 'CurrentStatus.user.meta.identity.firstname'),
      userTimezone: _.get(event, 'CurrentStatus.user.location.timezone'),
      completed: _.get(event, 'CurrentStatus.status') === 1 && _.get(event, 'CurrentStatus.cancel') === 0 && isInPast,
      invited: _.get(event, 'CurrentStatus.status') === 4 && _.get(event, 'CurrentStatus.cancel') === 0,
    };
  };

  const onSetNewDay = (day: any) => {
    const dayTimestamp = day.valueOf();

    // Check whether we have the day rendered. If not, it should not register the click
    if (
      momentTimezone(dayTimestamp).isSameOrAfter(daysInPeriod[0].date) &&
      momentTimezone(dayTimestamp).isSameOrBefore(daysInPeriod[daysInPeriod.length - 1].date)
    ) {
      const today = momentTimezone(dayTimestamp).tz(timezone);
      // Get the element in the calendar to position slide the user to the right position
      const elementOnCalendar = document.getElementById(
        `columnDay-${momentTimezone(dayTimestamp).format('DD/MM/YYYY')}`,
      );
      if (elementOnCalendar) elementOnCalendar.scrollIntoView({ inline: 'center', block: 'center' });
      setWeekSelected(today.week());
      setMonth(today.month());
      setYear(today.year());

      const selectedDay = momentTimezone(day).date();
      const selectedMonth = momentTimezone(day).month();
      const selectedYear = momentTimezone(day).year();
      const m = momentTimezone(selectedDate).date(selectedDay);
      const newDaySelected = m.month(selectedMonth).year(selectedYear).valueOf();
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setSelectedDate(newDaySelected);
    }
  };

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'entries' implicitly has an 'any' ... Remove this comment to see the full error message
  const onIntersectObject = ([entries]) => {
    const entryWeek = parseInt(_.get(entries, 'target.dataset.week'), 10);
    if (_.get(entries, 'isIntersecting')) {
      const timestamp = parseInt(_.get(entries, 'target.dataset.timestamp'), 10);
      // The detected boundingClientRect is bigger than 1000 when moving to the right
      const isMovingRight = entries.boundingClientRect.x > 1000;
      const dayOfTheWeek = momentTimezone(timestamp).day();

      // It should change the week only at a certain point
      if ((dayOfTheWeek === 4 && isMovingRight) || (dayOfTheWeek === 3 && !isMovingRight)) {
        setWeekSelected(entryWeek);
        setMonth(momentTimezone(timestamp).month());
        setYear(momentTimezone(timestamp).year());
      }
    }
  };

  const onSendMessage = async (participant: any) => {
    setOpenMessageDrawer(false);
    setLoading(true);
    const participantSessionsList = await apolloFetch.fetch(fetchParticipantSessionsSearch, {
      _booking_id: _.get(props.booking, '_id'),
      _user_id: _.get(participant, 'user._id'),
    });
    setLoading(false);
    setOpenMessageDrawer(true);
    setBookingParticipantSelected(_.get(participantSessionsList, 'data.participantSessionsSearch'));
    setParticipantFeedback(_.get(participant, 'feedback'));
    setParticipantSelected(participant);
  };

  const resetMessageState = () => {
    if (openMessageDrawer) {
      setOpenMessageDrawer(false);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setBookingParticipantSelected(null);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setParticipantFeedback(null);
    }
  };

  const onCloseSessionDetails = () => {
    setOpenSessionDetails(false);
    setSessionSelected(null);
  };

  const onCloseSessionToAddPopover = () => {
    setOpenSessionToAddPopover(false);
    setSessionToAdd([]);
  };

  const onCloseSessionToReschedulePopover = () => {
    setOpenSessionToReschedulePopover(false);
    setSessionToReschedule([]);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setSessionSelected();
    resetDragState();
  };

  const onCloseParticipantIssueDialog = () => {
    setOpenIssueDialog(false);
  };

  const onAddSessionLocally = (newSession: any) => {
    const newArrayOfSessions = _.clone(sessionTimes);
    newArrayOfSessions.push(newSession);
    setSessionTimes(newArrayOfSessions);

    // Add session in the db
    props.onAddSession(newSession);
  };

  const onDeleteSessionLocally = (session_id: any) => {
    let newArrayOfSessions = _.clone(sessionTimes);
    newArrayOfSessions = _.filter(newArrayOfSessions, (sessions: any) => sessions._id !== session_id);
    setSessionTimes(newArrayOfSessions);

    // Delete session in the db
    props.onDeleteSession(session_id);
  };

  const onRescheduleSessionLocally = (session_id: any, newSession: any) => {
    let newArrayOfSessions = _.clone(sessionTimes);
    const originalSession = _.find(newArrayOfSessions, (sessions: any) => sessions._id === session_id);
    newSession._researcher_user_id = _.get(originalSession, '_researcher_user_id') || connectedUser;

    newArrayOfSessions = _.filter(newArrayOfSessions, (sessions: any) => sessions._id !== session_id);
    newArrayOfSessions.push(newSession);
    setSessionTimes(newArrayOfSessions);

    // Reschedule session in the db
    props.onRescheduleSession(session_id, newSession);
  };

  const onAddSession = async (yPosition: any, date: any, timeContext: any, forcePopover = false) => {
    // Reverse engineer the time of the day based on the Y positioning
    const minutesClicked = yPosition * (2 - multiplier);

    // Get the possible times to create a session
    const timestampCreateSession = getTimestampToCreateSession({
      minutesClicked,
      date,
      action: 'add',
    });

    // Apply the time context to the create session timestamp
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const timeWithContext = timestampCreateSession[timeContext];
    const endEvent = momentTimezone(timeWithContext).add(sessionDuration, 'minutes').valueOf();

    // Check whether the session can be added
    //  Rules:
    //    Draft or In Review: It shouldn't let the user create on the same day or in the past
    //    Active: It shouldn't let the user create events in the past
    //    Completed: It shouldn't let the user create events
    if (isBookingCompleted) return;
    if (
      (isBookingInDraft || isBookingInReview) &&
      !forcePopover &&
      momentTimezone().tz(timezone).isSameOrAfter(timeWithContext, 'd')
    ) {
      return;
    }

    if (isBookingActive && momentTimezone().tz(timezone).isAfter(timeWithContext, 'm')) {
      return;
    }

    // If the booking is in Draft it shouldn't open a popover to ask the user to confirm the session creation, it should only create the session straighaway
    if (!isBookingInDraft || forcePopover) {
      const eventToAdd = [
        {
          _id: 'pendingEventToAdd',
          start: timeWithContext,
          end: endEvent,
          _researcher_user_id: connectedUser,
        },
      ];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: string; start: any; end: ... Remove this comment to see the full error message
      setSessionToAdd(eventToAdd);
    } else {
      const eventToAdd = {
        start: timeWithContext,
        end: endEvent,
        _researcher_user_id: connectedUser,
      };
      onAddSessionLocally(eventToAdd);
    }
  };

  const onRescheduleSession = ({ event, yPosition, date, timeContext, callback }: any) => {
    const currentParsedEvent = utils.parseJSON(_.get(event, 'target.dataset.event'));
    const isInPast = utils.isInPast(_.get(currentParsedEvent, 'end'), timezone);
    const isSessionCompleted = _.get(currentParsedEvent, 'completed');
    const isSessionInvited = _.get(currentParsedEvent, 'invited');
    // Reverse engineer the time of the day based on the Y positioning
    const minutesClicked = yPosition * (2 - multiplier);
    // Get the possible times to create a session
    const timestampCreateSession = getTimestampToCreateSession({
      minutesClicked,
      date,
      action: 'reschedule',
    });
    // Apply the time context to the create session timestamp
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const timeWithContext = timestampCreateSession[timeContext];
    const timeWithContextIsInPast = utils.isInPast(timeWithContext, timezone);

    const isReschedulingToDifferentTime = currentParsedEvent.start !== timeWithContext;
    // It should only be able to reschedule events on:
    //   * Non-completed bookings
    //   * Non invited sessions
    //   * Sessions that haven't been marked as complete
    //   * Different times
    if (
      isBookingCompleted ||
      (isInPast && !isSessionCompleted && !isSessionInvited && !isBookingInDraft) ||
      !isReschedulingToDifferentTime ||
      timeWithContextIsInPast
    ) {
      if (callback) callback();
    } else {
      const endEvent = momentTimezone(timeWithContext).add(sessionDuration, 'minutes').valueOf();

      // It should only open the reschedule popover if someone is confirmed for that session
      if (
        _.get(event, 'target.dataset.status') === 'confirmed' ||
        _.get(event, 'target.dataset.status') === 'invited' ||
        _.get(event, 'target.dataset.status') === 'completed'
      ) {
        const eventToReschedule = [
          {
            _id: 'pendingEventToReschedule',
            event_id: event.target.id,
            event_status: event.target.dataset.status,
            start: timeWithContext,
            end: endEvent,
          },
        ];
        setSessionSelected(utils.parseJSON(_.get(event, 'target.dataset.event')));
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: string; event_id: any; ev... Remove this comment to see the full error message
        setSessionToReschedule(eventToReschedule);
      } else {
        const newEventSessionTimes = {
          start: timeWithContext,
          end: endEvent,
        };

        onRescheduleSessionLocally(event.target.id, newEventSessionTimes);

        if (callback) callback();
      }
    }
  };

  const onDragStart = (ev: any) => {
    // This disables the default ghost when you drag a component on HTML
    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    ev.dataTransfer.setDragImage(img, 0, 0);

    // Selects the event that is getting dragged
    setDraggingEventId(ev.target.dataset.id);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    const targetDraggedTo = Number(event.currentTarget.dataset.timestamp);

    // Reverse engineer the time of the day based on the Y positioning
    const yPosition = event.clientY - event.currentTarget.getBoundingClientRect().top;
    const minutesClicked = yPosition * (2 - multiplier);

    // Get the timestamps from the position
    const timestampCreateSession = getTimestampToCreateSession({
      minutesClicked,
      date: targetDraggedTo,
      action: 'reschedule',
    });

    // When user changes day
    if (draggedTo !== targetDraggedTo) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setDraggedTo(targetDraggedTo);
    }
    // Save in state the draggable event data
    if (_.get(draggingEvent, 'start') !== _.get(timestampCreateSession, 'timestampLower')) {
      const dragSessionData = {
        _id: 'tempEventBeingDragged',
        start: _.get(timestampCreateSession, 'timestampLower'),
        end: momentTimezone(_.get(timestampCreateSession, 'timestampLower')).add(sessionDuration, 'minutes').valueOf(),
      };
      setDraggingEvent(dragSessionData);
    }
  };

  const onDragEnd = (event: any) => {
    const yPosition = event.clientY - event.currentTarget.getBoundingClientRect().top;
    onRescheduleSession({
      event,
      yPosition,
      date: draggedTo,
      timeContext: 'timestampLower',
      callback: resetDragState,
    });
  };

  const onClickToday = () => {
    // Scrolls horizontally
    const todayElement = document.querySelector('.columnDay-today');
    if (todayElement) todayElement.scrollIntoView({ inline: 'center' });
    const element = document.getElementById('daysColumnContainer');
    if (element) element.scrollBy({ left: element.clientWidth / 5 });

    // Scrolls vertically
    const topPosition = getTopPosition(momentTimezone());
    const hoursContainerElement = document.getElementById('hoursContainer');
    if (hoursContainerElement) hoursContainerElement.scrollTo({ top: topPosition - 150 });

    const today = momentTimezone().tz(timezone);
    setWeekSelected(today.week());
    setMonth(today.month());
    setYear(today.year());
  };

  const getTopPosition = (time: any) => {
    // Function to get the absolute position on an element on the calendar
    //  Logic: Get the number of minutes from midnight and multiply by 1.05
    //  (Which is the multipliers that comes from the total height of the calendar divided by 1440 [number of minutes in a day])
    const minutesFromMidnight = utils.getTimeFromMidnight(time, timezone, 'minutes');
    return minutesFromMidnight * multiplier;
  };

  const renderHeaderDay = ({ date, isToday }: any) => {
    return (
      <div
        id={`header-${date} ${isToday ? 'headerDay-today' : ''}`}
        key={`header-${date}`}
        className={`headerDay ${isToday ? 'today' : ''} ${utils.isWeekend(date, timezone) ? 'weekend' : ''}`}
      >
        <p className="weekday">{_.get(daysOfWeek[momentTimezone(date).tz(timezone).day()], 'day')}</p>
        <div className="dayContainer">
          <h2 className="day">{momentTimezone(date).tz(timezone).date()}</h2>
        </div>
      </div>
    );
  };

  const renderEventTitle = (event: any) => {
    // Method to render the title of the event.
    // For one-on-one sessions it should show the name of the person doing the interview
    // For focus groups it should show how many people are confirmed for the session
    if (isFocusGroup && !isBookingInDraft) {
      const confirmedParticipants = _.filter(
        event.participants,
        (participant: any) => participant.status === 1 && participant.cancel === 0,
      );
      return <p className="titleEvent">{_.size(confirmedParticipants)} Participants</p>;
    }
    if (isFocusGroup && isBookingInDraft) {
      return <p className="titleEvent">(Focus group)</p>;
    }
    if (_.get(event, 'CurrentStatus.user')) {
      return (
        <p className="titleEvent">
          {_.get(event, 'CurrentStatus.user.meta.identity.firstname')}{' '}
          {_.get(event, 'CurrentStatus.user.meta.identity.lastname')}
        </p>
      );
    }
  };

  const renderEventTimeTitle = ({ start, end, eventsAtTheSameTime, event = {} }: any) => {
    // Logic to define whether it should display the end time
    //  It should display if:
    //   * Session duration is longer than 30 minutes
    //   * There are not more than 2 events at the same time
    let shouldDisplayEndTime = true;
    if (sessionDuration <= 30 || _.size(eventsAtTheSameTime) > 2) shouldDisplayEndTime = false;
    return (
      <p className="time">
        {renderEventTiteWarning(event)}
        {start.format('h:mma')} {shouldDisplayEndTime && `- ${end.format('h:mma')}`}
      </p>
    );
  };

  const renderEventTiteWarning = (event: any) => {
    if (
      _.get(props, 'booking.config.participant_agreement.type') ===
        BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT &&
      _.get(event, 'CurrentStatus.status') === 1 &&
      _.get(event, 'CurrentStatus.cancel') === 0 &&
      _.get(event, 'CurrentStatus.Submission.agreement.status') !== SUBMISSION_AGREEMENT_STATUS.COMPLETED
    ) {
      return (
        <span style={{ marginRight: '0.5em', display: 'inline-flex' }}>
          <CircleAlert className="h-4 w-4" />
        </span>
      );
    }
    return null;
  };

  const renderEvent = (event: any, eventsOnDay: any) => {
    // Function to render the event in the calendar
    // It shouldn't display the event if the session is cancelled
    if (_.get(event, 'status') === 2 || _.get(event, 'status') === 3 || !_.get(event, '_id')) return null;

    const startEvent = momentTimezone(event.start).tz(timezone);
    const endEvent = momentTimezone(event.end).tz(timezone);
    const positionStartEvent = getTopPosition(startEvent);
    const isInPast = utils.isInPast(endEvent, timezone);
    const isSessionSelected = _.get(sessionSelected, '_id') === _.get(event, '_id');
    const sessionStatus = bookingUtils.getSessionStatus(
      _.get(event, 'CurrentStatus'),
      event,
      _.get(event, 'participants'),
      timezone,
    );
    const isDraggingEvent = draggingEventId && event._id === draggingEventId;

    // Check whether there's other events that have been rendered already for the same time
    // If there's more than one event for the same time, it should change the UI to reflect
    let positionLeftEvent = 0;
    let widthEvent = '100%';
    let hasMultipleEventsAtSameTime = false;
    let eventsAtTheSameTime = 1;
    if (_.size(eventsOnDay) > 1) {
      const filteredEventsOnDay = _.filter(
        eventsOnDay,
        (evt: any) => _.get(evt, 'status') !== 2 && _.get(evt, 'status') !== 3,
      );
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'number'.
      eventsAtTheSameTime = _.filter(filteredEventsOnDay, (evt: any) => evt.start === event.start);
      // Check whether the user is dragging an event over a session time that contains one or more sessions already
      // Also check whether the event drag it's being dragged from is not included already in the eventsAtTheSameTime array
      if (
        openSessionToReschedulePopover &&
        _.get(sessionToReschedule, '[0].start') === _.get(eventsAtTheSameTime, '[0].start')
      ) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'concat' does not exist on type 'number'.
        eventsAtTheSameTime = eventsAtTheSameTime.concat(sessionToReschedule[0]);
      } else if (
        _.get(eventsAtTheSameTime, '[0].start') === _.get(draggingEvent, 'start') &&
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        _.size(_.filter(eventsAtTheSameTime, (e: any) => e._id === draggingEventId)) === 0
      ) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'concat' does not exist on type 'number'.
        eventsAtTheSameTime = eventsAtTheSameTime.concat(draggingEvent);
      }
      const orderedEventsAtTheSameTime = utils.orderArrayByDate(eventsAtTheSameTime, 'created');

      if (_.size(orderedEventsAtTheSameTime) > 1) {
        // Calculate the width dinamically based on the number of events that are happening at the same time
        const calculatedWidth = 100 / _.size(orderedEventsAtTheSameTime);
        widthEvent = `${calculatedWidth}%`;
        hasMultipleEventsAtSameTime = true;
        const eventIndex = orderedEventsAtTheSameTime.findIndex((item: any) => item._id === _.get(event, '_id'));
        // Calculate the left positioning based on the number of events and the index of the event
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        positionLeftEvent = `${calculatedWidth * eventIndex}%`;
      }
    }

    // Empty slots in the past shouldn't be rendered
    if (_.get(sessionStatus, 'class') === 'available' && isInPast && !isBookingInDraft) return null;

    let researcher;
    if (_.get(event, '_researcher_user_id')) {
      researcher = _.find(
        sessionResearchers,
        (item: any) => item._id.toString() === event._researcher_user_id.toString(),
      );
    } else if (_.get(event, '_researcher_user_id') === null) {
      researcher = _.find(sessionResearchers, (item: any) => item._id.toString() === connectedUser);
    }

    return (
      <div
        id={event._id}
        key={event._id}
        className={`event status-${_.get(sessionStatus, 'class')} ${isInPast ? 'past' : ''} duration-${sessionDuration} ${
          isSessionSelected ? 'selected' : ''
        } ${isDraggingEvent ? 'dragging' : ''} ${
          _.has(event, 'event_status') ? `status-${event.event_status}` : ''
        } ${hasMultipleEventsAtSameTime ? 'hasMultipleEvents' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenSessionDetails(true);
          setSessionSelected(event);
        }}
        style={{
          top: positionStartEvent,
          height: eventsHeight,
          left: positionLeftEvent,
          width: widthEvent,
          borderLeft: researcher ? `5px solid ${_.get(researcher, 'color') || '#00D1FF'}` : '1px solid #CCC',
        }}
        data-id={event._id}
        data-status={_.get(sessionStatus, 'class')}
        data-event={JSON.stringify(getNormalisedEvent(event))}
        draggable
      >
        <div className="timeContainer">
          {renderEventTimeTitle({ start: startEvent, end: endEvent, eventsAtTheSameTime, event })}
          {renderEventTitle(event)}
        </div>
        <p className="status">{_.get(sessionStatus, 'labelAction')}</p>
        {isBookingInDraft && isInPast && <p className="titleEvent">Invalid session</p>}
        {isBookingInDraft && props.onDeleteSession && (
          <X
            className="closeIcon"
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onDeleteSessionLocally(event._id);
            }}
            style={{ width: 12, height: 12 }}
          />
        )}
      </div>
    );
  };

  const sortEvents = (dayEvents: any) => {
    return dayEvents.sort((a: any, b: any) => {
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return momentTimezone(a.start).tz(timezone) - momentTimezone(b.start).tz(timezone);
    });
  };

  const renderEventsForDay = ({ date, isToday, week }: any) => {
    // Get the events to render on the day
    const eventsOnDay = sortEvents(
      _.filter(sessionTimes, (item: any) => momentTimezone(date).tz(timezone).isSame(item.start, 'day')),
    );
    const eventToAddOnDay = _.filter(sessionToAdd, (item: any) =>
      momentTimezone(date).tz(timezone).isSame(item.start, 'day'),
    );
    const eventToRescheduleOnDay = _.filter(sessionToReschedule, (item: any) =>
      momentTimezone(date).tz(timezone).isSame(item.start, 'day'),
    );
    let eventBeingDragged;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'start' does not exist on type '{}'.
    if (_.get(draggingEvent, 'start') && momentTimezone(date).tz(timezone).isSame(draggingEvent.start, 'day')) {
      eventBeingDragged = {
        ..._.find(sessionTimes, (sessionTime: any) => sessionTime._id === draggingEventId),
        ...draggingEvent,
      };
    }
    const isInPast = momentTimezone().tz(timezone).isSameOrAfter(date);

    return (
      <div
        key={`columnDay-${date}`}
        id={`columnDay-${momentTimezone(date).tz(timezone).format('DD/MM/YYYY')}`}
        className={`columnDay columnDay${isToday ? '-today' : ''} ${utils.isWeekend(date, timezone) ? 'weekend' : ''} ${
          isInPast && isBookingInDraft ? 'past' : ''
        }`}
        data-week={week}
        data-timestamp={date}
        onDragOver={onDragOver}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onClick={(e) => {
          // It should only create sessions if a booking is not completed
          e.stopPropagation();
          e.preventDefault();

          const yPosition = e.clientY - e.currentTarget.getBoundingClientRect().top;
          onAddSession(yPosition, date, 'timestampLower', false);
        }}
      >
        {isToday && <div className="todayLine" id="todayLine" style={{ top: `${todayTopPosition}px` }} />}
        <div className="eventsColumn">
          {_.map(eventsOnDay, (event: any) => renderEvent(event, eventsOnDay))}
          {_.map(eventToAddOnDay, (event: any) => renderEvent(event, eventsOnDay))}
          {_.map(eventToRescheduleOnDay, (event: any) => renderEvent(event, eventsOnDay))}
          {eventBeingDragged && renderEvent(eventBeingDragged, eventsOnDay)}
        </div>
      </div>
    );
  };

  const renderHiddenExportButtons = () => {
    const filteredConfirmedSessions = _.filter(_.get(props, 'data.session'), (item: any) => {
      return _.get(item, 'CurrentStatus.status') === 1 && _.get(item, 'CurrentStatus.cancel') === 0;
    });
    let filteredSessions = _.filter(filteredConfirmedSessions, (item: any) =>
      momentTimezone(item.end).tz(timezone).isAfter(),
    );
    // If there's no upcoming sessions, it should get the completed sessions
    if (_.size(filteredSessions) === 0) {
      filteredSessions = _.filter(filteredConfirmedSessions, (item: any) =>
        momentTimezone(item.end).tz(timezone).isBefore(),
      );
    }
    const normalisedSessions = _.map(filteredSessions, (session: any) => {
      return {
        ...session,
        participants: [session.CurrentStatus],
      };
    });
    return (
      // eslint-disable-next-line
      <button type="button" className="hidden" id="btnExportSessions">
        <CSVLink
          data={csv.getTemplate('Sessions', { sessions: normalisedSessions, booking: props.booking })}
          filename={`askable_sessions_${momentTimezone().format('DDMMMYYYY-hhmmA')}.csv`}
          target=""
        />
      </button>
    );
  };

  const renderMonthYearTitle = () => {
    // Filter days by the week
    const daysOnWeek = _.filter(daysInPeriod, (days: any) => days.week === weekSelected);
    if (_.size(daysOfWeek) === 0) return '';
    const initialDate = _.get(daysOnWeek, '[0].date');
    const lastDate = _.get(daysOnWeek, `[${_.size(daysOnWeek) - 1}].date`);
    const monthFirstDay = momentTimezone(initialDate).tz(timezone).format('MMMM');
    const monthLastDay = momentTimezone(lastDate).tz(timezone).format('MMMM');
    const yearLastDay = momentTimezone(lastDate).tz(timezone).format('YYYY');
    if (monthFirstDay === monthLastDay) {
      return (
        <p className="monthYearTitle">
          {monthFirstDay} {yearLastDay}
        </p>
      );
    }
    return (
      <p className="monthYearTitle">
        {monthFirstDay} - {monthLastDay} {yearLastDay}
      </p>
    );
  };

  const renderResearchers = () => {
    return (
      <div className="researchersContainerHeader">
        {_.map(sessionResearchers, (researcher: any) => {
          return (
            <div key={researcher._id} className="researcherIcon" style={{ backgroundColor: researcher.color }}>
              <p className="researcherInitials">{researcher.name}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="calendarComponent">
      <div className="headerActionsContainer flex gap-1" onClick={() => resetMessageState()}>
        {props.onClose ? (
          <Button onClick={props.onClose} variant="ghost" aria-label="Close calendar">
            <ArrowLeft className="h-4 w-4" />
          </Button>
        ) : null}

        <Button
          onClick={() => {
            onClickToday();
          }}
        >
          Today
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            onClickToday();
            onAddSession(
              todayTopPosition,
              momentTimezone().tz(timezone).startOf('day').valueOf(),
              'timestampHigher',
              true,
            );
          }}
        >
          Add
        </Button>
        {renderMonthYearTitle()}
        {renderResearchers()}
      </div>
      <div
        className={`contentCalendarContainer ${isDisplayingHeaderAds ? 'headerAds' : ''}`}
        onClick={() => resetMessageState()}
      >
        {props.withMiniCalendar && (
          <div className="controlsContainer">
            <MiniCalendar
              timezone={timezone}
              week={weekSelected}
              month={month}
              year={year}
              onChangeMiniCalendarDate={(date: any) => {
                setMonth(date.month);
                setYear(date.year);
              }}
              onClickDay={(day: any) => onSetNewDay(day)}
              selectedDay={selectedDate}
            />
            {props.controlsComponent && props.controlsComponent}
          </div>
        )}
        <div className="calendarContainer">
          {props.loadingUI && (
            <div className="loadingContainer">
              <Spinner fadeIn="quarter" name="ball-clip-rotate" color="#FF5266" className="loadingSpinner mleft15" />
            </div>
          )}
          {!props.loadingUI && (
            <>
              <div className="daysRowContainer">
                <div className="leftHoursContainer" />
                <div id="daysRowContent" className="daysRowContent">
                  {_.map(daysInPeriod, (day: any) => renderHeaderDay(day))}
                </div>
              </div>
              <div className="hoursContainer" id="hoursContainer">
                <div className="leftHoursContainer">
                  {_.map(hoursOfDay, (hour: any) => {
                    return (
                      <div key={`hourHeader-${hour}`} className="hourContainer">
                        <span>{hour}</span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="daysColumnContainer"
                  id="daysColumnContainer"
                  onScroll={(e) => {
                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                    document.getElementById('daysRowContent').style.transform = `translateX(-${e.target.scrollLeft}px)`;
                  }}
                >
                  <div className="daysColumnContent">
                    <div className="hourRows">
                      {_.map(hoursOfDay, (hour: any) => (
                        <div key={`hourContent-${hour}`} className="hourContainer" />
                      ))}
                    </div>
                    {_.map(daysInPeriod, (day: any) => renderEventsForDay(day))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog
        open={openSessionDetails}
        onOpenChange={(open: boolean) => {
          if (!open) {
            onCloseSessionDetails();
          }
        }}
      >
        <DialogContent>
          <SessionDetailsPopover
            sessionSelected={sessionSelected}
            timezone={timezone}
            booking={_.get(props, 'booking')}
            onClose={onCloseSessionDetails}
            onSendMessage={onSendMessage}
            context={context}
            confirmedParticipants={props.confirmedParticipants}
            client={props.client}
            viewParticipantsScreen={props.onClose}
            sessionTimes={sessionTimes}
            onAssignSessionToResearcher={props.onAssignSessionToResearcher}
            onDeleteSession={() => onDeleteSessionLocally(sessionSelected._id)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSessionToAddPopover}
        onOpenChange={(open: boolean) => {
          if (!open) {
            onCloseSessionToAddPopover();
          }
        }}
      >
        <DialogContent>
          <SessionToAddPopover
            sessionToAdd={sessionToAdd[0]}
            timezone={timezone}
            booking_id={_.get(props, 'booking._id')}
            sessionDuration={sessionDuration}
            onClose={onCloseSessionToAddPopover}
            onAddSession={onAddSessionLocally}
            client={props.client}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSessionToReschedulePopover}
        onOpenChange={(open: boolean) => {
          if (!open) {
            onCloseSessionToReschedulePopover();
          }
        }}
      >
        <DialogContent>
          <SessionToReschedulePopover
            sessionToReschedule={sessionToReschedule[0]}
            timezone={timezone}
            booking_id={_.get(props, 'booking._id')}
            sessionDuration={sessionDuration}
            onClose={onCloseSessionToReschedulePopover}
            sessionSelected={sessionSelected}
            isFocusGroup={isFocusGroup}
            client={props.client}
          />
        </DialogContent>
      </Dialog>
      {openMessageDrawer && loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {openMessageDrawer && !loading && (
        <MessageDrawer
          open={openMessageDrawer}
          onOpenChange={(open: boolean) => {
            if (!open) {
              setOpenMessageDrawer(false);
            }
          }}
          submission_id={_.get(participantSelected, 'Submission._id')}
          booking={_.get(props, 'booking')}
          user={_.get(participantSelected, 'user')}
          bookingParticipant={bookingParticipantSelected}
          participantFeedback={participantFeedback}
          renderAsDrawer
        />
      )}
      {openIssueDialog && (
        <ParticipantIssueDialog
          open={openIssueDialog}
          onClose={onCloseParticipantIssueDialog}
          onClickDone={onCloseParticipantIssueDialog}
          participant={participantSelected}
          booking={_.get(props, 'booking')}
          client={props.client}
        />
      )}
      {props.data && renderHiddenExportButtons()}
    </div>
  );
}

export default deprecatedWithRouter(CalendarComponent);
