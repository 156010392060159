import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { analytics } from 'lib/analytics';

export const IdentifyUser = () => {
  const [hasIdentified, setHasIdentified] = useState(false);
  const { details } = useConnectedClient();

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'PROD' || !details || hasIdentified) {
      return;
    }

    datadogRum.setUser({
      id: details?.id!,
      email: details?.email!,
    });

    analytics.identify({
      id: details?.id!,
      email: details?.email!,
      name: `${details?.name?.firstname} ${details.name?.lastname}`,
      teamId: details.ConnectedTeam?._id,
      teamName: details.ConnectedTeam?.name ?? undefined,
    });

    setHasIdentified(true);
  }, [details]);

  return null;
};
