import { Button } from '@askable/ui/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useRef, useState } from 'react';

import type { ReactNode } from 'react';

/**
 * Wrap a list of items in a horizontal scrollable container
 * Optionally set a width for each item
 */

interface ScreenListScrollableProps {
  children: ReactNode;
  itemWidth?: number;
}

export const ScreenListScrollable = ({ children, itemWidth = 192 }: ScreenListScrollableProps) => {
  const scrollListRef = useRef<HTMLOListElement>(null);
  const [atIsStart, setIsAtStart] = useState(true);
  const [atIsEnd, setIsAtEnd] = useState(false);

  const onScroll = () => {
    const element = scrollListRef.current;
    if (!element) return;

    setIsAtStart(element.scrollLeft === 0);
    setIsAtEnd(element.scrollLeft >= element.scrollWidth - element.clientWidth);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    const amount = direction === 'left' ? -itemWidth * 2 : itemWidth * 2;
    scrollListRef.current?.scrollBy({ left: amount, behavior: 'smooth' });
  };

  return (
    <div className="relative">
      {!atIsStart ? (
        <Button
          aria-label="Scroll left"
          className="absolute -left-3 top-1/2 z-10 shadow-sm"
          onClick={() => handleScroll('left')}
          size="icon"
        >
          <ChevronLeft />
        </Button>
      ) : null}

      <ol
        ref={scrollListRef}
        onScroll={onScroll}
        className={`scroll-snap-x scroll-snap-mandatory grid grid-flow-col gap-3 overflow-x-scroll pb-4 before:w-3 before:content-['']`}
        style={{
          gridTemplateColumns: `0 repeat(auto-fill, ${itemWidth}px) 0`,
          gridAutoColumns: `${itemWidth}px`,
        }}
      >
        {children}
      </ol>

      {!atIsEnd ? (
        <Button
          aria-label="Scroll right"
          className="absolute -right-3 top-1/2 z-10 shadow-sm"
          onClick={() => handleScroll('right')}
          size="icon"
        >
          <ChevronRight />
        </Button>
      ) : null}
    </div>
  );
};

export const ScreenListScrollable2 = ({ children, itemWidth = 192 }: ScreenListScrollableProps) => {
  return (
    <div className="overflow-x-hidden">
      <ol
        className={`grid snap-x snap-mandatory auto-cols-[100%] grid-flow-col grid-cols-[repeat(auto-fill,${itemWidth}px)] gap-3
        overflow-x-auto pb-3`}
      >
        {children}
      </ol>
    </div>
  );
};
