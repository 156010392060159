import { gql } from '@apollo/client';

import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
  mutation markMultipleMessagesAsSeen($_messages_id: [ID]!) {
    markMultipleMessagesAsSeen(_messages_id: $_messages_id) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
