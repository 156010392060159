import { toast } from '@askable/ui/components/ui/sonner';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useEffect, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { match } from 'ts-pattern';
import { CombinedError, useMutation } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { StudyBlockType, StudyTaskBlockType } from 'generated/graphql';

import { UpdateStudyConfig } from '../data/UpdateStudyConfig.mutation';
import { UpdateStudyConfigTaskBlock } from '../data/UpdateStudyConfigTaskBlock.mutation';

import type { ActiveBlock } from '../hooks/useStudyActiveBlockId';
import type { ReactNode } from 'react';
import type { z } from 'zod';

export type BlockFormFields = z.infer<typeof blockSchema>;

export const BlockForm = ({
  children,
  activeBlock,
  activeBlockId,
}: {
  children: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}) => {
  const [, updateStudyConfig] = useMutation(UpdateStudyConfig);
  const [, updateStudyConfigTaskBlock] = useMutation(UpdateStudyConfigTaskBlock);

  const debounceTimer = useRef<NodeJS.Timeout>();
  const { newBlockId, setIsSaving, studyId } = useStudyContext();

  const form = useForm<BlockFormFields>({
    mode: 'all',
    resolver: zodResolver(blockSchema),
    defaultValues: {
      type: activeBlock.type,
      instructions: activeBlock.instructions,
      is_recording_enabled: activeBlock.is_recording_enabled,
      title: activeBlock.title,
      figma_prototype:
        activeBlock.type === StudyTaskBlockType.FigmaPrototype
          ? {
              file_id: activeBlock.figma_prototype?.file_id ?? '',
              start_screen_id: activeBlock.figma_prototype?.start_screen_id ?? '',
              goal_screen_id: activeBlock.figma_prototype?.goal_screen_id ?? '',
            }
          : undefined,
    },
  });

  // Validate form immediately if user is not currently creating a new one
  useEffect(() => {
    if (activeBlock._id !== newBlockId) {
      form.trigger();
    }
  }, [activeBlock._id, form, newBlockId]);

  const handleSubmit = useCallback(
    async (values: BlockFormFields) => {
      try {
        setIsSaving(true);

        // System blocks (welcome | thankyou) are stored in the study config, so we handle
        // them differently here
        const res = await match(values.type)
          .returnType<Promise<any>>()
          .with(StudyBlockType.Welcome, () =>
            updateStudyConfig({
              input: {
                _id: studyId,
                welcome_block: {
                  title: values.title,
                  instructions: values.instructions,
                },
              },
            }),
          )
          .with(StudyBlockType.ThankYou, () =>
            updateStudyConfig({
              input: {
                _id: studyId,
                thank_you_block: {
                  title: values.title,
                  instructions: values.instructions,
                },
              },
            }),
          )
          .with(StudyTaskBlockType.FigmaPrototype, () =>
            updateStudyConfigTaskBlock({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  is_recording_enabled: values.is_recording_enabled,
                  figma_prototype:
                    'figma_prototype' in values && values.figma_prototype?.file_id
                      ? {
                          file_id: values.figma_prototype.file_id,
                          start_screen_id: values.figma_prototype.start_screen_id,
                          goal_screen_id: values.figma_prototype.goal_screen_id || null,
                        }
                      : // TODO: check if we can/want to fix the gql schema to make the `figma_prototype` field nullable
                        // right now things break if we set it to null and then attempt to update it to an object :|
                        {},
                },
              },
            }),
          )
          .otherwise(() => {
            // TODO: unsupported type
            throw new Error('Could not update block data');
          });

        if (res.error) {
          throw new Error(res.error.message || 'Update failed');
        }
      } catch (err) {
        toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [activeBlockId, setIsSaving, studyId, updateStudyConfig, updateStudyConfigTaskBlock],
  );

  const { trigger, getValues, watch } = form;

  // Auto submit the form on every change
  // We're intentially bypassing react hook form here because we always want to submit, even when there
  // are validation errors!
  useEffect(() => {
    const subscription = watch(() => {
      if (activeBlockId !== newBlockId) {
        trigger();
      }
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        handleSubmit(getValues());
      }, 100);
    });
    return () => {
      subscription.unsubscribe();
      clearTimeout(debounceTimer.current);
    };
  }, [activeBlockId, getValues, trigger, watch, handleSubmit, newBlockId]);

  return <FormProvider {...form}>{children}</FormProvider>;
};
