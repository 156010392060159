interface Position {
  x: number;
  y: number;
}

interface ScrollOffset {
  x: number;
  y: number;
}

/* EMITTED MESSAGES */
export interface MousePressOrReleaseMessage {
  type: 'MOUSE_PRESS_OR_RELEASE';
  data: {
    // If there are overlays showing, this is the node id of the
    // topmost overlay on screen. If there are no overlays showing
    // it's the id of the screen we're showing.
    presentedNodeId: string;

    // Whether or not the user clicked a hotspot.
    handled: boolean;

    // When this event isn't handled, this is the topmost
    // layer under the cursor.
    targetNodeId: string;

    // Position relative to the top left corner of the target node;
    // this is unaffected by whether the target node is a scrolling
    // frame and has been scrolled.
    targetNodeMousePosition: Position;

    // The nested-most scrolling frame enclosing the targetNode, or
    // the targetNode if it scrolls.
    nearestScrollingFrameId: string;

    // Position relative to the top left corner of the scrolling frame;
    // this is unaffected by whether the target node is a scrolling
    // frame and has been scrolled.
    nearestScrollingFrameMousePosition: Position;

    // The scroll offset of the above scrolling frame. If the target
    // node is a scrolling frame, you can use this and targetNodeLocation
    // to find where the click happened in the layer content bounds.
    nearestScrollingFrameOffset: ScrollOffset;
  };
}

export interface PresentedNodeChangedMessage {
  type: 'PRESENTED_NODE_CHANGED';
  data: {
    // The ID of the top-most node on screen; either an overlay node id
    // or the id of the top level frame if there are no overlays showing.
    presentedNodeId: string;

    // In Figma, users can create connections that go "back" to the previous
    // frame. To do this, Figma maintains its own history stack of frames that
    // the user has navigated through.
    //
    // We intentionally exclude some transitions from the history stack: ones
    // triggered via hover, mouse in and mouse out because this best matches
    // user expectations.
    isStoredInHistory: boolean;

    // A map of (string) developer friendly node IDs of instances
    // -> (string) symbol IDs that they inherit from. This is useful for
    // determining the current states of interactive components on the screen.
    // This map only contains entries for instances in the current base screen
    // as well as any overlays being displayed.
    stateMappings: {
      [developerFriendlyNodeId: string]: string;
    };
  };
}

export interface InitialLoadMessage {
  type: 'INITIAL_LOAD';
  data: object;
}

export interface NewStateMessage {
  type: 'NEW_STATE';
  data: {
    // The developer-friendly node ID of the instance that changed state.
    nodeId: string;

    // The state ID (symbol ID) of the instance before the swap.
    currentVariantId: string;
    // The state ID (symbol ID) of the instance after the swap.
    newVariantId: string;
    // Same as PresentedNodeChangedMessage
    isStoredInHistory: boolean;
    // Whether or not this was caused by an "after delay" interaction.
    // This is important b/c people use these for looping animations etc.
    isTimedChange: boolean;
  };
}

export interface RequestCloseMessage {
  type: 'REQUEST_CLOSE';
  data: object;
}

/* SEND MESSAGES */

export type NavigateToFrameAndCloseOverlaysSendMessage = {
  type: 'NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS';
  data: {
    nodeId: string;
  };
};

export type NavigateForwardSendMessage = {
  type: 'NAVIGATE_FORWARD';
};

export type NavigateBackwardSendMessage = {
  type: 'NAVIGATE_BACKWARD';
};

export type ChangeComponentStateSendMessage = {
  type: 'CHANGE_COMPONENT_STATE';
  data: {
    nodeId: string;
    newVariantId: string;
  };
};

export type RestartSendMessage = {
  type: 'RESTART';
};

// presentedNodeChanged
// initialLoad
// newState
// requestClose

type FigmaMessageHandlers = {
  mousePressOrRelease?: (data: MousePressOrReleaseMessage) => void;
  presentedNodeChanged?: (data: PresentedNodeChangedMessage) => void;
  initialLoad?: (data: InitialLoadMessage) => void;
  newState?: (data: NewStateMessage) => void;
  requestClose?: (data: RequestCloseMessage) => void;
};

export function figmaMessageHandler(event: MessageEvent, handlers?: FigmaMessageHandlers) {
  const expectedOrigins = ['https://www.figma.com'];
  if (!expectedOrigins.includes(event.origin)) {
    if (event.origin && event.origin !== window.location.origin) {
      console.warn('Message from unexpected origin', event.origin);
    }
    return;
  }
  if (!event.data) {
    return;
  }
  if (!(event.data.type && event.data.data)) {
    return;
  }

  if (event.data.type === 'MOUSE_PRESS_OR_RELEASE') {
    const message = event.data as MousePressOrReleaseMessage;
    if (handlers?.mousePressOrRelease) {
      handlers.mousePressOrRelease(message);
    }
    return message;
  }
  if (event.data.type === 'PRESENTED_NODE_CHANGED') {
    const message = event.data as PresentedNodeChangedMessage;
    if (handlers?.presentedNodeChanged) {
      handlers.presentedNodeChanged(message);
    }
    return message;
  }
  if (event.data.type === 'INITIAL_LOAD') {
    const message = event.data as InitialLoadMessage;
    if (handlers?.initialLoad) {
      handlers.initialLoad(message);
    }
    return message;
  }
  if (event.data.type === 'NEW_STATE') {
    const message = event.data as NewStateMessage;
    if (handlers?.newState) {
      handlers.newState(message);
    }
    return message;
  }
  if (event.data.type === 'REQUEST_CLOSE') {
    const message = event.data as RequestCloseMessage;
    if (handlers?.requestClose) {
      handlers.requestClose(message);
    }
    return message;
  }
}

export function sendFigmaMessage(
  iframe: HTMLIFrameElement,
  message:
    | NavigateToFrameAndCloseOverlaysSendMessage
    | NavigateForwardSendMessage
    | NavigateBackwardSendMessage
    | ChangeComponentStateSendMessage
    | RestartSendMessage,
) {
  if (!iframe?.contentWindow?.postMessage) {
    console.error('Unable to post message');
    return;
  }
  iframe.contentWindow.postMessage(message, 'https://www.figma.com');
}

export function navigateFigmaPrototype(iframe: HTMLIFrameElement, nodeId: string) {
  sendFigmaMessage(iframe, { type: 'NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS', data: { nodeId } });
}
