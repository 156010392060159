import { gql } from '@apollo/client';

import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';

export default gql`
  mutation createBooking(
    $_project_id: ID
    $timezone: String!
    $currency: String!
    $_team_id: ID!
    $extra_setup_fields: BookingInput
    $country_code: String
    $flags: CreateBookingFlagsInput
  ) {
    createBooking(
      _project_id: $_project_id
      timezone: $timezone
      currency: $currency
      _team_id: $_team_id
      extra_setup_fields: $extra_setup_fields
      country_code: $country_code
      flags: $flags
    ) {
      ...bookingFields
    }
  }
  ${bookingFieldsFragment}
`;
