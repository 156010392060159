import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@askable/ui/components/ui/select';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';

function FrequencyDuration(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [researcherModeratedInterviews, setResearcherModeratedInterviews] = useState(
    _.get(askablePlusBrief, 'askable_plus.research_type.continuous_researcher_moderated.quota'),
  );
  const [AIModeratedInterviews, setAIModeratedInterviews] = useState(
    _.get(askablePlusBrief, 'askable_plus.research_type.continuous_ai_moderated.quota'),
  );
  const [projectDuration, setProjectDuration] = useState(_.get(askablePlusBrief, 'askable_plus.continuous_duration'));

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/frequency-duration`,
        stepId: 'project_setup_frequency_duration',
      });
    }
  }, []);

  const onUpdate = (
    value: number,
    type: 'continuous_duration' | 'continuous_ai_moderated' | 'continuous_researcher_moderated',
  ) => {
    switch (type) {
      case 'continuous_duration':
        setProjectDuration(value);
        break;
      case 'continuous_ai_moderated':
        setAIModeratedInterviews(value);
        break;
      case 'continuous_researcher_moderated':
        setResearcherModeratedInterviews(value);
        break;
      default:
        break;
    }
    const askablePlusBriefStateObj = {
      ...askablePlusBrief,
      askable_plus: {
        ...askablePlusBrief.askable_plus,
        continuous_duration: type === 'continuous_duration' ? value : projectDuration,
        research_type: {
          ...askablePlusBrief.askable_plus.research_type,
          continuous_ai_moderated: {
            ...askablePlusBrief.askable_plus.research_type.continuous_ai_moderated,
            quota: type === 'continuous_ai_moderated' ? value : AIModeratedInterviews,
          },
          continuous_researcher_moderated: {
            ...askablePlusBrief.askable_plus.research_type.continuous_researcher_moderated,
            quota: type === 'continuous_researcher_moderated' ? value : researcherModeratedInterviews,
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    props.history.push({ pathname: `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria` });
  };

  return (
    <div className="createAskablePlusBriefContent gap-4">
      <h1 className="title">Frequency & duration</h1>

      <div className="flex flex-col gap-1">
        <Label htmlFor="__input_ai_moderated">Moderated interviews</Label>
        <div className="flex items-center gap-2">
          <Input
            type="number"
            value={researcherModeratedInterviews}
            onChange={(e) => onUpdate(parseInt(e.target.value), 'continuous_researcher_moderated')}
            className="max-w-48"
          />
          <div className="text-sm">per week</div>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Label htmlFor="__input_ai_moderated">AI Moderated interviews</Label>
        <div className="flex items-center gap-2">
          <Input
            id="__input_ai_moderated"
            type="number"
            value={AIModeratedInterviews}
            onChange={(e) => onUpdate(parseInt(e.target.value), 'continuous_ai_moderated')}
            className="max-w-48"
          />
          <div className="text-sm">per week</div>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Label htmlFor="__input_ai_moderated">Over period</Label>

        <Select onValueChange={(e) => onUpdate(parseInt(e), 'continuous_duration')}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder={`${projectDuration} months`} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="3">3 months</SelectItem>
              <SelectItem value="6">6 months</SelectItem>
              <SelectItem value="12">12 months</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(FrequencyDuration);
