import { BOOKING_STATUS, BOOKING_STATUS_VALUE_TO_KEY } from 'lib/constants';
import { RESOLVED_BOOKING_TYPES } from 'utils/booking-utils';

import { FacetedFilter } from '../components/FacetedFilter';
import { Toolbar } from '../components/Toolbar';

import { statusData } from './BookingsCellStatus';
import { typeData } from './BookingsCellType';

import type { Table } from '@tanstack/react-table';

type Props<TData> = {
  table: Table<TData>;
};

const StatusFilterOrder: (typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS][] = [
  BOOKING_STATUS.ACTIVE,
  BOOKING_STATUS.IN_REVIEW,
  BOOKING_STATUS.DRAFT,
  BOOKING_STATUS.COMPLETED,
  BOOKING_STATUS.ARCHIVED,
];

const TypeFilterOrder: RESOLVED_BOOKING_TYPES[] = [
  RESOLVED_BOOKING_TYPES.VIDEO_CALL,
  RESOLVED_BOOKING_TYPES.SURVEY,
  RESOLVED_BOOKING_TYPES.IN_PERSON,
  RESOLVED_BOOKING_TYPES.LONGITUDINAL_STUDY,
  RESOLVED_BOOKING_TYPES.ONLINE_TASK,
  RESOLVED_BOOKING_TYPES.AI_MODERATED,
];

const statusOptions = StatusFilterOrder.reduce(
  (prev, value: (typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS]) => {
    return [...prev, { label: statusData[value].title, value: BOOKING_STATUS_VALUE_TO_KEY[value] }];
  },
  [] as { label: string; value: keyof typeof BOOKING_STATUS }[],
);

const typeOptions = TypeFilterOrder.reduce(
  (prev, value: RESOLVED_BOOKING_TYPES) => {
    return [...prev, { label: typeData[value].title, value }];
  },
  [] as { label: string; value: string }[],
);

export function BookingsToolbar<TData>({ table }: Props<TData>) {
  return (
    <Toolbar searchId="name" table={table}>
      {table.getColumn('type') && (
        <FacetedFilter
          popoverContentClassName="w-[224px]"
          column={table.getColumn('type')}
          title="Type"
          options={typeOptions}
        />
      )}
      {table.getColumn('status') && (
        <FacetedFilter
          popoverContentClassName="w-[192px]"
          column={table.getColumn('status')}
          title="Status"
          options={statusOptions}
        />
      )}
    </Toolbar>
  );
}
