import { DropdownMenuItem } from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { bookingUtils } from 'lib/booking';

import { CellActions } from '../components/CellActions';
import { DialogArchive } from '../components/DialogArchive';
import { DialogDuplicate } from '../components/DialogDuplicate';

import { ArchiveBookingMutation } from './data/ArchiveBooking.mutation';
import { DuplicateBookingMutation } from './data/DuplicateBooking.mutation';

import type { Booking, BookingConfig } from 'generated/graphql';

type Props = {
  status: Booking['status'];
  name: Booking['name'];
  _id: Booking['_id'];
  demo: BookingConfig['demo'];
};
export function BookingsCellActions({ status, name, _id, demo }: Props) {
  const navigate = useNavigate();
  const [, duplicateBooking] = useMutation(DuplicateBookingMutation);
  const [, archiveBooking] = useMutation(ArchiveBookingMutation);

  const [isDuplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const onConfirm = async () => {
    const newBooking = await duplicateBooking({
      booking_id: _id,
    });

    if (newBooking.error || !newBooking.data?.duplicateBooking?._id) {
      toast.error(
        `Duplication failed. ${newBooking.error ? newBooking.error?.message : 'Duplicated booking id not found'}`,
      );
      return;
    }

    navigate(`/booking-setup/${newBooking?.data?.duplicateBooking?._id}/project-setup/project-title`, {
      state: { bookingID: newBooking?.data?.duplicateBooking?._id },
    });
  };

  const onArchive = async () => {
    const archivedBooking = await archiveBooking({
      booking_id: _id,
    });

    if (archivedBooking.error) {
      toast.error(`Duplication failed. ${archivedBooking.error?.message}`);
      return;
    }

    toast.success('Booking archived');
    setArchiveDialogOpen(false);
  };

  const canDuplicate = bookingUtils.canDuplicate({ config: { demo } });
  const canArchive = bookingUtils.canArchive({ status, config: { demo } });

  if (!canDuplicate && !canArchive) {
    return null;
  }
  return (
    <>
      <CellActions
        dropdownMenuContents={
          <>
            {canDuplicate && (
              <DropdownMenuItem onClick={() => setDuplicateDialogOpen(true)}>Duplicate</DropdownMenuItem>
            )}
            {canArchive && <DropdownMenuItem onClick={() => setArchiveDialogOpen(true)}>Archive</DropdownMenuItem>}
          </>
        }
      />
      <DialogDuplicate
        open={isDuplicateDialogOpen}
        onDuplicate={onConfirm}
        onCancel={() => setDuplicateDialogOpen(false)}
        onOpenChange={setDuplicateDialogOpen}
        resourceName={name || ''}
        resourceTypeName="study"
      />
      <DialogArchive
        open={isArchiveDialogOpen}
        onArchive={onArchive}
        onCancel={() => setArchiveDialogOpen(false)}
        onOpenChange={setArchiveDialogOpen}
        resourceName={name || ''}
        resourceTypeName="study"
      />
    </>
  );
}
