import { TooltipProvider } from '@askable/ui/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

import { KindePropertyWrapper } from 'containers/Auth/KindePropertyWrapper';
import { RequireAuth } from 'containers/Auth/RequireAuth';

import { BuildStudy } from './BuildStudy/BuildStudy';
import { Preview } from './Preview/Preview';
import { Recruit } from './Recruit/Recruit';
import { Report } from './Report/Report';
import { Results } from './Results/Results';
import { StudiesContainer } from './StudiesContainer';

import type { RouteObject } from 'react-router-dom';

export const studiesRoutes: RouteObject[] = [
  {
    path: 'studies/:studyId/preview',
    element: (
      <ThemeProvider attribute="class">
        <HelmetProvider>
          <TooltipProvider>
            <Preview />
          </TooltipProvider>
        </HelmetProvider>
      </ThemeProvider>
    ),
  },
  {
    path: 'studies/:studyId',
    element: (
      <RequireAuth>
        <KindePropertyWrapper>
          <ThemeProvider attribute="class">
            <HelmetProvider>
              <TooltipProvider>
                <StudiesContainer />
              </TooltipProvider>
            </HelmetProvider>
          </ThemeProvider>
        </KindePropertyWrapper>
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="build" replace />,
      },
      {
        path: 'build',
        element: <BuildStudy />,
      },
      {
        path: 'recruit',
        element: <Recruit />,
      },
      {
        path: 'results',
        element: <Results />,
      },
      {
        path: 'report',
        element: <Report />,
      },
    ],
  },
];
