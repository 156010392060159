import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const Study = graphql(`
  query Study($id: ObjectID!) {
    study(_id: $id) {
      _id
      name
      type
      created
      updated
      status

      ... on UnmoderatedStudy {
        draft_config {
          _id
          welcome_block {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
          thank_you_block {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled
            ... on StudyFigmaPrototypeTaskBlock {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }
          }
        }
        live_config {
          _id
          welcome_block {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
          thank_you_block {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled
            ... on StudyFigmaPrototypeTaskBlock {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }
          }
        }
      }
    }
  }
`);

export type StudyType = NonNullable<NonNullable<ResultOf<typeof Study>>['study']>;
