import { cn, isMobileByWidth } from '@askable/ui/lib/utils';

import { Stat } from './Stat';

import type { ScreenProps as ResultsScreenProps } from 'containers/Studies/Results/Results';

export interface ScreenItemProps extends ResultsScreenProps {
  index: number;
  showDetails?: boolean;
  onClick?: (id: string) => void;
}

export const ScreenItem = ({
  duration,
  showDetails,
  height,
  id,
  image,
  index,
  responses,
  title,
  width,
  // onClick,
}: ScreenItemProps) => {
  const isMobile = isMobileByWidth(width);

  return (
    <li
      id={`screen-${id}`}
      className={`row-span-${showDetails ? 2 : 1} grid translate-y-0 snap-center grid-rows-[subgrid] items-center gap-2 opacity-100
      transition-all starting:translate-y-2 starting:opacity-0`}
      style={{ transitionDelay: `${30 * index}ms` }}
    >
      <div className="border-1 checkered overflow-hidden rounded-xl border-border">
        {image ? (
          <img
            alt={title}
            className={cn('aspect-[16/10] w-full object-cover object-top', {
              'aspect-[9/16] object-cover': isMobile,
            })}
            height={height}
            src={image}
            width={width}
          />
        ) : null}
      </div>

      {showDetails ? (
        <div className="flex flex-col gap-1">
          <div className="text-sm font-medium leading-tight">{title}</div>
          <div className="flex gap-3 text-sm text-foreground-subtle">
            {responses ? <Stat type="responses" value={responses} /> : null}
            {duration ? <Stat type="averageDuration" value={duration} /> : null}
          </div>
        </div>
      ) : null}
    </li>
  );
};
