/* Notes: 
- Component renders the iframe with the Figma prototype url
- Tracking of events (setting goal screens in builder, saving participant results, etc) will be
  handled by the parent component, depending on the use case
*/

import { useCallback, useEffect, useMemo, useState } from 'react';

import { figmaMessageHandler } from './figma';

type ClickEventType = {
  // fields TBA
  x: number;
  y: number;
  handled: boolean;
};

export type FigmaEmbedParams = {
  mode?: 'dev';
  footer?: 'false' | 'true';
  'viewport-controls'?: 'false' | 'true';
  'disable-default-keyboard-nav'?: 'false' | 'true';
  'show-proto-sidebar'?: 'false' | 'true';
  'hotspot-hints'?: 'false' | 'true';
  'device-frame'?: 'false' | 'true';
  scaling?: 'scale-down' | 'contain' | 'min-zoom' | 'scale-down-width' | 'fit-width' | 'free';
  'content-scaling'?: 'fixed' | 'responsive';
  'hide-ui'?: 'false' | 'true';
};

const DEFAULT_EMBED_PARAMS: FigmaEmbedParams = {
  footer: 'false',
  'viewport-controls': 'false',
  'disable-default-keyboard-nav': 'false',
  'show-proto-sidebar': 'false',
  'hotspot-hints': 'false',
  'hide-ui': 'true',
};

type Props = {
  clientId: string;
  /* Changing the file id will cause the iframe to reload */
  fileId: string;
  startingPointNodeId?: string;
  /* Changing the node id will cause the iframe to reload */
  loadingPlaceholder?: React.ReactNode;
  embedParams?: FigmaEmbedParams;
  /* Used for sending messages to the iframe (eg. navigating to a different frame without a reload) */
  iframeRef?: React.RefObject<HTMLIFrameElement>;
  onScreenChange?: (nodeId: string) => void;
  onClick?: (event: ClickEventType) => void;
  onLoad?: () => void;
  isBuilder?: boolean;
};

export const FigmaPrototypeFrame: React.FC<Props> = ({
  fileId,
  startingPointNodeId,
  loadingPlaceholder,
  embedParams,
  iframeRef,
  onScreenChange,
  onClick,
  onLoad,
  clientId,
  isBuilder,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const messageListener = useCallback(
    (event: MessageEvent) => {
      figmaMessageHandler(event, {
        mousePressOrRelease:
          onClick &&
          ((message) => {
            onClick({
              x: message.data.nearestScrollingFrameMousePosition?.x,
              y: message.data.nearestScrollingFrameMousePosition?.y,
              handled: !!message.data.handled,
            });
          }),
        presentedNodeChanged:
          onScreenChange &&
          ((message) => {
            onScreenChange(message.data.presentedNodeId);
          }),
        initialLoad: () => {
          new Promise((resolve) => {
            setTimeout(resolve, 2500);
          }).then(() => {
            setIsLoaded(true);
            onLoad?.();
          });
        },
      });
    },
    [onClick, onScreenChange, onLoad],
  );

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  const embedUrlParams = useMemo(() => {
    const params: { [k: string]: string } = {
      'client-id': clientId,
      'embed-host': 'askable',
      ...DEFAULT_EMBED_PARAMS,
      ...embedParams,
    };

    if (startingPointNodeId) {
      params['starting-point-node-id'] = startingPointNodeId;
      params['node-id'] = startingPointNodeId;
    }

    const isDarkMode = document.documentElement.classList.contains('dark');
    if (isBuilder) {
      params['bg-color'] = isDarkMode ? '454954' : 'e5e5e6';
    } else {
      params['bg-color'] = isDarkMode ? '2e3138' : 'F5F5F5';
    }

    return new URLSearchParams(params).toString();
  }, [startingPointNodeId, embedParams, clientId]);

  const embedUrl = useMemo(() => {
    if (!fileId) {
      return null;
    }

    return `https://embed.figma.com/proto/${fileId}?${embedUrlParams}`;
  }, [fileId, embedUrlParams]);

  useEffect(() => {
    setIsLoaded(false);
  }, [embedUrl]);

  if (!embedUrl) {
    return null;
  }

  return (
    <>
      <iframe
        src={embedUrl.toString()}
        className="h-full w-full flex-1 border-0"
        title="Interactive prototype"
        ref={iframeRef}
      />
      {!isLoaded && loadingPlaceholder}
    </>
  );
};
