import { Button } from '@askable/ui/components/ui/button';
import { FigmaPrototypeFrame } from '@askable/ui/components/unmod/figma-prototype-frame';
import { sendFigmaMessage } from '@askable/ui/components/unmod/figma-prototype-frame/figma';
import { cn } from '@askable/ui/lib/utils';
import { Loader2 } from 'lucide-react';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { FigmaFileDetails } from '../BuildStudy/data/FigmaFileDetails.query';

import type { FigmaEmbedParams } from '@askable/ui/components/unmod/figma-prototype-frame/figma-prototype-frame';
import type { ReactNode } from 'react';

const DESKTOP_WIDTH = 1200;
const SCROLLING_FRAME_HEIGHT = 2000;

interface FigmaPreviewProps {
  activeBlockId: string;
  children?: ReactNode;
  embedParams?: FigmaEmbedParams;
  fileId: string;
  previewMode?: boolean;
  showGoalScreenSelect?: boolean;
  startScreenId?: string;
  cancelGoalScreenSelect?: () => void;
  submitGoalScreenId?: (goalScreenId: string) => void;
  onSelectedNode: (nodeId: string) => void;
}

interface FigmaFrame {
  node_id: string;
  width?: number;
  height?: number;
}

export const FigmaPreview = ({
  activeBlockId,
  children,
  embedParams = {},
  fileId,
  previewMode = false,
  showGoalScreenSelect,
  startScreenId,
  cancelGoalScreenSelect = () => false,
  submitGoalScreenId = () => false,
  onSelectedNode,
}: FigmaPreviewProps) => {
  const { t } = useTranslation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [trackedNodeId, setTrackedNodeId] = useState<string | null>(null);
  const [{ data, fetching }] = useQuery({
    query: FigmaFileDetails,
    variables: { fileId },
  });

  // if activeBlockId changes we need to hide the iframe
  // this will keep the figma iframe always rendered which is probably faster
  useEffect(() => {
    if (activeBlockId) {
      setTrackedNodeId(null);
      setIsLoading(true);
    }
  }, [activeBlockId, startScreenId]);

  useEffect(() => {
    // Reset back to the start screen upon goal screen selection
    if (iframeRef.current && startScreenId) {
      sendFigmaMessage(iframeRef.current, {
        type: 'NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS',
        data: { nodeId: startScreenId },
      });
    }
  }, [showGoalScreenSelect, startScreenId]);

  const { isDesktopFrame, isScrollingFrame } = useMemo(() => {
    const currentFrame = ((data?.figmaFile?.frames as FigmaFrame[]) ?? []).find(
      (_frame) => _frame.node_id === startScreenId,
    );

    return {
      frame: currentFrame,
      isDesktopFrame: currentFrame ? (currentFrame.width ?? 0) > DESKTOP_WIDTH : false,
      isScrollingFrame: currentFrame ? (currentFrame.height ?? 0) > SCROLLING_FRAME_HEIGHT : false,
    };
  }, [data?.figmaFile?.frames, startScreenId]);

  const showLoader = isLoading || fetching;

  return (
    <div className="flex h-full flex-col gap-4">
      {!previewMode && showGoalScreenSelect ? (
        <header className="pt-3 font-semibold leading-none">
          {t('sections.studies.build.formFields.figmaPrototypeScreens.prompt')}
        </header>
      ) : null}

      <div className="drag-container relative h-full w-full flex-1 @container">
        <div
          className={cn('h-full w-full bg-prototype px-6', {
            'rounded-xl py-6 ': !previewMode,
            'border-prototype': !showGoalScreenSelect && !previewMode,
            'ring-[1.5px] ring-primary': showGoalScreenSelect,
          })}
        >
          {children || null}
          {showLoader ? (
            <div className="flex h-full w-full items-center justify-center">
              <Loader2 className="h-12 w-12 animate-spin opacity-50" aria-label={t('global.loading')} />
            </div>
          ) : null}

          <div className={cn('flex h-full w-full flex-1', { hidden: showLoader })}>
            <FigmaPrototypeFrame
              key={activeBlockId}
              isBuilder
              onLoad={() => setIsLoading(false)}
              clientId={import.meta.env.VITE_FIGMA_CLIENT_ID}
              iframeRef={iframeRef}
              fileId={fileId}
              startingPointNodeId={startScreenId ?? undefined}
              embedParams={{
                scaling: isDesktopFrame || isScrollingFrame ? 'scale-down-width' : 'scale-down',
                'device-frame': 'false',
                ...embedParams,
              }}
              onScreenChange={(nodeId) => {
                setTrackedNodeId(nodeId === startScreenId ? null : nodeId);
                onSelectedNode(nodeId);
              }}
              onClick={(event) => {
                console.log('click event', event);
              }}
            />
          </div>
        </div>
      </div>

      {/*
       * TODO: this whole mode should probably be a separate component, not blended into the preview it has not much
       * to do with same as we do with the start screen selection?
       */}
      {!previewMode && showGoalScreenSelect ? (
        <footer className="flex items-center justify-end gap-2">
          <Button variant="ghost" onClick={cancelGoalScreenSelect}>
            {t('global.cancel')}
          </Button>

          <Button
            disabled={!trackedNodeId}
            onClick={() => {
              if (!trackedNodeId) {
                return;
              }
              submitGoalScreenId(trackedNodeId);
              cancelGoalScreenSelect();
            }}
            variant="primary"
          >
            {t('sections.studies.setAsScreen', { type: t('sections.studies.goal').toLocaleLowerCase() })}
          </Button>
        </footer>
      ) : null}
    </div>
  );
};
