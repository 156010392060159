import _ from 'lodash';
import React, { useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Dialog } from 'components/common';

import './styles/accessDeniedMessageStyles.scss';

function AccessDeniedMessage(props: any) {
  const [openMessage, setOpenMessage] = useState(true);
  const message = _.get(props, 'message') || 'Oops! You are not allowed to see this 😕';

  return (
    <Dialog
      modal={false}
      open={openMessage}
      title="Access Denied!"
      styles={{ width: '375px' }}
      contentClassName="accessDeniedContainer"
      buttonsGroupClass="accessDeniedButtonsGroup"
      customActions={[
        {
          label: 'Okay',
          primary: true,
          buttonType: 'default',
          buttonContainerClass: 'okayButton',
          customFunction: () => {
            setOpenMessage(false);
            props.history.push({ pathname: '/' });
          },
        },
      ]}
    >
      <p className="textMessage">{message}</p>
    </Dialog>
  );
}

export default deprecatedWithRouter(AccessDeniedMessage);
