import { ReactNode, CSSProperties, useState, useCallback, useRef, useEffect } from 'react';
import { cn } from '@askable/ui/lib/utils';
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useDraggable, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '@askable/ui/components/ui/button';
import { ChevronRight, EyeOff, GripVertical } from 'lucide-react';
import { StudyBlockType } from './types';

/**
 * Task Card Draggable
 * Stores the position of the draggable card in localstorage
 * Collapsed state is controlled by the parent component
 */

interface Position {
  x: number;
  y: number;
}

interface CardState {
  position: Position;
}

interface CardSubheaderProps {
  dragHandleProps: Record<string, unknown>;
  subtitle: string;
}

export interface TaskCardDraggableProps {
  buttonLabel: string;
  children: ReactNode;
  collapseLabel?: string;
  id: string;
  isCollapsed?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  isPositionSaved?: boolean;
  subtitle: string;
  isLoading?: boolean;
  onProgress?: () => void;
  onCollapse: (isCollapsed: boolean) => void;
}

interface TaskCardProps extends Omit<TaskCardDraggableProps, 'isPositionSaved'> {
  position: Position;
}

interface TaskCardDraggableHeaderProps {
  instructions: string;
  title: string;
  type: StudyBlockType;
}

interface useContainerResizeProps {
  cardState: CardState;
  saveCardState: (state: CardState) => void;
}

const COLLAPSED_SIZE = { h: 60, w: 36 };
const EDGE_OFFSET = 8;
const INITIAL_OFFSET = 16;
const SNAP_THRESHOLD = 24;

// Snap to edge of the container when within the SNAP_THRESHOLD distance
const snapToEdge = (container: DOMRect, draggable: DOMRect, newPosition: Position): Position => {
  const { x, y } = newPosition;
  let snappedX = x;
  let snappedY = y;

  // Snap to left/right edges
  if (Math.abs(draggable.left - container.left) < SNAP_THRESHOLD) {
    snappedX = EDGE_OFFSET;
  } else if (Math.abs(container.right - draggable.right) < SNAP_THRESHOLD) {
    snappedX = container.width - draggable.width - EDGE_OFFSET;
  }

  // Snap to top/bottom edges
  if (Math.abs(draggable.top - container.top) < SNAP_THRESHOLD) {
    snappedY = container.height - draggable.height - EDGE_OFFSET;
  } else if (Math.abs(container.bottom - draggable.bottom) < SNAP_THRESHOLD) {
    snappedY = EDGE_OFFSET;
  }

  return { x: snappedX, y: snappedY };
};

// Custom hooks
const useContainer = () => {
  return useCallback(() => {
    const container = document.querySelector('.drag-container')?.getBoundingClientRect();

    if (!container) {
      throw new Error('Container not found');
    }

    return container;
  }, []);
};

// Custom hook for drag sensors
const useDragSensors = () => {
  return useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 8 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 6,
      },
    }),
  );
};

// Save card state in localstorage
const useCardState = (cardId: string, isPositionSaved: boolean) => {
  const [cardState, setCardState] = useState<CardState>(() => {
    const saved = localStorage.getItem(cardId);

    if (!saved || !isPositionSaved) {
      return {
        position: { x: INITIAL_OFFSET, y: INITIAL_OFFSET },
      };
    }
    const parsedState = JSON.parse(saved);
    return {
      ...parsedState,
    };
  });

  const saveCardState = useCallback(
    (newState: CardState) => {
      setCardState(newState);
      if (isPositionSaved) {
        localStorage.setItem(cardId, JSON.stringify(newState));
      }
    },
    [cardId],
  );

  return { cardState, saveCardState };
};

// Reposition card when container is resized below 480px
const useContainerResize = ({ cardState, saveCardState }: useContainerResizeProps) => {
  useEffect(() => {
    const containerEl = document.querySelector('.drag-container');
    if (!containerEl) {
      return;
    }

    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect.width < 480) {
        saveCardState({
          ...cardState,
          position: { x: INITIAL_OFFSET, y: INITIAL_OFFSET },
        });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(containerEl);
    return () => resizeObserver.disconnect();
  }, []);
};

// Whole header is draggable
const CardHeader = ({ subtitle, dragHandleProps }: CardSubheaderProps) => (
  <div
    className="group hidden !cursor-grab touch-none items-center justify-between gap-3 p-4 active:!cursor-grabbing md:flex"
    title="Drag card"
    {...dragHandleProps}
  >
    <div className="w-fit rounded-lg bg-background-subtle px-3 py-2 text-xs font-medium leading-none">{subtitle}</div>
    <GripVertical className="h-4 w-4 text-muted-foreground group-hover:text-foreground group-active:text-foreground" />
  </div>
);

const Card = ({
  buttonLabel = 'Go',
  children,
  collapseLabel,
  id,
  isCollapsed = false,
  isDisabled = false,
  isDraggable = true,
  isLoading = false,
  position,
  subtitle,
  onProgress,
  onCollapse,
}: TaskCardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [isFinishedDragging, setIsFinishedDragging] = useState(false);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled: !isDraggable || isCollapsed,
  });

  // We need the card height to animate it to expanded state
  useEffect(() => {
    if (contentRef.current) {
      const newHeight = !isDraggable && !isCollapsed ? null : contentRef.current.offsetHeight;
      setContentHeight(newHeight);
    }
  }, [children]);

  // We're applying duration-500 to the card for collapsing animation but don't want that while dragging
  useEffect(() => {
    if (isDragging) {
      setIsFinishedDragging(false);
    } else {
      setTimeout(() => setIsFinishedDragging(true), 501);
    }
  }, [isDragging]);

  const cardStyle: CSSProperties = {
    position: 'absolute',
    height: isCollapsed ? `${COLLAPSED_SIZE.h}px` : contentHeight ? `${contentHeight}px` : 'auto',
    width: isCollapsed ? `${COLLAPSED_SIZE.w}px` : undefined,
    left: isCollapsed ? 0 : `${position.x}px`,
    bottom: isCollapsed ? '84px' : `${position.y}px`,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
  };

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={cardStyle}
      className={cn(
        'z-20 grid max-h-[calc(100%_-_16px)] origin-center overflow-hidden rounded-xl bg-background shadow',
        {
          'rounded-l-none focus-within:shadow-lg hover:shadow-lg': isCollapsed,
          'w-[calc(100%_-_32px)] md:w-[380px]': !isCollapsed,
          'shadow-lg': isDragging,
          'duration-500 ease-anticipation motion-reduce:transition-none': isFinishedDragging,
        },
      )}
    >
      <div
        ref={contentRef}
        className={cn(
          `col-start-1 col-end-2 row-start-1 row-end-2 flex w-[350px] min-w-full flex-col justify-between pt-4 transition-all
          delay-200 motion-reduce:transition-none md:w-[380px] md:pt-0`,
          {
            'overflow-hidden opacity-0': isCollapsed,
          },
        )}
      >
        {isDraggable ? <CardHeader subtitle={subtitle} dragHandleProps={{ ...attributes, ...listeners }} /> : null}
        <div className="flex flex-1 flex-col gap-4 overflow-auto px-4 pb-4">{children}</div>
        <footer className="flex items-center justify-between gap-2 border-t border-border px-4 pb-4 pt-4">
          {collapseLabel ? (
            <Button variant="ghost" className="gap-2 text-muted-foreground" onClick={() => onCollapse(true)}>
              <EyeOff className="h-4 w-4" /> {collapseLabel}
            </Button>
          ) : (
            <div />
          )}
          <Button variant="outline" disabled={isDisabled} isLoading={isLoading} onClick={onProgress}>
            {buttonLabel}
          </Button>
        </footer>
      </div>

      <button
        className={cn(
          `pointer-events-none z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex -translate-x-2 flex-col items-center
          justify-center opacity-0 transition-all delay-300`,
          {
            [`pointer-events-auto translate-x-0 cursor-pointer opacity-100 hover:scale-110 focus:scale-110
            motion-reduce:transition-none`]: isCollapsed,
          },
        )}
        style={{ height: `${COLLAPSED_SIZE.h}px`, width: `${COLLAPSED_SIZE.w}px` }}
        onClick={() => onCollapse(false)}
        title="Expand"
      >
        <ChevronRight className="h-6 w-6" />
      </button>
    </div>
  );
};

export const TaskCardDraggableHeader = ({ instructions, title, type }: TaskCardDraggableHeaderProps) => {
  return (
    <header className="flex flex-col gap-1">
      <h3 className="text-lg font-semibold">{title ?? type}</h3>
      {instructions ? <p className="whitespace-pre-wrap text-sm">{instructions}</p> : null}
    </header>
  );
};

export const TaskCardDraggable = ({
  id,
  onCollapse,
  isCollapsed = false,
  isPositionSaved = true,
  ...props
}: TaskCardDraggableProps) => {
  const cardId = `card-${id}`;
  const getContainer = useContainer();
  const sensors = useDragSensors();
  const { cardState, saveCardState } = useCardState(cardId, isPositionSaved);

  useContainerResize({ cardState, saveCardState });

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { delta } = event;
      const newPosition = {
        x: Number((cardState.position.x + delta.x).toFixed(0)),
        y: Number((cardState.position.y - delta.y).toFixed(0)),
      };

      const container = getContainer();
      const draggable = document.getElementById(cardId)?.getBoundingClientRect();
      const snappedPosition = draggable ? snapToEdge(container, draggable, newPosition) : newPosition;

      saveCardState({
        ...cardState,
        position: snappedPosition,
      });
    },
    [cardState, getContainer, cardId, saveCardState],
  );

  return (
    <DndContext sensors={sensors} modifiers={[restrictToParentElement]} onDragEnd={onDragEnd}>
      <Card id={cardId} {...props} isCollapsed={isCollapsed} position={cardState.position} onCollapse={onCollapse} />
    </DndContext>
  );
};
