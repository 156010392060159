import { Alert, AlertDescription, AlertTitle } from '@askable/ui/components/ui/alert';
import { Badge } from '@askable/ui/components/ui/badge';
import { Link } from '@askable/ui/components/ui/link';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import { InfoIcon } from 'lucide-react';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, SlideFade, useDisclosure, Button as ChakraButton } from 'ui';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Button, Card } from 'components/common';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, REQUIRED_DEVICE_TYPE } from 'lib/constants';
import { utils } from 'lib/utils';

import type { CardProps } from 'components/common';
import type { ReactElement } from 'react';

import './styles/toolStyles.scss';

function PlatformTool(props: any) {
  const booking = _.get(props, 'booking');
  const selectedDevice = bookingUtils.getSelectedDevice(booking, REQUIRED_DEVICE_TYPE.ANY);
  const { SESSIONS_MOBILE } = useFeatureFlags(['SESSIONS_MOBILE']);
  const { t } = useTranslation();

  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [toolSelected, setToolSelected] = useState<{
    toolName?: string;
    toolImage?: string;
    toolLabel?: string;
  } | null>(null);

  const { isOpen: thirdPartyToolOpen, onToggle: toggleThirdPartyTools } = useDisclosure({
    defaultIsOpen: booking.config?.remote?.tool !== 'askableLive',
  });

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/tool`,
      stepId: 'project_setup_tool',
    });
    if (bookingUtils.includesBookingType(booking, [BOOKING_TYPE.IN_PERSON, BOOKING_TYPE.LONGITUDINAL_STUDY])) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/project-setup/quota` });
    }
    loadDefaultTool();
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    loadDefaultTool();
  }, [booking]);

  useEffect(() => {
    props.renderRightContent(rightSidebarContent);
    props.renderRightAppPreview(null);
  }, [toolSelected, bookingState]);

  const rightSidebarContent = () => {
    const data: { description: ReactElement; demoLink: string; learnMoreLink: string } = (() => {
      return {
        description: (
          <>
            <p className="cardText toolInfo">
              Askable Sessions - a video call tool specificially built for user research, fully integrated with the
              Askable experience.
            </p>
            <p className="cardText">• Out of the box, no download required</p>
            <p className="cardText">• Automated meeting invites</p>
            <p className="cardText">• Access security</p>
            <p className="cardText">• Record consent</p>
            <p className="cardText">• On-call note-taking</p>
            <p className="cardText">• Automated transcription</p>
          </>
        ),
        demoLink: '/askable-sessions/demo',
        learnMoreLink: 'https://help.askable.com/en/articles/6681854',
      };
    })();
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Your chosen tool</p>
        <Box
          padding="24px"
          border="1px"
          borderColor="gray.200"
          borderRadius="8px"
          id="__toolCardTipContainer"
          className="cardContainer slideInAnimation"
        >
          {_.get(toolSelected, 'toolImage') && (
            <div className={`toolImage ${_.get(toolSelected, 'toolImage')?.replace('.svg', '').replace('.png', '')}`}>
              <img
                className="illustration"
                src={`/logos/${_.get(toolSelected, 'toolImage')}`}
                alt="Type illustration"
              />
            </div>
          )}
          {_.get(toolSelected, 'toolName') === 'askableLive' && (
            <Fragment>
              {data.description}

              <div
                className="cardLinkContainer"
                onClick={() => {
                  window.open(data.demoLink, '_blank');
                }}
              >
                <span className="cardLink">Try a demo</span>
                <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
              </div>

              <div
                className="cardLinkContainer additionalLink"
                onClick={() => {
                  window.open(data.learnMoreLink, '_blank');
                }}
              >
                <span className="cardLink">Learn more</span>
                <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
              </div>
            </Fragment>
          )}
          {bookingUtils.isRemote(booking) && _.get(toolSelected, 'toolName') === 'other' && (
            <p id="__otherToolCardText" className="cardText thirdParty">
              You&apos;ve chosen to use other third party video call tool.
            </p>
          )}
          {bookingUtils.isRemote(booking) &&
            _.get(toolSelected, 'toolName') !== 'askableLive' &&
            _.get(toolSelected, 'toolName') !== 'other' && (
              <p id="__thirdPartyToolCardText" className="cardText thirdParty">
                You&apos;ve chosen to use a third party video call tool.
              </p>
            )}
          {bookingUtils.isOnlineTask(booking) && _.get(toolSelected, 'toolName') === 'optimalWorkshop' && (
            <p id="__optimalWorkshopCardText" className="cardText thirdParty">
              Optimal Workshop is a preferred Askable partner and one of the most popular tools for unmoderated UX
              testing.
              <br />
              <br />
            </p>
          )}
          {bookingUtils.isOnlineTask(booking) && (
            <p id="__thirdPartyToolCardText" className="cardText thirdParty">
              {`Since this is a 3rd party tool, please note that we cannot provide support for any issues you may have with ${
                _.get(toolSelected, 'toolLabel') === 'Other'
                  ? 'the other chosen tool.'
                  : `your ${_.get(toolSelected, 'toolLabel')} study.`
              }`}
            </p>
          )}
        </Box>

        {bookingUtils.isRemote(booking) && _.get(toolSelected, 'toolName') !== 'askableLive' && (
          <div className="headsUpContainer flipAnimation">
            <img className="headsUpIcon" src="/icons/warningIcon.svg" alt="Type illustration" />
            <p className="headsUpTitle">Heads up!</p>
            <p className="headsUpText">
              When using a third party tool, you will need to manually send meeting invite links and provide
              instructions to the participants. It&apos;s still easy for you to do that with Askable&apos;s built in
              messaging system.
            </p>
            <p className="headsUpText">
              We cannot guarantee participants are familiar with your chosen tool and we won&apos;t be able to offer any
              technical support.
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  const loadDefaultTool = () => {
    if (bookingUtils.isRemote(booking)) {
      const toolName = _.get(booking, 'config.remote.tool', 'askableLive');
      if (toolName === 'askableLive' && selectedDevice !== REQUIRED_DEVICE_TYPE.DESKTOP && !SESSIONS_MOBILE) {
        setToolSelected(null);
        return;
      }

      const toolImage = utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), toolName, 'logoName');
      const toolLabel = utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), toolName, 'label');
      setToolSelected({ toolName, toolImage, toolLabel });
    } else if (bookingUtils.isSurvey(booking)) {
      const toolName = _.get(booking, 'config.online_task.tool', 'surveyMonkey');
      const toolImage = utils.getLabelFromArray(bookingUtils.bookingSurveyToolOptions(), toolName, 'logoName');
      const toolLabel = utils.getLabelFromArray(bookingUtils.bookingSurveyToolOptions(), toolName, 'label');
      setToolSelected({ toolName, toolImage, toolLabel });
    } else if (bookingUtils.isOnlineTask(booking)) {
      const toolName = _.get(booking, 'config.online_task.tool', 'optimalWorkshop');
      const toolImage = utils.getLabelFromArray(bookingUtils.bookingOnlineTaskToolOptions(), toolName, 'logoName');
      const toolLabel = utils.getLabelFromArray(bookingUtils.bookingOnlineTaskToolOptions(), toolName, 'label');
      setToolSelected({ toolName, toolImage, toolLabel });
    }
  };

  const handleAnimation = () => {
    const element = document.getElementById('__toolCardTipContainer');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    element.classList.remove('slideInAnimation');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    setTimeout(() => element.classList.add('slideInAnimation'), 1);
  };

  const onClickCard = (tool: any) => {
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        online_task: {
          ...bookingState.config.online_task,
          tool: bookingUtils.isOnlineTask(booking) ? tool : null,
        },
        remote: {
          ...bookingState.config.remote,
          tool: bookingUtils.isRemote(booking) ? tool : null,
          askable_live: tool === 'askableLive',
        },
      },
    };
    setBookingState(bookingStateObj);

    const currentTool = bookingUtils.isOnlineTask(booking)
      ? _.get(booking, 'config.online_task.tool')
      : _.get(booking, 'config.remote.tool');
    if (tool !== currentTool) {
      const bookingObj = {
        config: {
          online_task: {
            tool: bookingUtils.isOnlineTask(booking) ? tool : null,
          },
          remote: {
            tool: bookingUtils.isRemote(booking) ? tool : null,
            askable_live: tool === 'askableLive',
          },
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { online_task: { tool:... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = (otherTool: any) => {
    let bookingObj = bookingData;
    let bookingStateObj = bookingState;
    if (otherTool) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ config: { online_task: { tool: string | nu... Remove this comment to see the full error message
      bookingObj = {
        config: {
          online_task: {
            tool: bookingUtils.isOnlineTask(booking) ? 'other' : null,
          },
          remote: {
            tool: bookingUtils.isRemote(booking) ? 'other' : null,
            askable_live: false,
          },
        },
      };
      bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          online_task: {
            ...bookingState.config.online_task,
            tool: bookingUtils.isOnlineTask(booking) ? 'other' : null,
          },
          remote: {
            ...bookingState.config.remote,
            tool: bookingUtils.isRemote(booking) ? 'other' : null,
            askable_live: false,
          },
        },
      };
    }
    setBookingState(bookingStateObj);
    const redirectTo = `/booking-setup/${booking._id}/project-setup/quota`;
    props.history.push({ pathname: redirectTo, booking: bookingObj, bookingState: bookingStateObj });
  };

  const renderPlatformLogo = (name: any) => {
    const className = name.replace('.svg', '').replace('.png', '');
    const tool = className.replace('Logo', '');
    let toolLabel = tool;
    if (bookingUtils.isRemote(booking)) {
      toolLabel = utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), tool, 'label');
    } else if (bookingUtils.isSurvey(booking)) {
      toolLabel = utils.getLabelFromArray(bookingUtils.bookingSurveyToolOptions(), tool, 'label');
    } else if (bookingUtils.isOnlineTask(booking)) {
      toolLabel = utils.getLabelFromArray(bookingUtils.bookingOnlineTaskToolOptions(), tool, 'label');
    }

    return (
      <Card
        id={`__${tool}Tool`}
        key={tool}
        image={`/logos/${name}`}
        selected={_.get(toolSelected, 'toolName') === tool}
        className={className}
        onClickCard={() => {
          if (_.get(toolSelected, 'toolName') === tool) return null;
          onClickCard(tool);
          handleAnimation();
          setTimeout(() => setToolSelected({ toolName: tool, toolImage: name, toolLabel }), 2);
        }}
      />
    );
  };

  const renderAskableLiveTool = () => {
    const isDesktop = selectedDevice === REQUIRED_DEVICE_TYPE.DESKTOP;
    const cardProps: Partial<CardProps> = (() => {
      const _onClickCard = (image: string) => () => {
        if (_.get(toolSelected, 'toolName') === 'askableLive') return null;
        onClickCard('askableLive');
        handleAnimation();
        setTimeout(
          () => setToolSelected({ toolName: 'askableLive', toolImage: image, toolLabel: 'Askable Sessions' }),
          2,
        );
      };
      return {
        image: '/logo.svg',
        onClickCard: _onClickCard('askableSessionsLogo.svg'),
      };
    })();

    const renderToolInfo = () => {
      return (
        <div className="flex max-w-xl flex-col gap-2">
          <div>
            Conduct research with secure access controls, on call note taking, automated transcripts, built in privacy
            and consent. Participants will be required to download the mobile app, if a mobile experience is required.{' '}
            <Link
              href="https://help.askable.com/en/articles/6681854"
              target="_blank"
              className="font-normal no-underline"
            >
              Learn more
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <Badge variant="outline">+5 credits per person</Badge>
          </div>
          {SESSIONS_MOBILE &&
          toolSelected?.toolName === 'askableLive' &&
          selectedDevice !== REQUIRED_DEVICE_TYPE.DESKTOP ? (
            <div>
              <Alert variant="info" className="mb-8 mt-4 max-w-xl">
                <InfoIcon className="h-4 w-4 " />
                <AlertTitle>{t('sections.booking.setup.device.alert.title')}</AlertTitle>
                <AlertDescription>{t('sections.booking.setup.device.alert.description')}</AlertDescription>
              </Alert>
            </div>
          ) : null}
        </div>
      );
    };
    return (
      <div className="platformToolGroup">
        <p className="platformToolLabel">
          Fully integrated ({isDesktop || SESSIONS_MOBILE ? 'recommended' : 'Desktop only'})
        </p>
        <div className="askableLiveCardContainer flex-col gap-4">
          <Card
            disabled={!(isDesktop || SESSIONS_MOBILE)}
            id="__askableLiveTool"
            key="askableLive"
            selected={_.get(toolSelected, 'toolName') === 'askableLive'}
            className="askableLiveCard"
            imageAlt="Askable Sessions"
            {...cardProps}
          />
          {renderToolInfo()}
        </div>
      </div>
    );
  };

  const renderRemoteTools = () => {
    const firstLine = ['zoomLogo.png', 'lookbackLogo.svg', 'microsoftTeamsLogo.png'];
    const secondLine = ['validatelyLogo.png', 'loop11Logo.png', 'googleMeetLogo.svg'];
    const thirdLine = ['ciscoWebexLogo.svg', 'hearsayLogo.png'];

    return (
      <div className="platformToolGroup">
        <p className="platformToolLabel">Third party software</p>
        <div className="platformToolLogos">{_.map(firstLine, (logo: any) => renderPlatformLogo(logo))}</div>
        <div className="platformToolLogos">{_.map(secondLine, (logo: any) => renderPlatformLogo(logo))}</div>
        <div className="platformToolLogos">{_.map(thirdLine, (logo: any) => renderPlatformLogo(logo))}</div>
      </div>
    );
  };

  const renderOnlineTaskTools = () => {
    const firstLine = ['optimalWorkshopLogo.png', 'loop11Logo.png', 'userZoomLogo.svg'];
    const secondLine = ['validatelyLogo.png', 'dscoutLogo.svg', 'qualtricsLogo.svg'];
    const thirdLine = ['usabilityHubLogo.svg', 'lookbackLogo.svg', 'mazeLogo.svg'];
    return (
      <div className="platformToolGroup">
        <div className="platformToolLogos">{_.map(firstLine, (logo: any) => renderPlatformLogo(logo))}</div>
        <div className="platformToolLogos">{_.map(secondLine, (logo: any) => renderPlatformLogo(logo))}</div>
        <div className="platformToolLogos">{_.map(thirdLine, (logo: any) => renderPlatformLogo(logo))}</div>
      </div>
    );
  };

  const renderSurveyTools = () => {
    const firstLine = ['surveyMonkeyLogo.svg', 'typeformLogo.svg', 'optimalWorkshopLogo.png'];
    const secondLine = ['surveyGizmoLogo.svg', 'googleFormsLogo.svg'];
    return (
      <div className="platformToolGroup">
        <div className="platformToolLogos">{_.map(firstLine, (logo: any) => renderPlatformLogo(logo))}</div>
        <div className="platformToolLogos">{_.map(secondLine, (logo: any) => renderPlatformLogo(logo))}</div>
      </div>
    );
  };

  return (
    <div className="createBookingContent">
      {bookingUtils.isRemote(booking) && (
        <Fragment>
          <h1 id="__pageTitle" className="title researchTitle">
            Which tool will you use to conduct the remote research?
          </h1>
          {_.get(booking, 'config.session.type') === 1 && renderAskableLiveTool()}
          <Flex flexDir="column" alignItems="flex-start" mt="4">
            <>
              <ChakraButton colorScheme="blue" variant="link" onClick={toggleThirdPartyTools}>
                I want to use a third party tool
              </ChakraButton>
              <SlideFade in={thirdPartyToolOpen}>
                {thirdPartyToolOpen && (
                  <>
                    {renderRemoteTools()}
                    <ChakraButton mt="4" variant="link" colorScheme="blue" onClick={() => onClickNext(true)}>
                      I&apos;m using another tool not listed here
                    </ChakraButton>
                  </>
                )}
              </SlideFade>
            </>
          </Flex>
        </Fragment>
      )}
      {bookingUtils.isOnlineTask(booking) && !bookingUtils.isSurvey(booking) && (
        <Fragment>
          <h1 id="__pageTitle" className="title">
            What tool will you use for your online task?
          </h1>
          {renderOnlineTaskTools()}
          <ChakraButton
            justifyContent="flex-start"
            mt="4"
            variant="link"
            colorScheme="blue"
            onClick={() => onClickNext(true)}
          >
            I&apos;m using another tool not listed here
          </ChakraButton>
        </Fragment>
      )}
      {bookingUtils.isSurvey(booking) && (
        <Fragment>
          <h1 id="__pageTitle" className="title">
            What tool will you use for your survey?
          </h1>
          {renderSurveyTools()}
          <ChakraButton
            justifyContent="flex-start"
            mt="4"
            variant="link"
            colorScheme="blue"
            onClick={() => onClickNext(true)}
          >
            I&apos;m using another tool not listed here
          </ChakraButton>
        </Fragment>
      )}

      <div className="buttonNextContainer">
        <Button label="Next" className="button" disabled={toolSelected === null} onClick={() => onClickNext(false)} />
      </div>
    </div>
  );
}

export default deprecatedWithRouter(PlatformTool);
