import { useMutation } from '@apollo/client';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import _ from 'lodash';
import { useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { LoadingOverlay } from 'components/common';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import changeFacilitatorContactMutation from 'data/mutations/booking/changeFacilitatorContact';
import { utils } from 'lib/utils';

import './styles/changeFacilitatorStyles.scss';

function ChangeFacilitatorContactPopover(props: any) {
  const [loadingUI, setLoadingUI] = useState(false);

  const context = useAppContext();

  const [changeFacilitatorContact] = useMutation(changeFacilitatorContactMutation, {
    onCompleted: () => {
      setLoadingUI(false);
      toast.success('Facilitator changed successfully');
    },
    onError: () => {
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      loadingUI(false);
    },
  });

  const onChangeFacilitatorContact = async (user: any) => {
    setLoadingUI(true);

    // Changes the facilitator
    await changeFacilitatorContact({
      variables: {
        booking_id: _.get(props, 'booking._id'),
        _facilitator_user_id: _.get(user, '_id'),
      },
    }).then(({ data }) => {
      if (data && data.changeFacilitatorContact) {
        if (props.onChangeFacilitator) {
          props.onChangeFacilitator(_.get(data, 'changeFacilitatorContact.config.contact'));
        }
      }
    });
  };

  const renderInviteTeamMemberLink = () => {
    return (
      <a
        className="inviteTeammate"
        onClick={() => {
          context.onOpenInviteTeamMemberModal(null, true);
        }}
      >
        + Invite a teammate to Askable
      </a>
    );
  };

  const renderUsersInTeam = (user: any) => {
    return (
      <DropdownMenuItem
        id={`__${user._id}`}
        key={user._id}
        className="flex flex-col items-start p-2"
        onClick={() => onChangeFacilitatorContact(user)}
      >
        <div>
          {_.get(user, 'User.meta.identity.firstname')} {_.get(user, 'User.meta.identity.lastname')}
        </div>
        <div className="text-xs text-muted-foreground">{_.get(user, 'User.email')}</div>
        <div className="text-xs text-muted-foreground">
          {utils.formatPhoneNumber(_.get(user, 'User.contact.phone.mobile'))}
        </div>
      </DropdownMenuItem>
    );
  };

  const renderNoUsersContainer = () => {
    return (
      <DropdownMenuLabel className="noUsersContainer">
        <p>You have no team members</p>
        {renderInviteTeamMemberLink()}
      </DropdownMenuLabel>
    );
  };

  const renderFacilitators = () => {
    const usersInTeam = _.filter(
      _.get(props.data, 'teamById.users'),
      (users: any) => users.status === 1 && users._id !== _.get(props, 'booking.config.contact._facilitator_user_id'),
    );
    if (_.size(usersInTeam) === 0) return renderNoUsersContainer();
    return (
      <>
        {_.map(usersInTeam, (users: any) => renderUsersInTeam(users))}
        <DropdownMenuLabel>{renderInviteTeamMemberLink()}</DropdownMenuLabel>
      </>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>Change</DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[25rem] overflow-auto">
        {loadingUI ? (
          <DropdownMenuLabel>
            <LoadingOverlay style={{ opacity: 0.8 }} />
          </DropdownMenuLabel>
        ) : null}
        {renderFacilitators()}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default deprecatedWithRouter(ChangeFacilitatorContactPopover);
