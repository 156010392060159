import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import _ from 'lodash';
import { CirclePlus, CircleX, List, Text } from 'lucide-react';
import { Component, Fragment } from 'react';
import Spinner from 'react-spinkit';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Modal, Button, LoadingOverlay } from 'components/common';
import createBulkQuestionsMutation from 'data/mutations/booking/createBulkQuestions';
import fetchBookingsByTeam from 'data/queries/booking/fetchBookingsByTeam';
import { bookingUtils } from 'lib/booking';
import { update } from 'lib/utils';

import './styles/modalBookingsList.scss';

class BookingsListModal extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedBooking: null,
      selectedQuestions: [],
      loading: false,
      statusesToSearchFor: [0, 1, 2, 3, 4, 5, 6, 7],
      sortOptionSelected: {
        sortBy: 'created',
        order: -1,
      },
      selectedFilterStatus: null,
    };

    this.parseBodyValues = this.parseBodyValues.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.resetState = this.resetState.bind(this);
    this.onSelectAllQuestions = this.onSelectAllQuestions.bind(this);
    this.isAllQuestionsSelected = this.isAllQuestionsSelected.bind(this);
    this.onCreateQuestions = this.onCreateQuestions.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    this.renderNoBookingsMessageContainer = this.renderNoBookingsMessageContainer.bind(this);
  }

  onSelectAllQuestions() {
    // If the size of the selected questions array is different to the size of all the questions it means that not all questions were selected
    if (this.isAllQuestionsSelected()) {
      this.setState((prevState) => ({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
        selectedQuestions: update(prevState.selectedQuestions, {
          $set: [],
        }),
      }));
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message
      const remainingQuestions = _.filter(this.state.selectedBooking.questions, (question: any) => {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
        return (_.size(_.filter(this.state.selectedQuestions, (selectedQuestions: any) => selectedQuestions._id === question._id)) === 0);
      });
      this.setState({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
        selectedQuestions: update(this.state.selectedQuestions, {
          $push: remainingQuestions,
        }),
      });
    }
  }

  onSelectQuestion(question: any, isChecked: any) {
    if (isChecked) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
      const newState = this.state.selectedQuestions.filter((item: any) => item._id !== question._id);
      this.setState((prevState) => ({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
        selectedQuestions: update(prevState.selectedQuestions, {
          $set: newState,
        }),
      }));
    } else {
      this.setState({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
        selectedQuestions: update(this.state.selectedQuestions, {
          $push: [question],
        }),
      });
    }
  }

  async onCreateQuestions() {
    this.setState({ loading: true });

    // prettier-ignore
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'createBulkQuestions' does not exist on t... Remove this comment to see the full error message
    await this.props.createBulkQuestions(this.props.booking_id, this.normaliseQuestions(this.state.selectedQuestions))
      .then(({ data }: any) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onCopyQuestions' does not exist on type ... Remove this comment to see the full error message
        if (this.props.onCopyQuestions) this.props.onCopyQuestions(data.createBulkQuestions);
      });

    this.setState({ loading: false });
    this.resetState();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onClose();
  }

  normaliseQuestions(questions: any) {
    return questions.map((question: any) => {
      return {
        title: question.title,
        description: question.description,
        config: {
          display_participant: question.config.display_participant,
          multiple_selection: question.config.multiple_selection,
          none_of_the_above: question.config.none_of_the_above,
          type: question.config.type,
        },
        options: question.options.map((options: any) => {
          return {
            label: options.label,
            screen_in: options.screen_in,
          };
        }),
      };
    });
  }

  isAllQuestionsSelected() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
    return _.size(this.state.selectedQuestions) === _.size(this.state.selectedBooking.questions);
  }

  parseBodyValues(bookings = []) {
    // Function to parse the values coming from the databse into a renderable array of objects to get passed to the table common component
    // It should not show the booking that the user is looking at the moment
    // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type 'never'.
    let filteredBookings = bookings.filter((booking) => booking._id !== _.get(this.props.location, 'state.bookingID'));

    if (
      _.get(this.state, 'sortOptionSelected.sortBy') === 'created' &&
      _.get(this.state, 'sortOptionSelected.order') === -1
    ) {
      filteredBookings = bookingUtils.getCustomSortLogic(filteredBookings);
    }

    return filteredBookings.map((booking) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type 'never'.
      const bookingStatus = bookingUtils.bookingStatus().find((item: any) => item.value === booking.status);
      return {
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type 'never'.
        _id: booking._id,
        status: bookingStatus?.label,
        statusClassName: bookingStatus?.class,
        bookingTypeLabel: this.renderBookingType(booking),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
        bookingName: booking.name || 'Untitled study',
        bookingDates: bookingUtils.getBookingDateSummary(booking),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'total_participants' does not exist on ty... Remove this comment to see the full error message
        totalParticipants: `${booking.total_participants} people`,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'never'.
        bookingOwner: `${_.get(booking.user, 'meta.identity.firstname')} ${_.get(booking.user, 'meta.identity.lastname')}`,
        questions: _.get(booking, 'config.question'),
      };
    });
  }

  resetState() {
    setTimeout(() => {
      this.setState({
        selectedBooking: null,
        selectedQuestions: [],
        statusesToSearchFor: [0, 1, 2, 3, 4, 5, 6, 7],
        selectedFilterStatus: null,
      });
    }, 50);
  }

  renderBookingType(booking: any) {
    const bookingTypeSettings = bookingUtils.getBookingTypeSettings(booking);

    return (
      <div className="bookingTypeContent">
        {_.get(bookingTypeSettings, 'defaultIcon')}
        <span>{_.get(bookingTypeSettings, 'label')}</span>
      </div>
    );
  }

  renderContent(booking: any, isClickable = true) {
    const {
      _id,
      status,
      statusClassName,
      bookingTypeLabel,
      bookingName,
      bookingDates,
      totalParticipants,
      bookingOwner,
    } = booking;

    return (
      <div
        className={`bookingRow ${isClickable ? 'clickable' : ''}`}
        key={`row_${_id}`}
        onClick={() => {
          if (isClickable) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'duplicateBooking' does not exist on type... Remove this comment to see the full error message
            if (this.props.duplicateBooking) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClickDuplicateBooking' does not exist ... Remove this comment to see the full error message
              this.props.onClickDuplicateBooking(booking);
            } else {
              this.setState({ selectedBooking: booking });
              setTimeout(() => {
                document.getElementsByClassName('modalBodyCustomStyles')[0].scrollTo(0, 0);
              }, 50);
            }
          }
        }}
      >
        <div className="statusContainer">
          <span className={`status ${statusClassName}`} />
          <span>{status}</span>
        </div>
        {bookingTypeLabel}
        <div className="bookingNameContainer">
          <a className="bookingName">{bookingName}</a>
        </div>
        <div className="bookingDatesContainer">
          <span className="bookingDates">{bookingDates}</span>
        </div>
        <div className="totalParticipantsContainer">
          <span className="totalParticipants">{totalParticipants}</span>
        </div>
        <div className="bookingOwnerContainer">
          <span className="bookingOwner">{bookingOwner}</span>
        </div>
      </div>
    );
  }

  renderAnswerOptions(answer: any, index: any) {
    return (
      <div key={`answer_${answer.label}_${index}`} className="answer">
        {answer.screen_in ? (
          <CirclePlus color="#219653" className="h-4 w-4" />
        ) : (
          <CircleX color="#EB5757" className="h-4 w-4" />
        )}
        <span className="text">{answer.label}</span>
      </div>
    );
  }

  renderBookingQuestion(question: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
    const isChecked = _.size(_.filter(this.state.selectedQuestions, (item: any) => item._id === question._id)) > 0;
    return (
      <div
        className="questionRow"
        key={`question_${question._id}`}
        onClick={() => this.onSelectQuestion(question, isChecked)}
      >
        <Checkbox
          key={`questionContainer_${question._id}`}
          className="mr-4"
          checked={isChecked}
          onChange={() => this.onSelectQuestion(question, isChecked)}
        />
        <div className="typesContainer">
          {question.config.type === 2 ? (
            <div className="typeContainer">
              <Text color="#888" className="h-4 w-4" />
              <p className="type">Short Answer</p>
            </div>
          ) : (
            <div className="typeContainer">
              <List color="#888" className="h-4 w-4" />
              <p className="type">Multi Choice</p>
            </div>
          )}
        </div>
        <div className="questionContainer">
          <p className="question">{question.title}</p>
          {question.description && <p className="description">{question.description}</p>}
          {question.config.multiple_selection && <p className="multipleSelection">Select as many as you like</p>}
          {question.options.length > 0 && (
            <div className="answersContainer">{question.options.map(this.renderAnswerOptions)}</div>
          )}
        </div>
      </div>
    );
  }

  renderNoBookingsMessageContainer(statusToSearchFor: any) {
    const bookingStatusLabel = _.get(
      bookingUtils.bookingStatus().find((item: any) => item.value === statusToSearchFor[0]),
      'label',
    );
    return (
      <div className="noBookingsFoundContainer">
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'statusesToSearchFor' does not exist on t... Remove this comment to see the full error message */}
        {_.size(this.state.statusesToSearchFor) > 1 && (
          <Fragment>
            <h3>You have no studies</h3>
            <p>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message */}
              <a onClick={() => this.props.history.push({ pathname: '/booking-setup/create' })}>Click here </a>
              to create one now
            </p>
          </Fragment>
        )}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'statusesToSearchFor' does not exist on t... Remove this comment to see the full error message */}
        {_.size(this.state.statusesToSearchFor) === 1 && (
          <Fragment>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            <h3>No {bookingStatusLabel.toLowerCase()} studies</h3>
            <p>
              Do you want to{' '}
              <a onClick={() => this.setState({ selectedFilterStatus: 'Show all studies' }, () => this.resetState())}>
                show all studies
              </a>{' '}
              instead?
            </p>
          </Fragment>
        )}
      </div>
    );
  }

  render() {
    if (!_.has(this.props, 'team_id')) return;
    return (
      <Modal
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        open={this.props.open}
        onClose={() => {
          this.resetState();
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.props.onClose();
        }}
        className="bookingsListModal"
        bodyClassName="modalBodyCustomStyles"
        repositionOnUpdate={false}
      >
        <Fragment>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message */}
          <div className={`bookingsContainer ${!this.state.selectedBooking ? 'visible' : ''}`}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'duplicateBooking' does not exist on type... Remove this comment to see the full error message */}
            <h3>{`Select a study to ${this.props.duplicateBooking ? 'duplicate' : 'copy from'}`}</h3>
            <Query
              query={fetchBookingsByTeam}
              variables={{
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'team_id' does not exist on type 'Readonl... Remove this comment to see the full error message
                _team_id: this.props.team_id,
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'statusesToSearchFor' does not exist on t... Remove this comment to see the full error message
                status: this.state.statusesToSearchFor,
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'sortOptionSelected' does not exist on ty... Remove this comment to see the full error message
                sortBy: this.state.sortOptionSelected.sortBy,
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'sortOptionSelected' does not exist on ty... Remove this comment to see the full error message
                order: this.state.sortOptionSelected.order,
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'duplicateBooking' does not exist on type... Remove this comment to see the full error message
                excludeBookingsWithNoQuestions: !this.props.duplicateBooking,
                excludeAskablePlusBookings: false,
              }}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'client' does not exist on type 'Readonly... Remove this comment to see the full error message
              client={this.props.client}
            >
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '({ loading, error, data }: any) => string | ... Remove this comment to see the full error message */}
              {({ loading, error, data }: any) => {
                if (error) return `Error ${error.message}`;
                if (loading) {
                  return <Spinner fadeIn="none" name="ball-clip-rotate" color="#FF5266" className="loadingSpinner" />;
                }
                return (
                  <div className="bookingsTable">
                    {this.parseBodyValues(data.bookingsByTeam).map((booking) => this.renderContent(booking, true))}

                    {_.size(data.bookingsByTeam) === 0
                      ? /* @ts-expect-error ts-migrate(2339) FIXME: Property 'statusesToSearchFor' does not exist on t... Remove this comment to see the full error message */
                        this.renderNoBookingsMessageContainer(this.state.statusesToSearchFor)
                      : null}
                  </div>
                );
              }}
            </Query>
          </div>
          <div
            id="bookingSelectedContainer"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message
            className={`bookingSelectedContainer ${this.state.selectedBooking ? 'visible' : ''}`}
          >
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message */}
            {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
            <a
              className="backLink"
              onClick={(event) => {
                event.preventDefault();
                this.resetState();
              }}
            >
              &larr; Back
            </a>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message */}
            {this.state.selectedBooking && (
              <Fragment>
                <div className="bookingsTable">
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message */}
                  {this.renderContent(this.state.selectedBooking, false)}
                </div>
                <div className="questionsContainer">
                  <div className="questionHeader">
                    <h3>Select the questions you&apos;d like to add</h3>
                    <a onClick={this.onSelectAllQuestions}>
                      {`${this.isAllQuestionsSelected() ? 'Unselect' : 'Select'} all questions`}
                    </a>
                  </div>
                  <div className="questionContent">
                    {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedBooking' does not exist on type ... Remove this comment to see the full error message */}
                    {_.map(this.state.selectedBooking.questions, (question: any) =>
                      this.renderBookingQuestion(question),
                    )}
                  </div>
                  <div className="buttonContainer">
                    <Button
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
                      label={`Add (${_.size(this.state.selectedQuestions)}) question${_.size(this.state.selectedQuestions) !== 1 ? 's' : ''}`}
                      onClick={this.onCreateQuestions}
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedQuestions' does not exist on typ... Remove this comment to see the full error message
                      disabled={_.size(this.state.selectedQuestions) === 0}
                      size="default"
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      </Modal>
    );
  }
}

const createQuestionsContainer = graphql(createBulkQuestionsMutation, {
  props: ({ mutate }) => ({
    createBulkQuestions: (booking_id: any, questions: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      mutate({
        variables: { booking_id, questions },
      }),
  }),
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ComponentClass<{}, any>' is not ... Remove this comment to see the full error message
export default deprecatedWithRouter(createQuestionsContainer(BookingsListModal));
