import { useTranslation } from 'react-i18next';

interface FormErrorMessageProps {
  prefix: string;
  message?: string;
}

export const FormErrorMessage = ({ prefix, message }: FormErrorMessageProps) => {
  const { t } = useTranslation();

  if (!message) {
    return null;
  }

  return (
    <div className="text-sm text-destructive">
      {t(`${prefix}.${message}`, { defaultValue: t('formValidation.invalid') })}
    </div>
  );
};
