import { ProjectStatus } from 'generated/graphql';

import { FacetedFilter } from '../components/FacetedFilter';
import { Toolbar } from '../components/Toolbar';

import { statusData } from './ProjectsCellStatus';

import type { Table } from '@tanstack/react-table';

const StatusFilterOrder: ProjectStatus[] = [
  ProjectStatus.Active,
  ProjectStatus.Draft,
  ProjectStatus.Completed,
  ProjectStatus.Archived,
];

const statusOptions = StatusFilterOrder.reduce(
  (prev, value) => {
    return [...prev, { label: statusData[value].title, value }];
  },
  [] as { label: string; value: string }[],
);

type Props<TData> = {
  table: Table<TData>;
};

export function ProjectsToolbar<TData>({ table }: Props<TData>) {
  return (
    <Toolbar searchId="name" table={table}>
      {table.getColumn('status') && (
        <FacetedFilter
          popoverContentClassName="w-[192px]"
          column={table.getColumn('status')}
          title="Status"
          options={statusOptions}
        />
      )}
    </Toolbar>
  );
}
