import { AnchorButton, Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { cn } from '@askable/ui/lib/utils';
import { Archive, Ellipsis, ExternalLink, CirclePlay, Copy, Loader2 } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, CombinedError } from 'urql';
import { z } from 'zod';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { getUnmodUrl } from 'utils/url-utils';

import { StudyName } from '../BuildStudy/components/StudyName';
import { useStudyContext } from '../StudiesContainer';
import { ArchiveStudy } from '../data/ArchiveStudy.mutation';
import { DuplicateStudy } from '../data/DuplicateStudy.mutation';
import { PublishStudy } from '../data/PublishStudy.mutation';
import { blockSchema } from '../data/schemas/blockSchema';

import { InvalidIndicator } from './InvalidIndicator';
import { PreviewLink, PreviewLinkButton } from './PreviewLink';
import { StudySavingIndicator } from './StudySavingIndicator';
import { StudyStatusIndicator } from './StudyStatusIndicator';

export const StudiesHeader = () => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const { study, isSaving, setIsSaving, newBlockId, isBuildDisabled } = useStudyContext();
  const location = useLocation();
  const section = location.pathname.split('/')[3];

  const [, archiveStudy] = useMutation(ArchiveStudy);
  const [, publishStudy] = useMutation(PublishStudy);
  const [{ fetching }, duplicateStudy] = useMutation(DuplicateStudy);

  const navItems = [
    { label: t('sections.studies.tabs.build'), to: 'build' },
    { label: t('sections.studies.tabs.recruit'), to: 'recruit' },
    { label: t('sections.studies.tabs.results'), to: 'results' },
    { label: t('sections.studies.tabs.report'), to: 'report' },
  ];

  const isValid = useMemo(() => {
    const welcomeBlock = study.draft_config?.welcome_block;
    const thankYouBlock = study.draft_config?.thank_you_block;
    const blocks = study.draft_config?.task_blocks?.filter((block) => block?._id !== newBlockId) || [];
    const arraySchema = z.array(blockSchema);
    const buildParsed = arraySchema.safeParse([welcomeBlock, thankYouBlock, ...blocks]).success;

    return {
      build: buildParsed,
    };
  }, [newBlockId, study]);

  const handleArchive = useCallback(async () => {
    try {
      setIsSaving(true);
      const { error } = await archiveStudy({ input: { _id: study._id } });

      if (error) {
        throw new Error(error.message || 'Failed to archive study');
      }
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    } finally {
      setTimeout(() => setIsSaving(false), 200);
    }
  }, [archiveStudy, setIsSaving, t, study._id]);

  const handlePublish = useCallback(async () => {
    try {
      const { error } = await publishStudy({ input: { _id: study._id } });

      if (error) {
        throw new Error(error.message || 'Failed to publish changes');
      }
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    } finally {
      setTimeout(() => setIsSaving(false), 200);
    }
  }, [publishStudy, setIsSaving, t, study._id]);

  const handleDuplicate = useCallback(async () => {
    try {
      const { data, error } = await duplicateStudy({
        input: {
          _id: study._id,
        },
      });

      if (error || !data) {
        throw new Error(error?.message || 'Failed to duplicate study');
      }

      window.location.href = `/studies/${data.duplicateStudy?._id}/build?block_id=welcome#editor`;
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    }
  }, [duplicateStudy, study, t]);

  return (
    <header className="studies-header">
      <div className="left-0 grid grid-cols-2 items-center gap-2 border-b border-border bg-background px-2 py-3 lg:grid-cols-3">
        <StudyName id={study._id} name={study.name} />

        <nav className="order-4 col-start-1 col-end-3 flex w-full gap-1 justify-self-center lg:order-2 lg:col-start-2 lg:col-end-3 lg:w-fit">
          {navItems.map((item) => (
            <AnchorButton
              key={item.to}
              variant="ghost"
              className={cn('relative flex-1 rounded-lg px-4 lg:flex-none', { 'bg-secondary': section === item.to })}
              asChild
            >
              <Link to={item.to} replace>
                {item.label}
                {item.to === 'build' && section !== 'build' ? <InvalidIndicator isValid={isValid.build} /> : null}
              </Link>
            </AnchorButton>
          ))}
        </nav>

        <div className="order-3 flex items-center gap-3 justify-self-end pr-2">
          <StudyStatusIndicator study={study} />
          <StudySavingIndicator isSaving={isSaving} created={study.created} updated={study.updated} />

          <PreviewLinkButton />

          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost">
                {fetching ? <Loader2 className="h-4 w-4 animate-spin" /> : <Ellipsis className="h-4 w-4" />}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="z-[2101]" align="end">
              <DropdownMenuItem className="lg:!hidden" asChild>
                <PreviewLink />
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handlePublish} disabled={isSaving || !isValid.build || isBuildDisabled}>
                <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.publish')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleDuplicate} disabled={fetching}>
                <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleArchive} disabled={isSaving}>
                <Archive className="h-4 w-4" /> {t('sections.studies.actions.archive')}
              </DropdownMenuItem>
              {/* Add a link to view study in Sessions for the Askable Dev team only */}
              {details?.team?.id === '65dfc40b350be69299301788' ? (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => window.open(getUnmodUrl(study._id), '_blank')}>
                    <ExternalLink className="h-4 w-4" /> {t('sections.studies.actions.test')}
                  </DropdownMenuItem>
                </>
              ) : null}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};
