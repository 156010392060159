import { Button } from '@askable/ui/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@askable/ui/components/ui/dropdown-menu';
import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { Blocks } from '@askable/ui/components/unmod/icons';
import { cn } from '@askable/ui/lib/utils';
import { X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddBlockList } from './AddBlockList';

import type { StudyTaskBlockType } from 'generated/graphql';

interface AddBlockProps {
  isDisabled?: boolean;
  isFullBlock?: boolean;
  // TODO: type should be derived from `blockOptions`, but rn we don't support the disabled types in the BE schema
  onSelect: (type: StudyTaskBlockType) => void;
}

export const AddBlock = ({ isFullBlock = true, isDisabled = false, onSelect }: AddBlockProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu modal={false} open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <button
          type="button"
          className={cn(
            `border-1 flex w-full items-center justify-between gap-1 rounded-xl border-secondary bg-secondary text-sm ring-[0.5px]
            ring-transparent`,
            '@[10rem]:w-full @[10rem]:p-2',
            'hover:border-input focus:border-input focus-visible:outline-none active:border-input',
            'aria-[expanded=true]:border-input',
            { 'flex-col @[10rem]:p-3': isFullBlock },
          )}
          disabled={isDisabled}
        >
          <div className={cn('hidden flex-col items-center gap-2', { '@[10rem]:flex': isFullBlock })}>
            <Blocks size={44} />
            <div className="text-balance text-xs font-normal leading-none text-muted-foreground">
              {t('sections.studies.build.blocks.add.title')}
            </div>
          </div>

          <div className={cn('flex items-center gap-1', { 'justify-center pt-1': isFullBlock })}>
            <div className="p-1 py-[3px]">
              <IconBlock type="add" size="sm" hasBackground={!isFullBlock} />
            </div>

            <div className="hidden flex-col text-left @[10rem]:flex">
              <div className="font-semibold">{t('sections.studies.build.blocks.add.title')}</div>
              <div className={cn('text-xs text-muted-foreground', { hidden: isFullBlock })}>
                {t('sections.studies.build.blocks.add.description')}
              </div>
            </div>
          </div>
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        side="right"
        className="add-block-content md:border-1 w-screen rounded-none border-none p-2 shadow-none lg:w-fit lg:rounded-lg lg:border-border
          lg:shadow-md"
      >
        <Button variant="ghost" className="lg:!hidden" size="icon" onClick={() => setIsOpen(false)}>
          <X className="h-4 w-4" />
        </Button>
        <AddBlockList onSelect={onSelect} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
