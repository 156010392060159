import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Button } from '@askable/ui/components/ui/button';
import { toast } from '@askable/ui/components/ui/sonner';
import _ from 'lodash';
import { FileText } from 'lucide-react';
import { useEffect, Fragment, useState } from 'react';
import { Link, Text, Flex, Box, Badge, Input, FormLabel, FormControl, FormHelperText, FormErrorMessage } from 'ui';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Card, HorizontalNarrowCard as NdaCard } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { CreateTemplateForm } from 'containers/Settings/containers/Resources/components/CreateTemplateForm';
import { bookingUtils } from 'lib/booking';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE, BOOKING_ESIGNATURE_TEMPLATE_TYPE } from 'lib/constants';
import { validations } from 'lib/validations';

import createESignatureTemplateMutation from '../../../data/mutations/esignature_templates/createESignatureTemplate';
import getTemplatesQuery from '../../../data/queries/esignature_templates/getBookingESignatureTemplates';
import getESignaturePreviewURLQuery from '../../../data/queries/esignature_templates/getESignaturePreviewURL';

import './styles/legalPrivacy.scss';

function LegalPrivacy(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  // const [bookingData, setBookingData] = useState();

  const [agreementType, setAgreementType] = useState<number | null>(
    booking?.config?.participant_agreement?.type ?? null,
  );

  const [newTemplatePlaceholder, setNewTemplatePlaceholder] = useState<{ name?: string; _id?: string } | void>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(
    booking?.config?.participant_agreement?._template_id ?? null,
  );

  const [bookingAgreementRecipientsInput, setBookingAgreementRecipientsInput] = useState<string | null>(null);
  const [bookingAgreementRecipients, setBookingAgreementRecipients] = useState<string[]>([]);
  const [bookingAgreementRecipientsError, setBookingAgreementRecipientsError] = useState<string | null | void>(null);
  const [previewUrlLoading, setPreviewUrlLoading] = useState<string | null>(null);

  const getTemplates = useQuery(getTemplatesQuery, { variables: { _booking_id: booking?._id || null } });
  const [getPreviewURL] = useLazyQuery(getESignaturePreviewURLQuery);

  const [createTemplate] = useMutation(createESignatureTemplateMutation);

  const saveBookingAgreement = () => {
    const agreement: {
      type: number | null;
      _template_id?: string | null;
      recipients?: string[] | null;
    } = {
      type: BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS,
      _template_id: null,
      recipients: null,
    };

    if (agreementType && agreementType !== BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS) {
      agreement.type = agreementType;
      agreement._template_id = selectedTemplate || null;
      if (_.get(props, 'booking.config.participant_agreement.recipients')) {
        agreement.recipients = bookingAgreementRecipients;
      } else if (_.isEmpty(bookingAgreementRecipients)) {
        agreement.recipients = getBookingDefaultAgreementRecipients();
        setBookingAgreementRecipients(agreement.recipients);
        setBookingAgreementRecipientsInput(agreement.recipients.join(', '));
      }
    }

    const updateBookingState = {
      ...bookingState,
      config: {
        ...booking.config,
        participant_agreement: agreement,
      },
    };

    props.validateBooking(updateBookingState, document.location.pathname, true);

    return props.updateBooking(updateBookingState, { config: { participant_agreement: agreement } });
  };

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/legal-privacy`,
      stepId: 'additional_info_legal_privacy',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
    if (_.get(props.booking, 'config.participant_agreement')) {
      if (agreementType === null) {
        setAgreementType(
          props.booking.config.participant_agreement.type || BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS,
        );
      }
      if (selectedTemplate === null) {
        setSelectedTemplate(_.get(props.booking, 'config.participant_agreement._template_id') || null);
      }
      if (bookingAgreementRecipientsInput === null) {
        const defaultBookingAgreementRecipients: string[] = [].concat(
          _.get(props.booking, 'config.participant_agreement.recipients') || [],
        );
        // if (!_.get(props.booking, 'config.participant_agreement.recipients') && defaultBookingAgreementRecipients.length === 0) {
        //   defaultBookingAgreementRecipients.push(...getBookingDefaultAgreementRecipients());
        // }
        setBookingAgreementRecipientsInput(_.uniq(defaultBookingAgreementRecipients).join(', '));
        setBookingAgreementRecipients(defaultBookingAgreementRecipients);
      }
    }
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [booking]);

  useEffect(() => {
    saveBookingAgreement().catch((err: any) => {
      console.error(err);
    });
  }, [agreementType, selectedTemplate, bookingAgreementRecipients.join(', ')]);

  const templatesList = _.get(getTemplates, 'data.getBookingESignatureTemplates', []);

  useEffect(() => {
    if (selectedTemplate) return;
    const defaultTemplate = _.find(templatesList, { global: true }) || templatesList[0];
    if (defaultTemplate?._id) {
      setSelectedTemplate(defaultTemplate._id);
    }
  }, [templatesList]);

  const getBookingAgreementRecipients = (input: string | null) => {
    if (!input) return { emails: [], error: null };
    const emailAddresses = input
      .split(/[,\s]+/)
      .filter((email) => email.trim())
      .map((email) => ({ email, valid: validations.validateEmail(email) }));

    let error;

    if (emailAddresses.length === 0) {
      error = 'Please provide at least one email address';
    }
    const invalidEmail = _.find(emailAddresses, { valid: false });
    if (!error && invalidEmail) {
      error = `"${invalidEmail.email}" is not a valid email address`;
    }
    // prettier-ignore
    return {
      emails: _.chain(emailAddresses).filter({ valid: true }).map('email').uniq()
.value(),
      error,
    };
  };

  const getBookingDefaultAgreementRecipients = () => {
    if (_.get(booking, 'config.contact.UserOrganiser.email')) {
      return [booking.config.contact.UserOrganiser.email];
    }
    if (_.get(booking, 'config.contact.UserFacilitator.email')) {
      return [booking.config.contact.UserFacilitator.email];
    }

    return [];
  };

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__legalStepFaqCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Can all agreements be sent to a central email address?"
          cardText="Yes! You can specify that email address on the template level, in Settings > Resources > ⋮ > Edit. Once added the email address will always receive copies of all signed agreements that use that template."
          linkText="Go to Resources"
          onLinkClick={() => {
            window.open('/settings/resources', '_blank');
          }}
        />
      </Fragment>
    );
  };

  const onClickCard = (selectedType: number) => {
    setAgreementType(selectedType);
  };

  const onClickNext = () => {
    let redirectTo = `/booking-setup/${booking._id}/session-times/calendar`;
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      redirectTo = `/booking-setup/${booking._id}/review-submit/confirm-booking`;
    }
    props.history.push({ pathname: redirectTo, booking: null, bookingState: null });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Legal &amp; Privacy
      </h1>
      <p className="label">
        We offer a few options to assist with fulfilling your legal obligations when conducting research with Askable
        participants.
      </p>
      <div className="legalPrivacyTypes">
        <Card
          title="Use Askable’s standard Terms and Conditions"
          className="legalPrivacyCard"
          content={[
            {
              label: (
                <>
                  All Askable participants are bound by our confidentiality and consent agreements outlined in clauses
                  19 and 20 of{' '}
                  <Link href="https://www.askable.com/legal/terms" isExternal size="sm" color="blue.500">
                    Terms and Conditions
                  </Link>
                  .
                </>
              ),
            },
          ]}
          selected={agreementType === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS}
          onClickCard={() => onClickCard(BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS)}
        />
        <Card
          title="Use a custom agreement"
          className="legalPrivacyCard"
          content={[
            {
              label: (
                <>
                  <p>
                    <Text size="sm">+5 credits per person</Text>
                  </p>
                  <p>Upload or select from custom agreements. Invited participants must sign to proceed.</p>
                </>
              ),
            },
          ]}
          selected={agreementType === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT}
          onClickCard={() => onClickCard(BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT)}
        />
      </div>

      {agreementType === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT && (
        <>
          <div style={{ maxWidth: '548px' }}>
            <CreateTemplateForm
              onCreate={async ({ name }) => {
                const template = {
                  name,
                  type: BOOKING_ESIGNATURE_TEMPLATE_TYPE.PARTICIPANT_AGREEMENT,
                  _team_id: booking?._team_id,
                };
                const createTemplateResult = await createTemplate({ variables: { template } })
                  .then((result) => {
                    getTemplates.refetch().then(() => {
                      setNewTemplatePlaceholder();
                    });
                    const newTemplate = _.get(result, 'data.createESignatureTemplate');
                    if (newTemplate) {
                      setNewTemplatePlaceholder(newTemplate);
                      setSelectedTemplate(newTemplate._id);
                    }
                    return newTemplate;
                  })
                  .catch(() => {
                    toast.error('Failed to create your new template');
                  });

                return createTemplateResult;
              }}
              emailBody={{
                booking: booking?.name,
              }}
            >
              {({ open, button: createTemplateButton, form: createTemplateForm, createdTemplates }) => (
                <>
                  <Flex mt="8" direction="row" justifyContent="space-between">
                    <Text fontWeight="bold">Select a template</Text>
                    {!open && createTemplateButton}
                  </Flex>
                  {open && <Box mb={2}>{createTemplateForm}</Box>}
                  {createdTemplates.length > 0 && (
                    <Box mt={4} mb={2}>
                      {createdTemplates}
                    </Box>
                  )}
                </>
              )}
            </CreateTemplateForm>
          </div>

          <Box mt="4" className="esignature-templates">
            {newTemplatePlaceholder && !_.find(templatesList, { _id: newTemplatePlaceholder._id }) && (
              <NdaCard
                label={newTemplatePlaceholder.name}
                icon={<FileText opacity={0.2} className="h-4 w-4" />}
                // badge={_.get(template, 'provider.reference') ? undefined : 'IN REVIEW'}
                selected={selectedTemplate === newTemplatePlaceholder._id}
              />
            )}
            {templatesList.map((template: any) => (
              <NdaCard
                key={template._id}
                label={
                  <>
                    {template.name}
                    {!_.get(template, 'provider.reference') && (
                      <Badge variant="solid" colorScheme="gray" ml="2">
                        IN REVIEW
                      </Badge>
                    )}
                  </>
                }
                icon={template.global ? ASKABLE_LOGO_SVG : <FileText className="h-4 w-4" />}
                // badge={_.get(template, 'provider.reference') ? undefined : 'IN REVIEW'}
                rightButton={
                  _.get(template, 'provider.reference')
                    ? {
                        label: 'Preview',
                        isLoading: previewUrlLoading === template._id,
                        onClick: async () => {
                          setPreviewUrlLoading(template?._id || null);
                          const previewResult = await getPreviewURL({ variables: { _id: template._id } }).catch(
                            (err) => {
                              console.error(err);
                            },
                          );

                          if (previewResult?.data?.getESignaturePreviewURL) {
                            window.open(previewResult.data.getESignaturePreviewURL);
                          } else {
                            toast.error('The preview link for this template could not be found');
                          }
                          setPreviewUrlLoading(null);
                        },
                      }
                    : undefined
                }
                onClickCard={() => {
                  setSelectedTemplate(template._id);
                }}
                selected={selectedTemplate === template._id}
              />
            ))}
          </Box>

          <Box mt="8">
            <FormControl isInvalid={!!bookingAgreementRecipientsError}>
              <FormLabel fontWeight="bold" mb="4" fontSize="md">
                Enter recipient(s) for signed agreements
              </FormLabel>
              <Input
                value={bookingAgreementRecipientsInput || ''}
                onChange={({ target }) => {
                  setBookingAgreementRecipientsInput(target.value);
                  if (bookingAgreementRecipientsError && !getBookingAgreementRecipients(target.value).error) {
                    setBookingAgreementRecipientsError(null);
                  }
                }}
                onBlur={() => {
                  const recipients = getBookingAgreementRecipients(bookingAgreementRecipientsInput);
                  setBookingAgreementRecipientsError(recipients.error);
                  setBookingAgreementRecipients(recipients.emails);
                  if (!recipients.error) {
                    setBookingAgreementRecipientsInput(recipients.emails.join(', '));
                  }
                }}
                placeholder=""
                mb="1"
              />
              {!bookingAgreementRecipientsError && (
                <>
                  <FormHelperText color="gray.500" fontSize="md">
                    Enter email addresses separated by comma.
                  </FormHelperText>
                  <FormHelperText color="gray.500" fontSize="md" maxWidth={548}>
                    The recipient(s) you add in this step will only be added for this study, and will not receive any
                    other signed agreements for any other studies.
                  </FormHelperText>
                </>
              )}
              <FormErrorMessage fontSize="md">
                {bookingAgreementRecipientsError || 'Email address input is invalid'}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </>
      )}

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

const ASKABLE_LOGO_SVG = (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.82195 1.00756C16.0266 0.868728 17.8543 2.61044 17.9925 8.79468C18.0483 11.2872 17.7965 13.0702 17.0712 14.3206C15.5112 17.823 11.8971 19.5651 10.2465 20L10.1781 16.9395C3.97293 17.0783 2.14572 15.3369 2.00748 9.15236C1.86924 2.96812 3.61683 1.14641 9.82195 1.00756ZM10.0537 7.89805C9.28898 7.88007 8.68301 7.21691 8.70003 6.41667C8.71706 5.61668 9.35065 4.98241 10.1153 5.00039C10.8798 5.01835 11.486 5.68176 11.469 6.48175C11.452 7.28199 10.8181 7.91601 10.0537 7.89805ZM10.0325 8.89411C11.1793 8.92107 13.0269 9.21333 13.0013 10.4134C12.9758 11.6138 11.7073 13.0406 9.94512 12.9991C8.18269 12.9577 6.97641 11.4728 7.00196 10.2724C7.0275 9.07233 8.88544 8.86716 10.0325 8.89411Z"
    />
  </svg>
);

export default deprecatedWithRouter(LegalPrivacy);
