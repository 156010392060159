import { gql } from '@apollo/client';

import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';

export default gql`
  mutation createSession($booking_id: ID!, $session: [BookingInputSession]!) {
    createSession(booking_id: $booking_id, session: $session) {
      ...bookingFields
    }
  }
  ${bookingFieldsFragment}
`;
