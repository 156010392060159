import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@askable/ui/components/ui/table';
import { cn } from '@askable/ui/lib/utils';
import { format, formatISO } from 'date-fns';
import { HandHelping, TicketPlus } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

import { TableLoading } from 'components/Loading/TableLoading';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { utils } from 'lib/utils';

import { SettingsContainer } from '../../components/SettingsContainer';

import { UsageTabCreditActivity } from './data/UsageTabCreditActivity.query';

type CreditActivityItemProps = {
  accountingType: number;
  amount?: number;
  bookingId?: string;
  bookingName?: string;
  comment?: string;
  created?: number;
  type?: number;
  userName?: string;
};

const CreditActivityItem = ({
  accountingType,
  amount,
  bookingId,
  bookingName,
  comment,
  created,
  type,
  userName,
}: CreditActivityItemProps) => {
  const { t } = useTranslation();
  const derivedName = type === 12 ? comment : (bookingName ?? 'N/A');

  const usedType: 'refund' | 'added' | string = (() => {
    if (accountingType === 1) {
      return '';
    }

    switch (type) {
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return 'refund';
      default:
        return 'added';
    }
  })();

  return (
    <TableRow applyDefaultHoverStyle>
      <TableCell className="font-medium">
        {bookingId ? (
          <Link to={`/bookings/${bookingId}`}>{derivedName}</Link>
        ) : (
          <span className="text-muted-foreground">{derivedName}</span>
        )}
      </TableCell>
      <TableCell>
        <div className="flex flex-col">
          {utils.numberWithCommas(amount)} {t('global.credits').toLocaleLowerCase()}
          {usedType ? (
            <div
              className={cn('flex w-fit items-center gap-1 rounded-md p-1 text-xs font-medium leading-none', {
                'bg-success text-success-foreground': usedType === 'added',
                'bg-warning text-warning-foreground': usedType === 'refund',
              })}
            >
              {usedType === 'refund' ? <HandHelping className="h-3 w-3" /> : <TicketPlus className="h-3 w-3" />}
              {usedType === 'refund' ? t('sections.settings.usage.refund') : t('sections.settings.usage.added')}
            </div>
          ) : null}
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap">
        {created ? (
          <time dateTime={formatISO(created)} title={formatISO(created)}>
            {format(created, 'dd MMM yyyy')}
          </time>
        ) : null}
      </TableCell>
      <TableCell className="whitespace-nowrap">{userName}</TableCell>
    </TableRow>
  );
};

export const SettingsUsage = () => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const [{ data, fetching }] = useQuery({
    query: UsageTabCreditActivity,
    variables: {
      _team_id: details?.ConnectedTeam?._id!,
    },
  });

  const tableData = useMemo(() => {
    return data?.creditActivityByTeam ?? [];
  }, [data]);

  if (fetching) {
    return <TableLoading className="py-6" />;
  }

  return (
    <SettingsContainer width="wide" title={t('sections.settings.usage.title')}>
      {fetching ? (
        <TableLoading className="py-6" />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('sections.settings.usage.columns.study')}</TableHead>
              <TableHead className="whitespace-nowrap">{t('sections.settings.usage.columns.credits')}</TableHead>
              <TableHead>{t('sections.settings.usage.columns.date')}</TableHead>
              <TableHead>{t('sections.settings.usage.columns.user')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="relative">
            {tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center text-muted-foreground">
                  {t('sections.settings.billing.emptyTable')}
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((credit) => {
                return credit ? (
                  <CreditActivityItem
                    accountingType={credit.accounting_type ?? 0}
                    amount={credit.amount!}
                    bookingId={credit.Booking?._id as string}
                    bookingName={credit?.Booking?.name ?? ''}
                    comment={credit.comment!}
                    created={credit.created!}
                    key={credit._id!}
                    type={credit.type!}
                    userName={credit?.User?.displayName ?? credit?.Booking?.user?.displayName ?? 'N/A'}
                  />
                ) : null;
              })
            )}
          </TableBody>
        </Table>
      )}
    </SettingsContainer>
  );
};
