import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { ChevronDown } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { supportedLanguages, type SupportedLanguage } from 'src/i18n';

/**
 * Language Switcher Component
 * List the supported language and allow the user to switch between them
 * @todo add country flags if this will also change the localisation
 */

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const changeLanguageHandler = (lang: SupportedLanguage) => {
    i18n.changeLanguage(lang);
    // Save to local storage
    localStorage.setItem('lang', lang);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-fit gap-4 text-sm" variant="outline">
          {supportedLanguages.find((l) => l.code === i18n.language)?.label}
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" side="bottom" align="end">
        {supportedLanguages.map((lang) => (
          <DropdownMenuItem
            key={lang.code}
            onClick={() => changeLanguageHandler(lang.code)}
            className={i18n.language === lang.code ? 'font-bold' : undefined}
          >
            {lang.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSwitcher;
