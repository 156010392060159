/**
 * Currently we face the issue is that a lot of askable plus pages that are copied and pasted with minor changes, which causes a lot of duplicated code.
 * This is a temporary solution to reduce the duplicated code by creating a shared component for QuotaInput.
 * However, this component contains some extra a bit of UI which is not great for long term solution.
 * We should refactor this component to be more reusable and flexible when new design introduced.
 */

import { Button } from '@askable/ui/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { cn } from '@askable/ui/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import type { UseFormReturn } from 'react-hook-form';

const formSchema = z.object({
  quota: z.coerce.number().gte(1, 'Quota must be greater than or equal to 1').positive(),
});

type QuotaFormSchemaType = z.infer<typeof formSchema>;

export type QuotaInputRef = { form: UseFormReturn<QuotaFormSchemaType> };

type Props = {
  title: string;
  description: string;
  defaultValues?: QuotaFormSchemaType;
  onQuotaValueChange: (quota: QuotaFormSchemaType['quota']) => void;
  onClickNext: () => void;
  className?: string;
};

export const QuotaInput = forwardRef<QuotaInputRef, Props>(
  ({ title, description, onQuotaValueChange, defaultValues, onClickNext, className }, ref) => {
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues,
    });

    useImperativeHandle(ref, () => ({ form }), [form]);

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onClickNext)}>
          <div className={cn(className)}>
            <h1 className="title">{title}</h1>
            <div className="flex max-w-sm flex-col gap-2">
              <FormField
                control={form.control}
                name="quota"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{description}</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);

                          try {
                            // Ideally we want to run this when we validate the form onChange, however react-hook-form currently doesn't provide a way to achieve this.
                            // We can set 'mode' to 'onChange' in useForm, but this will trigger validation after field.onChange is called
                            // Therefore, we need to manually validate the form onChange
                            const validatedForm = formSchema.parse({ quota: e.target.value });
                            onQuotaValueChange(validatedForm.quota);
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                        className="w-48"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="buttonNextContainer">
              <Button variant="primary" size="lg" type="submit">
                Next
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );
  },
);
