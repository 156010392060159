import { ApolloConsumer } from '@apollo/client';
import { AskableIconLoading } from '@askable/ui/components/ui/askable-icon';
import { datadogRum } from '@datadog/browser-rum';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { FeatureFlagsProvider, UserFeatureFlagProvider } from 'feature-flags';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Outlet, useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { Provider } from 'urql';

import { ErrorBoundaryGeneric } from 'components/ErrorBoundaryGeneric/ErrorBoundaryGeneric';
import { LoadingSuspense } from 'components/LoadingSuspense';
import { NotFound } from 'components/NotFound/NotFound';
import { SessionsRedirect } from 'components/SessionsRedirect/SessionsRedirect';
import { AppProvider, SSOLoginContainer } from 'components/common';
import CreditsOrderAction from 'components/dashboard/creditsOrderAction';
import JoinNUFPAction from 'components/dashboard/joinNUFPAction';
import RequestCreditsAction from 'components/dashboard/requestCreditsAction';
import RequestJoinAction from 'components/dashboard/requestJoinAction';
import { AuthWrapper } from 'containers/Auth/AuthWrapper';
import { KindePropertyWrapper } from 'containers/Auth/KindePropertyWrapper';
import { RequireAuth } from 'containers/Auth/RequireAuth';
import { CompleteProfileContainer } from 'containers/CompleteProfile/CompleteProfileContainer';
import { discoverRoutes } from 'containers/Discover/discover-routes';
import { FigmaAuthCallbackContainer } from 'containers/FigmaAuth/FigmaAuthCallbackContainer';
import { BookingsContainer } from 'containers/Root/Bookings/BookingsContainer';
import { ProjectsContainer } from 'containers/Root/Projects/ProjectsContainer';
import { RootContainer } from 'containers/Root/RootContainer';
import { settingsRoutes } from 'containers/Settings/settings-routes';
import { StudiesListContainer } from 'containers/Studies/StudiesListContainer';
import { studiesRoutes } from 'containers/Studies/studies-routes';
import { RedirectToTeams } from 'containers/Teams/RedirectToTeams';
import { teamRoutes } from 'containers/Teams/teams-routes';
import { ConnectedClientProvider, useConnectedClient } from 'context/ConnectedClientContext';
import { globalVariables } from 'lib/globalVariables';
import { localStorage } from 'lib/storage';
import { teamUtils } from 'lib/teams';
import { FIGMA_AUTH_CALLBACK_PATH } from 'src/hooks/useFigmaAuth';

import App from './components/app/view';
import CreateBooking from './components/createBooking/createBooking';
import RecruitmentType from './components/createBooking/recruitmentType';
import SsoCallbackContainer from './components/login/ssoCallback';
import SsoLogoutCallbackContainer from './components/login/ssoLogoutCallback';
import { LoginContainer } from './components/login/view';
import LogoutContainer from './components/logout/view';
import AskablePlusProjectCompletedContainer from './components/manageAskablePlus/components/projectCompletedScreen';
import { SignupContainer } from './components/signup/view';
import { bookingRoutes } from './containers/Booking/booking-routes';
import { urqlClient } from './network/client';
import { askablePlusRoutes } from './routes/askable-plus-routes';
import { researcherRoutes } from './routes/researcher-routes';

import type { ApolloClient } from '@apollo/client';
import type { FC, ReactNode } from 'react';

const featureFlagConfig =
  import.meta.env.VITE_ENVIRONMENT === 'PROD'
    ? {
        impressionListener: {
          logImpression: ({ impression }: { impression: { feature: string; treatment: string } }) => {
            datadogRum.addFeatureFlagEvaluation(impression.feature, impression.treatment);
          },
        },
      }
    : {};

const ParticipantRedirect = lazy(
  () => import('./components/ErrorBoundaryParticipantRedirect/ErrorBoundaryParticipantRedirect'),
);

const LoginSelectContainer = lazy(() => import('./containers/LoginSelect/LoginSelectContainer'));
const ManageAskablePlusContainer = lazy(() => import('./components/manageAskablePlus/askablePlus'));

const ContentLoading = () => {
  return <div className="p-4">Loading...</div>;
};

// Method to check whether the client is using the new manage booking V2 experience
const BookingsRedirect = () => {
  const params = useParams();
  const pathname = `/bookings/${params.bookingID}${params.screen && params.screen !== 'applicants' ? `/${params.screen}` : ''}`;
  return <Navigate replace to={{ pathname }} />;
};

export const InnerApp: FC<{ client: ApolloClient<object> }> = ({ client }) => {
  const DEFAULT_ROOT_NAVIGATION = '/studies';
  const kinde = useKindeAuth();
  const { details } = useConnectedClient();

  const isTeamAdmin = teamUtils.isAdminByRoleId(details?.teamRole);
  const ssoManage = kinde?.getPermission?.('manage:sso') ?? { isGranted: isTeamAdmin };

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <RequireAuth>
          <KindePropertyWrapper>
            <App client={client} />
          </KindePropertyWrapper>
        </RequireAuth>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={DEFAULT_ROOT_NAVIGATION} replace />,
        },
        {
          path: '/',
          element: <RootContainer />,
          children: [
            {
              path: 'studies',
              element: <BookingsContainer />,
            },
            {
              path: 'askable-plus',
              element: <ProjectsContainer />,
            },
            {
              path: 'studies-unmod',
              element: <StudiesListContainer />,
            },
            {
              path: 'dashboard',
              element: <Navigate to={DEFAULT_ROOT_NAVIGATION} replace />,
            },
          ],
        },
        {
          path: 'askable-sessions',
          element: <Outlet />,
          children: [
            {
              path: '*',
              element: <SessionsRedirect />,
            },
          ],
        },
        { path: 'request_credits_action', element: <RequestCreditsAction /> },
        { path: 'join-nufp', element: <JoinNUFPAction /> },
        { path: 'request_join', element: <RequestJoinAction /> },
        {
          path: 'credits/order',
          element: <CreditsOrderAction />,
        },
        ...bookingRoutes,
        ...settingsRoutes({
          ssoManage: ssoManage.isGranted,
          researchManage: isTeamAdmin,
        }),
        {
          path: 'manage-booking/:bookingID/:screen?',
          element: <BookingsRedirect />,
        },
        {
          path: 'bookings/:bookingID/applicants',
          element: <BookingsRedirect />,
        },
        {
          path: 'bookings/:bookingID/sessions',
          element: <BookingsRedirect />,
        },
        {
          path: 'manage-askable-plus-project',
          element: <Outlet />,
          children: [
            {
              path: 'project-completed',
              element: <AskablePlusProjectCompletedContainer client={client} />,
            },
            {
              path: ':projectID',
              element: (
                <Suspense fallback={<ContentLoading />}>
                  <ManageAskablePlusContainer client={client} />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'booking-setup',
          children: [
            {
              path: 'recruitment-type',
              element: <RecruitmentType />,
            },
            {
              path: ':screen',
              element: <CreateBooking client={client} />,
            },
            {
              path: ':bookingID/:screen/:subScreen',
              element: <CreateBooking client={client} />,
            },
          ],
        },
        {
          path: 'booking/recruitment-type',
          element: <Navigate to="/booking-setup/recruitment-type" replace />,
        },
        ...askablePlusRoutes,
      ],
    },
    ...studiesRoutes,
    ...discoverRoutes,
    ...teamRoutes,
    ...researcherRoutes,
    {
      path: 'complete-profile',
      element: (
        <RequireAuth>
          <CompleteProfileContainer />
        </RequireAuth>
      ),
    },
    {
      path: FIGMA_AUTH_CALLBACK_PATH,
      element: (
        <RequireAuth>
          <FigmaAuthCallbackContainer />
        </RequireAuth>
      ),
    },
    {
      path: 'join-team/:requestId',
      element: <RedirectToTeams />,
    },
    {
      path: 'shared-invite/:teamId',
      element: <RedirectToTeams />,
    },
    {
      path: 'signin-another-team',
      element: <RedirectToTeams />,
    },
    {
      path: 'join-new-team',
      element: <RedirectToTeams />,
    },
    {
      path: '/signup',
      element: <SignupContainer />,
    },
    {
      path: '/login',
      element: <LoginContainer />,
    },
    {
      path: '/logout',
      element: <LogoutContainer />,
    },
    {
      path: '/sso/login/callback',
      element: <SsoCallbackContainer />,
    },
    {
      path: '/sso/logout/callback',
      element: <SsoLogoutCallbackContainer />,
    },
    {
      path: '/sso-login',
      element: <SSOLoginContainer />,
    },
    {
      path: '/participant-redirect',
      element: <ParticipantRedirect />,
    },
    {
      path: 'login-select',
      element: (
        <LoadingSuspense>
          <LoginSelectContainer />
        </LoadingSuspense>
      ),
    },
    {
      path: 'whoops',
      element: <ErrorBoundaryGeneric />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return routes;
};

const LoadingState = () => (
  <div className="flex h-full w-full items-center justify-center">
    <AskableIconLoading />
  </div>
);

const ContextWrapper: FC<{ client: ApolloClient<Object> }> = ({ client }) => {
  return (
    <ConnectedClientProvider>
      {({ isReady: clientIsReady, details }) => {
        if (!clientIsReady) {
          return <LoadingState />;
        }

        return (
          <UserFeatureFlagProvider
            app="clients"
            userId={details?.id ?? undefined}
            anonymousAttributes={{ demo: !!globalVariables.getEnvironmentVariables().isDemo }}
            userAttributes={{
              team: details?.team?.id as string,
              type: (() => {
                if (details?.type?.researcher) {
                  return 'researcher';
                }
                if (details?.type?.client) {
                  return 'client';
                }
                return undefined;
              })(),
            }}
          >
            {({ isReady }) => {
              if (!isReady) {
                return <LoadingState />;
              }
              return (
                <AppProvider>
                  <InnerApp client={client} />
                </AppProvider>
              );
            }}
          </UserFeatureFlagProvider>
        );
      }}
    </ConnectedClientProvider>
  );
};

function AppRoutes() {
  return (
    <Router>
      <AccessTokenSaver__DEPRECATED>
        <Provider value={urqlClient}>
          <FeatureFlagsProvider
            config={featureFlagConfig}
            apiKey={globalVariables.getEnvironmentVariables().featureFlagsApiKey}
            app="clients"
            attributes={{ demo: !!globalVariables.getEnvironmentVariables().isDemo }}
          >
            {({ isReady }) => {
              if (!isReady) {
                return <LoadingState />;
              }

              return (
                <AuthWrapper>
                  <ApolloConsumer>{(client) => <ContextWrapper client={client} />}</ApolloConsumer>
                </AuthWrapper>
              );
            }}
          </FeatureFlagsProvider>
        </Provider>
      </AccessTokenSaver__DEPRECATED>
    </Router>
  );
}

const AccessTokenSaver__DEPRECATED = ({ children }: { children: ReactNode }) => {
  const [params] = useSearchParams();

  useEffect(() => {
    const token = params.get('token');
    if (!token) {
      return;
    }

    localStorage.save('access_token', token);
  }, [params]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AppRoutes;
