import { toast } from '@askable/ui/components/ui/sonner';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, CombinedError } from 'urql';

import { CreateStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/CreateStudyConfigTaskBlock.mutation';
import { UpdateStudyConfigTaskBlockOrder } from 'containers/Studies/BuildStudy/data/UpdateStudyConfigTaskBlockOrder.mutation';
import { useStudyActiveBlockId } from 'containers/Studies/BuildStudy/hooks/useStudyActiveBlockId';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { AddBlock } from '../components/AddBlock';
import { Block } from '../components/Block';
import { SortableList } from '../components/SortableList';

import type { StudyTaskBlock, StudyTaskBlockType } from 'generated/graphql';

export const BlocksSidebar = () => {
  const { t } = useTranslation();
  const { study, setNewBlockId, setActiveSection, setIsSaving, isBuildDisabled } = useStudyContext();
  const { activeBlockId, setActiveBlockId } = useStudyActiveBlockId();

  const [, createStudyConfigTaskBlock] = useMutation(CreateStudyConfigTaskBlock);
  const [, updateStudyConfigTaskBlockOrder] = useMutation(UpdateStudyConfigTaskBlockOrder);

  const navRef = useRef<HTMLDivElement>(null);

  // TODO stop type casting any here
  const [blocks, setBlocks] = useState((study.draft_config?.task_blocks || []) as StudyTaskBlock[]);
  useEffect(() => {
    setBlocks((study.draft_config?.task_blocks || []) as StudyTaskBlock[]);
  }, [study.draft_config?.task_blocks]);

  useEffect(() => {
    return () => setNewBlockId(null);
  }, [setNewBlockId, activeBlockId]);

  const handleAddBlock = useCallback(
    async (type: StudyTaskBlockType) => {
      const { data, error } = await createStudyConfigTaskBlock({
        input: {
          _id: study._id,
          task_block: {
            type,
            title: '',
            instructions: '',
            // TODO: check with product/design what the actual logic is
            is_recording_enabled: type === 'figma_prototype',
          },
        },
      });

      if (error || !data?.createStudyConfigTaskBlock) {
        toast.error(error?.graphQLErrors[0].message ?? 'Error creating block');
        return;
      }

      const newBlocks = data.createStudyConfigTaskBlock.draft_config.task_blocks;
      const newBlockId = newBlocks[newBlocks.length - 1]?._id;
      if (newBlockId) {
        setActiveBlockId(newBlockId);

        // hacky way to not validate the new block straight away
        setTimeout(() => {
          setNewBlockId(newBlockId);
          setActiveSection('editor');
        }, 0);
      }
    },

    [createStudyConfigTaskBlock, setActiveBlockId, setActiveSection, setNewBlockId, study._id],
  );

  const updateBlockOrder = useCallback(
    async (ids: string[]) => {
      setIsSaving(true);

      try {
        const { error } = await updateStudyConfigTaskBlockOrder({
          input: { _id: study._id, _task_block_ids: ids },
        });

        if (error) {
          throw error;
        }
      } catch (err) {
        toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [study._id, updateStudyConfigTaskBlockOrder, t, setIsSaving],
  );

  const handleBlockReorder = (updatedBlocks: StudyTaskBlock[]) => {
    const originalIds = blocks.map((b) => b?._id);
    const updatedIds = updatedBlocks.map((b) => b?._id);

    // Order unchanged
    if (updatedIds.length === originalIds.length && updatedIds.every((_id, index) => _id === originalIds[index])) {
      return;
    }

    setBlocks(updatedBlocks);
    updateBlockOrder(updatedIds);
  };

  return (
    <nav className="build-nav overflow-auto @container" id="nav">
      <div className="relative flex h-full min-w-[5rem] flex-col items-center gap-2 bg-background p-4" ref={navRef}>
        <Block data={study.draft_config?.welcome_block!} isActive={activeBlockId === 'welcome'} />

        {blocks.length > 0 && !isBuildDisabled ? (
          <SortableList
            onChange={handleBlockReorder}
            items={blocks}
            renderItem={(block) => (
              <Block
                key={block?._id}
                data={block}
                isMovable
                isActive={activeBlockId ? activeBlockId === block?._id : false}
              />
            )}
          />
        ) : null}

        {blocks.length > 0 && isBuildDisabled
          ? blocks.map((block) => (
              <Block
                key={block?._id}
                data={block}
                isMovable
                isActive={activeBlockId ? activeBlockId === block?._id : false}
              />
            ))
          : null}

        {!isBuildDisabled ? <AddBlock isFullBlock={blocks?.length === 0} onSelect={handleAddBlock} /> : null}
        <Block data={study.draft_config?.thank_you_block!} isActive={activeBlockId === 'thank_you'} />
      </div>
    </nav>
  );
};
