import { Alert, AlertTitle, AlertDescription } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { CheckCircle } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage } from 'ui';

import type { FC, ReactNode } from 'react';
import type { ESignatureTemplate } from 'src/generated/graphql';

type Props = {
  loading?: boolean;
  children: ({
    open,
    button,
    form,
    createdTemplates,
  }: {
    open: boolean;
    button: ReactNode;
    form: ReactNode;
    createdTemplates: ReactNode[];
  }) => ReactNode;
  onCreate?: ({ name }: { name: string }) => Promise<any>;
  emailBody?: { booking?: string | null; team?: string | null };
};

export const CreateTemplateForm: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [newTemplateForm, setNewTemplateForm] = useState<boolean>(false);
  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [newTemplateError] = useState<string | null>(null);

  const [createdTemplates, setCreatedTemplates] = useState<any[]>([]);
  const pushCreatedTemplate = (template: any) => {
    setCreatedTemplates([...createdTemplates, template]);
  };

  useEffect(() => {
    setNewTemplateName('');
  }, [newTemplateForm]);

  return (
    <>
      {props.children({
        open: newTemplateForm,
        button: (
          <Button
            onClick={() => {
              setNewTemplateForm(true);
            }}
          >
            {t('sections.settings.resources.newTemplateAdd')}
          </Button>
        ),
        form: (
          <form
            className="flex flex-col gap-2 rounded-md border border-border p-4"
            onSubmit={async (event) => {
              event.preventDefault();
              if (props.onCreate) {
                await props.onCreate({ name: newTemplateName }).then((newTemplate: ESignatureTemplate) => {
                  pushCreatedTemplate(newTemplate);
                });
              }
              setNewTemplateForm(false);
            }}
          >
            <FormControl isInvalid={!!newTemplateError} className="flex flex-col gap-1">
              <Label htmlFor="newTemplateName" className="flex flex-col gap-1">
                {t('sections.settings.resources.newTemplateName')}
                <div className="text-sm font-normal text-muted-foreground">
                  {t('sections.settings.resources.newTemplateNameDescription')}
                </div>
              </Label>

              <Input
                id="newTemplateName"
                autoFocus
                value={newTemplateName}
                onChange={(e) => {
                  setNewTemplateName(e.target.value);
                }}
                placeholder="New template name"
                disabled={!!props.loading}
                required
              />

              <FormErrorMessage fontSize="sm">
                {newTemplateError || t('sections.settings.resources.templateValidationError')}
              </FormErrorMessage>
            </FormControl>

            <Button type="submit" variant="primary" disabled={props.loading}>
              {props.loading ? `${t('global.loading')}...` : t('global.add')}
            </Button>
          </form>
        ),
        createdTemplates: createdTemplates.map((template) => (
          <Alert variant="info" key={template._id} className="flex gap-2">
            <CheckCircle className="-mt-1 h-5 w-5" />
            <div className="flex flex-col gap-1">
              <AlertTitle>{t('sections.settings.resources.newTemplateAddedTitle')}</AlertTitle>
              <AlertDescription>{t('sections.settings.resources.newTemplateAddedDescription')}</AlertDescription>
            </div>
          </Alert>
        )),
      })}
    </>
  );
};
