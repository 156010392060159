import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { ResearcherCertificationStatus, UserMetaUxLength } from 'generated/graphql';
import { utils } from 'lib/utils';

import type { Maybe } from 'generated/graphql';
import type { FC, PropsWithChildren } from 'react';

type SignUpMethods = 'google' | 'email';
type ResearcherSignUpState = {
  linkedInURL: string;
  googleId: Maybe<string>;
  uxResearchLength: UserMetaUxLength;
  signUpMethod: SignUpMethods;
  acquisition: {
    source: Maybe<string>;
    campaign: Maybe<string>;
    medium: Maybe<string>;
  };
  details: {
    email: string;
    firstname?: string;
    lastname?: string;
  };
};

type NextPayload = { action?: Actions; routeState?: { [key: string]: any } };

type ResearcherSignUpContext = {
  state: ResearcherSignUpState;
  step: string | null;
  canGoBack: boolean;
  actions: {
    goBack: () => void;
    // eslint-disable-next-line no-unused-vars
    next: (payload?: NextPayload) => void;
  };
};

const ResearcherContext = createContext<ResearcherSignUpContext | undefined>(undefined);

export function useResearcherRegister() {
  const ctx = useContext(ResearcherContext);

  if (ctx === undefined) {
    throw new Error('useResearcherRegister cannot be used outside of ResearcherSignUpContextProvider');
  }

  return ctx;
}

type Routing = {
  [route: string]: {
    previous: Maybe<string>;
    next: Maybe<string>;
  };
};

// I think its important to be verbose and clear when it comes to routing
const ONBOARD_ROUTING_MAP: Routing = {
  // '/researcher/register/ux-length': {
  //   previous: '/researcher/register/linkedin',
  //   next: '/researcher/register/methods',
  // },
  '/researcher/register/methods': {
    previous: null,
    next: '/', // Next route is the home page
  },
};

const SIGNUP_FLOW = {
  login: {
    '/researcher/login': {
      previous: null,
      next: null,
    },
    '/researcher/forgot-password': {
      previous: '/researcher/login',
      next: '/researcher/forgot-password/confirm',
    },
    '/researcher/forgot-password/confirm': {
      previous: '/researcher/forgot-password',
      next: null,
    },
  },
  signup: {
    '/researcher/register/email': {
      previous: null,
      next: '/researcher/register/details',
    },
    '/researcher/register/details': {
      previous: '/researcher/register/email',
      next: '/researcher/certification',
    },
    '/researcher/certification': {
      previous: null,
      next: '/',
    },
  },
  onboard: ONBOARD_ROUTING_MAP,
};

const DEFAULT_VALUE: ResearcherSignUpState = {
  linkedInURL: '',
  googleId: null,
  uxResearchLength: UserMetaUxLength.OneOrLess,
  signUpMethod: 'email',
  acquisition: {
    source: null,
    campaign: null,
    medium: null,
  },
  details: {
    email: '',
  },
};

type Actions =
  | {
      type: 'set_signup_method';
      payload: {
        firstname?: string;
        lastname?: string;
        googleId?: string;
        method: SignUpMethods;
        email: string;
      };
    }
  | {
      type: 'set_onboarding_details';
      payload: {
        uxResearchLength?: Maybe<UserMetaUxLength>;
        linkedInURL?: Maybe<string>;
      };
    }
  | {
      type: 'set_acquisition';
      payload: ResearcherSignUpState['acquisition'];
    };

function researcherSignupReducer(state = DEFAULT_VALUE, action: Actions): ResearcherSignUpState {
  switch (action.type) {
    case 'set_onboarding_details': {
      return {
        ...state,
        linkedInURL: action.payload.linkedInURL ?? '',
        uxResearchLength: action.payload.uxResearchLength ?? UserMetaUxLength.OneOrLess,
      };
    }

    case 'set_acquisition': {
      return {
        ...state,
        acquisition: action.payload,
      };
    }

    case 'set_signup_method': {
      return {
        ...state,
        signUpMethod: action.payload.method,
        googleId: action.payload.googleId ?? null,
        details: {
          ...state.details,
          firstname: action.payload.firstname ?? state.details.firstname,
          lastname: action.payload.lastname ?? state.details.lastname,
          email: action.payload.email,
        },
      };
    }
    default:
      return state;
  }
}

export const ResearcherAuthProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const { details, authState } = useConnectedClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, dispatch] = useReducer(researcherSignupReducer, DEFAULT_VALUE, () => {
    return {
      ...DEFAULT_VALUE,
      linkedInURL: details?.social?.linkedin?.profile_url ?? '',
      uxResearchLength: details?.researcher?.ux_length ?? UserMetaUxLength.OneOrLess,
    };
  });

  // Checks whether it's trying to force a researcher sign up. It should sign the user out if that's the case
  useEffect(() => {
    const args = utils.parseQueryParams(location?.search);
    const forceSignUp = args?.get('forceSignup');

    const acqSource = args?.get('utm_source') || args?.get('acquisition_source') || '';
    const acqCampaign = args?.get('utm_campaign') || args?.get('acquisition_campaign') || '';
    const acqMedium = args?.get('utm_medium') || args?.get('acquisition_medium') || '';

    if (forceSignUp) {
      const restOfParams = location.search.split('&').filter((a) => !a.includes('forceSignup'));
      const queryString = restOfParams.join(',').replace(/\?/g, '');

      window.location.replace(`/logout?redirect=researcher/register/email?${queryString}`);

      return;
    }

    if (acqSource || acqCampaign) {
      dispatch({
        type: 'set_acquisition',
        payload: {
          campaign: acqCampaign,
          source: acqSource,
          medium: acqMedium,
        },
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch({
      type: 'set_onboarding_details',
      payload: {
        uxResearchLength: details?.researcher?.ux_length,
        linkedInURL: details?.social?.linkedin?.profile_url,
      },
    });
  }, [details]);

  const currentRoute = useMemo(() => {
    return {
      step:
        Object.keys(SIGNUP_FLOW.onboard).indexOf(location.pathname) !== -1
          ? Object.keys(SIGNUP_FLOW.onboard).indexOf(location.pathname) + 1
          : null,
      route: { ...SIGNUP_FLOW.signup, ...SIGNUP_FLOW.onboard, ...SIGNUP_FLOW.login }[location.pathname],
    };
  }, [location.pathname]);

  useEffect(() => {
    /**
     * This effect will verify everytime a route changes to make sure that the state matches
     * the users location and act accordingly
     */
    switch (location.pathname) {
      case '/researcher/login':
        if (authState === 'authenticated') {
          navigate('/', { replace: true });
        }

        break;
      case '/researcher/certification': {
        if (details?.researcher?.certification?.status === ResearcherCertificationStatus.Certified) {
          navigate('/', { replace: true });
        }
        break;
      }
      case '/researcher/register/email':
      case '/researcher/register/details': {
        if (authState === 'authenticated') {
          navigate('/', { replace: true });
        }

        break;
      }

      default: {
        break;
      }
    }
  }, [currentRoute, authState]);

  const goBack = () => {
    if (!currentRoute.route?.previous) {
      // Handle the null situation
      return;

      // Maybe we can just fall back to the history goBack;
      // history.goBack();
    }

    navigate(currentRoute.route.previous);
  };

  const next = (payload?: NextPayload) => {
    if (!currentRoute.route?.next) {
      // Handle the null situation
      return;
    }

    if (payload?.action) {
      dispatch(payload?.action);
    }

    navigate(currentRoute.route.next, payload?.routeState ?? {});
  };

  const value = useMemo(() => {
    return {
      state,
      canGoBack: currentRoute.route?.previous !== null,
      step:
        currentRoute.step !== null ? `Step ${currentRoute.step} of ${Object.keys(ONBOARD_ROUTING_MAP).length}` : null,
      actions: {
        goBack,
        next,
      },
    };
  }, [state, currentRoute, goBack, next]);

  return <ResearcherContext.Provider value={value}>{children}</ResearcherContext.Provider>;
};
