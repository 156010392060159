import _ from 'lodash';
import { Component } from 'react';
import { CheckIcon } from 'ui';

import '../invitationDialog/confirmationMessage.scss';

class RevokeInvitationConfirmationMessage extends Component<{}, { step: number }> {
  constructor(props: any) {
    super(props);

    this.state = {
      step: 1,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ step: 2 }, () => {
        setTimeout(() => {
          // Calls a custom finisher
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFinishCustom' does not exist on type '... Remove this comment to see the full error message
          this.props.onFinishCustom();
        }, 1000);
      });
    }, 600);
  }

  inflectName(recipient: any) {
    return recipient.split('').pop() === 's' ? `${recipient}'` : `${recipient}'s`;
  }

  renderConfirmationMessageDescription(step: any, participant: any, participantsToInvite: any) {
    let recipient = _.get(participant, 'user.meta.identity.firstname');
    if (!recipient) recipient = `${_.size(participantsToInvite)} people`;
    switch (step) {
      case 1:
        return <h3>Sending SMS to {recipient}...</h3>;
      case 2:
        return (
          <h3>
            <strong>{this.inflectName(recipient)}</strong> invite has been successfully revoked.
          </h3>
        );
      default:
        return <h3>Sending SMS to {recipient}...</h3>;
    }
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showConfirmationMessage' does not exist ... Remove this comment to see the full error message
      <div className={`confirmationMessageOnInvitation ${this.props.showConfirmationMessage ? 'visible' : ''}`}>
        <CheckIcon
          style={{
            color: 'green',
            padding: '20%',
          }}
          visibility={this.state.step === 2 ? 'visible' : 'hidden'}
          className="animatedIcon animatedDone"
        />
        {this.renderConfirmationMessageDescription(
          this.state.step,
          _.get(this.props, 'singleSession.participant'),
          _.get(this.props, 'bulkSession.participants'),
        )}
      </div>
    );
  }
}

export default RevokeInvitationConfirmationMessage;
