import { Table, TableBody, TableHead, TableHeader, TableRow } from '@askable/ui/components/ui/table';
import { useTranslation } from 'react-i18next';

import { ParticipantTableRow } from 'containers/Studies/Results/components/ParticipantTableRow';
import { Section } from 'containers/Studies/Results/components/Section';

import type { ParticipantProps } from 'containers/Studies/Results/Results';

interface IndividualResponsesProps {
  participants: ParticipantProps[];
}

export const IndividualResponses = ({ participants }: IndividualResponsesProps) => {
  const { t } = useTranslation();

  return (
    <Section
      description={t('sections.studiesResults.individualResponsesDescription')}
      id="individual-responses"
      title={t('sections.studiesResults.individualResponsesTitle')}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t('sections.studiesResults.participants', { count: 1 })}</TableHead>
            <TableHead>{t('sections.studiesResults.outcome')}</TableHead>
            <TableHead className="text-end">{t('sections.studiesResults.duration')}</TableHead>
            <TableHead className="text-end">{t('sections.studiesResults.misclickRate')}</TableHead>
            <TableHead className="text-end">{t('sections.studiesResults.screensViewed')}</TableHead>
            <TableHead>{t('sections.studiesResults.completionDate')}</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {participants.map((participant) => (
            <ParticipantTableRow key={participant.id} participant={participant} />
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};
