import type { ConnectedClientContext } from 'context/ConnectedClientContext';
import type { Booking } from 'generated/graphql';

export function userCanViewBooking(
  booking?: Pick<Booking, '_owner_id' | '_team_id'>,
  viewer?: ConnectedClientContext['details'],
): boolean {
  if (booking?._team_id?.toString() === viewer?.ConnectedTeam?._id?.toString()) {
    return true;
  }

  if (booking?._owner_id?.some((b) => b?.toString() === viewer?.id?.toString())) {
    return true;
  }

  return false;
}
