import { useEffect, useState } from 'react';

import { utils } from 'lib/utils';

export const useUserLatLong = () => {
  const [geolocation, setGeolocation] = useState({
    latitude: null,
    longitude: null,
  });
  useEffect(() => {
    const lat = utils.parseJSON(localStorage.getItem('latitudeByIp'));
    const lng = utils.parseJSON(localStorage.getItem('longitudeByIp'));
    if (lat && lng) {
      setGeolocation({
        latitude: lat,
        longitude: lng,
      });
    }
  }, []);

  return geolocation;
};
