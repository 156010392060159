import { Link } from '@askable/ui/components/ui/link';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { JoinTeamDocument, TeamInvitesDocument, TeamsByEmailDomainDocument } from 'generated/graphql';
import { useHandleCombinedError } from 'src/hooks/useHandleCombinedError';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';
import { invariant } from 'utils/error_utils';

import { JoinTeamCard } from './JoinTeamCard';
import { SectionContainer } from './SectionContainer';

import type {
  JoinTeamInput,
  JoinTeamMutation,
  JoinTeamMutationVariables,
  TeamInvitesQuery,
  TeamInvitesQueryVariables,
  TeamsByEmailDomainQuery,
  TeamsByEmailDomainQueryVariables,
} from 'generated/graphql';

export const JoinTeams = () => {
  const {
    details: viewer,
    authState,
    actions: { maybeNavigateToCompleteProfile, updateClientDetails },
  } = useConnectedClient();
  const navigate = useNavigate();
  const [joinTeamInput, setJoinTeamInput] = useState<JoinTeamInput | null>(null);

  const [{ fetching, error }, joinTeam] = useMutation<JoinTeamMutation, JoinTeamMutationVariables>(JoinTeamDocument);
  const [{ data }] = useQuery<TeamInvitesQuery, TeamInvitesQueryVariables>({
    query: TeamInvitesDocument,
    variables: {
      teamFilter: {
        expired: {
          ne: true,
        },
      },
    },
  });

  const [{ data: domainTeams }] = useQuery<TeamsByEmailDomainQuery, TeamsByEmailDomainQueryVariables>({
    query: TeamsByEmailDomainDocument,
    variables: {
      userId: viewer?.id!,
    },
    pause: authState !== 'authenticated',
  });

  const { switchTeam, switchingTeams } = useSwitchTeam({ updateClientDetails });
  const { reuse } = useHandleCombinedError(error);

  const [, domain] = viewer?.email?.split('@') ?? [];

  const domainBasedTeams = useMemo(() => {
    return domainTeams?.teamsByEmailMatch?.filter((a) => !viewer?.Teams?.some((b) => b?._id === a?._id)) ?? [];
  }, [domainTeams]);

  const hasTeamsToJoin = useMemo(() => {
    return domainBasedTeams.length > 0 || (data?.viewer?.invites?.length ?? 0) > 0;
  }, [domainBasedTeams, data]);

  const showFooter = useMemo(() => {
    return (viewer?.Teams?.length ?? 0) > 0 || hasTeamsToJoin;
  }, [hasTeamsToJoin, viewer]);

  const handleJoinTeam = (teamId: string) => async () => {
    try {
      setJoinTeamInput({
        _id: teamId,
      });
      const result = await joinTeam({
        input: {
          _id: teamId,
        },
      });
      if (result.error) {
        throw result.error;
      }

      invariant(result.data?.joinTeam?._id, 'Failed to join team');

      const switchTeamResult = await switchTeam(result.data?.joinTeam?._id);

      if (switchTeamResult === 'kinde_switch') {
        return;
      }

      if (maybeNavigateToCompleteProfile(viewer, '/')) {
        return;
      }

      navigate('/');
    } catch (e) {
      setJoinTeamInput(null);
      reuse(e as { message: string });
    }
  };

  const networking = fetching || switchingTeams;

  return (
    <div>
      {hasTeamsToJoin && (
        <SectionContainer title="Join a team">
          <div className="space-y-2">
            {data?.viewer?.invites?.map((invite) => {
              return (
                <JoinTeamCard
                  disabled={networking}
                  key={invite?._id}
                  isLoading={networking && joinTeamInput?._id === invite?._team_id}
                  onJoinTeam={handleJoinTeam(invite?._team_id!)}
                  teamName={invite?.team_name!}
                  teamId={invite?._team_id!}
                >
                  Invited by <strong>{invite?.InviterUser?.displayName}</strong>
                </JoinTeamCard>
              );
            })}
            {domainBasedTeams?.map((team) => {
              return (
                <JoinTeamCard
                  disabled={networking}
                  isLoading={networking && joinTeamInput?._id === team?._id}
                  onJoinTeam={handleJoinTeam(team?._id!)}
                  key={team?._id}
                  teamName={team?.name!}
                  teamId={team?._id!}
                >
                  Anyone with <strong>@{domain}</strong> email address can join
                </JoinTeamCard>
              );
            })}
          </div>
        </SectionContainer>
      )}
      {showFooter && (
        <div className="flex w-full items-center justify-center space-x-2 pt-20 text-sm">
          <p className="text-muted-foreground">Can't find the team you are looking for?</p>
          <Link href="/logout">Try another email</Link>
        </div>
      )}
    </div>
  );
};
