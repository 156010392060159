import { Button } from '@askable/ui/components/ui/button';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import { useEffect, Fragment, useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { SelectSearch } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'lib/data/phoneCountryCodes' or... Remove this comment to see the full error message
import countryCodeData from 'lib/data/phoneCountryCodes';
import { location } from 'lib/location';

function YourTimezone(props: any) {
  const booking = _.get(props, 'booking');
  // to be removed once multiRegion is live - ask-6128-multiregion-cleanup
  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);
  const locationData = MULTIREGION_COUNTRIES
    ? bookingUtils.formattedLocations(_.get(booking, 'config.criteria.locations'))
    : [_.find(countryCodeData, { region: _.get(booking, 'config.location.country') })];

  const [bookingState, setBookingState] = useState(booking);
  const [timezone, setTimezone] = useState(_.get(booking, 'config.timezone'));
  const [bookingData, setBookingData] = useState();

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/your-timezone`,
      stepId: 'additional_info_your_timezone',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    if (bookingUtils.isOnlineTask(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/additional-info/closing-date` });
    } else if (bookingUtils.isInPerson(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/additional-info/session-location` });
    }
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [booking]);

  useEffect(() => {
    setTimezone(_.get(bookingState, 'config.timezone'));
  }, [bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <span>
            {`Since you're doing ${
              locationData.length > 1
                ? 'a study in multiple locations'
                : `a ${locationData[0] || 'name'}-wide remote study,`
            } we'll need to know your timezone to do the time conversions for any participants that are in a different timezone to you.`}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__yourTimezoneCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Why does timezone matter?"
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'https://help.askable.com/en/articles/4849198-how-do-you-handle-timezones-in-askable',
              '_blank',
            );
          }}
        />
      </Fragment>
    );
  };

  const onChangeValue = (value: any) => {
    setTimezone(value);

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        timezone: value,
      },
    };
    setBookingState(bookingStateObj);

    if (value !== _.get(booking, 'config.timezone')) {
      const bookingObj = {
        config: {
          timezone: value,
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { timezone: any; }; }'... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/additional-info/description`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Your timezone
      </h1>
      <SelectSearch
        selectOptions={location.getListOfTimezonesFormatted()}
        value={timezone}
        onChange={(value: any) => onChangeValue(value)}
      />
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(YourTimezone);
