import { Button } from '@askable/ui/components/ui/button';
import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';
import { useIntercom } from 'react-use-intercom';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { SelectSearch } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import { location } from 'lib/location';

function YourTimezone(props: any) {
  const intercom = useIntercom();

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [timezone, setTimezone] = useState(_.get(askablePlusBrief, 'askable_plus.additional_info.timezone'));

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Additional Info',
        subStep: `/askable-plus/${askablePlusBrief._id}/additional-info/your-timezone`,
        stepId: 'additional_info_your_timezone',
      });
      props.renderRightContent(rightContent());
    }
  }, []);

  const rightContent = () => {
    const cardTexts = [
      {
        cardText:
          'A researcher will be matched to your project once submitted. This researcher will run the remote moderated interviews on your behalf. Recordings of the interviews will also be provided.',
      },
    ];

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__quotaCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Who will run the interview sessions?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            intercom.showNewMessages();
          }}
          linkText="Chat to us"
          hideArrow
        />
      </Fragment>
    );
  };

  const onChangeValue = (value: any) => {
    setTimezone(value);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            timezone: value,
          },
        },
        additional_info: {
          ...askablePlusBriefState.askable_plus.additional_info,
          timezone: value,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/additional-info/legal-privacy`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Your timezone</h1>
      <SelectSearch
        selectOptions={location.getListOfTimezonesFormatted()}
        value={timezone}
        onChange={(value: any) => onChangeValue(value)}
      />
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(YourTimezone);
