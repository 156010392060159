import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Switch } from '@askable/ui/components/ui/switch';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoPopover } from 'containers/Studies/components/InfoPopover';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

export const RecordField = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<BlockFormFields>();

  return (
    <FormField
      control={control}
      name="is_recording_enabled"
      render={({ field }) => (
        <FormItem>
          <div className="relative flex items-center justify-between gap-2">
            <FormLabel className="flex w-fit items-center gap-1 py-2" aria-describedby="record-description">
              {t('sections.studies.build.formFields.isRecordingEnabled.label')}{' '}
              <InfoPopover
                title={t('sections.studies.build.formFields.isRecordingEnabled.infoTitle')}
                description={t('sections.studies.build.formFields.isRecordingEnabled.infoDescription')}
                moreLink="https://help.askable.com/en/articles/10853460"
              />
            </FormLabel>
            <FormControl>
              <Switch
                checked={field.value ?? false}
                disabled={isDisabled}
                size="small"
                onCheckedChange={(checked) => setValue(field.name, checked)}
              />
            </FormControl>
          </div>

          <div className="text-pretty text-sm text-muted-foreground" id="record-description">
            {t('sections.studies.build.formFields.isRecordingEnabled.description')}
          </div>
        </FormItem>
      )}
    />
  );
};
