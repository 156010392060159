import { Tabs as ShadTabs, TabsList, TabsTrigger } from '@askable/ui/components/ui/tabs';

/**
 * Extend Tabs component with custom styles
 */

interface TabsProps {
  tabs: { label: string; value: string }[];
  value: string;
  onValueChange: (value: string) => void;
}

export const Tabs = ({ tabs, value, onValueChange }: TabsProps) => {
  return (
    <ShadTabs defaultValue={value} value={value} onValueChange={onValueChange}>
      <TabsList className="bg-transparent">
        {tabs.map((tab) => (
          <TabsTrigger
            key={tab.value}
            value={tab.value}
            className="rounded-none border-b-2 border-transparent py-2 data-[state=active]:border-primary"
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </ShadTabs>
  );
};
