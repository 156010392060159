import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import _ from 'lodash';
import { CircleAlert, CircleCheckBig, X } from 'lucide-react';
import { useState, useEffect, useMemo } from 'react';
import Spinner from 'react-spinkit';

import { validations } from 'lib/validations';

function AssetVariant(props: any) {
  const [assetURL, setAssetURL] = useState('');
  const [assetID, setAssetID] = useState('');
  const [assetName, setAssetName] = useState('');
  const [linkValidated, setLinkValidated] = useState(false);
  const [validatingLink, setValidatingLink] = useState(false);
  const [linkError, setLinkError] = useState(null);
  const [linkFocused, setLinkFocused] = useState('');
  const [, setVariantNameFocused] = useState('');

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedOnChangeAssetURL.cancel();
    };
  }, []);

  useEffect(() => {
    setLinkError(null);
    if (
      _.get(props.askablePlusBriefSteps, 'link_to_assets') === 'error' &&
      _.size(_.get(props.askablePlusBrief, 'askable_plus.assets')) === 0
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Required"' is not assignable to... Remove this comment to see the full error message
      setLinkError('Required');
    }
  }, [props]);

  useEffect(() => {
    if (_.get(props, 'asset._id')) {
      const { _id, url, name } = props.asset;
      setAssetURL(url);
      setAssetID(_id);
      setAssetName(name);
      setLinkValidated(validations.validateURL(url));
    } else {
      setAssetName(`Asset ${props.index + 1}`);
    }
  }, [props.asset]);

  const onChangeAssetURL = (value: any, name: any) => {
    setLinkError(null);
    setLinkValidated(false);
    setValidatingLink(true);
    if (value !== '') {
      // Validate the link input by the user
      if (validations.validateURL(value)) {
        setLinkValidated(true);
        onUpdateAsset({
          url: value,
          name,
        });
      }
    }
    setValidatingLink(false);
  };

  const debouncedOnChangeAssetURL = useMemo(() => _.debounce(onChangeAssetURL, 300), [props]);

  const onUpdateAsset = ({ url, name }: any) => {
    const objectToSendBack = {
      _id: assetID,
      index: props.index,
      name,
      url,
    };
    props.onAssetUpdated(objectToSendBack);
  };

  const renderValidationIcon = () => {
    if (validatingLink) {
      return <Spinner fadeIn="none" name="ball-clip-rotate" color="#FF5266" />;
    }

    if (!linkValidated && linkFocused !== 'focused' && assetURL !== '') {
      return <CircleAlert className="h-4 w-4 bg-background text-destructive" />;
    }

    if (linkValidated) {
      return <CircleCheckBig className="h-4 w-4 bg-background text-success" />;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-3 flex gap-1">
        <Label htmlFor={`__variationName_${props.index}`} className="hidden">
          Asset name
        </Label>
        <Input
          id={`__variationName_${props.index}`}
          value={assetName}
          onChange={(e) => {
            setAssetName(e.target.value);
            if (assetID) {
              onUpdateAsset({
                url: assetURL,
                name: e.target.value,
              });
            }
          }}
          placeholder="Asset name"
          onFocus={() => setVariantNameFocused('focused')}
          onBlur={() => setVariantNameFocused('')}
        />

        <Label htmlFor={`__variationLink_${props.index}`} className="hidden">
          Asset link
        </Label>
        <div className="relative w-full">
          <Input
            id={`__variationLink_${props.index}`}
            value={assetURL}
            onChange={(e) => {
              setAssetURL(e.target.value);
              debouncedOnChangeAssetURL(e.target.value, assetName);
            }}
            placeholder="Paste the link to your asset"
            disabled={props.disableChangeLink}
            variant={linkError ? 'error' : undefined}
            onFocus={() => setLinkFocused('focused')}
            onBlur={() => setLinkFocused('')}
          />
          <div className="absolute right-1 top-2">{renderValidationIcon()}</div>
        </div>

        <Button variant="ghost" onClick={() => props.onRemoveVariant(assetID)} disabled={!linkValidated}>
          <X className="h-4 w-4" />
          <div className="sr-only">Remove variant</div>
        </Button>
      </div>
    </div>
  );
}

export default AssetVariant;
