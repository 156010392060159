import { gql } from '@apollo/client';

import { eSignatureTemplateFieldsFragment } from 'data/fragments/esignature_templates/eSignatureTemplateFields';

export default gql`
  mutation updateESignatureTemplate($_id: ID!, $template: ESignatureTemplateInput!) {
    updateESignatureTemplate(_id: $_id, template: $template) {
      ...eSignatureTemplateFields
    }
  }
  ${eSignatureTemplateFieldsFragment}
`;
