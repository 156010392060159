import type { ReactNode } from 'react';

interface SectionProps {
  children: ReactNode;
  description?: string;
  id: string;
  title: string;
}

export const Section = ({ children, description, id, title }: SectionProps) => {
  return (
    <section className="flex flex-col gap-3" id={id}>
      <header className="flex flex-col gap-1">
        <h2 className="text-xl font-semibold">{title}</h2>
        {description ? <div className="text-sm text-foreground-subtle">{description}</div> : null}
      </header>

      {children}
    </section>
  );
};
