import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getPageTitle } from '../utils/getPageTitle';

import { ResultsHeader } from './components/ResultsHeader';
import { SideNav } from './components/SideNav';
import { TaskBlockContent } from './containers/TaskBlockContent';

import type { StudyBlockType, StudyTaskBlockType } from 'generated/graphql';

// @todo get types from backend
export type TaskType = `${StudyBlockType}` | `${StudyTaskBlockType}` | 'multiple_choice'; // Add all possible types
export type TaskSectionType = 'success' | 'incomplete';
export type StatType = 'successful' | 'incomplete' | 'responses' | 'misclickRate' | 'averageDuration';

export interface StatProps {
  subtitle?: string;
  type: StatType;
  value: number | string;
}

export interface ScreenProps {
  duration?: number;
  height: number;
  id: string;
  image: string;
  responses?: number;
  title: string;
  width: number;
}

export interface TaskSectionProps {
  clickRate: number;
  duration: number;
  id: string;
  responses: number;
  screens: ScreenProps[];
  title: string;
  type: TaskSectionType;
}

interface TaskProps {
  id: string;
  responses: number;
  sections: TaskSectionProps[];
  stats: StatProps[];
  title: string;
  type: TaskType;
}

export interface ParticipantProps {
  completed: number;
  duration: number;
  id: string;
  misclickRate: number;
  name: string;
  outcome: TaskSectionType;
  screensViewed: number;
  status: string;
  thumbnail: string;
}

interface ResultsProps {
  id: string;
  name: string;
  status: string;
  participants: ParticipantProps[];
  tasks: TaskProps[];
  completed: number;
  created: number;
}

type ResultsContextProps = {
  results: ResultsProps | null;
  activeBlockId: string | null;
  activeParticipantId: string | null;
  setActiveBlockId: (id: string | null) => void;
  setActiveParticipantId: (id: string | null) => void;
};

// @todo replace with real data
const dummyResults: ResultsProps = {
  id: '123',
  name: 'Test study',
  status: 'active',
  completed: 1733293371000,
  created: 1731263371000,
  participants: [
    {
      completed: 1733293371000,
      duration: 235,
      id: '1',
      misclickRate: 0.3,
      name: 'Donald Bradman',
      outcome: 'success',
      screensViewed: 7,
      status: 'active',
      thumbnail: 'https://picsum.photos/id/80/72/128?grayscale',
    },
    {
      completed: 1731263371000,
      duration: 154,
      id: '2',
      misclickRate: 0.3,
      name: 'Ian Botham',
      outcome: 'incomplete',
      screensViewed: 3,
      status: 'active',
      thumbnail: 'https://picsum.photos/id/155/72/128?grayscale',
    },
    {
      completed: 1731263371000,
      duration: 78,
      id: '3',
      misclickRate: 0.25,
      name: 'Sara Swimmer',
      outcome: 'success',
      screensViewed: 4,
      status: 'active',
      thumbnail: 'https://picsum.photos/id/144/72/128?grayscale',
    },
  ],
  tasks: [
    {
      id: '1',
      title: 'Test task 1',
      type: 'figma_prototype',
      responses: 12,
      stats: [
        { type: 'successful', value: 0.75, subtitle: '9 responses' },
        { type: 'incomplete', value: 0.25 },
        { type: 'misclickRate', value: 0.2 },
        { type: 'averageDuration', value: 25.3 },
      ],
      sections: [
        {
          id: '1',
          title: 'Path 1',
          type: 'success',
          responses: 6,
          clickRate: 0.1,
          duration: 154,
          screens: [
            {
              id: '1',
              title: 'Mobile Screen 1',
              image: 'https://picsum.photos/id/237/400/700?grayscale',
              width: 400,
              height: 700,
              responses: 3,
              duration: 12,
            },
            {
              id: '2',
              title: 'Mobile Screen 2',
              image: 'https://picsum.photos/id/290/400/700?grayscale',
              width: 400,
              height: 700,
              responses: 6,
              duration: 73,
            },
            {
              id: '3',
              title: 'Mobile Screen 3',
              image: 'https://picsum.photos/id/180/400/700?grayscale',
              width: 400,
              height: 700,
              responses: 6,
              duration: 23,
            },
            {
              id: '4',
              title: 'Mobile Screen 4',
              image: 'https://picsum.photos/id/33/400/700?grayscale',
              width: 400,
              height: 700,
              responses: 6,
              duration: 9,
            },
            {
              id: '5',
              title: 'Desktop Screen 5',
              image: 'https://picsum.photos/id/29/800/400?grayscale',
              width: 800,
              height: 400,
              responses: 5,
              duration: 16,
            },
            {
              id: '6',
              title: 'Desktop Screen 6',
              image: 'https://picsum.photos/id/42/800/1700?grayscale',
              width: 800,
              height: 1700,
              responses: 3,
              duration: 54,
            },
          ],
        },
        {
          id: '2',
          title: 'Path 2',
          type: 'incomplete',
          responses: 3,
          clickRate: 0.2,
          duration: 40,
          screens: [],
        },
      ],
    },
    {
      id: '2',
      title: 'Test task 2',
      type: 'multiple_choice',
      responses: 6,
      stats: [],
      sections: [
        {
          id: '3',
          title: 'Path 4',
          type: 'incomplete',
          responses: 4,
          clickRate: 0.54,
          duration: 130,
          screens: [
            {
              id: '1',
              title: 'Mobile Screen 1',
              image: 'https://picsum.photos/id/237/400/700?grayscale',
              width: 400,
              height: 700,
              responses: 6,
              duration: 23,
            },
          ],
        },
      ],
    },
  ],
};

const ResultsContext = createContext<ResultsContextProps>({
  activeBlockId: null,
  activeParticipantId: null,
  results: null,
  setActiveBlockId: () => null,
  setActiveParticipantId: () => null,
});

export const useResultsContext = () => {
  return useContext(ResultsContext);
};

export const Results = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [activeBlockId, setActiveBlockId] = useState<string | null>(null);
  const [activeParticipantId, setActiveParticipantId] = useState<string | null>(null);

  const blockId = searchParams.get('block_id');

  // Temporarily hide the results page. Add ?results=true to the URL to show it
  const showResults = searchParams.get('results');

  useEffect(() => {
    setActiveBlockId(blockId ?? dummyResults.tasks[0]?.id);
  }, [blockId, setActiveBlockId]);

  const resultsContextValue = useMemo<ResultsContextProps>(
    () => ({
      activeBlockId,
      activeParticipantId,
      results: dummyResults,
      setActiveBlockId,
      setActiveParticipantId,
    }),
    [activeBlockId, activeParticipantId],
  );

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.tabs.results'), resultsContextValue?.results?.name ?? '')}</title>
      </Helmet>

      <ResultsContext.Provider value={resultsContextValue}>
        {showResults ? (
          <>
            <ResultsHeader />
            <SideNav />
            <main className="main lg:pr-4">
              <TaskBlockContent />
            </main>
          </>
        ) : (
          <main className="main lg:pr-4">
            <div className="h-full rounded-xl bg-background p-3 lg:p-4">{t('sections.studies.tabs.results')}</div>
          </main>
        )}
      </ResultsContext.Provider>
    </>
  );
};
