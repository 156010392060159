import { Palmtree } from '@askable/ui/components/unmod/icons';

interface EmptyStateProps {
  title?: string;
}

export const EmptyState = ({ title }: EmptyStateProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2 p-3 opacity-70">
      <Palmtree size={48} />
      {title ? <div className="text-center font-medium">{title}</div> : null}
    </div>
  );
};
