import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { isNumber } from 'radash';
import { useState, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Box } from 'ui';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import projectAskablePlusCreditPrice from 'data/queries/project/projectAskablePlusCreditPrice';
import fetchTeamById from 'data/queries/teams/fetchTeamById';
import { askablePlusUtils } from 'lib/askablePlus';
import { bookingUtils } from 'lib/booking';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';

// Queries

// Styles
import './styles/priceCardContainerStyles.scss';

function PriceCardContainer(props: any) {
  const intercom = useIntercom();
  const { details } = useConnectedClient();

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const teamByIdData = useQuery(fetchTeamById, { variables: { _id: details?.team?.id } });
  const briefObj = {
    ..._.omit(askablePlusBrief, ['steps', 'team', 'users', 'owner', 'bookings', 'pricing', 'rating', 'admin']),
    askable_plus: {
      ...askablePlusBrief.askable_plus,
      research_type: {
        ...utils.omitValueRecursively(askablePlusBrief.askable_plus?.research_type, 'Booking'),
      },
    },
  };
  const askablePlusCredits = useQuery(projectAskablePlusCreditPrice, {
    variables: {
      project: utils.removeTypenames(briefObj),
    },
  });
  const taxesForCountry = teamUtils.getTax();
  const currency = teamUtils.getCurrency();

  const { data: creditPacks } = useQuery(FetchCreditPacksQuery);

  // Credits to buy is equals to the number of participants needed minus the number of credits the team currently has
  const creditsToBeUsed = askablePlusCredits.data?.projectAskablePlusCreditPrice?.total_credits;
  const creditsRemaining = teamUtils.getCredits(_.get(teamByIdData, 'data.teamById'));
  const creditsToBuy =
    isNumber(creditsToBeUsed) && isNumber(creditsRemaining) ? creditsToBeUsed - creditsRemaining : undefined;
  const pricePerParticipant = bookingUtils.getPricePerParticipant(creditPacks?.creditPacks as [], creditsToBuy);
  const briefPrice =
    isNumber(creditsToBuy) && isNumber(pricePerParticipant) ? creditsToBuy * pricePerParticipant : undefined;

  const [shouldBlinkPrice, setShouldBlinkPrice] = useState(false);

  // When number of credits change it should blink in yellow
  useEffect(() => {
    setShouldBlinkPrice(true);
    setTimeout(() => {
      setShouldBlinkPrice(false);
    }, 200);
  }, [creditsToBeUsed]);

  const parseDuration = (duration: any, type: any, subtype: any) => {
    const session = utils.getLabelFromArray(bookingUtils.sessionDurations(type, subtype), duration);
    const parsedDuration = typeof session === 'string' ? session.slice(0, -1) : `${session} mins`;
    return parsedDuration;
  };

  const renderCreditsBalanceContainer = () => {
    return (
      <div className="creditsBalanceContainer">
        <span>Your credit balance</span>
        <h3>
          {utils.numberWithCommas(creditsRemaining)} credit{creditsRemaining > 1 ? 's' : ''}
        </h3>
      </div>
    );
  };

  const renderCreditsToBuyContainer = () => {
    return (
      <div className="creditsToBuyContainer">
        {creditsToBuy && (
          <p>
            {creditsRemaining > 0 ? 'Top up with' : 'Price for'} {utils.numberWithCommas(creditsToBuy)} credit
            {creditsToBuy > 1 ? 's' : ''}:
          </p>
        )}
        <h3>
          {
            briefPrice
              ? `${currency.symbol}${utils.formatMoney(briefPrice, 2)} ${currency.code}`
              : '\u00A0' /* Non-breaking space */
          }
          {taxesForCountry?.tax_rate && <span> + {_.get(taxesForCountry, 'tax_label')}</span>}
        </h3>
      </div>
    );
  };

  const renderLongitudinalTimes = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
    );
    const workloadMeasure = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.measure',
    );
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
    );
    const workloadMeasureLabel = bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1);
    const workloadFrequencyLabel = bookingUtils.getFrequencyLabel(workloadFrequency);

    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
    );
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    const periodFrequencyLabel = bookingUtils.getFrequencyLabel(periodFrequency, periodTime > 1);
    return (
      <>
        {workloadTime && (
          <span className="cardText">
            {workloadTime} {workloadMeasureLabel} per {workloadFrequencyLabel}
          </span>
        )}
        {periodTime && (
          <p className="cardText">
            &emsp;&ensp; To be done over {periodTime} {periodFrequencyLabel}
          </p>
        )}
        {_.size(_.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.longitudinal_study.extra')) > 0 && (
          <>
            <p className="cardText">• Extra time:</p>
            {_.map(
              _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.longitudinal_study.extra'),
              (item: any) => {
                const extraTime = _.get(item, 'time', '');
                const extraMeasure = _.get(item, 'measure');
                const extraDescription = _.get(item, 'description', '');
                const extraMeasureLabel = bookingUtils.getMeasureLabel(extraMeasure, extraTime > 1);
                return (
                  <p className="cardText">
                    &emsp;&ensp; • {extraTime} {extraMeasureLabel} for {extraDescription}
                  </p>
                );
              },
            )}
          </>
        )}
      </>
    );
  };

  const renderDiscoveryInterviewInfo = () => {
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.quota');
    const duration = parseDuration(
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration'),
      2,
      null,
    );
    return (
      <div className="pbottom10">
        <p className="cardText">&emsp;● Discovery Interview</p>
        <p className="cardText">
          &emsp;&ensp; {quota} x {duration}
        </p>
      </div>
    );
  };

  const renderUsabilityTestingInfo = () => {
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota');
    const duration = parseDuration(
      _.get(askablePlusBrief, 'askable_plus.research_type.usability.session.duration'),
      2,
      null,
    );
    return (
      <div className="pbottom10">
        <p className="cardText">&emsp;● Usability Testing</p>
        <p className="cardText">
          &emsp;&ensp; {quota} x {duration}
        </p>
      </div>
    );
  };

  const renderCompetitiveAnalysisInfo = () => {
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.competitive_analysis.quota');
    return (
      <div className="pbottom10">
        <p className="cardText">&emsp;● Competitive Analysis</p>
        <p className="cardText">
          &emsp;&ensp; {quota} {utils.pluralize('competitor', quota)}
        </p>
      </div>
    );
  };

  const renderSurveyInfo = () => {
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.survey.quota');
    const duration = parseDuration(_.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration'), 3, 2);
    return (
      <div className="pbottom10">
        <p className="cardText">&emsp;● Survey</p>
        <p className="cardText">
          &emsp;&ensp; {quota} x {duration}
        </p>
      </div>
    );
  };

  const renderLongitudinalStudyInfo = () => {
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota');
    return (
      <div className="pbottom10">
        <p className="cardText">&emsp;● Longitudinal Study</p>
        <p className="cardText">
          &emsp;&ensp; {quota} x {renderLongitudinalTimes()}
        </p>
      </div>
    );
  };

  const renderContinuousDiscoveryInfo = () => {
    const AIModeratedInterviewsQuota = _.get(
      askablePlusBrief,
      'askable_plus.research_type.continuous_ai_moderated.quota',
    );
    const moderatedInterviewsQuota = _.get(
      askablePlusBrief,
      'askable_plus.research_type.continuous_researcher_moderated.quota',
    );
    const continuousDuration = _.get(askablePlusBrief, 'askable_plus.continuous_duration');

    return (
      <ul className="pbottom10 list-disc pl-4 text-[#666]">
        <li className="text-lg leading-4 ">
          <p className="cardText">Moderated interviews: {moderatedInterviewsQuota} per week</p>
        </li>
        <li className="text-lg leading-4">
          <p className="cardText">AI moderated interviews: {AIModeratedInterviewsQuota} per week</p>
        </li>
        <li className="text-lg leading-4">
          <p className="cardText">Over {continuousDuration} months</p>
        </li>
      </ul>
    );
  };

  const renderDeliverables = () => {
    const allDeliverables = askablePlusUtils.deliverables();
    return (
      <>
        <p className="cardText">Deliverables:</p>
        <div className="pbottom10">
          {_.map(allDeliverables, (deliverable: any) => {
            if (_.get(askablePlusBrief, `askable_plus.deliverables[${deliverable.key}]`)) {
              return (
                <>
                  <p className="cardText">&emsp; ● {deliverable.label}</p>
                  {deliverable.key === 'other' && (
                    <p className="cardText wrapText">
                      &emsp;&ensp;&ensp; {_.get(askablePlusBrief, 'askable_plus.deliverables.other_description')}
                    </p>
                  )}
                </>
              );
            }
          })}
          {askablePlusUtils.isContinuousDiscoverType(askablePlusBrief) && (
            <p className="cardText">&emsp; ● Insight dashboard access</p>
          )}
        </div>
      </>
    );
  };

  return (
    <Box padding="24px" border="1px" borderColor="gray.200" borderRadius="8px" className="cardContainer">
      <p id="__bookingTotalCredits" className={`cardTitle credits ${shouldBlinkPrice ? 'shouldBlink' : ''}`}>
        {utils.numberWithCommas(creditsToBeUsed)} Credits
      </p>
      <div className="cardPrice">
        {creditsRemaining === 0 &&
          // Hide price when loading / invalid (NaN)
          (typeof briefPrice === 'number' && briefPrice > 0 ? (
            <>
              {currency.symbol}
              {utils.formatMoney(briefPrice, 2)} {currency.code}
              {taxesForCountry?.tax_label && <span> + {taxesForCountry.tax_label}</span>}
            </>
          ) : (
            '\u00A0' /* Non-breaking space */
          ))}
        {creditsRemaining > 0 && renderCreditsBalanceContainer()}
        {(creditsRemaining ?? 0) > 0 && (creditsToBuy ?? 0) > 0 && renderCreditsToBuyContainer()}
        {creditsRemaining === 0 && (
          <div
            className="getBulkCreditsLink"
            onClick={() => {
              props.context.onOpenBuyCreditsModal({
                team_id: props.team._id,
                creditsToBuy: 10000,
                onSuccess: () => {
                  // Close the checkout page
                  props.context.onCloseBuyCreditsModal({ shouldGoBack: true });
                },
              });
            }}
          >
            or get bulk credits and save <br />
            up to 30%
          </div>
        )}
      </div>
      <p className="cardText additionalText">
        <u>Includes</u> participant incentives
      </p>
      <p className="cardText">
        <u>Includes</u> researcher fees
      </p>
      {!props.condensedCard && (
        <>
          <div className="line" />
          {askablePlusUtils.totalResearchTypesSelected(askablePlusBrief) > 0 && <p className="cardText">Research:</p>}
          {askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief) && renderDiscoveryInterviewInfo()}
          {askablePlusUtils.hasUsabilityTestingType(askablePlusBrief) && renderUsabilityTestingInfo()}
          {askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief) && renderCompetitiveAnalysisInfo()}
          {askablePlusUtils.hasSurveyType(askablePlusBrief) && renderSurveyInfo()}
          {askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief) && renderLongitudinalStudyInfo()}
          {askablePlusUtils.isContinuousDiscoverType(askablePlusBrief) && renderContinuousDiscoveryInfo()}
          {renderDeliverables()}
          <p className="cardText">{`${askablePlusUtils.isProjectForProfessionals(askablePlusBrief) ? 'Premium' : 'Standard'} incentive`}</p>
          <p className="cardText additionalText">Got a pricing question?</p>
        </>
      )}
      <div
        className="cardLinkContainer noMarginTop"
        onClick={() => {
          intercom.showNewMessages();
        }}
      >
        <span className="cardLink">Chat to us</span>
      </div>
    </Box>
  );
}

export default PriceCardContainer;
