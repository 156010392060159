import Spinner from 'react-spinkit';
import { CheckIcon } from 'ui';

import { Modal } from 'components/common';

import './modalPaymentStatusStyles.scss';

/*
    Payment Method 1 = Credit Card
    Payment Method 2 = Invoice
*/
export default function ModalPaymentStatus(props: any) {
  function getStepDescription(step: any, payment_method: any) {
    if (step === 1 && payment_method === 1) {
      return (
        <h2>
          <strong>Securely</strong> processing your payment...
        </h2>
      );
    }
    if (step === 2 && payment_method === 1) {
      return (
        <h2 className="confirmationMessage">
          Payment <strong>successful</strong>
        </h2>
      );
    }
    if (step === 1 && payment_method === 2) {
      return (
        <h2>
          <strong>Securely</strong> generating your invoice...
        </h2>
      );
    }
    if (step === 2 && payment_method === 2) {
      return (
        <h2 className="confirmationMessage">
          Invoice sent <strong>successfully</strong>
        </h2>
      );
    }
  }
  return (
    <Modal open={props.open} className="modalPaymentComponent" bodyClassName="modalPayment" modal={false}>
      <>
        <CheckIcon
          style={{
            color: 'green',
            padding: '20%',
          }}
          visibility={props.step === 2 ? 'visible' : 'hidden'}
          className="animatedIcon animatedDone"
        />
        {props.step === 1 ? (
          <>
            <Spinner fadeIn="quarter" name="ball-clip-rotate" color="#FF5266" className="loadingSpinner mleft15" />
            {getStepDescription(props.step, props.payment_method)}
          </>
        ) : (
          getStepDescription(props.step, props.payment_method)
        )}
      </>
    </Modal>
  );
}
