import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Tabs, TabsList, TabsTrigger } from '@askable/ui/components/ui/tabs';
import { cn } from '@askable/ui/lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResultsContext } from 'containers/Studies/Results/Results';

import { NavParticipantBlock } from './NavParticipantBlock';
import { NavTaskBlock } from './NavTaskBlock';

export const SideNav = () => {
  const { t } = useTranslation();
  const { results, activeBlockId, activeParticipantId } = useResultsContext();
  const [activeTab, setActiveTab] = useState('blocks');
  const [searchQueryParticipants, setSearchQueryParticipants] = useState('');

  const filteredParticipants =
    results?.participants.filter((participant) =>
      participant.name.toLowerCase().includes(searchQueryParticipants.toLowerCase()),
    ) ?? [];

  return (
    <nav className="page-nav flex h-full flex-col overflow-hidden rounded-xl bg-background pt-3 lg:ml-4 lg:pt-4">
      <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className="px-3 lg:px-4">
        <TabsList className="w-full">
          <TabsTrigger value="blocks" className="flex-1">
            {t('sections.studiesResults.blocks', { count: results?.tasks.length })}
          </TabsTrigger>
          <TabsTrigger value="participants" className="flex-1">
            {t('sections.studiesResults.participants', { count: results?.participants.length })}
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <div className={cn('flex-1 overflow-auto p-3 lg:p-4', { hidden: activeTab !== 'blocks' })}>
        <ol className="flex flex-col gap-2">
          {results?.tasks.map((taskBlock) => (
            <li key={taskBlock.id}>
              <NavTaskBlock
                id={taskBlock.id}
                isActive={taskBlock.id === activeBlockId}
                title={taskBlock.title}
                type={taskBlock.type}
              />
            </li>
          ))}
        </ol>
      </div>

      <div className="overflow-auto">
        <div
          className={cn('flex flex-1 flex-col gap-2 overflow-auto p-3 lg:p-4', {
            hidden: activeTab !== 'participants',
          })}
        >
          <Label htmlFor="filter-participants" className="sr-only">
            {t('sections.studiesResults.filter')}
          </Label>
          <Input
            className="w-full bg-background-subtle hover:bg-secondary focus:bg-secondary"
            id="filter-participants"
            placeholder={`${t('sections.studiesResults.filter')}...`}
            type="search"
            value={searchQueryParticipants}
            variant="borderless"
            onChange={(e) => setSearchQueryParticipants(e.target.value)}
          />

          <ol className="flex flex-col gap-2">
            {filteredParticipants.map((participant) => (
              <li key={participant.id}>
                <NavParticipantBlock
                  completed={participant.completed}
                  id={participant.id}
                  isActive={participant.id === activeParticipantId}
                  name={participant.name}
                />
              </li>
            ))}

            {filteredParticipants.length === 0 ? (
              <div className="py-4 text-sm text-muted-foreground">{t('sections.studiesResults.noParticipants')}</div>
            ) : null}
          </ol>
        </div>
      </div>
    </nav>
  );
};
