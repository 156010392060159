import { cn } from '@askable/ui/lib/utils';

import type { ReactNode } from 'react';

type Props = {
  title: string;
  className?: string;
  children?: ReactNode;
};

export const SectionContainer = ({ title, children, className }: Props) => {
  return (
    <div className={cn('space-y-4', className)}>
      <h2 className="text-lg font-medium text-foreground">{title}</h2>
      {children}
    </div>
  );
};
