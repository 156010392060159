import { Button } from '@askable/ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { bookingUtils } from 'lib/booking';
import { useIsNewPssBooking } from 'utils/booking-utils';

import type { BookingTableSchemaType } from './bookings-table-schema';
import type { Row } from '@tanstack/react-table';

export const BookingCellRateExperience = ({ row }: { row: Row<BookingTableSchemaType> }) => {
  const navigate = useNavigate();
  const newPssBooking = useIsNewPssBooking(row.original.created);
  const canRateExperience = bookingUtils.canRateExperience(row.original, newPssBooking);

  if (!canRateExperience) {
    return null;
  }
  const onClick = () => {
    const { url, state } = bookingUtils.getBookingLinkURL(row.original);
    navigate(url, { state });
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0" onClick={onClick}>
            <Star className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Rate experience</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
