import '@askable/ui/styles/globals.css';

import { ApolloProvider } from '@apollo/client';
import { Toaster } from '@askable/ui/components/ui/sonner';
import { TooltipProvider } from '@askable/ui/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import { AskableUIProvider } from 'ui';

import { client } from 'data/client';
import { globalVariables } from 'lib/globalVariables';

import i18n from './i18n';
import Routes from './routes';

import './styles/scrollBarStyles.scss';
import './styles/styles.scss';

const IS_PROD = import.meta.env.VITE_ENVIRONMENT === 'PROD';

if (IS_PROD) {
  import('@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
      site: 'us3.datadoghq.com',
      service: 'clients',
      env: 'production',
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      enableExperimentalFeatures: ['feature_flags'],
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [{ match: import.meta.env.VITE_GRAPHQL_URL, propagatorTypes: ['tracecontext'] }],
      beforeSend: () => {
        if (localStorage.getItem('connectedAsClient')) {
          return false;
        }

        return true;
      },
    });
  });
}
// Fetch the environment
const environment = globalVariables.getEnvironmentVariables().environment;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    <I18nextProvider i18n={i18n}>
      <IntercomProvider appId={environment === 'PROD' ? 'w5j4obnp' : 's9umtlvp'}>
        <ApolloProvider client={client}>
          <AskableUIProvider>
            <ThemeProvider attribute="class">
              <TooltipProvider>
                <Routes />
              </TooltipProvider>
            </ThemeProvider>
          </AskableUIProvider>
        </ApolloProvider>
        <Toaster />
      </IntercomProvider>
    </I18nextProvider>
  </HelmetProvider>,
);
