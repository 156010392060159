import { gql } from '@apollo/client';

import { participantSessionsHistoryFieldsFragment } from 'data/fragments/bookingSubmission/participantSessionsHistoryFields';

export const participantSessionsPageCalendarFragment = gql`
  fragment participantSessionsPageCalendar on SessionsType {
    _id
    _session_id
    _user_id
    status
    cancel
    cancel_reason
    user {
      _id
      email
      meta {
        identity {
          firstname
          lastname
          gender
          birthday {
            year
            timestamp
          }
          languages {
            english {
              speak {
                native
                fluent
                conversational
                beginner
              }
            }
          }
        }
        family {
          status {
            single
            relationship
            married
            divorced
            widowed
          }
        }
        education {
          highschool
          some_university
          undergraduate
          postgraduate
          apprenticeship
        }
        work {
          status {
            fulltime
            parttime
            fulltime_student
            unemployed
            homeduties
            retired
          }
        }
      }
      location {
        city
        state
        timezone
      }
      contact {
        phone {
          country_code
          mobile
        }
      }
    }
    completed
    rating {
      # Remove punctuality and engagement here when you can confirm its not being used anymore as it seems to be used used in calendar, but its legacy field so feel free to remove when its safe to do.
      engagement
      punctuality
      overall
    }
    feedback {
      participant
    }
    Submission {
      _id
      data {
        _question_id
        _answer_id
        value
      }
      agreement {
        status
      }
    }
    history {
      ...participantSessionsHistoryFields
    }
  }
  ${participantSessionsHistoryFieldsFragment}
`;
