import { Button } from '@askable/ui/components/ui/button';
import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';
import { useIntercom } from 'react-use-intercom';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Card } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';

import './styles/legalPrivacyStyles.scss';

function LegalPrivacy(props: any) {
  const intercom = useIntercom();

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [legalPrivacyType, setLegalPrivacyType] = useState(
    _.get(askablePlusBrief, 'askable_plus.additional_info.nda_guide_type'),
  );

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Additional Info',
        subStep: `/askable-plus/${askablePlusBrief._id}/additional-info/legal-privacy`,
        stepId: 'additional_info_legal_privacy',
      });
      props.renderRightContent(rightContent());
    }
  }, []);

  const rightContent = () => {
    const cardTexts = [
      {
        cardText: (
          <span>
            Any researcher we source for you will sign an agreement with you before they begin work on your project.
          </span>
        ),
      },
      { cardText: <span>This includes protection for you for things like:</span>, additionalText: true },
      { cardText: <span>&emsp;&bull;&ensp;Customer information</span> },
      { cardText: <span>&emsp;&bull;&ensp;Intellectual property</span> },
      { cardText: <span>&emsp;&bull;&ensp;Marketing information</span> },
      { cardText: <span>&emsp;&bull;&ensp;Business operations</span> },
      { cardText: <span>&emsp;&bull;&ensp;Product information</span> },
    ];
    return (
      <>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__legalPrivacySelectionCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="What's included in Askable's standard non-disclosure agreement?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            intercom.showNewMessages();
          }}
          linkText="Chat to us"
          hideArrow
        />
      </>
    );
  };

  const onClickCard = (type: any) => {
    setLegalPrivacyType(type);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        additional_info: {
          ...askablePlusBriefState.askable_plus.additional_info,
          nda_guide_type: type,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/additional-info/link-to-assets`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Legal & Privacy</h1>
      <p className="label">
        We take your legal and privacy needs seriously. Please let us know how you&apos;d like to proceed:
      </p>
      <div className="legalPrivacySelectionTypes">
        <Card
          title="Use Askable's standard Non-Disclosure agreement"
          className="legalPrivacySelectionCard"
          content={[
            {
              label:
                'Askable will provide you and the chosen Askable+ researchers with a standard NDA to sign before starting.',
            },
          ]}
          selected={legalPrivacyType === 1}
          onClickCard={() => onClickCard(1)}
        />
        <Card
          title="Use custom agreements"
          className="legalPrivacySelectionCard"
          content={[
            {
              label:
                'You or your legal team will provide your own non-disclosure and privacy agreements for the researcher to sign.',
            },
          ]}
          selected={legalPrivacyType === 2}
          onClickCard={() => onClickCard(2)}
        />
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(LegalPrivacy);
