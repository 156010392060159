import { useQuery } from '@apollo/client';
import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { toast } from '@askable/ui/components/ui/sonner';
import { Switch } from '@askable/ui/components/ui/switch';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'components/common';
import updateMeMutation from 'data/mutations/user/updateMe';
import fetchUserById from 'data/queries/user/fetchUserById';

import { SectionHeader } from '../../components/SectionHeader';
import { SettingsContainer } from '../../components/SettingsContainer';

import type { UpdateMeMutation, UpdateMeMutationVariables } from 'generated/graphql';

type NotificationCategory = 'reminders' | 'promotional';
type NotificationType = 'email' | 'sms' | 'push';
type UserSettings = {
  settings: {
    notification: {
      [parentKey in NotificationCategory]: {
        [childKey in NotificationType]: boolean;
      };
    };
  };
};

export const SettingsNotifications = () => {
  const { t } = useTranslation();
  const { loading: loadingUser, data: userData, refetch: refetchUser } = useQuery(fetchUserById);
  const [, updateMe] = useMutation<UpdateMeMutation, UpdateMeMutationVariables>(updateMeMutation);
  const user = userData?.userByID;

  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [userState, setUserSettings] = useState<UserSettings>({
    settings: {
      notification: {
        reminders: {
          email: user?.settings?.notification?.reminders.email || true,
          sms: user?.settings?.notification?.reminders.sms || true,
          push: user?.settings?.notification?.reminders.push || true,
        },
        promotional: {
          email: user?.settings?.notification?.promotional.email || true,
          sms: user?.settings?.notification?.promotional.sms || true,
          push: user?.settings?.notification?.promotional.push || true,
        },
      },
    },
  });

  useEffect(() => {
    if (user && user._id) {
      setUserSettings(() => ({
        settings: {
          notification: {
            reminders: {
              email: user?.settings?.notification?.reminders.email,
              sms: user?.settings?.notification?.reminders.sms,
              push: user?.settings?.notification?.reminders.push,
            },
            promotional: {
              email: user?.settings?.notification?.promotional.email,
              sms: user?.settings?.notification?.promotional.sms,
              push: user?.settings?.notification?.promotional.push,
            },
          },
        },
      }));
    }
  }, [user]);

  const onSave = async (event: any) => {
    event.preventDefault();

    setSaving(true);
    setError('');

    try {
      await updateMe({ user: { ...userState } });

      await refetchUser();

      toast.success(
        t('confirmations.updated', {
          title: t('sections.settings.notifications.title'),
        }),
      );
    } catch (err: any) {
      setError(err?.graphQLErrors[0].message);
    } finally {
      setSaving(false);
    }
  };

  const renderToggle = (category: NotificationCategory, type: NotificationType) => {
    const checked = userState.settings.notification[category][type];
    const label = t(`sections.settings.notifications.${type}`);

    return (
      <div className="flex items-center justify-between gap-2">
        <Label htmlFor={`${category}_${type}`} className="w-full min-w-[10rem] py-2">
          {label}
        </Label>
        <Switch
          id={`${category}_${type}`}
          checked={checked}
          onCheckedChange={(active) => {
            setUserSettings((prevState: UserSettings) => ({
              settings: {
                notification: {
                  ...prevState.settings.notification,
                  [category as NotificationCategory]: {
                    ...prevState.settings.notification[category as NotificationCategory],
                    [type]: active,
                  },
                },
              },
            }));
          }}
        />
      </div>
    );
  };

  return (
    <SettingsContainer title={t('sections.settings.notifications.title')}>
      {loadingUser ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      {error ? (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : null}

      <form onSubmit={onSave} className="flex flex-col gap-8">
        <section className="flex flex-col gap-4">
          <SectionHeader
            title={t('sections.settings.notifications.studyUpdatesTitle')}
            description={t('sections.settings.notifications.studyUpdatesDescription')}
          />

          {renderToggle('reminders', 'email')}
          {renderToggle('reminders', 'sms')}
        </section>

        <section className="flex flex-col gap-4">
          <SectionHeader
            title={t('sections.settings.notifications.marketingTipsTitle')}
            description={t('sections.settings.notifications.marketingTipsDescription')}
          />

          {renderToggle('promotional', 'email')}
        </section>

        <Button type="submit" variant="primary" isLoading={saving}>
          {t('global.save')}
        </Button>
      </form>
    </SettingsContainer>
  );
};
