import { Button } from '@askable/ui/components/ui/button';
import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import CardContainer from 'components/createBooking/components/cardContainer';
import { utils, update } from 'lib/utils';

import AssetVariant from './assetVariant';

import './styles/linkToAssetsStyles.scss';

function LinkToAssets(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Additional Info',
        subStep: `/askable-plus/${askablePlusBrief._id}/additional-info/link-to-assets`,
        stepId: 'additional_info_link_to_assets',
      });
      props.renderRightContent(rightContent());
    }
  }, []);

  useEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: any; name: any; url: any;... Remove this comment to see the full error message
    setAssets(normaliseLinks(_.get(askablePlusBrief, 'askable_plus.assets', [])));
  }, [askablePlusBrief]);

  const normaliseLinks = (links: any) => {
    // Method to normalise links
    return _.map(links, (link: any) => {
      return {
        _id: link._id,
        name: link.name,
        url: link.url,
      };
    });
  };

  const rightContent = () => {
    const cardTexts = [
      {
        cardText:
          'Since your project will be run by an Askable+ researcher, you can update your links up until the Askable+ researcher begins recruiting for participants.',
      },
      {
        cardText:
          'If you want to update your links after participant recruitment has started, please talk your Askable+ researcher.',
        additionalText: true,
      },
    ];

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__linkToTaskCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Can I update the links later?"
          cardTexts={cardTexts}
        />
      </Fragment>
    );
  };

  const onAssetUpdated = (asset: any) => {
    const urlLink = utils.prependURLProtocol(asset.url);
    let newArrayAssets: any = [];
    const internalIndex = _.findIndex(assets, (item: any) => item._id === _.get(asset, '_id'));
    if (internalIndex < 0) {
      newArrayAssets = normaliseLinks(
        update(assets, {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; url: any; name: any; }[]' is ... Remove this comment to see the full error message
          $push: [
            {
              _id: utils.newObjectId(),
              url: urlLink,
              name: asset.name,
            },
          ],
        }),
      );
    } else {
      newArrayAssets = normaliseLinks(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: number]: { _id: { $set: an... Remove this comment to see the full error message
        update(assets, {
          [internalIndex]: {
            _id: { $set: _.get(asset, '_id', utils.newObjectId()) },
            url: { $set: urlLink },
            name: { $set: asset.name },
          },
        }),
      );
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        assets: newArrayAssets,
      },
    };

    setAssets(newArrayAssets);
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onRemoveAsset = (link_id: any) => {
    const internalIndex = _.findIndex(assets, (asset: any) => asset._id === link_id);
    const newArrayAssets = update(assets, {
      $splice: [[internalIndex, 1]],
    });

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        assets: newArrayAssets,
      },
    };

    setAssets(newArrayAssets);
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const renderAssets = () => {
    const filteredAssets = _.filter(assets, (asset: any, index: any) => index !== 0);
    return _.map(filteredAssets, (asset: any, index: any) => {
      return renderAssetUI({ asset, index: index + 1 });
    });
  };

  const renderAssetUI = ({ asset, index }: any) => {
    return (
      <AssetVariant
        key={_.get(asset, '_id') || `asset-${index}`}
        index={index}
        asset={asset}
        onAssetUpdated={onAssetUpdated}
        onRemoveVariant={onRemoveAsset}
        isLinkEmpty={!_.has(asset, 'url') || _.get(asset, 'url') === ''}
        {...props}
      />
    );
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/additional-info/additional-notes`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Link to your assets</h1>
      <p className="label">
        This can be a link to a prototype, survey, Miro board, or any other assets you would like the researcher to use
        for this project.
      </p>
      <div className="linkToAssetContainer">
        {renderAssetUI({ asset: assets[0], index: 0 })}
        {renderAssets()}
      </div>
      {_.size(assets) > 0 && (
        <div
          className="linkTextContainer mtop20"
          onClick={() => {
            setAssets(
              update(assets, {
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ index: number; }[]' is not assignable to t... Remove this comment to see the full error message
                $push: [{ index: assets.length }],
              }),
            );
          }}
        >
          <span className="linkTextLabel">+ Add another</span>
        </div>
      )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(LinkToAssets);
