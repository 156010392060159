import { gql } from '@apollo/client';

import { participantSessionsPageCalendarFragment } from 'data/fragments/bookingSubmission/participantSessionsPageCalendar';

export default gql`
  query FetchParticipantSessionsSearch(
    $_booking_id: [ID]
    $_user_id: [ID]
    $_session_id: [ID]
    $status: [Int]
    $cancel: [Int]
    $_participant_session_id: [ID]
    $limit: Int
  ) {
    participantSessionsSearch(
      _booking_id: $_booking_id
      _user_id: $_user_id
      _session_id: $_session_id
      _participant_session_id: $_participant_session_id
      status: $status
      cancel: $cancel
      limit: $limit
    ) {
      ...participantSessionsPageCalendar
      session {
        _id
        start
        end
        status
        _researcher_user_id
        ResearcherUser {
          _id
          name
        }
        askable_live_rooms {
          sid
          composition_sid
        }
      }
    }
  }
  ${participantSessionsPageCalendarFragment}
`;
