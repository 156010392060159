import { cn } from '@askable/ui/lib/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  isValid?: boolean;
}

export const InvalidIndicator = ({ isValid = true }: Props) => {
  const { t } = useTranslation();
  const label = !isValid ? t('validation.invalidField') : t('validation.unpublishedFields');

  return (
    <div
      className={cn(
        'absolute -right-[1px] -top-[1px] flex aspect-square h-3 w-3 items-center rounded-full p-[3px] pt-[2px] text-lightest',
        {
          'bg-destructive': !isValid,
        },
      )}
      title={label}
      aria-label={label}
    />
  );
};
