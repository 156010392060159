import { graphql } from 'src/graphql';

export const FigmaOauthCallback = graphql(`
  mutation FigmaOauthCallback($code: String!, $redirectUrl: String!) {
    figmaOauthCallback(code: $code, redirectUri: $redirectUrl) {
      oauth_access_token_expiry
      is_connected
    }
  }
`);
