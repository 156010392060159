import { Pagination } from '@askable/ui/components/ui/pagination';
import {
  Table as TableComp,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableWrapper,
} from '@askable/ui/components/ui/table';
import { flexRender } from '@tanstack/react-table';

import { TableNoDataToDisplay } from './TableNoDataToDisplay';
import { TableSearchNoResult } from './TableSearchNoResult';

import type { TableNoDataToDisplayProps } from './TableNoDataToDisplay';
import type { Table as TableType } from '@tanstack/react-table';
import type { ReactNode } from 'react';

type Props<TData> = {
  table: TableType<TData>;
  toolbar: ReactNode;
  fetching: boolean;
  searchId: string;
  tableNoDataToDisplayUi: TableNoDataToDisplayProps;
  skeletons: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Table = <TData extends unknown>({
  skeletons,
  table,
  searchId,
  tableNoDataToDisplayUi,
  toolbar,
  fetching,
}: Props<TData>) => {
  const tableRows = table.getRowModel().rows;

  const outerTableContent = (() => {
    if (fetching) {
      return skeletons;
    }
    if (!tableRows.length && table.getState().columnFilters.length > 0) {
      return <TableSearchNoResult searchValue={(table.getColumn(searchId).getFilterValue() as string) || ''} />;
    }

    if (!tableRows.length) {
      return <TableNoDataToDisplay {...tableNoDataToDisplayUi} />;
    }

    return null;
  })();

  return (
    <div className="space-y-4">
      {toolbar}
      <TableWrapper>
        <TableComp>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const w = header.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : header.getSize();
                  return (
                    <TableHead style={{ width: w }} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {(() => {
              if (outerTableContent) {
                return null;
              }

              return tableRows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const canResize = cell.column.getCanResize();
                    const w = cell.column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : cell.column.getSize();
                    return (
                      <TableCell
                        style={{
                          width: w,
                          ...(canResize ? {} : { minWidth: w, maxWidth: w }),
                        }}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })()}
          </TableBody>
        </TableComp>
        {outerTableContent}
      </TableWrapper>
      <Pagination
        pageCount={table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        disabledGoFirstPage={!table.getCanPreviousPage()}
        disabledGoPreviousPage={!table.getCanPreviousPage()}
        disabledGoNextPage={!table.getCanNextPage()}
        disabledGoLastPage={!table.getCanNextPage()}
        onGoFirstPageClick={() => table.setPageIndex(0)}
        onGoPreviousPageClick={() => table.previousPage()}
        onGoNextPageClick={() => table.nextPage()}
        onGoLastPageClick={() => table.setPageIndex(table.getPageCount() - 1)}
      />
    </div>
  );
};
