import { cn } from '@askable/ui/lib/utils';
import { type PropsWithChildren } from 'react';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';

export const ErrorPage = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <MinimalPage bodyClasses={cn('my-6 md:m-auto h-auto md:max-w-md py-4 space-y-4', className)}>
      {children}
    </MinimalPage>
  );
};
