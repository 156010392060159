import { useFeatureFlags } from 'feature-flags';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Navigate, useParams, useLocation, matchPath } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';

import { PaymentOverDueBanner } from 'components/PaymentOverdueBanner/PaymentOverdueBanner';
import { HeaderAds } from 'components/common';
import { StudiesHeader } from 'containers/Studies/components/StudiesHeader';
import { Study } from 'containers/Studies/data/Study.query';
import { useConnectedClient } from 'context/ConnectedClientContext';
import Header from 'src/components/common/Header/view';
import { useFigmaAuth } from 'src/hooks/useFigmaAuth';

import { EmptyState } from './components/EmptyState';
import { SkeletonLoader } from './components/SkeletonLoader';

import type { UnmoderatedStudy } from 'generated/graphql';
import type { ReactNode } from 'react';
import type { FigmaAuthStatus } from 'src/hooks/useFigmaAuth';

import './Studies.css';

interface StudyContextProps {
  isSaving: boolean;
  isBuildDisabled: boolean;
  newBlockId: string | null;
  study: UnmoderatedStudy;
  studyId: string;
  setActiveSection: (section: 'nav' | 'editor' | 'preview') => void;
  setIsSaving: (isSaving: boolean) => void;
  setNewBlockId: (newBlockId: string | null) => void;
  figmaAuth: {
    authenticate: (cb?: { onTimeout?: () => void; onSuccess?: () => void }) => void;
    status: FigmaAuthStatus;
  };
}

const StudyContext = createContext<StudyContextProps>({} as any);

export const useStudyContext = () => {
  return useContext(StudyContext);
};

// Copied from `components/App/view.tsx`
// TODO: ideally we just render this in one place as the root router layout instead of multiple times
// manually, that way uplifting the component also becomes less involved
function Layout({ children }: { children: ReactNode }) {
  const { details: clientDetails } = useConnectedClient();

  return (
    <div className="flex h-full flex-col">
      {!clientDetails?.type?.researcher ? <PaymentOverDueBanner teamId={clientDetails?.ConnectedTeam?._id!} /> : null}
      <HeaderAds />
      <Header />

      {children}
    </div>
  );
}

export const StudiesContainer = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  const { studyId } = params;
  invariant(studyId, 'Study ID is required');

  // Get current section name eg. build
  const location = useLocation();
  const pattern = 'studies/:studyId/:section';
  const match = matchPath(pattern, location.pathname);
  const currentSection = match?.params?.section;

  const [isSaving, setIsSaving] = useState(false);
  const [newBlockId, setNewBlockId] = useState<null | string>(null);
  // TODO drive this from URL state
  const [activeSection, setActiveSection] = useState('nav');

  const [{ data, fetching }] = useQuery({
    query: Study,
    variables: { id: studyId },
    pause: !UNMODERATED_STUDY,
  });

  const figmaAuth = useFigmaAuth();

  const value: StudyContextProps = useMemo(
    () => ({
      isSaving,
      newBlockId,
      setActiveSection,
      setIsSaving,
      setNewBlockId,
      isBuildDisabled: data?.study?.status !== 'draft',
      study: data?.study as UnmoderatedStudy,
      studyId,
      figmaAuth: {
        authenticate: ({ onTimeout, onSuccess } = {}) => {
          if (figmaAuth.status !== 'connected') {
            figmaAuth.openAuthPopup({
              polling: {
                onTimeout,
                onAuthenticated: onSuccess,
              },
            });
          }
        },
        status: figmaAuth.status,
      },
    }),
    [isSaving, newBlockId, data?.study, studyId, figmaAuth],
  );

  /* Set the active section from the URL hash to navigate to it on mobile */
  useEffect(() => {
    setActiveSection(location.hash ? location.hash.replace('#', '') : 'nav');
  }, [location]);

  if (!UNMODERATED_STUDY) {
    return <Navigate to="/not-found" />;
  }

  if (fetching && !data?.study) {
    return (
      <Layout>
        <SkeletonLoader section={currentSection} />
      </Layout>
    );
  }

  if (!data?.study) {
    return (
      <Layout>
        <EmptyState title={t('sections.studies.studyNotFound')} />
      </Layout>
    );
  }

  return currentSection === 'preview' ? (
    <Outlet />
  ) : (
    <StudyContext.Provider value={value}>
      <Layout>
        <div
          className={`studies-container ${currentSection}-container h-full min-h-0 w-full flex-auto bg-secondary text-foreground
          show-${activeSection}`}
        >
          <StudiesHeader />
          <Outlet />
        </div>
      </Layout>
    </StudyContext.Provider>
  );
};
