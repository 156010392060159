import { graphql } from 'src/graphql';

export const Studies = graphql(`
  query Studies($first: Int!, $filter: StudiesFilter!) {
    studies(first: $first, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
      nodes {
        _id
        name
        type
        created
        updated
        status
      }
    }
  }
`);
