/* eslint-disable no-nested-ternary */
import { Button } from '@askable/ui/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { toast } from '@askable/ui/components/ui/sonner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { Figma, FigmaLogo } from '@askable/ui/components/unmod/icons';
import { cn } from '@askable/ui/lib/utils';
import { ErrorMessage } from '@hookform/error-message';
import { format } from 'date-fns';
import { RefreshCcw, Trash } from 'lucide-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FigmaFileDetails } from 'containers/Studies/BuildStudy/data/FigmaFileDetails.query';
import { SaveFigmaFile } from 'containers/Studies/BuildStudy/data/SaveFigmaFile.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InfoAlert } from 'containers/Studies/components/InfoAlert';
import { InfoPopover } from 'containers/Studies/components/InfoPopover';
import { parseFigmaEmbedUrl } from 'utils/string-utils';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

interface TextareaFieldProps {
  isDisabled?: boolean;
}

export const FigmaFileField = ({ isDisabled }: TextareaFieldProps) => {
  const { t } = useTranslation();
  const { figmaAuth } = useStudyContext();
  const { control, formState, setValue, watch } = useFormContext<BlockFormFields>();

  const [fileUrl, setFileUrl] = useState('');
  const [isImporting, setIsImporting] = useState(false);

  const fileId = watch('figma_prototype.file_id');
  const [figmaFileQuery] = useQuery({
    query: FigmaFileDetails,
    pause: !fileId,
    variables: { fileId: fileId! },
  });
  const figmaFile = figmaFileQuery.data?.figmaFile ?? null;

  const [saveFigmaFile, saveFigmaFileMutation] = useMutation(SaveFigmaFile);
  const isFileLoading = !!figmaFileQuery.fetching || !!saveFigmaFile.fetching;

  const handleImport = async () => {
    try {
      const saveFile = async () => {
        const details = parseFigmaEmbedUrl(fileUrl);
        if (!details.isValid) {
          throw new Error(details.error);
        }
        const { error } = await saveFigmaFileMutation({ fileId: details.fileId });
        if (error?.graphQLErrors?.length) {
          throw new Error(error?.graphQLErrors?.[0]?.message);
        }

        setValue('figma_prototype.file_id', details.fileId);
        setValue('figma_prototype.start_screen_id', details.startingPointNodeId || '');
        setValue('figma_prototype.goal_screen_id', '');
        setFileUrl('');

        setIsImporting(false);
      };

      setIsImporting(true);

      // TODO: ideally `figmaAuth.authenticate` can deal with this, so we don't have to handle
      // it every time with use it
      if (figmaAuth.status === 'connected') {
        await saveFile();
        return;
      }

      figmaAuth.authenticate({
        onTimeout: () => {
          toast.error(t('sections.studies.build.formValidation.figmaPrototypeFileId.timeout'));
        },
        onSuccess: async () => {
          await saveFile().catch((e: Error) => {
            // This is slightly awkward, cause the callback pattern breaks the top level catch :|
            toast.error(e?.message || 'Figma file import failed');
            setIsImporting(false);
          });
        },
      });
    } catch (e: any) {
      toast.error(e?.message || 'Figma file import failed');
      setIsImporting(false);
    }
  };

  return (
    <FormField
      control={control}
      name="figma_prototype.file_id"
      render={({ field }) => (
        <FormItem>
          <FormLabel hasErrorStyle={false} className=" -mb-1 flex w-fit items-center gap-1">
            {t('sections.studies.build.formFields.figmaPrototypeFileId.label')}
            <InfoPopover
              title={t('sections.studies.build.formFields.figmaPrototypeFileId.infoTitle')}
              description={t('sections.studies.build.formFields.figmaPrototypeFileId.infoDescription')}
              moreLink="https://help.askable.com/en/articles/10853483"
            />
          </FormLabel>
          <FormControl>
            <>
              <Input type="hidden" name={field.name} />

              {field.value ? (
                <>
                  <div
                    className={cn(
                      'border-1 flex items-center gap-3 rounded-xl border-border bg-background-subtle p-3',
                      { 'opacity-50': !field.value },
                    )}
                  >
                    <div className="aspect-square rounded-sm bg-darkest p-2">
                      <FigmaLogo size={20} />
                    </div>
                    <div className="flex w-0 grow basis-0 flex-col justify-between self-stretch">
                      <div className="truncate text-sm font-medium leading-tight">
                        {isFileLoading || !figmaFile ? (
                          <Skeleton className="w-full rounded">&nbsp;</Skeleton>
                        ) : (
                          figmaFile.name
                        )}
                      </div>
                      <div className="text-xs leading-tight text-foreground-subtle">
                        {isFileLoading || !figmaFile ? (
                          <Skeleton className="w-2/3 rounded">&nbsp;</Skeleton>
                        ) : figmaFile?.updated ? (
                          <>
                            {t('global.updated')} {format(figmaFile.updated, 'h:mmaaa, d MMM yyyy')}
                          </>
                        ) : null}
                      </div>
                    </div>
                    {!isDisabled ? (
                      <div className="flex gap-1">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              aria-label={t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRefresh')}
                              onClick={async () => {
                                const { error } = await saveFigmaFileMutation({ fileId: field.value! });
                                if (error) {
                                  // TODO(ASK-8722): Handle this error
                                  // TODO: ^ is this enough handling, or was this referring to something else
                                  toast.error(error.graphQLErrors?.[0]?.message);
                                  setValue(field.name, '');
                                }
                              }}
                              size="icon"
                              variant="ghost"
                              disabled={isFileLoading || isDisabled}
                            >
                              <RefreshCcw className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRefresh')}
                          </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              aria-label={t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRemove')}
                              onClick={async () => {
                                setValue(field.name, '');
                                setValue('figma_prototype', {
                                  file_id: '',
                                  start_screen_id: '',
                                  goal_screen_id: '',
                                });
                              }}
                              size="icon"
                              variant="ghost"
                              disabled={isDisabled}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRemove')}
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>

                  {figmaFile?.link_is_public === false ? (
                    // TODO(ASK-8724): handle this case
                    <div className="text-sm text-destructive">
                      {t('sections.studies.build.formValidation.figmaPrototypeFileId.notPublic')}
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="flex gap-1">
                  <Input
                    id="prototype-import"
                    placeholder="www.figma.com/link"
                    value={fileUrl}
                    disabled={isDisabled || isImporting}
                    onChange={(e) => setFileUrl(e.target.value)}
                  />
                  <Button
                    variant="secondary"
                    className="h-auto min-h-0"
                    isLoading={isImporting}
                    onClick={handleImport}
                    disabled={!fileUrl || isDisabled}
                  >
                    {t('sections.studies.import')}
                  </Button>
                </div>
              )}
            </>
          </FormControl>

          <ErrorMessage
            errors={formState.errors}
            name={field.name}
            render={({ message }) => (
              <FormErrorMessage message={message} prefix="sections.studies.build.formValidation" />
            )}
          />

          {!isDisabled && !field.value ? (
            <InfoAlert
              title={t('sections.studies.build.formFields.figmaPrototypeFileId.calloutTitle')}
              description={t('sections.studies.build.formFields.figmaPrototypeFileId.calloutDescription')}
              Icon={<Figma size={32} />}
              id="figma_info"
              moreLink="https://help.askable.com/en/articles/10853483"
            />
          ) : null}
        </FormItem>
      )}
    />
  );
};
