import { useQuery } from '@apollo/client';
import { cn } from '@askable/ui/lib/utils';
import _ from 'lodash';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box } from 'ui';

import { withAppContext } from 'components/HOCS';
import { PaymentOverDueBanner } from 'components/PaymentOverdueBanner/PaymentOverdueBanner';
import { Header, HeaderAds } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchSettings from 'data/queries/settings/fetchSettings';
import { globalVariables } from 'lib/globalVariables';
import { oauth } from 'lib/oauth';
import { settings } from 'lib/settings';
import { teamUtils } from 'lib/teams';

// Init the oAuth lib
oauth.init();

function App(props: any) {
  const { authState, details: clientDetails } = useConnectedClient();
  const intercom = useIntercom();
  const isManageBookingPage = _.includes(_.get(window, 'location.pathname'), '/bookings');

  useEffect(() => {
    startIntercomComponent();
  }, []);

  useEffect(() => {
    // Start Intercom component
    updateIntercomComponent();
  }, [props]);

  const settingsData = useQuery(fetchSettings);

  const getIntercomUserData = () => {
    let user = null;
    // It should only show to users who are logged in and have a company assigned already
    // It should also only show the component for production environments
    if (authState === 'authenticated') {
      if (_.has(clientDetails, 'email')) {
        user = {
          email: clientDetails?.email,
          name: `${clientDetails?.name?.firstname} ${clientDetails?.name?.lastname}`.replace(/^\s+|\s+$/g, ''),
          Company: _.get(clientDetails, 'team.name'),
          onPage: window.location.pathname,
          userId: clientDetails?.id,
          phone: _.get(clientDetails, 'phone'),
          customAttributes: {
            user_type: clientDetails?.type?.researcher ? 'researcher' : 'client',
          },
          hideDefaultLauncher: true,
        };
      }
    }

    return user;
  };

  const startIntercomComponent = () => {
    const { enableIntercom } = globalVariables.getEnvironmentVariables();
    if (enableIntercom) {
      const user = getIntercomUserData();
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ email: Maybe<string> | undefin... Remove this comment to see the full error message
      intercom.boot(user);
    }
  };

  const updateIntercomComponent = () => {
    const { enableIntercom } = globalVariables.getEnvironmentVariables();
    if (enableIntercom) {
      const user = getIntercomUserData();
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ email: Maybe<string> | undefin... Remove this comment to see the full error message
      intercom.update(user);
    }
  };

  if (_.size(_.get(settingsData, 'data.settings')) > 0) {
    settings.save(settingsData.data.settings);
  }

  // Store the teams' currency code and symbol in state
  teamUtils.save({
    tax: clientDetails?.ConnectedTeam?.settings?.billing?.tax ?? undefined,
    currency: clientDetails?.ConnectedTeam?.settings?.billing?.currency ?? undefined,
  });

  const isNewStudiesAndPlusProjectsTablePage =
    window.location.pathname === '/studies' || window.location.pathname === '/plus-projects';

  return (
    <Box
      position="relative"
      className={cn('appRoot', {
        'block-overscroll-behaviour': isManageBookingPage,
        'flex flex-col': isNewStudiesAndPlusProjectsTablePage,
      })}
    >
      {!clientDetails?.type?.researcher && <PaymentOverDueBanner teamId={clientDetails?.ConnectedTeam?._id!} />}
      {authState === 'authenticated' && <HeaderAds />}
      {authState === 'authenticated' && <Header />}
      {!_.get(settingsData, 'error.networkError') &&
        _.get(settingsData, 'data.settings', []).length > 0 &&
        props.children}
      <Outlet />
    </Box>
  );
}

export default withAppContext(App);
