import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { ImagePlus } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadImage, MAX_FILE_SIZE } from 'shared-utils/hooks/useUploadImage';
import { useMutation } from 'urql';

import { UploadArea } from 'generated/graphql';

import { UploadProfileImageMutation } from '../data/UploadProfileImage';

import type { ChangeEvent } from 'react';
import type { UploadMimeType } from 'shared-utils/hooks/useUploadImage';

const maxFileSizeInMb = MAX_FILE_SIZE / 1024 / 1024;

type UploadProfileImageProps = {
  onErrorMessage?: (message: string) => void;
  onLoading?: (loading: boolean) => void;
  onRemove: () => void;
  onUploadComplete: (url: string) => void;
  userId: string;
  userPicture?: string | null;
};
export const UploadProfileImage = ({
  userId,
  userPicture,
  onRemove,
  onErrorMessage,
  onLoading,
  onUploadComplete,
}: UploadProfileImageProps) => {
  const { t } = useTranslation();

  const getPresignedURL = async (mimeType: UploadMimeType) => {
    const response = await createUpload({ input: { area: UploadArea.Profile, area_id: userId, mime_type: mimeType } });
    if (response.error) {
      return Promise.reject(response.error);
    }

    const url = response.data?.createUpload.upload.url;
    const putFileUrl = response.data?.createUpload.put_file_url;

    if (!url || !putFileUrl) {
      return Promise.reject(t('sections.settings.profile.profilePicture.uploadError'));
    }
    return { url, putFileUrl };
  };

  const [, createUpload] = useMutation(UploadProfileImageMutation);
  const { upload, loading, error } = useUploadImage(getPresignedURL);
  const imageEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onErrorMessage && error) {
      onErrorMessage(error);
    }
  }, [error, onErrorMessage]);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading, onLoading]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const { url } = await upload(file);
    if (url) {
      onUploadComplete(url);
    }
  };

  return (
    <div className="flex gap-4">
      <Label
        htmlFor="__user_profile_picture"
        className="border-1 flex aspect-square h-16 w-16 shrink-0 items-center justify-center rounded-full md:h-32 md:w-32"
      >
        {userPicture ? (
          <img src={userPicture} alt="Profile for user" className="h-32 w-32 rounded-full object-cover" />
        ) : (
          <ImagePlus size={24} className="text-muted-foreground" />
        )}
        <Input
          ref={imageEl}
          id="__user_profile_picture"
          type="file"
          className="hidden h-32 w-32 rounded-full"
          onChange={handleUpload}
          accept="image/jpeg, image/png, image/jpg, image/webp"
        />
      </Label>
      <div className="flex flex-col items-start justify-center gap-2">
        <div className="font-semibold">{t('sections.settings.profile.profilePicture.title')}</div>
        <div className="flex gap-2">
          <Button variant="outline" onClick={() => imageEl.current?.click()}>
            {t('sections.settings.profile.profilePicture.upload')}
          </Button>

          {userPicture ? (
            <Button variant="ghost" onClick={onRemove}>
              {t('sections.settings.profile.profilePicture.remove')}
            </Button>
          ) : null}
        </div>
        <span className="text-xs text-muted-foreground">
          {t('sections.settings.profile.profilePicture.accepts')}: jpeg, png, webp.{' '}
          {t('sections.settings.profile.profilePicture.maxFileSize')}: {maxFileSizeInMb}MB
        </span>
      </div>
    </div>
  );
};
