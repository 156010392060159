import { BetaBadge } from '@askable/ui/components/beta-badge';
import { Button } from '@askable/ui/components/ui/button';
import classNames from 'classnames';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';

import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Card } from 'components/common';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, DEFAULT_QUOTA, BOOKING_SUBTYPE, REQUIRED_DEVICE_TYPE } from 'lib/constants';
import { findNextRoute } from 'utils/booking-utils';

import './styles/researchTypeStyles.scss';

function ResearchType(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(_.get(props, 'booking'));
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [bookingData, setBookingData] = useState(null);

  const oldType = booking?.type;
  const { AI_MODERATED } = useFeatureFlags(['AI_MODERATED'], { application: 'clients' });
  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/research-type`,
      stepId: 'project_setup_research_type',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  useEffect(() => {
    props.renderRightContent(null);
    props.renderRightAppPreview(null);
  }, [bookingState]);

  // Method to get the total number of participants based on the clients selection
  // It does take account the research type to find out what should be the default value
  const getTotalParticipants = (type: number, subtype: number) => {
    let totalParticipants = booking?.total_participants;
    if (type === BOOKING_TYPE.ONLINE && subtype === BOOKING_SUBTYPE.AI_MODERATED) {
      return DEFAULT_QUOTA.AI_MODERATED;
    }

    // Check what the user is changing from and what are they changing to. Based on that, make a decision on what the new quota should be
    switch (oldType) {
      case BOOKING_TYPE.IN_PERSON:
      case BOOKING_TYPE.LONGITUDINAL_STUDY:
      case BOOKING_TYPE.REMOTE:
        if (
          (oldType === BOOKING_TYPE.IN_PERSON && totalParticipants === DEFAULT_QUOTA.IN_PERSON) ||
          (oldType === BOOKING_TYPE.LONGITUDINAL_STUDY && totalParticipants === DEFAULT_QUOTA.LONGITUDINAL_STUDY) ||
          (oldType === BOOKING_TYPE.REMOTE && totalParticipants === DEFAULT_QUOTA.REMOTE)
        ) {
          // Changing to online task
          if (type === BOOKING_TYPE.ONLINE && subtype === BOOKING_SUBTYPE.ONLINE_TASK) {
            totalParticipants = DEFAULT_QUOTA.ONLINE_TASK;
            // Changing to survey
          } else if (type === BOOKING_TYPE.ONLINE && subtype === BOOKING_SUBTYPE.SURVEY) {
            totalParticipants = DEFAULT_QUOTA.SURVEY;
          } else if (type === BOOKING_TYPE.ONLINE && subtype === BOOKING_SUBTYPE.AI_MODERATED) {
            totalParticipants = DEFAULT_QUOTA.SURVEY;
          }
          // Changing to In Person
          if (type === BOOKING_TYPE.IN_PERSON) {
            totalParticipants = DEFAULT_QUOTA.IN_PERSON;
          }
          // Changing to Longitudinal
          if (type === BOOKING_TYPE.LONGITUDINAL_STUDY) {
            totalParticipants = DEFAULT_QUOTA.LONGITUDINAL_STUDY;
          }
          // Changing to Remote
          if (type === BOOKING_TYPE.REMOTE) {
            totalParticipants = DEFAULT_QUOTA.REMOTE;
          }
        }
        return totalParticipants;
      case BOOKING_TYPE.ONLINE:
        // Changing to in person, remote or longitudinal
        if (
          type === BOOKING_TYPE.IN_PERSON ||
          type === BOOKING_TYPE.LONGITUDINAL_STUDY ||
          type === BOOKING_TYPE.REMOTE
        ) {
          // Changing from online task
          if (
            booking?.config?.online_task?.type === BOOKING_SUBTYPE.ONLINE_TASK &&
            totalParticipants === DEFAULT_QUOTA.ONLINE_TASK
          ) {
            totalParticipants = type === BOOKING_TYPE.REMOTE ? DEFAULT_QUOTA.REMOTE : DEFAULT_QUOTA.IN_PERSON;
          }
          // Changing from survey
          if (
            booking?.config?.online_task?.type === BOOKING_SUBTYPE.SURVEY &&
            totalParticipants === DEFAULT_QUOTA.SURVEY
          ) {
            totalParticipants = type === BOOKING_TYPE.REMOTE ? DEFAULT_QUOTA.REMOTE : DEFAULT_QUOTA.IN_PERSON;
          }
        } else if (type === BOOKING_TYPE.ONLINE) {
          // Changing from online task to survey
          if (
            booking?.config?.online_task?.type === BOOKING_SUBTYPE.ONLINE_TASK &&
            subtype === BOOKING_SUBTYPE.SURVEY &&
            totalParticipants === DEFAULT_QUOTA.ONLINE_TASK
          ) {
            totalParticipants = DEFAULT_QUOTA.SURVEY;
            // Changing from survey to online task
          } else if (
            booking?.config?.online_task?.type === BOOKING_SUBTYPE.SURVEY &&
            subtype === BOOKING_SUBTYPE.ONLINE_TASK &&
            totalParticipants === DEFAULT_QUOTA.SURVEY
          ) {
            totalParticipants = DEFAULT_QUOTA.ONLINE_TASK;
          }
        }
        return totalParticipants;
      default:
        return totalParticipants;
    }
  };

  const onClickCard = (type: number, subtype: any = null) => {
    // Change number of participants
    const totalParticipants = getTotalParticipants(type, subtype);

    const newBookingSteps = {
      ...bookingSteps,
      project_setup: null,
      project_setup_research_type: 'seen',
      project_setup_tool: null,
      project_setup_quota: null,
      project_setup_session_duration: null,
      project_setup_study_duration: null,
      project_setup_task_duration: null,
      additional_info:
        oldType === type ||
        (oldType === BOOKING_TYPE.LONGITUDINAL_STUDY && type === BOOKING_TYPE.ONLINE) ||
        (oldType === BOOKING_TYPE.ONLINE && type === BOOKING_TYPE.LONGITUDINAL_STUDY)
          ? _.get(bookingSteps, 'additional_info')
          : null,
      additional_info_your_timezone:
        type !== BOOKING_TYPE.REMOTE ? null : _.get(bookingSteps, 'additional_info_your_timezone'),
      additional_info_session_location:
        type !== BOOKING_TYPE.IN_PERSON ? null : _.get(bookingSteps, 'additional_info_session_location'),
      additional_info_closing_date:
        type === BOOKING_TYPE.IN_PERSON || type === BOOKING_TYPE.REMOTE
          ? null
          : _.get(bookingSteps, 'additional_info_closing_date'),
      additional_info_devices:
        type === BOOKING_TYPE.IN_PERSON || type === BOOKING_TYPE.REMOTE
          ? null
          : _.get(bookingSteps, 'additional_info_devices'),
      additional_info_legal_privacy: _.get(bookingSteps, 'additional_info_legal_privacy'),
      link_to_task: null,
      link_to_task_survey: null,
      link_to_task_online_task: null,
      session_times:
        type === BOOKING_TYPE.ONLINE || type === BOOKING_TYPE.LONGITUDINAL_STUDY
          ? null
          : _.get(bookingSteps, 'session_times'),
      session_times_calendar:
        type === BOOKING_TYPE.ONLINE || type === BOOKING_TYPE.LONGITUDINAL_STUDY
          ? null
          : _.get(bookingSteps, 'session_times_calendar'),
      session_times_backup:
        type === BOOKING_TYPE.ONLINE || type === BOOKING_TYPE.LONGITUDINAL_STUDY
          ? null
          : _.get(bookingSteps, 'session_times_backup'),
      review_submit: null,
      review_submit_confirm_booking: null,
    };

    // Create new booking object
    const bookingObj = {
      type,
      total_participants: totalParticipants,
      config: {
        location: {
          country: _.get(booking, 'config.location.country') || '',
          name: '',
          street1: '',
          city: '',
          state: '',
          postal_code: '',
          longitude: null,
          latitude: null,
          level: '',
          region: '',
        },
        options: {
          review_submission: type !== BOOKING_TYPE.ONLINE, // It should set the cherry picking setting if it's an online survey
        },
        in_context: {
          location_type: 0,
          travel_time_limit: 0,
        },
        online_task: {
          required_device: [REQUIRED_DEVICE_TYPE.DESKTOP],
          required_features: [],
        },
        criteria: {
          locations: {
            states: [],
            bounds: [],
            countries: MULTIREGION_COUNTRIES ? [] : undefined,
          },
        },
        information: {
          special_instructions:
            type === BOOKING_TYPE.ONLINE ? '' : _.get(booking, 'config.information.special_instructions') || '',
        },
      },
      steps: newBookingSteps,
    };

    const bookingStateObj = {
      ...bookingState,
      type,
      total_participants: totalParticipants,
      config: {
        ...bookingState.config,
        location: {
          ...bookingState.config.location,
          ...bookingObj.config.location,
        },
        options: {
          ...bookingState.config.options,
          ...bookingObj.config.options,
        },
        in_context: {
          ...bookingState.config.in_context,
          ...bookingObj.config.in_context,
        },
        online_task: {
          ...bookingState.config.online_task,
          ...bookingObj.config.online_task,
        },
        criteria: {
          ...bookingState.config.criteria,
          locations: {
            ...bookingState.config.criteria.locations,
            ...bookingObj.config.criteria.locations,
          },
        },
        information: {
          ...bookingState.config.information,
          ...bookingObj.config.information,
        },
      },
      steps: newBookingSteps,
    };

    // Changed to Online Task or Survey
    if (type === BOOKING_TYPE.ONLINE) {
      // Creates a default session
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingObj.config.session = {
        type: 2,
        slot_min: totalParticipants,
        slot_max: totalParticipants,
        duration: subtype === BOOKING_SUBTYPE.SURVEY ? 5 : 10,
        time_limit: 120,
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingStateObj.config.session = bookingObj.config.session;

      // Sets subtype Online Task or Survey
      bookingObj.config.online_task = {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: null; tool: string; links: never[]; ... Remove this comment to see the full error message
        type: subtype,
        tool: 'optimalWorkshop',
        links: [],
        required_device: [],
      };
      bookingStateObj.config.online_task = bookingObj.config.online_task;

      // If it's a survey, it should set the participants category
      if (subtype === BOOKING_SUBTYPE.SURVEY) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message
        bookingObj.config.participant_category = 1;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
        bookingObj.config.online_task.tool = 'surveyMonkey';
        bookingObj.config.online_task.required_features = [];
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message
        bookingStateObj.config.participant_category = bookingObj.config.participant_category;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
        bookingStateObj.config.online_task.tool = bookingObj.config.online_task.tool;
        bookingStateObj.config.online_task.required_features = bookingObj.config.online_task.required_features;
      }

      // Clear booking sessions
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ type:... Remove this comment to see the full error message
      bookingObj.session = [];
      const predefinedCompletedBy = momentTimezone(momentTimezone().add(5, 'days')).endOf('day');
      bookingStateObj.session = [
        {
          start: predefinedCompletedBy.valueOf(),
          end: predefinedCompletedBy.valueOf(),
        },
      ];

      // Delete Remote and Longitudinal Study configs
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
      bookingObj.config.remote = {
        tool: null,
        askable_live: false,
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
      bookingStateObj.config.remote = bookingObj.config.remote;

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingObj.config.longitudinal_study = {
        extra: [],
        participant_workload: {
          frequency: null,
          measure: null,
          time: null,
        },
        period: {
          frequency: null,
          time: null,
        },
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingStateObj.config.longitudinal_study = bookingObj.config.longitudinal_study;

      if (subtype === BOOKING_SUBTYPE.AI_MODERATED) {
        // @ts-expect-error ts-migrate(2339) FIXME: Type 'number' is not assignable to type 'never'.
        bookingObj.config.online_task.required_features = [1, 2];
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
        bookingObj.config.online_task.tool = 'askableAiModerated';
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
        bookingObj.config.session.duration = 30;
      }
      // Changed to Remote or In Person
    } else if (type === BOOKING_TYPE.IN_PERSON || type === BOOKING_TYPE.REMOTE) {
      // If it's changing from online task to anything else it should default the session type to 1 on 1 interviews
      const sessionType =
        oldType === BOOKING_TYPE.ONLINE || oldType === BOOKING_TYPE.LONGITUDINAL_STUDY
          ? 1
          : _.get(booking, 'config.session.type');
      // If we're changing from to remote or in-person we should also check if the slot_max and slot_min are right
      const slotMax = sessionType === 1 ? 1 : totalParticipants;

      // Creates a default session
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingObj.config.session = {
        duration: 45,
        time_limit: 45,
        type: type === BOOKING_TYPE.REMOTE ? 1 : sessionType,
        slot_min: slotMax,
        slot_max: slotMax,
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingStateObj.config.session = bookingObj.config.session;

      // If the user is changing the booking type from Online task / Longitudinal Study to either In person or Remote it should restart the session times
      if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ type:... Remove this comment to see the full error message
        bookingObj.session = [];
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ type:... Remove this comment to see the full error message
        bookingStateObj.session = bookingObj.session;
      }

      // Changing from remote to in person
      if (bookingUtils.isRemote(booking) && type === BOOKING_TYPE.IN_PERSON) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
        bookingObj.config.remote = {
          tool: null,
          askable_live: false,
        };
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
        bookingStateObj.config.remote = bookingObj.config.remote;
      }

      // Changing to remote and 1 on 1
      if (type === BOOKING_TYPE.REMOTE && sessionType === 1) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
        bookingObj.config.remote = {
          tool: 'askableLive',
          askable_live: true,
        };
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
        bookingStateObj.config.remote = bookingObj.config.remote;
      }

      // Delete Online Task and Longitudinal Study configs
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'links' does not exist on type '{ require... Remove this comment to see the full error message
      bookingObj.config.online_task.links = [];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
      bookingObj.config.online_task.tool = null;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ required... Remove this comment to see the full error message
      bookingObj.config.online_task.type = null;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingObj.config.longitudinal_study = {
        extra: [],
        participant_workload: {
          frequency: null,
          measure: null,
          time: null,
        },
        period: {
          frequency: null,
          time: null,
        },
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'links' does not exist on type '{ require... Remove this comment to see the full error message
      bookingStateObj.config.online_task.links = bookingObj.config.online_task.links;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
      bookingStateObj.config.online_task.tool = bookingObj.config.online_task.tool;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ required... Remove this comment to see the full error message
      bookingStateObj.config.online_task.type = bookingObj.config.online_task.type;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingStateObj.config.longitudinal_study = bookingObj.config.longitudinal_study;

      // Changed to Longitudinal Study
    } else if (type === BOOKING_TYPE.LONGITUDINAL_STUDY) {
      // Creates a default session
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingObj.config.session = {
        type: 1,
        slot_min: totalParticipants,
        slot_max: totalParticipants,
        duration: 100, // 20 min per day for 5 days
        time_limit: 18000, // 5 days
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ locat... Remove this comment to see the full error message
      bookingStateObj.config.session = bookingObj.config.session;

      // Creates default configs
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingObj.config.longitudinal_study = {
        participant_workload: {
          time: 20,
          measure: 1,
          frequency: 1,
        },
        period: {
          time: 5,
          frequency: 1,
        },
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal_study' does not exist on ty... Remove this comment to see the full error message
      bookingStateObj.config.longitudinal_study = bookingObj.config.longitudinal_study;

      // Clear booking sessions
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ type:... Remove this comment to see the full error message
      bookingObj.session = [];
      const daysPeriod = bookingUtils.getLongitudinalStudyPeriod(bookingState) || 5;
      const predefinedCompletedBy = momentTimezone(momentTimezone().add(daysPeriod, 'days')).endOf('day');
      bookingStateObj.session = [
        {
          start: predefinedCompletedBy.valueOf(),
          end: predefinedCompletedBy.valueOf(),
        },
      ];

      // Delete Online Task and Remote configs
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'links' does not exist on type '{ require... Remove this comment to see the full error message
      bookingObj.config.online_task.links = [];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
      bookingObj.config.online_task.tool = null;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ required... Remove this comment to see the full error message
      bookingObj.config.online_task.type = null;
      bookingObj.config.online_task.required_device = [];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
      bookingObj.config.remote = {
        tool: null,
        askable_live: false,
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'links' does not exist on type '{ require... Remove this comment to see the full error message
      bookingStateObj.config.online_task.links = bookingObj.config.online_task.links;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tool' does not exist on type '{ required... Remove this comment to see the full error message
      bookingStateObj.config.online_task.tool = bookingObj.config.online_task.tool;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ required... Remove this comment to see the full error message
      bookingStateObj.config.online_task.type = bookingObj.config.online_task.type;
      bookingStateObj.config.online_task.required_device = bookingObj.config.online_task.required_device;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'remote' does not exist on type '{ locati... Remove this comment to see the full error message
      bookingStateObj.config.remote = bookingObj.config.remote;
    }

    const shouldUpdateBookingName =
      booking.name.includes(' Participants') && booking.name.includes(_.get(props, 'booking.team.name'));
    if (shouldUpdateBookingName) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{ type: an... Remove this comment to see the full error message
      bookingObj.name = `${_.get(props, 'booking.team.name')} - ${bookingObj.total_participants} Participants`;
      bookingStateObj.name = `${_.get(props, 'booking.team.name')} - ${bookingStateObj.total_participants} Participants`;
    }

    setBookingState(bookingStateObj);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: any; total_participants:... Remove this comment to see the full error message
    setBookingData(bookingObj);
  };

  const bookingType = {
    type: _.get(bookingState, 'type'),
    subtype: _.get(bookingState, 'config.online_task.type'),
  };

  const onClickNext = async () => {
    let redirectTo = findNextRoute(booking, 'Research method', 'Project Setup');
    if (bookingType.type === BOOKING_TYPE.IN_PERSON) redirectTo = `/booking-setup/${booking._id}/project-setup/quota`;

    props.history.push({
      pathname: redirectTo,
      booking: bookingData,
      bookingState,
    });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title researchTitle">
        How will you be conducting your research?
      </h1>
      <div className="researchTypeGroup">
        <p className="researchTypeLabel">Moderated</p>
        <div className="researchTypeTasks">
          <Card
            id="__videoCallCard"
            title="Video Call"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Askable Sessions, Zoom, Google Meet' }, { label: 'Microsoft Teams, etc' }]}
            image="/illustrations/type-videocall.svg"
            selected={bookingType.type === BOOKING_TYPE.REMOTE}
            onClickCard={() => onClickCard(BOOKING_TYPE.REMOTE)}
          />
          <Card
            id="__inPersonCard"
            title="In-person"
            className="researchTypeCard"
            vertical
            content={[{ label: '1 on 1 interviews, Focus groups' }, { label: 'In home visits' }]}
            image="/illustrations/type-inperson.svg"
            selected={bookingType.type === BOOKING_TYPE.IN_PERSON}
            onClickCard={() => onClickCard(BOOKING_TYPE.IN_PERSON)}
          />
        </div>
      </div>
      <div className="researchTypeGroup">
        <p className="researchTypeLabel">Unmoderated</p>
        <div className="researchTypeTasks">
          {(AI_MODERATED || bookingUtils.isAiModeration(booking)) && (
            <div
              id="__aiInterviewCard"
              className={classNames(
                'cardComponent',
                {
                  vertical: true,
                  selected:
                    bookingType.type === BOOKING_TYPE.ONLINE && bookingType.subtype === BOOKING_SUBTYPE.AI_MODERATED,
                },
                'newResearchTypeCard',
              )}
              onClick={() => onClickCard(BOOKING_TYPE.ONLINE, 3)}
            >
              <img className="nillustration" src="/illustrations/ai-moderated.svg" alt="Type illustration" />
              <div className="content">
                <div className="newHeader">
                  <h2>AI Moderated</h2>
                  <BetaBadge align="end" className="ml-3" title="New" />
                </div>
                <p>
                  Let an AI researcher conduct discovery interviews for you.{' '}
                  <a target="_blank" href="/" className="font-semibold">
                    Learn more
                  </a>
                </p>
                <ul>
                  <li>Provide your research objectives</li>
                  <li>Run continuous discovery on autopilot</li>
                  <li>Get automated insights to keep your team focused on opportunities</li>
                </ul>
              </div>
            </div>
          )}
          <Card
            id="__onlineTaskCard"
            title="Online Task"
            className="researchTypeCard"
            vertical
            content={[
              { label: 'Prototype testing, card sorts, tree' },
              { label: 'testing, think-out-loud tests, etc' },
            ]}
            image="/illustrations/type-onlinetask.svg"
            selected={bookingType.type === BOOKING_TYPE.ONLINE && bookingType.subtype === BOOKING_SUBTYPE.ONLINE_TASK}
            onClickCard={() => onClickCard(BOOKING_TYPE.ONLINE, BOOKING_SUBTYPE.ONLINE_TASK)}
          />
          <Card
            id="__surveyCard"
            title="Survey"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Survey Monkey, Typeform, Google Forms, Surveygizmo, etc' }]}
            image="/illustrations/type-survey.svg"
            selected={bookingType.type === BOOKING_TYPE.ONLINE && bookingType.subtype === BOOKING_SUBTYPE.SURVEY}
            onClickCard={() => onClickCard(BOOKING_TYPE.ONLINE, BOOKING_SUBTYPE.SURVEY)}
          />
          <Card
            id="__longitudinalStudyCard"
            title="Longitudinal study"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Participants do multiple tasks over a longer time' }]}
            image="/illustrations/type-longitudinalstudy.svg"
            selected={bookingType.type === BOOKING_TYPE.LONGITUDINAL_STUDY}
            onClickCard={() => onClickCard(BOOKING_TYPE.LONGITUDINAL_STUDY)}
          />
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ResearchType);
