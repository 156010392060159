import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { cn } from '@askable/ui/lib/utils';
import { Copy, Ellipsis, Trash } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation, CombinedError } from 'urql';

import { CreateStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/CreateStudyConfigTaskBlock.mutation';
import { DeleteStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/DeleteStudyConfigTaskBlock.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { PreviewLink } from 'containers/Studies/components/PreviewLink';
import { StudyTaskBlockType } from 'generated/graphql';

import { useStudyActiveBlockId } from '../hooks/useStudyActiveBlockId';

import type { StudyFigmaPrototypeTaskBlock } from 'generated/graphql';

export type BlockAction = 'duplicate' | 'delete';

interface BlockActionsProps {
  isVisible?: boolean;
  blockId: string;
}

const createTitleCopy = (title: string) => {
  // Check if title ends with (Copy N) and change it to (Copy N+1)
  // TODO: localise "Copy"
  const titleMatch = title.match(/\(Copy( \d+)?\)$/);
  if (!titleMatch) {
    return `${title} (Copy)`;
  }

  const number = titleMatch[1] ? parseInt(titleMatch[1]) + 1 : 2;
  return title.replace(/\(Copy( \d+)?\)$/, `(Copy ${number})`);
};

export const BlockActions = ({ isVisible = true, blockId }: BlockActionsProps) => {
  const { t } = useTranslation();
  const { study, setNewBlockId, isBuildDisabled } = useStudyContext();
  const { setActiveBlockId } = useStudyActiveBlockId();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [, createStudyConfigTaskBlock] = useMutation(CreateStudyConfigTaskBlock);
  const [, deleteStudyConfigTaskBlock] = useMutation(DeleteStudyConfigTaskBlock);

  const block = useMemo(
    () =>
      match(blockId)
        .with('welcome', () => null)
        .with('thank_you', () => null)
        .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === blockId)),
    [study, blockId],
  );

  const handleDuplicateBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }

      // Calculate new position for copied block to be added underneath the original block
      const blockIndex = study.draft_config?.task_blocks?.findIndex?.((b) => b?._id === blockId);
      const position =
        blockIndex !== undefined && blockIndex > -1 ? blockIndex + 1 : study.draft_config?.task_blocks?.length || 0;

      const newBlockId = await match(block.type)
        .with(StudyTaskBlockType.FigmaPrototype, async () => {
          const { error, data } = await createStudyConfigTaskBlock({
            input: {
              _id: study._id,
              position,
              task_block: {
                type: block.type,
                title: createTitleCopy(block.title || ''),
                instructions: block.instructions,
                is_recording_enabled: !!block.is_recording_enabled,
                // TODO: should be fixed in schema update
                // https://github.com/askableorg/services/pull/1107
                // https://github.com/askableorg/frontends/pull/1933
                figma_prototype: {
                  file_id: (block as StudyFigmaPrototypeTaskBlock).figma_prototype?.file_id ?? null,
                  start_screen_id: (block as StudyFigmaPrototypeTaskBlock).figma_prototype?.start_screen_id ?? null,
                  goal_screen_id: (block as StudyFigmaPrototypeTaskBlock).figma_prototype?.goal_screen_id ?? null,
                },
              },
            },
          });

          if (!data?.createStudyConfigTaskBlock) {
            throw new Error('Something went wrong');
          }

          if (error) {
            throw new Error(error.message);
          }

          const newBlocks = data.createStudyConfigTaskBlock.draft_config.task_blocks;
          return newBlocks[position]?._id;
        })
        .otherwise(() => {
          // Unsupported block type
        });

      if (newBlockId) {
        setActiveBlockId(newBlockId);
        // hacky way to not validate the new block straight away
        setTimeout(() => setNewBlockId(newBlockId), 0);
      }
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    }
  };

  const handleDeleteBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }

      const currentIndex = study.draft_config?.task_blocks?.findIndex((b) => b?._id === blockId);
      const previousBlockId = currentIndex > 0 ? study.draft_config?.task_blocks[currentIndex - 1]?._id : undefined;
      const { error } = await deleteStudyConfigTaskBlock({
        input: {
          _id: study._id,
          _task_block_id: blockId,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      setActiveBlockId(previousBlockId ?? 'welcome');
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    }
  };

  if (!block) {
    // TODO: error handling
    return null;
  }

  return (
    <>
      <DropdownMenu modal={false}>
        <div className={cn({ 'cursor-not-allowed': isBuildDisabled })}>
          <DropdownMenuTrigger asChild disabled={isBuildDisabled}>
            <Button
              size="icon"
              disabled={isBuildDisabled}
              variant="ghost"
              className={cn(
                'transition-opacity group-hover:opacity-100 group-focus:opacity-100 aria-[expanded=true]:opacity-100 @[10rem]:flex',
                { 'lg:opacity-0': !isVisible },
              )}
            >
              <Ellipsis className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
        </div>

        <DropdownMenuContent className="z-[2101]" align="end">
          <DropdownMenuItem className="lg:!hidden" asChild>
            <PreviewLink />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDuplicateBlock}>
            <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation();

              if (block.title === '') {
                handleDeleteBlock();
              } else {
                setIsOpenConfirm(true);
              }
            }}
          >
            <Trash className="h-4 w-4" /> {t('sections.studies.actions.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={isOpenConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.deleteBlockConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('sections.studies.actions.deleteBlockConfirmDescription')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirm(false)} className="w-full sm:w-fit">
              {t('global.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenConfirm(false);
                handleDeleteBlock();
              }}
              className="w-full sm:w-fit"
            >
              {t('global.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
