import { toast } from '@askable/ui/components/ui/sonner';
import { useFeatureFlags } from 'feature-flags';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowRightIcon, Box, Button, Flex, Heading, Image, Link, Text } from 'ui';
import { useMutation } from 'urql';

import { CreateStudy } from 'containers/Studies/data/CreateStudy.mutation';

import './styles/createBookingStyles.scss';

function RecruitmentType() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  const [{ fetching }, createStudy] = useMutation(CreateStudy);

  return (
    <Flex direction="column" maxWidth="3xl" marginX="auto" paddingY={10}>
      <Heading size="lg" mb={10} textAlign="center" color="gray.800" fontWeight="semibold">
        Start a new research study
      </Heading>
      <Flex bgColor="blue.100" p={12} borderRadius="xl">
        <Box pr={10}>
          <Image src="/askable-plus-logo-complete-reverse.svg" mb={6} width="150px" />
          <Heading color="blue.800" fontWeight="semibold" size="md" mb={2}>
            Need us to run your research?
          </Heading>
          <Text maxW="xl" color="blue.800" pr={4}>
            Brief us in, and we’ll conduct unbiased research and deliver an actionable insights report so you can move
            forward with confidence.
          </Text>
        </Box>
        <Button colorScheme="blue" onClick={() => navigate('/askable-plus/create')} alignSelf="center">
          <RouterLink to="/askable-plus/create">Let’s go</RouterLink>
        </Button>
      </Flex>
      <div className="flex flex-col gap-4 px-12 py-6">
        <Link fontSize="xl">
          <RouterLink to="/booking-setup/create">
            I've got this, I just need the right participants <ArrowRightIcon ml={2} />
          </RouterLink>
        </Link>
        {UNMODERATED_STUDY && (
          <Button
            variant="link"
            alignSelf="flex-start"
            color="blue.500"
            fontSize="xl"
            isLoading={fetching}
            onClick={async () => {
              const { data, error } = await createStudy({
                input: {
                  type: 'unmoderated',
                  name: t('sections.studies.newStudy'),
                  welcome_block: {
                    title: t('sections.studies.build.blocks.welcome.defaultTitle'),
                    instructions: t('sections.studies.build.blocks.welcome.defaultDescription'),
                    is_recording_enabled: false,
                  },
                  thank_you_block: {
                    title: t('sections.studies.build.blocks.thank_you.defaultTitle'),
                    instructions: t('sections.studies.build.blocks.thank_you.defaultDescription'),
                    is_recording_enabled: false,
                  },
                },
              });

              if (error || !data?.createStudy) {
                toast.error(error?.graphQLErrors[0].message ?? 'Error creating study');
                return;
              }
              navigate(`/studies/${data.createStudy._id}/build`);
            }}
          >
            Create Unmoderated Study <ArrowRightIcon ml={2} />
          </Button>
        )}
      </div>
    </Flex>
  );
}

export default RecruitmentType;
