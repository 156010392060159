import _ from 'lodash';

import { askablePlusUtils } from 'lib/askablePlus';

import type { Project, ProjectAskablePlusBriefSteps } from 'generated/graphql';

const stepperMenuUtils = {
  projectSetupSubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
    const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
    const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
    const hasCompetitiveAnalysisType = askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief);
    const isContinuousDiscoveryType = askablePlusUtils.isContinuousDiscoverType(askablePlusBrief);
    const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);
    const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Project title',
      id: 'project_setup_project_title',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/project-title`,
      completed:
        !askablePlusBrief || stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_project_title'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_project_title'),
      validateStep: stepperMenuUtils.validateProjectTitle(askablePlusBrief),
    });
    subSteps.push({
      index: 10,
      label: 'Project brief',
      id: 'project_setup_project_brief',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/project-brief`,
      completed:
        !askablePlusBrief || stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_project_brief'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_project_brief'),
      validateStep: stepperMenuUtils.validateProjectBrief(askablePlusBrief),
    });
    subSteps.push({
      index: 15,
      label: 'Research type',
      id: 'project_setup_research_type',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/research-type`,
      completed:
        !askablePlusBrief || stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_research_type'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_research_type'),
      validateStep: stepperMenuUtils.validateResearchType(askablePlusBrief),
    });
    subSteps.push({
      index: 20,
      label: 'Deliverables',
      id: 'project_setup_deliverables',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/deliverables`,
      completed:
        !askablePlusBrief || stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_deliverables'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_deliverables'),
      validateStep: stepperMenuUtils.validateDeliverables(askablePlusBrief),
    });
    if (!isContinuousDiscoveryType) {
      subSteps.push({
        index: 25,
        label: 'Due date',
        id: 'project_setup_due_date',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/due-date`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_due_date'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_due_date'),
        validateStep: stepperMenuUtils.validateDueDate(askablePlusBrief),
      });
    }
    if (totalResearchTypesSelected === 1 && !isContinuousDiscoveryType) {
      subSteps.push({
        index: 30,
        label: 'Quota',
        id: 'project_setup_quota',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/quota`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_quota'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_quota'),
        validateStep: stepperMenuUtils.validateGeneralQuota(askablePlusBrief),
      });
      if (!hasCompetitiveAnalysisType) {
        let menuLabel = 'Session duration';
        if (hasUsabilityTestingType) menuLabel = 'Session duration';
        else if (hasSurveyType) menuLabel = 'Survey duration';
        else if (hasLongitudinalStudyType) menuLabel = 'Study duration';

        subSteps.push({
          index: 35,
          label: menuLabel,
          id: 'project_setup_session_duration',
          link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/session-duration`,
          completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_session_duration'),
          error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_session_duration'),
          validateStep: stepperMenuUtils.validateGeneralSessionDuration(askablePlusBrief),
        });
      }
      if (hasDiscoveryInterviewType || hasUsabilityTestingType) {
        subSteps.push({
          index: 40,
          label: 'Meeting format',
          id: 'project_setup_meeting_format',
          link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/meeting-format`,
          completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_meeting_format'),
          error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_meeting_format'),
          validateStep: stepperMenuUtils.validateGeneralMeetingFormat(askablePlusBrief),
        });
      }
      if (hasDiscoveryInterviewType) {
        subSteps.push({
          index: 45,
          label: 'Session format',
          id: 'project_setup_session_format',
          link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/session-format`,
          completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_session_format'),
          error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_session_format'),
          validateStep: stepperMenuUtils.validateGeneralSessionFormat(askablePlusBrief),
        });
      }
    }
    if (isContinuousDiscoveryType) {
      subSteps.push({
        index: 50,
        label: 'Frequency & duration',
        id: 'project_setup_frequency_duration',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/project-setup/frequency-duration`,
        completed:
          !askablePlusBrief ||
          stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'project_setup_frequency_duration'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'project_setup_frequency_duration'),
        validateStep: stepperMenuUtils.validateDeliverables(askablePlusBrief),
      });
    }
    return subSteps;
  },
  discoveryInterviewSubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Quota',
      id: 'discovery_interview_quota',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/discovery-interview/quota`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'discovery_interview_quota'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'discovery_interview_quota'),
      validateStep: stepperMenuUtils.validateQuota(askablePlusBrief, 'discovery'),
    });
    subSteps.push({
      index: 10,
      label: 'Session duration',
      id: 'discovery_interview_session_duration',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/discovery-interview/session-duration`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'discovery_interview_session_duration'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'discovery_interview_session_duration'),
      validateStep: stepperMenuUtils.validateSessionDuration(askablePlusBrief, 'discovery'),
    });
    subSteps.push({
      index: 15,
      label: 'Meeting format',
      id: 'discovery_interview_meeting_format',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/discovery-interview/meeting-format`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'discovery_interview_meeting_format'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'discovery_interview_meeting_format'),
      validateStep: stepperMenuUtils.validateMeetingFormat(askablePlusBrief, 'discovery'),
    });
    subSteps.push({
      index: 20,
      label: 'Session format',
      id: 'discovery_interview_session_format',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/discovery-interview/session-format`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'discovery_interview_session_format'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'discovery_interview_session_format'),
      validateStep: stepperMenuUtils.validateSessionFormat(askablePlusBrief, 'discovery'),
    });
    return subSteps;
  },
  usabilityTestingSubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Quota',
      id: 'usability_testing_quota',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/usability-testing/quota`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'usability_testing_quota'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'usability_testing_quota'),
      validateStep: stepperMenuUtils.validateQuota(askablePlusBrief, 'usability'),
    });
    subSteps.push({
      index: 10,
      label: 'Session duration',
      id: 'usability_testing_session_duration',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/usability-testing/session-duration`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'usability_testing_session_duration'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'usability_testing_session_duration'),
      validateStep: stepperMenuUtils.validateSessionDuration(askablePlusBrief, 'usability'),
    });
    subSteps.push({
      index: 15,
      label: 'Meeting format',
      id: 'usability_testing_meeting_format',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/usability-testing/meeting-format`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'usability_testing_meeting_format'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'usability_testing_meeting_format'),
      validateStep: stepperMenuUtils.validateMeetingFormat(askablePlusBrief, 'usability'),
    });
    return subSteps;
  },
  surveySubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Quota',
      id: 'survey_quota',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/survey/quota`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'survey_quota'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'survey_quota'),
      validateStep: stepperMenuUtils.validateQuota(askablePlusBrief, 'survey'),
    });
    subSteps.push({
      index: 10,
      label: 'Session duration',
      id: 'survey_duration',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/survey/survey-duration`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'survey_duration'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'survey_duration'),
      validateStep: stepperMenuUtils.validateSessionDuration(askablePlusBrief, 'survey'),
    });
    return subSteps;
  },
  longitudinalStudySubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Quota',
      id: 'longitudinal_study_quota',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/longitudinal-study/quota`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'longitudinal_study_quota'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'longitudinal_study_quota'),
      validateStep: stepperMenuUtils.validateQuota(askablePlusBrief, 'longitudinal'),
    });
    subSteps.push({
      index: 10,
      label: 'Session duration',
      id: 'longitudinal_study_duration',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/longitudinal-study/study-duration`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'longitudinal_study_duration'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'longitudinal_study_duration'),
      validateStep: stepperMenuUtils.validateStudyDuration(askablePlusBrief),
    });
    return subSteps;
  },
  competitiveAnalysisSubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Quota',
      id: 'competitive_analysis_quota',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/competitive-analysis/quota`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'competitive_analysis_quota'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'competitive_analysis_quota'),
      validateStep: stepperMenuUtils.validateQuota(askablePlusBrief, 'competitive_analysis'),
    });
    return subSteps;
  },
  audienceSubSteps(
    askablePlusBrief: Project,
    askablePlusBriefSteps: ProjectAskablePlusBriefSteps,
    isMultiRegion: boolean,
  ) {
    const subSteps = [];
    if (askablePlusBrief?.askable_plus?.audience?.booking_config?.criteria?.exclude_participants_from_time) {
      subSteps.push({
        index: 10,
        label: 'Demographic filters',
        id: 'audience_demographic_filters',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/demographic-filters`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_demographic_filters'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_demographic_filters'),
        validateStep: stepperMenuUtils.validateDemographicFiltersCustomScreener(askablePlusBrief),
      });
      subSteps.push({
        index: 15,
        label: 'Custom screener',
        id: 'audience_custom_screener',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/custom-screener`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_custom_screener'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_custom_screener'),
        validateStep: stepperMenuUtils.validateDemographicFiltersCustomScreener(askablePlusBrief),
      });
    } else {
      subSteps.push({
        index: 5,
        label: 'Participant criteria',
        id: 'audience_participant_criteria',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/participant-criteria`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_participant_criteria'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_participant_criteria'),
        validateStep: stepperMenuUtils.validateParticipantCriteria(askablePlusBrief),
      });
    }
    subSteps.push({
      index: 20,
      label: 'Incentives',
      id: 'audience_incentives',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/incentives`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_incentives'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_incentives'),
      validateStep: stepperMenuUtils.validateIncentives(askablePlusBrief),
    });
    if (isMultiRegion) {
      subSteps.push({
        index: 25,
        label: 'Participant locations',
        id: 'audience_participant_locations',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/participant-locations`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_participant_locations'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_participant_locations'),
        validateStep: stepperMenuUtils.validateParticipantLocationsMultiRegion(askablePlusBrief),
      });
    } else {
      subSteps.push({
        index: 25,
        label: 'Participant locations',
        id: 'audience_participant_locations',
        link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/audience/participant-locations`,
        completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'audience_participant_locations'),
        error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'audience_participant_locations'),
        validateStep: stepperMenuUtils.validateParticipantLocations(askablePlusBrief),
      });
    }

    return subSteps;
  },
  additionalInfoSubSteps(askablePlusBrief: any, askablePlusBriefSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Moderation guide',
      id: 'additional_info_moderation_guide',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/additional-info/moderation-guide`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'additional_info_moderation_guide'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'additional_info_moderation_guide'),
      validateStep: stepperMenuUtils.validateModerationGuide(askablePlusBrief),
    });
    subSteps.push({
      index: 10,
      label: 'Your timezone',
      id: 'additional_info_your_timezone',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/additional-info/your-timezone`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'additional_info_your_timezone'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'additional_info_your_timezone'),
      validateStep: stepperMenuUtils.validateYourTimezone(askablePlusBrief),
    });
    subSteps.push({
      index: 15,
      label: 'Legal & Privacy',
      id: 'additional_info_legal_privacy',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/additional-info/legal-privacy`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'additional_info_legal_privacy'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'additional_info_legal_privacy'),
      validateStep: stepperMenuUtils.validateLegalPrivacy(askablePlusBrief),
    });
    subSteps.push({
      index: 20,
      label: 'Link to assets',
      id: 'additional_info_link_to_assets',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/additional-info/link-to-assets`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'additional_info_link_to_assets'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'additional_info_link_to_assets'),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      validateStep: stepperMenuUtils.validateLinkToAssets(askablePlusBrief),
    });
    subSteps.push({
      index: 25,
      label: 'Additional notes',
      id: 'additional_info_additional_notes',
      link: `/askable-plus/${_.get(askablePlusBrief, '_id', ':projectID')}/additional-info/additional-notes`,
      completed: stepperMenuUtils.isStepCompleted(askablePlusBriefSteps, 'additional_info_additional_notes'),
      error: stepperMenuUtils.stepHasErrors(askablePlusBriefSteps, 'additional_info_additional_notes'),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      validateStep: stepperMenuUtils.validateAdditionalNotes(askablePlusBrief),
    });
    return subSteps;
  },
  isStepCompleted(askablePlusBriefSteps: any, step: any) {
    if (!askablePlusBriefSteps || askablePlusBriefSteps === null) return false;
    return (
      _.get(askablePlusBriefSteps, `[${step}]`) === 'seen' || _.get(askablePlusBriefSteps, `[${step}]`) === 'error'
    );
  },
  stepHasErrors(askablePlusBriefSteps: any, step: any) {
    return (
      (_.get(askablePlusBriefSteps, `[${step}]`) && _.get(askablePlusBriefSteps, `[${step}]`) === 'error') || false
    );
  },
  allStepsSeen(askablePlusBriefSteps: any, stepsGroup: any) {
    const stepsNotViewed = _.filter(stepsGroup, (item: any) => !_.get(askablePlusBriefSteps, `[${item.id}]`));
    return !(_.size(stepsNotViewed) > 0);
  },
  validateConfirmAskablePlusBrief({
    askablePlusBrief,
    validatedSteps,
    projectSetupSteps,
    discoveryInterviewSteps,
    usabilityTestingSteps,
    surveySteps,
    longitudinalStudySteps,
    competitiveAnalysisSteps,
    audienceSteps,
    additionalInfoSteps,
  }: any) {
    const allProjectSetupStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, projectSetupSteps);
    const allAudienceStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, audienceSteps);
    const allAdditionalInfoStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, additionalInfoSteps);

    // Validates all seen Project Setup steps
    let projectSetupStepError = false;
    _.map(projectSetupSteps, (item: any) => {
      const stepError = item.validateStep;
      const keyObjectiveError = stepperMenuUtils.validateKeyObjective(askablePlusBrief);
      const projectContextError = stepperMenuUtils.validateProjectContext(askablePlusBrief);
      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'project_setup_project_brief') {
          validatedSteps.project_setup_key_objective = keyObjectiveError ? 'error' : 'seen';
          validatedSteps.project_setup_project_context = projectContextError ? 'error' : 'seen';
          validatedSteps.project_setup_project_brief = keyObjectiveError || projectContextError ? 'error' : 'seen';
        } else {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        }
      } else if (item.id === 'project_setup_project_brief') {
        if (keyObjectiveError) validatedSteps.project_setup_key_objective = 'error';
        if (projectContextError) validatedSteps.project_setup_project_context = 'error';
        if (keyObjectiveError || projectContextError) validatedSteps.project_setup_project_brief = 'error';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError) projectSetupStepError = true;
    });

    if (allProjectSetupStepsSeen) {
      validatedSteps.project_setup = projectSetupStepError ? 'error' : 'seen';
    } else {
      validatedSteps.project_setup = projectSetupStepError ? 'error' : null;
    }

    // Validates all seen Discovery Interview steps
    if (discoveryInterviewSteps) {
      const allDiscoveryInterviewStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, discoveryInterviewSteps);

      let discoveryInterviewStepError = false;
      _.map(discoveryInterviewSteps, (item: any) => {
        const stepError = item.validateStep;
        if (_.get(validatedSteps, `[${item.id}]`)) {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps[`${item.id}`] = 'error';
        }
        if (stepError) discoveryInterviewStepError = true;
      });

      if (allDiscoveryInterviewStepsSeen) {
        validatedSteps.discovery_interview = discoveryInterviewStepError ? 'error' : 'seen';
      } else {
        validatedSteps.discovery_interview = discoveryInterviewStepError ? 'error' : null;
      }
    }

    // Validates all seen Usability Testing steps
    if (usabilityTestingSteps) {
      const allUsabilityTestingStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, usabilityTestingSteps);

      let usabilityTestingStepError = false;
      _.map(usabilityTestingSteps, (item: any) => {
        const stepError = item.validateStep;
        if (_.get(validatedSteps, `[${item.id}]`)) {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps[`${item.id}`] = 'error';
        }
        if (stepError) usabilityTestingStepError = true;
      });

      if (allUsabilityTestingStepsSeen) {
        validatedSteps.usability_testing = usabilityTestingStepError ? 'error' : 'seen';
      } else {
        validatedSteps.usability_testing = usabilityTestingStepError ? 'error' : null;
      }
    }

    // Validates all seen Survey steps
    if (surveySteps) {
      const allSurveyStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, surveySteps);

      let surveyStepError = false;
      _.map(surveySteps, (item: any) => {
        const stepError = item.validateStep;
        if (_.get(validatedSteps, `[${item.id}]`)) {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps[`${item.id}`] = 'error';
        }
        if (stepError) surveyStepError = true;
      });

      if (allSurveyStepsSeen) {
        validatedSteps.survey = surveyStepError ? 'error' : 'seen';
      } else {
        validatedSteps.survey = surveyStepError ? 'error' : null;
      }
    }

    // Validates all seen Longitudinal Study steps
    if (longitudinalStudySteps) {
      const allLongitudinalStudyStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, longitudinalStudySteps);

      let longitudinalStudyStepError = false;
      _.map(longitudinalStudySteps, (item: any) => {
        const stepError = item.validateStep;
        if (_.get(validatedSteps, `[${item.id}]`)) {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps[`${item.id}`] = 'error';
        }
        if (stepError) longitudinalStudyStepError = true;
      });

      if (allLongitudinalStudyStepsSeen) {
        validatedSteps.longitudinal_study = longitudinalStudyStepError ? 'error' : 'seen';
      } else {
        validatedSteps.longitudinal_study = longitudinalStudyStepError ? 'error' : null;
      }
    }

    // Validates all seen Competitive Analysis steps
    if (competitiveAnalysisSteps) {
      const allCompetitiveAnalysisStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, competitiveAnalysisSteps);

      let competitiveAnalysisStepError = false;
      _.map(competitiveAnalysisSteps, (item: any) => {
        const stepError = item.validateStep;
        if (_.get(validatedSteps, `[${item.id}]`)) {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps[`${item.id}`] = 'error';
        }
        if (stepError) competitiveAnalysisStepError = true;
      });

      if (allCompetitiveAnalysisStepsSeen) {
        validatedSteps.competitive_analysis = competitiveAnalysisStepError ? 'error' : 'seen';
      } else {
        validatedSteps.competitive_analysis = competitiveAnalysisStepError ? 'error' : null;
      }
    }

    // Validates all seen Audience Info steps
    let audienceStepError = false;
    _.map(audienceSteps, (item: any) => {
      const stepError = item.validateStep;
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError) audienceStepError = true;
    });

    if (allAudienceStepsSeen) {
      validatedSteps.audience = audienceStepError ? 'error' : 'seen';
    } else {
      validatedSteps.audience = audienceStepError ? 'error' : null;
    }

    // Validates all seen Additional Info steps
    let additionalInfoStepError = false;
    _.map(additionalInfoSteps, (item: any) => {
      const stepError = item.validateStep;
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError) additionalInfoStepError = true;
    });

    if (allAdditionalInfoStepsSeen) {
      validatedSteps.additional_info = additionalInfoStepError ? 'error' : 'seen';
    } else {
      validatedSteps.additional_info = additionalInfoStepError ? 'error' : null;
    }

    return validatedSteps;
  },
  validateAskablePlusBriefSteps(askablePlusBrief: any, askablePlusBriefSteps: any, page: any, isMultiRegion: boolean) {
    if (!page) return null;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const projectSetupSteps = stepperMenuUtils.projectSetupSubSteps(askablePlusBrief);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const discoveryInterviewSteps = stepperMenuUtils.discoveryInterviewSubSteps(askablePlusBrief);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const usabilityTestingSteps = stepperMenuUtils.usabilityTestingSubSteps(askablePlusBrief);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const surveySteps = stepperMenuUtils.surveySubSteps(askablePlusBrief);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const longitudinalStudySteps = stepperMenuUtils.longitudinalStudySubSteps(askablePlusBrief);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const competitiveAnalysisSteps = stepperMenuUtils.competitiveAnalysisSubSteps(askablePlusBrief);
    const audienceSteps = stepperMenuUtils.audienceSubSteps(askablePlusBrief, askablePlusBriefSteps, isMultiRegion);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const additionalInfoSteps = stepperMenuUtils.additionalInfoSubSteps(askablePlusBrief);

    let validatedSteps = _.clone(askablePlusBriefSteps || {});

    // Test if you are navigating inside Research Setup steps
    const projectSetupStepSelected = _.find(projectSetupSteps, (item: any) => item.link.includes(page));
    if (projectSetupStepSelected) {
      // Validates Project Setup steps
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
      if (projectSetupStepSelected.id === 'additional_info_description') {
        const keyObjectiveError = stepperMenuUtils.validateKeyObjective(askablePlusBrief);
        const projectContextError = stepperMenuUtils.validateProjectContext(askablePlusBrief);
        validatedSteps.project_setup_key_objective = keyObjectiveError ? 'error' : 'seen';
        validatedSteps.project_setup_project_context = projectContextError ? 'error' : 'seen';
        validatedSteps.project_setup_project_brief = keyObjectiveError || projectContextError ? 'error' : 'seen';
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        validatedSteps[projectSetupStepSelected.id] = projectSetupStepSelected.validateStep ? 'error' : 'seen';
      }
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);
      return validatedSteps;
    }

    // Test if you are navigating inside Discovery Interview steps
    const discoveryInterviewSelected = _.find(discoveryInterviewSteps, (item: any) => item.link.includes(page));
    if (discoveryInterviewSelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Usability Testing steps
    const usabilityTestingSelected = _.find(usabilityTestingSteps, (item: any) => item.link.includes(page));
    if (usabilityTestingSelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Survey steps
    const surveySelected = _.find(surveySteps, (item: any) => item.link.includes(page));
    if (surveySelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);

      // Validates Survey steps
      validatedSteps = stepperMenuUtils.validateSurvey(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Longitudinal Study steps
    const longitudinalStudySelected = _.find(longitudinalStudySteps, (item: any) => item.link.includes(page));
    if (longitudinalStudySelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);

      // Validates Survey steps
      validatedSteps = stepperMenuUtils.validateSurvey(askablePlusBrief, validatedSteps);

      // Validates Longitudinal Study steps
      validatedSteps = stepperMenuUtils.validateLongitudinalStudy(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Competitive Analysis steps
    const competitiveAnalysisSelected = _.find(competitiveAnalysisSteps, (item: any) => item.link.includes(page));
    if (competitiveAnalysisSelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);

      // Validates Survey steps
      validatedSteps = stepperMenuUtils.validateSurvey(askablePlusBrief, validatedSteps);

      // Validates Longitudinal Study steps
      validatedSteps = stepperMenuUtils.validateLongitudinalStudy(askablePlusBrief, validatedSteps);

      // Validates Competitive Analysis steps
      validatedSteps = stepperMenuUtils.validateCompetitiveAnalysis(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Audience steps
    const audienceSelected = _.find(audienceSteps, (item: any) => item.link.includes(page));
    if (audienceSelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);

      // Validates Survey steps
      validatedSteps = stepperMenuUtils.validateSurvey(askablePlusBrief, validatedSteps);

      // Validates Longitudinal Study steps
      validatedSteps = stepperMenuUtils.validateLongitudinalStudy(askablePlusBrief, validatedSteps);

      // Validates Competitive Analysis steps
      validatedSteps = stepperMenuUtils.validateCompetitiveAnalysis(askablePlusBrief, validatedSteps);

      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(askablePlusBrief, validatedSteps, isMultiRegion);
    }

    // Test if you are navigating inside Additional Info steps
    const additionalInfoStepSelected = _.find(additionalInfoSteps, (item: any) => item.link.includes(page));
    if (additionalInfoStepSelected) {
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(askablePlusBrief, validatedSteps);

      // Validates Discovery Interview steps
      validatedSteps = stepperMenuUtils.validateDiscoveryInterview(askablePlusBrief, validatedSteps);

      // Validates Usability Testing steps
      validatedSteps = stepperMenuUtils.validateUsabilityTesting(askablePlusBrief, validatedSteps);

      // Validates Survey steps
      validatedSteps = stepperMenuUtils.validateSurvey(askablePlusBrief, validatedSteps);

      // Validates Longitudinal Study steps
      validatedSteps = stepperMenuUtils.validateLongitudinalStudy(askablePlusBrief, validatedSteps);

      // Validates Competitive Analysis steps
      validatedSteps = stepperMenuUtils.validateCompetitiveAnalysis(askablePlusBrief, validatedSteps);

      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(askablePlusBrief, validatedSteps, isMultiRegion);

      // Validates Additional Info steps
      validatedSteps = stepperMenuUtils.validateAdditionalInfo(askablePlusBrief, validatedSteps);
    }

    // Test if you are navigating inside Review & Submit step
    if (page.includes('/review-submit/confirm-brief')) {
      validatedSteps = stepperMenuUtils.validateConfirmAskablePlusBrief({
        askablePlusBrief,
        validatedSteps,
        projectSetupSteps,
        discoveryInterviewSteps,
        usabilityTestingSteps,
        surveySteps,
        longitudinalStudySteps,
        competitiveAnalysisSteps,
        audienceSteps,
        additionalInfoSteps,
      });
    }

    return validatedSteps;
  },
  validateProjectSetup(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const projectSetupSteps = stepperMenuUtils.projectSetupSubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, projectSetupSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      const keyObjectiveError = stepperMenuUtils.validateKeyObjective(askablePlusBrief);
      const projectContextError = stepperMenuUtils.validateProjectContext(askablePlusBrief);
      let projectSetupValidate = false;
      _.map(projectSetupSteps, (item: any) => {
        if (item.id === 'project_setup_project_brief') {
          stepsValidated.project_setup_key_objective = keyObjectiveError ? 'error' : 'seen';
          stepsValidated.project_setup_project_context = projectContextError ? 'error' : 'seen';
          stepsValidated.project_setup_project_brief = keyObjectiveError || projectContextError ? 'error' : 'seen';
          if (keyObjectiveError || projectContextError) projectSetupValidate = true;
        } else {
          stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
          if (item.validateStep) projectSetupValidate = true;
        }
      });
      stepsValidated.project_setup = projectSetupValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenProjectSetup(askablePlusBrief, stepsValidated, projectSetupSteps);
    }
    return stepsValidated;
  },
  validateSeenProjectSetup(askablePlusBrief: any, validatedSteps: any, projectSetupSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const projectSetupPreviousSteps = _.filter(projectSetupSteps, (item: any) => seenPages.includes(item.id));
    const keyObjectiveError = stepperMenuUtils.validateKeyObjective(askablePlusBrief);
    const projectContextError = stepperMenuUtils.validateProjectContext(askablePlusBrief);
    _.map(projectSetupPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'project_setup_project_brief') {
          validatedSteps.project_setup_key_objective = keyObjectiveError ? 'error' : 'seen';
          validatedSteps.project_setup_project_context = projectContextError ? 'error' : 'seen';
          validatedSteps.project_setup_project_brief = keyObjectiveError || projectContextError ? 'error' : 'seen';
        } else {
          validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        }
      }
    });
    if (_.get(validatedSteps, 'project_setup') === 'error') {
      let projectSetupValidate = false;
      _.map(projectSetupSteps, (item: any) => {
        if (item.id === 'additional_info_description') {
          if (keyObjectiveError || projectContextError) projectSetupValidate = true;
        } else if (item.validateStep) {
          projectSetupValidate = true;
        }
      });
      validatedSteps.project_setup = projectSetupValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateDiscoveryInterview(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const discoveryInterviewSteps = stepperMenuUtils.discoveryInterviewSubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, discoveryInterviewSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let discoveryInterviewValidate = false;
      _.map(discoveryInterviewSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) discoveryInterviewValidate = true;
      });
      stepsValidated.discovery_interview = discoveryInterviewValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenDiscoveryInterview(stepsValidated, discoveryInterviewSteps);
    }
    return stepsValidated;
  },
  validateSeenDiscoveryInterview(validatedSteps: any, discoveryInterviewSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const discoveryInterviewPreviousSteps = _.filter(discoveryInterviewSteps, (item: any) =>
      seenPages.includes(item.id),
    );
    _.map(discoveryInterviewPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'discovery_interview') === 'error') {
      let discoveryInterviewValidate = false;
      _.map(discoveryInterviewSteps, (item: any) => {
        if (item.validateStep) discoveryInterviewValidate = true;
      });
      validatedSteps.discovery_interview = discoveryInterviewValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateUsabilityTesting(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const usabilityTestingSteps = stepperMenuUtils.usabilityTestingSubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, usabilityTestingSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let usabilityTestingValidate = false;
      _.map(usabilityTestingSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) usabilityTestingValidate = true;
      });
      stepsValidated.usability_testing = usabilityTestingValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenUsabilityTesting(stepsValidated, usabilityTestingSteps);
    }
    return stepsValidated;
  },
  validateSeenUsabilityTesting(validatedSteps: any, usabilityTestingSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const usabilityTestingPreviousSteps = _.filter(usabilityTestingSteps, (item: any) => seenPages.includes(item.id));
    _.map(usabilityTestingPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'usability_testing') === 'error') {
      let usabilityTestingValidate = false;
      _.map(usabilityTestingSteps, (item: any) => {
        if (item.validateStep) usabilityTestingValidate = true;
      });
      validatedSteps.usability_testing = usabilityTestingValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateSurvey(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const surveySteps = stepperMenuUtils.surveySubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, surveySteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let surveyValidate = false;
      _.map(surveySteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) surveyValidate = true;
      });
      stepsValidated.survey = surveyValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenSurvey(stepsValidated, surveySteps);
    }
    return stepsValidated;
  },
  validateSeenSurvey(validatedSteps: any, surveySteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const surveyPreviousSteps = _.filter(surveySteps, (item: any) => seenPages.includes(item.id));
    _.map(surveyPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'survey') === 'error') {
      let surveyValidate = false;
      _.map(surveySteps, (item: any) => {
        if (item.validateStep) surveyValidate = true;
      });
      validatedSteps.survey = surveyValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateLongitudinalStudy(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const longitudinalStudySteps = stepperMenuUtils.longitudinalStudySubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, longitudinalStudySteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let longitudinalStudyValidate = false;
      _.map(longitudinalStudySteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) longitudinalStudyValidate = true;
      });
      stepsValidated.longitudinal_study = longitudinalStudyValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenLongitudinalStudy(stepsValidated, longitudinalStudySteps);
    }
    return stepsValidated;
  },
  validateSeenLongitudinalStudy(validatedSteps: any, longitudinalStudySteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const longitudinalStudyPreviousSteps = _.filter(longitudinalStudySteps, (item: any) => seenPages.includes(item.id));
    _.map(longitudinalStudyPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'longitudinal_study') === 'error') {
      let longitudinalStudyValidate = false;
      _.map(longitudinalStudySteps, (item: any) => {
        if (item.validateStep) longitudinalStudyValidate = true;
      });
      validatedSteps.longitudinal_study = longitudinalStudyValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateCompetitiveAnalysis(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const competitiveAnalysisSteps = stepperMenuUtils.competitiveAnalysisSubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, competitiveAnalysisSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let competitiveAnalysisValidate = false;
      _.map(competitiveAnalysisSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) competitiveAnalysisValidate = true;
      });
      stepsValidated.competitive_analysis = competitiveAnalysisValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenCompetitiveAnalysis(stepsValidated, competitiveAnalysisSteps);
    }
    return stepsValidated;
  },
  validateSeenCompetitiveAnalysis(validatedSteps: any, competitiveAnalysisSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const competitiveAnalysisPreviousSteps = _.filter(competitiveAnalysisSteps, (item: any) =>
      seenPages.includes(item.id),
    );
    _.map(competitiveAnalysisPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'competitive_analysis') === 'error') {
      let competitiveAnalysisValidate = false;
      _.map(competitiveAnalysisSteps, (item: any) => {
        if (item.validateStep) competitiveAnalysisValidate = true;
      });
      validatedSteps.competitive_analysis = competitiveAnalysisValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateAudience(askablePlusBrief: any, validatedSteps: any, isMultiRegion: boolean) {
    const audienceSteps = stepperMenuUtils.audienceSubSteps(askablePlusBrief, validatedSteps, isMultiRegion);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, audienceSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let audienceValidate = false;
      _.map(audienceSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) audienceValidate = true;
      });
      stepsValidated.audience = audienceValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenAudience(stepsValidated, audienceSteps);
    }
    return stepsValidated;
  },
  validateSeenAudience(validatedSteps: any, audienceSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const audiencePreviousSteps = _.filter(audienceSteps, (item: any) => seenPages.includes(item.id));
    _.map(audiencePreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'audience') === 'error') {
      let audienceValidate = false;
      _.map(audienceSteps, (item: any) => {
        if (item.validateStep) audienceValidate = true;
      });
      validatedSteps.audience = audienceValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateAdditionalInfo(askablePlusBrief: any, validatedSteps: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const additionalInfoSteps = stepperMenuUtils.additionalInfoSubSteps(askablePlusBrief);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, additionalInfoSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      let additionalInfoValidate = false;
      _.map(additionalInfoSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) additionalInfoValidate = true;
      });
      stepsValidated.additional_info = additionalInfoValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenAdditionalInfo(stepsValidated, additionalInfoSteps);
    }

    return stepsValidated;
  },
  validateSeenAdditionalInfo(validatedSteps: any, additionalInfoSteps: any) {
    const seenPages = Object.keys(validatedSteps);
    const additionalInfoPreviousSteps = _.filter(additionalInfoSteps, (item: any) => seenPages.includes(item.id));
    _.map(additionalInfoPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'additional_info') === 'error') {
      let additionalInfoValidate = false;
      _.map(additionalInfoSteps, (item: any) => {
        if (item.validateStep) additionalInfoValidate = true;
      });
      validatedSteps.additional_info = additionalInfoValidate ? 'error' : null;
    }

    return validatedSteps;
  },
  validateProjectTitle(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'name')) return true;
    return false;
  },
  validateKeyObjective(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.objective')) return true;
    return false;
  },
  validateProjectContext(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.description')) return true;
    return false;
  },
  validateProjectBrief(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.objective') || !_.get(askablePlusBrief, 'askable_plus.description')) {
      return true;
    }

    return false;
  },
  validateResearchType(askablePlusBrief: any) {
    const researchTypes: any = [];
    _.map(askablePlusUtils.researchTypes(), (researchType: any) => {
      const project = `askable_plus.research_type[${researchType.key}]`;
      if (_.get(askablePlusBrief, `${project}.quota`) || _.get(askablePlusBrief, `${project}.project_duration`)) {
        researchTypes.push(researchType.label);
      }
    });
    return _.size(researchTypes) === 0;
  },
  validateDeliverables(askablePlusBrief: any) {
    const deliverables: any = [];
    _.map(askablePlusUtils.deliverables(), (deliverable: any) => {
      if (_.get(askablePlusBrief, `askable_plus.deliverables[${deliverable.key}]`)) {
        deliverables.push(deliverable);
      }
    });
    return _.size(deliverables) === 0;
  },
  validateDueDate(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.due_date')) return true;
    return false;
  },
  validateGeneralQuota(askablePlusBrief: any) {
    const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
    const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
    const hasCompetitiveAnalysisType = askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief);
    const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);
    const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

    if (
      !hasDiscoveryInterviewType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.quota') >= 1 &&
      !hasUsabilityTestingType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota') >= 1 &&
      !hasCompetitiveAnalysisType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.competitive_analysis.quota') >= 1 &&
      !hasSurveyType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.survey.quota') >= 1 &&
      !hasLongitudinalStudyType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota') >= 1
    ) {
      return true;
    }

    return false;
  },
  validateGeneralSessionDuration(askablePlusBrief: any) {
    const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
    const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
    const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);
    const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

    if (hasLongitudinalStudyType) return stepperMenuUtils.validateStudyDuration(askablePlusBrief);
    if (
      !hasDiscoveryInterviewType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration') >= 1 &&
      !hasUsabilityTestingType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.usability.session.duration') >= 1 &&
      !hasSurveyType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration') >= 1
    ) {
      return true;
    }

    return false;
  },
  validateGeneralMeetingFormat(askablePlusBrief: any) {
    const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
    const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);

    if (
      !hasDiscoveryInterviewType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.booking_type') >= 1 &&
      !hasUsabilityTestingType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.usability.booking_type') >= 1
    ) {
      return true;
    }

    return false;
  },
  validateGeneralSessionFormat(askablePlusBrief: any) {
    const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);

    if (
      !hasDiscoveryInterviewType &&
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.type') >= 1
    ) {
      return true;
    }

    return false;
  },
  validateQuota(askablePlusBrief: any, key: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, `askable_plus.research_type[${key}].quota`) >= 1) return true;
    return false;
  },
  validateSessionDuration(askablePlusBrief: any, key: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, `askable_plus.research_type[${key}].session.duration`) >= 1) return true;
    return false;
  },
  validateStudyDuration(askablePlusBrief: any) {
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    // You cannot select a period frequency lower than the workload frequency
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
      1,
    );
    if (periodFrequency < workloadFrequency) return true;
    return false;
  },
  validateMeetingFormat(askablePlusBrief: any, key: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, `askable_plus.research_type[${key}].booking_type`) >= 1) return true;
    return false;
  },
  validateSessionFormat(askablePlusBrief: any, key: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, `askable_plus.research_type[${key}].session.type`) >= 1) return true;
    return false;
  },
  validateParticipantCriteria(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.audience.description')) return true;
    return false;
  },
  validateDemographicFiltersCustomScreener(askablePlusBrief: any) {
    if (
      _.size(_.get(askablePlusBrief, 'askable_plus.audience.booking_config.criteria.filters_order')) === 0 &&
      _.size(_.get(askablePlusBrief, 'askable_plus.audience.booking_config.question')) === 0
    ) {
      return true;
    }

    return false;
  },
  validateIncentives(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.audience.booking_config.participant_category')) return true;
    return false;
  },
  validateParticipantLocations(askablePlusBrief?: Project | null) {
    const bookingConfig = askablePlusBrief?.askable_plus?.audience?.booking_config;

    if (bookingConfig?.location?.country) {
      return false;
    }

    const locations = bookingConfig?.criteria?.locations;
    if (locations?.bounds?.length || locations?.states?.length || locations?.countries?.length) {
      return false;
    }

    return true;
  },
  validateParticipantLocationsMultiRegion(askablePlusBrief?: Project | null) {
    const bookingConfig = askablePlusBrief?.askable_plus?.audience?.booking_config;

    const locations = bookingConfig?.criteria?.locations;
    if (locations?.bounds?.length || locations?.states?.length || locations?.countries?.length) {
      return false;
    }

    return true;
  },
  validateLinkToAssets() {
    // if (_.size(_.get(askablePlusBrief, 'askable_plus.assets')) === 0) return true;
    return false;
  },
  validateModerationGuide(askablePlusBrief: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, 'askable_plus.additional_info.moderation_guide_type') >= 1) return true;
    return false;
  },
  validateYourTimezone(askablePlusBrief: any) {
    if (!_.get(askablePlusBrief, 'askable_plus.additional_info.timezone')) return true;
    return false;
  },
  validateLegalPrivacy(askablePlusBrief: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(askablePlusBrief, 'askable_plus.additional_info.nda_guide_type') >= 1) return true;
    return false;
  },
  validateAdditionalNotes() {
    return false;
  },
};

export { stepperMenuUtils };
