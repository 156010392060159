import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import React, { useState, useEffect } from 'react';

function ScheduleMessage(props: any) {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const timezone = _.get(props.booking, 'config.timezone');

  useEffect(() => {
    if (showFullMessage) props.onScrollToBottom();
  });

  return (
    <div className="scheduledMessage">
      <img alt="Reminder Icon" src="/icons/reminderIcon.svg" />
      <div className="scheduledMessageContent">
        <p className={`message ${showFullMessage ? 'full-message' : ''}`}>Reminder - {props.message}</p>
        <a
          onClick={() => {
            setShowFullMessage(!showFullMessage);
          }}
        >
          {showFullMessage ? 'Hide full message' : 'See full message'}
        </a>
        <p className="time">
          Scheduled for {momentTimezone(props.scheduledTime).tz(timezone).format('H:mm A DD MMM YYYY')} (
          {momentTimezone(props.scheduledTime).tz(timezone).fromNow()})
        </p>
      </div>
    </div>
  );
}

export default ScheduleMessage;
