import { cn } from '@askable/ui/lib/utils';
import { Click, Message } from './icons';
import { Eye } from 'lucide-react';
import { ReactNode } from 'react';

interface InfoAlertProps {
  description?: string;
  title?: string;
  type: 'instructions' | 'feedback' | 'view-only';
  centerAlign?: boolean;
  children?: ReactNode;
}

export const InfoAlert = ({
  description,
  title,
  type = 'instructions',
  centerAlign = false,
  children,
}: InfoAlertProps) => {
  return (
    <div
      className={cn('flex w-fit gap-2 text-pretty rounded-lg border-0 bg-background-subtle p-4', {
        'bg-info-foreground': type === 'view-only',
      })}
    >
      <div className={cn('flex gap-4', { 'items-center': centerAlign })}>
        <div className="scale-x-100 transform">
          {type === 'instructions' ? <Click size={40} /> : null}
          {type === 'feedback' ? <Message size={40} /> : null}
          {type === 'view-only' ? <Eye className="h-8 w-8" /> : null}
        </div>
        <div className="flex flex-col gap-1">
          {title && <div className="font-semibold">{title}</div>}
          {description && <div className="text-balance text-sm">{description}</div>}
          {children && children}
        </div>
      </div>
    </div>
  );
};
