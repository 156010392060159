import { Query } from '@apollo/client/react/components';
import _ from 'lodash';
import React, { Fragment } from 'react';

import { LoadingOverlay, Button, Dialog } from 'components/common';
import fetchBookingSubmissionWithParticipantExclusion from 'data/queries/bookingSubmission/findBookingSubmissionWithParticipantExclusion';
import { bookingUtils } from 'lib/booking';

import ConfirmationMessageInvitationDialog from './confirmationMessage';
import InvitationDialogContentContainer from './contentContainer';

const InviteParticipant = (props: any) => {
  let sessionAssigned = _.get(props, 'singleSession.session');
  const { isAvailableForSession } = props.singleSession;
  let { participant } = props.singleSession;
  if (props.isBulkInvite && _.size(props.bulkSession.participants) > 0) {
    participant = _.get(props, 'bulkSession.participants[0]');
  }

  if (bookingUtils.isOnlineTask(props.booking) || bookingUtils.isLongitudinal(props.booking)) {
    [sessionAssigned] = props.booking.session;
  }

  const renderInviteTitle = (previousAttendance: any) => {
    if (props.isBulkInvite && _.size(props.bulkSession.participants) > 0) {
      return (
        <p>
          Invite <strong>all {_.size(props.bulkSession.participants)}</strong> participants?
        </p>
      );
    }
    if (previousAttendance) {
      return (
        <p>
          You can't invite {_.get(participant, 'user.meta.identity.firstname')} to this study because{' '}
          {_.get(participant, 'user.meta.identity.gender') === 'male' ? 'he' : 'she'}'s been selected for another
          session already.
        </p>
      );
    }
    return (
      <p>
        Invite {_.get(participant, 'user.meta.identity.firstname')} {_.get(participant, 'user.meta.identity.lastname')}
        {!bookingUtils.isOnlineTask(props.booking) && !bookingUtils.isLongitudinal(props.booking)
          ? ' for this session?'
          : '?'}
      </p>
    );
  };

  const renderButtonsContainer = (previousAttendance: any) => {
    // If it's not inviting multiple people on and it has previously attended a session for the client, it should display a message and an OKAY button to dismiss
    if (!props.isBulkInvite && previousAttendance) {
      return (
        <a onClick={props.onCloseInvitationDialog} className="link">
          OKAY
        </a>
      );
    }
    return (
      <Button
        label={`${props.isBulkInvite ? `Send [${_.size(props.bulkSession.participants)}] Invites` : 'Send Invite'}`}
        variant="default"
        size="default"
        className="btnSendInvite mtop20"
        onClick={props.onConfirm}
      />
    );
  };

  return (
    <Dialog
      open={props.open}
      modal={false}
      onRequestClose={props.onCloseInvitationDialog}
      styles={{ width: 300 }}
      className="inviteParticipantsContainer"
    >
      <Query
        query={fetchBookingSubmissionWithParticipantExclusion}
        variables={{ userId: _.get(participant, 'user._id'), bookingId: props.booking._id }}
        fetchPolicy="cache-and-network"
        client={props.client}
      >
        {({ loading, data }: any) => {
          const previousAttendance = _.get(data, 'findBookingSubmission[0].previousAttendance[0]');
          return (
            <Fragment>
              {(props.loading || loading) && <LoadingOverlay style={{ opacity: 0.8 }} />}
              {props.showConfirmationMessage ? (
                <ConfirmationMessageInvitationDialog {...props} />
              ) : (
                <Fragment>
                  {renderInviteTitle(previousAttendance)}
                  <InvitationDialogContentContainer
                    participant={participant}
                    session={sessionAssigned}
                    previousAttendance={previousAttendance}
                    isAvailableForSession={isAvailableForSession}
                    {...props}
                  />
                  <div className="buttonsContainer">{renderButtonsContainer(previousAttendance)}</div>
                </Fragment>
              )}
            </Fragment>
          );
        }}
      </Query>
    </Dialog>
  );
};

export default InviteParticipant;
