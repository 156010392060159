import { AnchorButton, Button } from '@askable/ui/components/ui/button';
import { TooltipTrigger, Tooltip, TooltipContent } from '@askable/ui/components/ui/tooltip';
import { ArrowUpRight, Calendar, Info, MessageCircle, Settings } from 'lucide-react';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonBadge, ButtonBadgeGroup } from 'ui';
import { useQuery } from 'urql';

import { useBooking } from 'containers/Booking/BookingContainer';
import { useMessagesSeenSubscriptionSubscription, useMessagesSubscriptionSubscription } from 'generated/graphql';
import { BOOKING_TYPE, MESSAGE_DIRECTION } from 'lib/constants';

import { UnreadMessages } from '../data/UnreadMessages.query';
import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';

import type { FC } from 'react';
import type { StackProps } from 'ui';

type Props = StackProps & {
  bookingType: number;
  bookingName: string;
  bookingId: string;
};

type MessagesButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

const InsightLinkButton = () => {
  const booking = useBooking();

  if (!booking?.config?.remote?.askable_live) {
    return null;
  }

  return (
    <AnchorButton
      target="_blank"
      rel="noopener noreferrer"
      href={`/askable-sessions/insights?teamId=${booking?._team_id}`}
      variant="ghost"
    >
      Insights
      <ArrowUpRight className="h-4 w-4" />
    </AnchorButton>
  );
};

const MessagesButton: FC<MessagesButtonProps> = memo(({ onClick }) => {
  const params = useParams<{ bookingId: string }>();

  const [unreadMessages, setUnreadMessages] = useState<string[]>([]);

  const [{ data }] = useQuery({
    query: UnreadMessages,
    requestPolicy: 'cache-and-network',
    pause: !params.bookingId,
    variables: {
      bookingId: params.bookingId!,
    },
  });

  useEffect(() => {
    if (data && data.unreadMessages.length > 0) {
      setUnreadMessages(data.unreadMessages ?? []);
    }
  }, [data]);

  useMessagesSubscriptionSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        subscriptionData.data?.messagesSubscription?.direction !== MESSAGE_DIRECTION.PARTICIPANT_TO_CLIENT ||
        subscriptionData.data?.messagesSubscription?.seen !== null
      ) {
        return;
      }

      setUnreadMessages((prev) => {
        return [...prev, subscriptionData.data?.messagesSubscription?._id!];
      });
    },
    variables: {
      filter: {
        _booking_id: params.bookingId,
      },
    },
  });

  useMessagesSeenSubscriptionSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      setUnreadMessages((prev) => {
        return prev.filter((a) => !subscriptionData.data?.messagesSeenSubscription?.find((b) => b?._id === a));
      });
    },
    variables: {
      _messages_id: unreadMessages,
    },
  });

  return (
    <ButtonBadgeGroup shouldShow={unreadMessages.length >= 1}>
      <Button variant="ghost" onClick={onClick}>
        <MessageCircle className="h-5 w-5" />
        <span className="hidden md:inline-block">Messages</span>
      </Button>
      <ButtonBadge alignItems="center" background="brand.500" justifyContent="center" border="none">
        <div className="font-xs text-destructive-foreground">
          {unreadMessages.length > 9 ? '9+' : unreadMessages.length}
        </div>
      </ButtonBadge>
    </ButtonBadgeGroup>
  );
});

export const bookingRoutesNames = {
  overview: 'overview',
  participants: 'participants',
  messages: 'messages',
  calendar: 'calendar',
  settings: 'settings',
} as const;

export const BookingParticipantsNavigation: FC<Props> = memo(({ bookingType, bookingName, children, bookingId }) => {
  const showCalendarButton = bookingType === BOOKING_TYPE.REMOTE || bookingType === BOOKING_TYPE.IN_PERSON;
  const setSidePanel = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
  const panelState = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);
  const navigate = useNavigate();

  const handleInfoClick = () => {
    if (panelState?.panel === 'booking-details') {
      setSidePanel(null);
      return;
    }

    setSidePanel({
      panel: 'booking-details',
      type: 'single',
    });
  };

  const handleSettingsClick = () => {
    navigate(`/bookings/${bookingId}/settings`);
  };

  const handleCalendarClick = () => {
    navigate(`/bookings/${bookingId}/calendar`);
  };

  const handleMessagesClick = () => {
    navigate(`/bookings/${bookingId}/messages`);
  };

  return (
    <div className="shrink-1 flex w-full flex-col overflow-hidden">
      <div
        className="flex flex-col gap-2 border-b border-border bg-background-subtle py-2 pl-4 pr-2 md:flex-row md:items-center
          md:justify-between md:px-4"
      >
        <div className="flex items-center gap-2">
          <h3 className="font-semibold">Studies / {bookingName}</h3>

          <div className="hidden md:block">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" aria-label="Open booking settings" onClick={handleSettingsClick}>
                  <Settings className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Settings</TooltipContent>
            </Tooltip>
          </div>
        </div>

        <div className="flex justify-between gap-2">
          <div className="flex gap-1">
            <div className="block md:hidden">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon" aria-label="Open booking settings" onClick={handleSettingsClick}>
                    <Settings className="h-5 w-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">Settings</TooltipContent>
              </Tooltip>
            </div>

            {showCalendarButton ? (
              <Button variant="ghost" onClick={handleCalendarClick} className="aspect-square md:aspect-auto">
                <Calendar className="h-5 w-5" />
                <span className="hidden md:inline-block">Calendar</span>
              </Button>
            ) : null}

            <MessagesButton onClick={handleMessagesClick} isOpen />

            <div className="hidden h-full w-[1px] bg-border md:mx-2 md:block" />

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  aria-label={panelState ? 'Close booking overview' : 'Open booking overview'}
                  variant="ghost"
                  size="icon"
                  onClick={handleInfoClick}
                >
                  <Info className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom" align="end">
                Overview
              </TooltipContent>
            </Tooltip>
          </div>
          <InsightLinkButton />
        </div>
      </div>
      {children}
    </div>
  );
});
