import { gql } from '@apollo/client';

import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export default gql`
  query FetchUsersByTeamId($_id: ID) {
    teamById(_id: $_id) {
      ...teamFields
    }
  }
  ${teamFieldsFragment}
`;
