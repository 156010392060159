import { gql } from '@apollo/client';

import { projectFieldsFragment } from 'data/fragments/project/projectFields';

export default gql`
  query FetchProjectById($id: ID) {
    projectByID(id: $id) {
      ...projectFields
    }
  }
  ${projectFieldsFragment}
`;
