import { Button } from '@askable/ui/components/ui/button';
import { Select, SelectContent, SelectTrigger } from '@askable/ui/components/ui/select';
import _ from 'lodash';
import { Check, Trash } from 'lucide-react';
import { useState, useEffect } from 'react';

import './styles/industryDropdown.scss';

function IndustryDropdown(props: any) {
  const [popoverType, setPopoverType] = useState('industry');
  const [industrySelected, setIndustrySelected] = useState();
  const [rolesSelected, setRolesSelected] = useState([]);

  useEffect(() => {
    const { industrySaved } = props;
    if (_.get(industrySaved, '_industry_id')) {
      setIndustrySelected(industrySaved);
    }
    if (_.size(_.get(industrySaved, 'subcategories')) > 0) {
      setRolesSelected(industrySaved.subcategories);
    }
  }, [props.industrySaved._industry_id]);

  function handleOnClickPopover(event: any, popoverTypeSelected: any) {
    event.preventDefault();

    setPopoverType(popoverTypeSelected);
  }

  function normaliseIndustry(industry: any) {
    return {
      _industry_id: industry._id ? industry._id : industry._industry_id,
      industry_name: industry.name ? industry.name : industry._industry_name,
    };
  }

  function normaliseSubcategory(subcategory: any) {
    return {
      _subcategory_id: subcategory._id ? subcategory._id : subcategory._subcategory_id,
      subcategory_name: subcategory.name ? subcategory.name : subcategory.subcategory_name,
    };
  }

  function renderContent(data: any, index: any) {
    let isSelected = false;
    let hasBeenSavedAlready = false;

    // Check if industry is selected
    if (popoverType === 'industry' && data._id === _.get(industrySelected, '_industry_id')) {
      isSelected = true;
    }
    // Check if it should disable the industry
    if (
      popoverType === 'industry' &&
      isSelected === false &&
      _.size(_.filter(props.industriesSaved, (industry: any) => industry._industry_id === data._id)) > 0
    ) {
      hasBeenSavedAlready = true;
    }
    // Check if the role is selected
    if (
      popoverType === 'roles' &&
      _.size(_.filter(rolesSelected, (role: any) => role._subcategory_id === data._id)) > 0
    ) {
      isSelected = true;
    }
    return (
      <div
        key={`${data._industry_id}-${index}`}
        className={`flex cursor-pointer items-center gap-2 p-1 hover:bg-accent ${hasBeenSavedAlready ? 'text-muted-foreground' : ''}`}
        onClick={() => {
          if (popoverType === 'industry') {
            if (hasBeenSavedAlready) return;

            const normalisedIndustry = normaliseIndustry(data);
            // Set the industry selected
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _industry_id: any; industry_na... Remove this comment to see the full error message
            setIndustrySelected(normalisedIndustry);

            // Reset roles in case the user is changing the industry
            setRolesSelected([]);

            // Return the selected options to the parent component
            props.onFinishIndustrySelection({
              industry: normalisedIndustry,
              roles: [],
            });
          }
          if (popoverType === 'roles') {
            let newRolesSelected;
            // If the role is already selected, then it should remove it
            if (isSelected) {
              newRolesSelected = _.filter(rolesSelected, (role: any) => role._subcategory_id !== data._id);
            } else {
              newRolesSelected = _.clone(rolesSelected);
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
              newRolesSelected.push(data);
              newRolesSelected = _.map(newRolesSelected, (item: any) => normaliseSubcategory(item));
            }
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
            setRolesSelected(newRolesSelected);

            // Return the selected options to the parent component
            props.onFinishIndustrySelection({
              industry: industrySelected,
              roles: newRolesSelected,
            });
          }
        }}
      >
        <div>{data.name}</div>
        {isSelected ? <Check className="h-4 w-4" /> : null}
      </div>
    );
  }

  const hasSelectedIndustry = _.has(industrySelected, '_industry_id');
  const hasSelectedRoles = _.size(rolesSelected) > 0;

  return (
    <div className="mb-2 flex w-full items-center gap-2">
      <Select
        defaultValue={industrySelected}
        onValueChange={(value: any) => handleOnClickPopover(value, 'industry')}
        onOpenChange={() => setPopoverType('industry')}
      >
        <SelectTrigger className="overflow-hidden">
          {industrySelected ? (industrySelected as any).industry_name : 'Select an industry'}
        </SelectTrigger>
        <SelectContent>
          {_.map(props.industries, (industry: any, index: any) => renderContent(industry, index))}
        </SelectContent>
      </Select>
      <Select
        defaultValue={rolesSelected[0]}
        onValueChange={(value: any) => handleOnClickPopover(value, 'roles')}
        onOpenChange={() => setPopoverType('roles')}
        disabled={!hasSelectedIndustry}
      >
        <SelectTrigger className="overflow-hidden">
          {hasSelectedRoles
            ? `(${rolesSelected.length}) ${_.map(rolesSelected, (role: any) => role.subcategory_name).join(', ')}`
            : 'Any role'}
        </SelectTrigger>
        <SelectContent>
          {industrySelected
            ? _.map(
                _.find(props.industries, (industry: any) => industry._id === (industrySelected as any)._industry_id)
                  .subcategories,
                (roles: any, index: any) => renderContent(roles, index),
              )
            : null}
        </SelectContent>
      </Select>
      {props.onRemoveIndustry ? (
        <Button variant="ghost" onClick={props.onRemoveIndustry}>
          <Trash className="h-4 w-4" />
        </Button>
      ) : null}
    </div>
  );
}
export default IndustryDropdown;
