import { AnchorButton, Button as ShadButton } from '@askable/ui/components/ui/button';

import type { ButtonVariants } from '@askable/ui/components/ui/button';
import type { ReactNode } from 'react';

/**
 * Wrapper for the Button component
 * @todo deprecate this once all buttons in the app have been replaced with @askable/ui/components/ui/button
 */

type ButtonProps = {
  className?: string;
  disabled?: boolean;
  icon?: ReactNode;
  id?: string;
  label?: ReactNode;
  onClick?: any;
  size?: ButtonVariants['size'];
  variant?: ButtonVariants['variant'];
};

type AnchorButtonProps = ButtonProps & {
  href: string;
  target?: '_blank' | string;
};

type ButtonButtonProps = ButtonProps & {
  type?: 'button' | 'submit';
};

const Button = (props: AnchorButtonProps | ButtonButtonProps) => {
  const { id, className, disabled, icon, label, onClick, size = 'lg', variant = 'primary' } = props;

  if ('href' in props) {
    const { href, target } = props;
    return (
      <AnchorButton
        className={className}
        disabled={disabled}
        href={href}
        id={id}
        onClick={onClick}
        size={size}
        target={target ?? undefined}
        variant={variant}
      >
        {icon}
        {label}
      </AnchorButton>
    );
  }

  const { type } = props;
  return (
    <ShadButton
      className={className}
      disabled={disabled}
      id={id}
      onClick={onClick}
      size={size}
      type={type}
      variant={variant}
    >
      {icon}
      {label}
    </ShadButton>
  );
};

export default Button;
