import { Button } from '@askable/ui/components/ui/button';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Heading, VStack, Text } from 'ui';

import type { FC, PropsWithChildren } from 'react';

type Props = {
  // Define the error boundary location
  location: string;
};

export const ErrorBoundaryPssTable: FC<PropsWithChildren<Props>> = ({ children }) => {
  const intercom = useIntercom();
  const navigate = useNavigate();

  const fallback = () => {
    const ddContext = datadogRum.getInternalContext();

    const handleReload = () => {
      intercom.hide();

      navigate(0);
    };

    const handleSupportClick = () => {
      intercom.showNewMessages(`I'm experiencing an issue with my booking.\n\n Event ID: ${ddContext?.view?.id}`);
    };

    return (
      <VStack spacing={8} mt="40">
        <Heading>An unknown error has occurred</Heading>
        <VStack spacing={1}>
          <Text>Try refreshing your browser.</Text>
          <Text className="flex items-center">
            If this doesn't fix your issue{' '}
            <Button variant="link" size="lg" className="-ml-2" onClick={handleSupportClick}>
              contact support.
            </Button>
          </Text>
        </VStack>
        <Button onClick={handleReload} variant="primary">
          Refresh browser
        </Button>
      </VStack>
    );
  };

  return <ErrorBoundary fallbackRender={fallback}>{children}</ErrorBoundary>;
};
